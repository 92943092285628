import type { SVGBaseProps } from '@/types/common'
import {
  FileAiIcon,
  FileBmpIcon,
  FileCorrupted,
  FileCsvIcon,
  FileDocIcon,
  FileDocxIcon,
  FileDxfIcon,
  FileGifIcon,
  FileHeicIcon,
  FileJpegIcon,
  FileJpgIcon,
  FileOdgIcon,
  FileOdpIcon,
  FileOdsIcon,
  FileOdtIcon,
  FilePdfIcon,
  FilePngIcon,
  FilePptIcon,
  FilePsdIcon,
  FileSvgIcon,
  FileTifIcon,
  FileTxtIcon,
  FileVsdIcon,
  FileVsdxIcon,
  FileXlsIcon,
  FileXlsxIcon
} from '@/components/icon'

export const allowedTypes = [
  // Image formats
  'image/jpeg', // JPG/JPEG
  'image/png', // PNG
  'image/heic', // HEIC
  'image/webp', // WebP
  'image/gif', // GIF
  'image/bmp', // BMP

  // Document formats
  'application/pdf', // PDF
  'application/msword', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'text/csv', // CSV
  'application/vnd.ms-powerpoint', // PPT
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
  'text/plain' // TXT
]

export const ACCEPTED_IMAGE_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  WEBP: 'image/webp',
  GIF: 'image/gif',
  BMP: 'image/bmp'
} as const

export const ACCEPTED_IMAGE_FORMATS = Object.values(ACCEPTED_IMAGE_TYPES).join(',')

export const isDocument = (file: File) => allowedTypes.includes(file.type) && !file.type.startsWith('image/')

export const documentIconMap: Record<string, (props: SVGBaseProps) => JSX.Element> = {
  'image/jpg': FileJpgIcon,
  'image/jpeg': FileJpegIcon,
  'image/png': FilePngIcon,
  'application/pdf': FilePdfIcon,
  'application/msword': FileDocIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileDocxIcon,
  'application/vnd.ms-excel': FileXlsIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileXlsxIcon,
  'application/vnd.ms-powerpoint': FilePptIcon,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': FilePptIcon,
  'application/vnd.visio': FileVsdIcon,
  'application/vnd.ms-visio.drawing, application/vnd.ms-visio.viewer': FileVsdxIcon,
  'application/vnd.oasis.opendocument.text': FileOdtIcon,
  'application/vnd.oasis.opendocument.spreadsheet': FileOdsIcon,
  'application/vnd.oasis.opendocument.presentation': FileOdpIcon,
  'application/vnd.oasis.opendocument.graphics': FileOdgIcon,
  'application/dxf': FileDxfIcon,
  'application/ai': FileAiIcon,
  'application/psd': FilePsdIcon,
  'image/gif': FileGifIcon,
  'image/bmp': FileBmpIcon,
  'image/tiff': FileTifIcon,
  'image/svg+xml': FileSvgIcon,
  'text/plain': FileTxtIcon,
  'text/csv': FileCsvIcon,
  'image/heic': FileHeicIcon,
  'corrupted file': FileCorrupted
}

import { useMemo } from 'react'
import { ChevronFirst, ChevronLast, ChevronLeft, ChevronRight } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { rowPrePageOptions } from '@/constants/pagination'
import { cn } from '@/utils/classnames'
import { usePagination } from '@/hooks/usePagination'
import { useQueryParamsHandler } from '@/hooks/useQueryParamsHandler'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/base'

interface PaginationProps {
  className?: string
  totalCount: number
  isMinMode?: boolean
  prePageLabel?: string
}

export function Pagination({ className, totalCount, isMinMode = false, prePageLabel }: PaginationProps) {
  const { t } = useTranslation()
  const { onUrlUpdate } = useQueryParamsHandler()
  const { limit, page, offset } = usePagination()
  const totalPages = Math.ceil(totalCount / limit)
  const pageActions = useMemo(() => {
    return isMinMode
      ? [
          { action: 'prev', icon: <ChevronLeft />, value: page - 1, disable: page <= 1 },
          { action: 'next', icon: <ChevronRight />, value: page + 1, disable: page >= totalPages }
        ]
      : [
          { action: 'first', icon: <ChevronFirst />, value: 1, disable: page <= 1 },
          { action: 'prev', icon: <ChevronLeft />, value: page - 1, disable: page <= 1 },
          { action: 'next', icon: <ChevronRight />, value: page + 1, disable: page >= totalPages },
          { action: 'last', icon: <ChevronLast />, value: totalPages, disable: page >= totalPages }
        ]
  }, [isMinMode, page, totalPages])

  const handleChangeLimit = (selected: string) => {
    onUrlUpdate({ page: '1', limit: selected }, ['page', 'limit'])
  }

  const handleChangePage = (selected: string) => {
    onUrlUpdate({ page: selected, limit: `${limit}` }, ['page', 'limit'])
  }

  return (
    totalCount > 0 && (
      <div className={cn('flex items-center gap-4 self-end text-sm max-sm:w-full max-sm:flex-col', className)}>
        {/* limit picker */}
        <div className={'flex items-center gap-x-2'}>
          <span className={'whitespace-nowrap text-grey'}>{prePageLabel || t('RowsPerPage')}</span>
          <Select defaultValue={`${limit}`} onValueChange={(value) => handleChangeLimit(value)} value={`${limit}`}>
            <SelectTrigger className={'h-6 border border-grey bg-transparent px-1 text-grey'}>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {rowPrePageOptions.map((item) => (
                <SelectItem key={item} value={`${item}`}>
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* current range */}
        <span className={'min-w-[80px] text-center text-grey'}>
          {`${Math.min(offset + 1, totalCount)}-${Math.min(offset + limit, totalCount)} of ${totalCount}`}
        </span>

        {/* page picker */}
        <div className={'flex items-center gap-x-1'}>
          {pageActions.map((item) => (
            <Button
              className={'border-gray-200'}
              key={item.action}
              title={item.action}
              onClick={() => handleChangePage(`${item.value}`)}
              disabled={item.disable}
            >
              {item.icon}
            </Button>
          ))}
        </div>
      </div>
    )
  )
}

import { Encryption } from "../database/encryption";
import { IVSaltFieldKey } from "../encryption/utils";
import { PermissionCategory } from "../refPaths";
import { OmitKeys } from "../utils";

export enum PermissionLevel {
  None = 0,
  View = 1,
  Edit = 2,
  Create = 3,
  Delete = 4,
}

export type Delegate = {
  // NOTE: The keys are the PermissionCategory for Firebase rule matching, and may be therefore uppercased
  [PermissionCategory.Finance]: PermissionLevel;
  [PermissionCategory.Insurance]: PermissionLevel;
  [PermissionCategory.Property]: PermissionLevel;
  [PermissionCategory.Art]: PermissionLevel;
  [PermissionCategory.Wine]: PermissionLevel;
  [PermissionCategory.OtherCollectables]: PermissionLevel;
  [PermissionCategory.Belonging]: PermissionLevel;
  listProperty: PermissionLevel;
  maxPermission: PermissionLevel;
  status: "active" | "inactive";
  // @Encrypted
  delegatorName: string; // To be encrypted by the delegatee
  // @Encrypted
  email: string; // Should be encrypted by the delegator
  // @Encrypted
  role: string; // Should be encrypted by the delegator
  // @Encrypted
  name: string; // Should be encrypted by the delegator
};

export namespace Delegate {
  export const EncryptionFieldKeyDelegator = "_encryptedDelegator";
  export const EncryptionFieldKeyDelegatee = "_encryptedDelegatee";

  export interface EncryptionFieldDelegator {
    [EncryptionFieldKeyDelegator]: { data: string;[IVSaltFieldKey]: string };
  }

  export interface EncryptionFieldDelegatee {
    [EncryptionFieldKeyDelegatee]: {
      data: string;
      [IVSaltFieldKey]: string;
    };
  }

  export type EncryptedKeysDelegator = keyof Pick<
    Delegate,
    "email" | "role" | "name"
  >;
  export type EncryptedKeysDelegatee = keyof Pick<Delegate, "delegatorName">;

  export type EncryptedDelegator = OmitKeys<Delegate, EncryptedKeysDelegator> &
    EncryptionFieldDelegator;
  export type EncryptedDelegatee = OmitKeys<Delegate, EncryptedKeysDelegatee> &
    EncryptionFieldDelegatee;
  export type EncryptedBoth = OmitKeys<
    Delegate,
    EncryptedKeysDelegatee | EncryptedKeysDelegator
  > &
    EncryptionFieldDelegator &
    EncryptionFieldDelegatee;

  export type EncryptedPartDelegator = Pick<Delegate, EncryptedKeysDelegator>;
  export type EncryptedPartDelegatee = Pick<Delegate, EncryptedKeysDelegatee>;

  export const encryptedKeysArrayDelegator: readonly (keyof EncryptedPartDelegator)[] =
    ["email", "role", "name"] as const;

  export const encryptedKeysArrayDelegatee: readonly (keyof EncryptedPartDelegatee)[] =
    ["delegatorName"] as const;

  export async function encryptByDelegator(
    rawData: Delegate | EncryptedDelegatee,
    encryption: Encryption
  ): Promise<EncryptedDelegator | EncryptedBoth> {
    const EncryptedPart: Pick<Delegate, any> = <any>{};

    encryptedKeysArrayDelegator.forEach((key) => {
      EncryptedPart[key] = rawData[key] as any;
      delete rawData[key];
    });
    const iv = encryption.generateNewIVSalt();
    return {
      ...rawData,
      [EncryptionFieldKeyDelegator]: {
        data: await encryption.encryptAndStringify(EncryptedPart, iv),
        [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
      },
    };
  }

  export async function encryptByDelegatee(
    rawData: EncryptedDelegator,
    encryption: Encryption
  ): Promise<EncryptedBoth> {
    const EncryptedPart: Pick<EncryptedDelegator, any> = <any>{};
    const newData: Partial<EncryptedDelegator> = { ...rawData };

    encryptedKeysArrayDelegatee.forEach((key) => {
      EncryptedPart[key] = rawData[key] as any;
      delete newData[key];
    });

    const iv = encryption.generateNewIVSalt();
    return {
      ...newData,
      [EncryptionFieldKeyDelegatee]: {
        data: await encryption.encryptAndStringify(EncryptedPart, iv),
        [IVSaltFieldKey]: encryption.convertIVSaltToBase64(iv),
      },
    } as EncryptedBoth;
  }

  export async function decryptByDelegator(
    data: EncryptedBoth | EncryptedDelegator,
    encryption: Encryption
  ): Promise<EncryptedDelegatee | Delegate> {
    const decrypted: Pick<
      EncryptedDelegatee | Delegate,
      EncryptedKeysDelegator
    > = await encryption.decryptAndStringify(
      data[EncryptionFieldKeyDelegator]["data"],
      encryption.convertBase64ToIVSalt(
        data[EncryptionFieldKeyDelegator][IVSaltFieldKey]
      )
    );
    const { [EncryptionFieldKeyDelegator]: _, ...rest } = data;

    return <EncryptedDelegatee | Delegate>{
      ...rest,
      ...decrypted,
    };
  }

  export async function decryptByDelegatee(
    data: EncryptedBoth | EncryptedDelegatee,
    encryption: Encryption
  ): Promise<EncryptedDelegator | Delegate> {
    try {
      const encryptedPart: Pick<
        EncryptedDelegator | Delegate,
        EncryptedKeysDelegatee
      > = await encryption.decryptAndStringify(
        data[EncryptionFieldKeyDelegatee]["data"],
        encryption.convertBase64ToIVSalt(
          data[EncryptionFieldKeyDelegatee][IVSaltFieldKey]
        )
      );
      const { [EncryptionFieldKeyDelegatee]: _, ...rest } = data;
      return <EncryptedDelegator | Delegate>{
        ...rest,
        ...encryptedPart,
      };
    } catch (e) {
      console.error(e);
      throw new Error("Failed to decrypt delegate data");
    }
  }
}

export type DelegateInvite = {
  ownerId: string; // The user who sent the invite
  email: string; // Do NOT encrypt this.  We need this for responding to the invite
  status: "pending" | "rejected" | "expired"; // We directly convert the invite into a delegate on acceptance
  expiration?: Date;
  permissions: Delegate.EncryptedDelegator;
};

export type DelegateData = {
  [userID: string]: Delegate.EncryptedBoth;
};

export const defaultDelegate: Delegate = {
  [PermissionCategory.Finance]: PermissionLevel.None,
  [PermissionCategory.Insurance]: PermissionLevel.None,
  [PermissionCategory.Property]: PermissionLevel.None,
  [PermissionCategory.Art]: PermissionLevel.None,
  [PermissionCategory.Wine]: PermissionLevel.None,
  [PermissionCategory.OtherCollectables]: PermissionLevel.None,
  [PermissionCategory.Belonging]: PermissionLevel.None,
  listProperty: PermissionLevel.None,
  maxPermission: PermissionLevel.None,
  status: "active",
  delegatorName: "",
  name: "",
  role: "",
  email: "",
};
import {
  OmitKeys,
  UpdateObject,
  AllowedDecimalPlaces,
  addDecimal,
  calculatePercentage,
  subDecimal,
} from "../utils";
import { AggregateBase, AggregateRoot, IAggregateData } from "./aggregate";
import {
  Amount,
  AssetType,
  Categories,
  categoriesFromAssetType,
  Optional,
  TargetCurrencyExchangeRateDataMap,
} from "./common";
import { Account, Event as AccountEventInner } from "./cashAndBanking";
import { AccountMin } from "./cashAndBanking/institution";
import { EventEnvelope } from "./event";
import { CurrentAccount } from "./cashAndBanking/currentAccount";
import Decimal from "decimal.js";
import { Cash } from "./cashAndBanking/cash";
import { CreditCard } from "./cashAndBanking/creditCard";
import { DocumentReference, Transaction } from "../../coreFirebase";
import {
  CashAndBankingSummaryTypeVersion,
  VersionedType,
  VersionedTypeString,
  assureSummaryVersion,
  validateTypeUpToDate,
} from "./typeVersion";
import {
  RelationsOfAsset,
  RoleToAsset,
  fromRelationsOfAsset,
  isRole,
} from "./relations";
import { DataPoisoned } from "./error";
import { ArtSummary } from "./artSummary";
import { PropertySummary } from "./propertySummary";
import { WineSummary } from "./wineSummary";
import { BelongingSummary } from "./belongingSummary";

export type Event = EventEnvelope<AccountEventInner>;

export type SupportLiabilityType =
  | AssetType.Property
  | AssetType.Art
  | AssetType.WineAndSpirits
  | AssetType.OtherCollectables
  | AssetType.Belonging;

export const supportLiabilityTypes: SupportLiabilityType[] = [
  AssetType.Property,
  AssetType.Art,
  AssetType.WineAndSpirits,
  AssetType.OtherCollectables,
  AssetType.Belonging,
];

//#NOTE this summary provides summary page data, liabilities of all assets and institutionId
export interface CashAndBankingSummary extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.CashAndBankingSummary, 2>;
  id: AssetType.CashAndBanking;

  accounts: {
    [id: string]: OmitKeys<AccountMin, "name"> & { institution: string };
  };
}

export namespace CashAndBankingSummary {
  export function assureVersion(
    input: CashAndBankingSummary,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      CashAndBankingSummaryTypeVersion,
      errorOnCoreOutDated
    );
  }

  export interface Display {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    institutions: {
      assets: DisplayItem[];
      liabilities: DisplayItem[];
    };
  }
  interface DisplayItem {
    name: string;
    subtypes: {
      originValue: Amount;
      value: Amount;
      percentage: number;
    }[];
    value: Amount;
    percentage: number;
  }

  export function toDisplay(
    input: CashAndBankingSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap
  ): Display {
    const currency = exchangeRate.targetCurrency;
    let assets = new Decimal(0);
    let liabilities = new Decimal(0);
    const assetsMap: { [institutionName: string]: DisplayItem } = {};
    const liabilitiesMap: { [institutionName: string]: DisplayItem } = {};

    Object.entries(input.accounts).forEach(([_id, account]) => {
      const subAcctIdToBaseValueMap: { [subAcctId: string]: Decimal } = {};
      let totalValue = account.subAccounts
        .reduce((sum, v) => {
          subAcctIdToBaseValueMap[v.id] = new Decimal(v.balance.value)
            .mul(exchangeRate.rates[v.balance.currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces);
          return sum.add(subAcctIdToBaseValueMap[v.id]);
        }, new Decimal(0))
        .mul(account.ownedPercentage)
        .div(100)
        .toDecimalPlaces(AllowedDecimalPlaces)
        .toNumber();
      let map: { [institutionName: string]: DisplayItem };
      if (totalValue == 0) {
        map = Account.typeDefaultIsLiability(account.subtype)
          ? liabilitiesMap
          : assetsMap;
      } else if (totalValue > 0) {
        map = assetsMap;
        assets = assets.add(totalValue);
      } else {
        map = liabilitiesMap;
        totalValue = Math.abs(totalValue);
        liabilities = liabilities.add(totalValue);
      }

      if (!map[account.institution]) {
        map[account.institution] = {
          name: account.institution,
          subtypes: [],
          percentage: 0,
          value: { currency, value: 0 },
        };
      }
      account.subAccounts.map((subAccount) => {
        const maybeSubtype = map[account.institution].subtypes.find(
          (v) => v.originValue.currency == subAccount.balance.currency
        );
        const ownedOriginValue = new Decimal(subAccount.balance.value)
          .abs()
          .mul(account.ownedPercentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .toNumber();
        const ownedValue = subAcctIdToBaseValueMap[subAccount.id]
          .abs()
          .mul(account.ownedPercentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .toNumber();
        if (maybeSubtype) {
          maybeSubtype.originValue.value = addDecimal(
            maybeSubtype.originValue.value,
            ownedOriginValue
          );
          maybeSubtype.value.value = addDecimal(
            maybeSubtype.value.value,
            ownedValue
          );
        } else {
          map[account.institution].subtypes.push({
            originValue: {
              currency: subAccount.balance.currency,
              value: ownedOriginValue,
            },
            value: {
              currency,
              value: ownedValue,
            },
            percentage: 0,
          });
        }
      });
      map[account.institution].value.value = addDecimal(
        map[account.institution].value.value,
        totalValue
      );
    });

    const netValue = subDecimal(assets, liabilities);

    const result: Display = {
      assets: {
        currency,
        value: assets.toNumber(),
      },
      liabilities: {
        currency,
        value: liabilities.toNumber(),
      },
      netValue: {
        currency,
        value: netValue,
      },
      institutions: {
        assets: Object.values(assetsMap),
        liabilities: Object.values(liabilitiesMap),
      },
    };

    result.institutions.assets.forEach((v) => {
      v.percentage = calculatePercentage(v.value.value, result.assets.value);
      v.subtypes.forEach((subtype) => {
        subtype.percentage = calculatePercentage(
          subtype.value.value,
          v.value.value
        );
      });
    });
    result.institutions.liabilities.forEach((v) => {
      v.percentage = calculatePercentage(
        v.value.value,
        result.liabilities.value
      );
      v.subtypes.forEach((subtype) => {
        subtype.percentage = calculatePercentage(
          subtype.value.value,
          v.value.value
        );
      });
    });

    result.institutions.assets.sort((a, b) => b.value.value - a.value.value);
    result.institutions.liabilities.sort(
      (a, b) => b.value.value - a.value.value
    );
    return result;
  }

  export interface GlobalDashboardData {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
    categoryLiabilities: {
      [category in Categories]: Amount;
    };
  }

  export function toGlobalDashboardData(
    input: CashAndBankingSummary,
    exchangeRate: TargetCurrencyExchangeRateDataMap,
    liabilityRelations: RelationsOfAsset[],
    ignoreAssetTypes: AssetType[] = [],
    summaries: {
      [AssetType.Art]: Optional<ArtSummary>;
      [AssetType.Property]: Optional<PropertySummary>;
      [AssetType.WineAndSpirits]: Optional<WineSummary>;
      [AssetType.OtherCollectables]: Optional<BelongingSummary>;
      [AssetType.Belonging]: Optional<BelongingSummary>;
    }
  ): GlobalDashboardData {
    const currency = exchangeRate.targetCurrency;
    let assets = new Decimal(0);
    let liabilities = new Decimal(0);
    const liabilityMap: { [accountId: string]: number } = {};

    Object.entries(input.accounts).forEach(([id, account]) => {
      const totalValue = account.subAccounts
        .reduce((sum, v) => {
          return new Decimal(v.balance.value)
            .mul(exchangeRate.rates[v.balance.currency].rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .add(sum);
        }, new Decimal(0))
        .toNumber();
      if (totalValue == 0) {
        return;
      } else if (totalValue > 0) {
        assets = assets.add(totalValue);
      } else {
        liabilities = liabilities.add(Math.abs(totalValue));
      }
      if (
        account.subtype == Account.Type.LoanAccount ||
        account.subtype == Account.Type.MortgageAccount
      ) {
        liabilityMap[id] = Math.abs(totalValue);
      }
    });
    const categoryLiabilities: GlobalDashboardData["categoryLiabilities"] = {
      [Categories.MyFinances]: {
        currency,
        value: 0,
      },
      [Categories.MyProperties]: {
        currency,
        value: 0,
      },
      [Categories.MyCollectables]: {
        currency,
        value: 0,
      },
      [Categories.MyBelongings]: {
        currency,
        value: 0,
      },
    };
    liabilityRelations.forEach((v) => {
      const { id: accountId, relatedTargets } = fromRelationsOfAsset(v);
      Object.values(relatedTargets).forEach((target) => {
        if (!isRole(target, RoleToAsset.AssociatedAsset)) return;
        const { targetId, assetType } = target;
        if (!assetType) throw new DataPoisoned("AssetType is missing");
        if (ignoreAssetTypes.includes(assetType as AssetType)) return;
        // NOTE: do not include sold or archived
        if (
          (assetType === AssetType.Art &&
            (!summaries[assetType] ||
              summaries[assetType]!.items[targetId].sold)) ||
          (assetType === AssetType.Property &&
            (!summaries[assetType] ||
              summaries[assetType]!.properties[targetId].sold ||
              summaries[assetType]!.properties[targetId].archived))
        ) {
          return;
        }
        const percentage = target.relations[RoleToAsset.AssociatedAsset]!;
        const category = categoriesFromAssetType(assetType as AssetType);
        categoryLiabilities[category].value = new Decimal(
          liabilityMap[accountId] || 0
        )
          .mul(percentage)
          .div(100)
          .toDecimalPlaces(AllowedDecimalPlaces)
          .add(categoryLiabilities[category].value)
          .toNumber();
      });
    });

    const result: GlobalDashboardData = {
      assets: {
        currency,
        value: assets.toNumber(),
      },
      liabilities: {
        currency,
        value: liabilities.toNumber(),
      },
      netValue: {
        currency,
        value: subDecimal(assets, liabilities),
      },
      categoryLiabilities,
    };
    return result;
  }

  export function defaultValue() {
    const summary: CashAndBankingSummary = {
      "@type": CashAndBankingSummaryTypeVersion,
      id: AssetType.CashAndBanking,
      version: 0,
      accounts: {},
      // institutionId: {},
    };
    return summary;
  }

  export async function newAggregateRoot(
    transaction: Transaction,
    docRef: DocumentReference<CashAndBankingSummary>
  ) {
    const snapshot = await transaction.get(docRef);
    const summary = assureSummaryVersion(
      snapshot.data(),
      assureVersion,
      defaultValue
    );
    return new AggregateRoot(new CashAndBankingSummaryAggregate(summary));
  }
}

export class CashAndBankingSummaryAggregate extends AggregateBase<
  CashAndBankingSummary,
  never,
  AccountEventInner
> {
  state: CashAndBankingSummary;
  declare relatedUpdates: never;

  constructor(state: CashAndBankingSummary) {
    super();
    this.state = state;
    this.kind = state.id;
  }

  handle(): Event[] {
    throw new Error("summary cannot handle command");
  }

  apply(event: Event): this {
    const account = this.state.accounts[event.aggregateId];
    let subAccountId: string;
    switch (event.data.kind) {
      case AccountEventInner.Kind.AssetCreated:
        if (account) throw new Error("Account already exists");
        if (
          event.data.asset.subtype == Account.Type.CurrentAccount ||
          event.data.asset.subtype == Account.Type.SavingAccount
        ) {
          this.state.accounts[event.aggregateId] = {
            id: event.aggregateId,
            subtype: event.data.asset.subtype,
            subAccounts: event.data.asset.subAccounts.map((subAccount) => ({
              id: subAccount.id,
              balance: { currency: subAccount.balance.currency, value: 0 },
              isDefault: subAccount.isDefault || false,
            })),
            closed: false,
            ownedPercentage: 100,
            updateAt: event.time,
            institution: event.data.asset.institution,
          };
        } else {
          this.state.accounts[event.aggregateId] = {
            id: event.aggregateId,
            subtype: event.data.asset.subtype,
            subAccounts: [
              {
                id: event.aggregateId,
                balance: {
                  currency: event.data.asset.value.currency,
                  value: 0,
                },
                isDefault: true,
              },
            ],
            closed: false,
            ownedPercentage:
              (<Cash.Encrypted>event.data.asset).ownership?.myOwnership || 100,
            updateAt: event.time,
            institution: event.data.asset.institution,
          };
        }
        break;
      case AccountEventInner.Kind.AssetUpdated: {
        if (!account) throw new Error("Account doesn't exist");
        let updated = false;
        if (
          (account.subtype == Account.Type.CurrentAccount ||
            account.subtype == Account.Type.SavingAccount) &&
          (<CurrentAccount.UpdateEncrypted>event.data.asset).subAccounts
        ) {
          this.state.accounts[event.aggregateId].subAccounts = (<
            CurrentAccount.UpdateEncrypted
          >event.data.asset).subAccounts.map((subAccount) => ({
            id: subAccount.id,
            balance: subAccount.balance,
            isDefault: subAccount.isDefault || false,
          }));
          updated = true;
        }
        //#NOTE single currency account
        const institutionUpdate = (<UpdateObject<CreditCard.UpdateEncrypted>>(
          event.data.asset
        )).institution;
        if (account.subtype != Account.Type.Cash && institutionUpdate) {
          this.state.accounts[event.aggregateId].institution =
            institutionUpdate;
          updated = true;
        }
        if (updated)
          this.state.accounts[event.aggregateId].updateAt = event.time;
        break;
      }
      case AccountEventInner.Kind.AssetDeleted:
        if (!account) throw new Error("Account doesn't exist");
        delete this.state.accounts[event.aggregateId];
        break;
      case AccountEventInner.Kind.AccountClosed:
        if (!account) throw new Error("Account doesn't exist");
        account.closed = true;
        break;
      case AccountEventInner.Kind.ShareholderUpdated:
        if (!account) throw new Error("Account doesn't exist");
        account.ownedPercentage = event.data.current?.myOwnership || 100;
        break;
      case AccountEventInner.Kind.TransactionUpdated:
      case AccountEventInner.Kind.TransactionAdded:
      case AccountEventInner.Kind.TransactionDeleted: {
        if (!account) throw new Error("Account doesn't exist");
        subAccountId = event.data.subAccountId || event.aggregateId;
        const subAccount = account.subAccounts.find(
          (subAccount) => subAccount.id == subAccountId
        );
        if (subAccount) {
          if (subAccount.balance.currency != event.data.valueChange.currency)
            throw new Error("Currency mismatch");
          subAccount.balance.value = addDecimal(
            subAccount.balance.value,
            event.data.valueChange.value
          );
        } else throw new Error("subAccount not found");
        break;
      }
      case AccountEventInner.Kind.ValueUpdated:
        if (!account) throw new Error("Account doesn't exist");
        if (event.data.primaryValue) {
          account.subAccounts[0].balance = event.data.primaryValue;
        } else if (event.data.subAccountValues) {
          event.data.subAccountValues.forEach((subAccountValue) => {
            const subAccount = account.subAccounts.find(
              (subAccount) => subAccount.id == subAccountValue.id
            );
            if (subAccount) {
              subAccount.balance = subAccountValue.value;
            } else throw new Error("subAccount not found");
          });
        }
        break;
    }
    return this;
  }
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20fill%3D%22%237ED321%22%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M7.776%2022.482h1.23v4.985c0%20.68-.212%201.22-.637%201.621-.425.4-.985.6-1.682.6-.742%200-1.315-.187-1.719-.56-.403-.375-.605-.896-.605-1.563h1.23c0%20.377.094.661.281.852.187.19.458.285.813.285.332%200%20.597-.11.794-.33.196-.219.295-.523.295-.91v-4.98Zm3.877%204.467v2.642h-1.235v-7.11h2.72c.794%200%201.424.207%201.891.62.467.414.701.961.701%201.641%200%20.697-.229%201.239-.686%201.626-.457.388-1.098.581-1.921.581h-1.47Zm0-.99h1.484c.44%200%20.775-.104%201.006-.31.231-.208.347-.506.347-.897%200-.384-.117-.69-.352-.92-.234-.23-.556-.348-.967-.354h-1.518v2.48Zm9.311.458h-2.92v2.188h3.414v.986h-4.649v-7.11h4.614v.997h-3.377v1.963h2.92v.976Zm6.949%202.251c-.257.335-.614.59-1.07.762a4.37%204.37%200%200%201-1.552.259c-.596%200-1.123-.135-1.582-.406a2.7%202.7%200%200%201-1.062-1.16c-.25-.502-.379-1.089-.389-1.76v-.556c0-1.074.258-1.913.772-2.517.514-.604%201.232-.906%202.153-.906.791%200%201.42.195%201.885.586.465.39.745.954.84%201.69h-1.212c-.137-.857-.633-1.285-1.49-1.285-.553%200-.974.2-1.262.598-.288.399-.437.984-.446%201.756v.547c0%20.768.162%201.364.485%201.79.324.424.774.636%201.35.636.632%200%201.081-.143%201.348-.43v-1.391h-1.465v-.938h2.696v2.725Z%22%2F%3E%3Cpath%20fill%3D%22%234C8B07%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileJpegIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        fill={'#7ED321'}
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
      />
      <path
        fill={'#FFF'}
        d={
          'M7.776 22.482h1.23v4.985c0 .68-.212 1.22-.637 1.621-.425.4-.985.6-1.682.6-.742 0-1.315-.187-1.719-.56-.403-.375-.605-.896-.605-1.563h1.23c0 .377.094.661.281.852.187.19.458.285.813.285.332 0 .597-.11.794-.33.196-.219.295-.523.295-.91v-4.98Zm3.877 4.467v2.642h-1.235v-7.11h2.72c.794 0 1.424.207 1.891.62.467.414.701.961.701 1.641 0 .697-.229 1.239-.686 1.626-.457.388-1.098.581-1.921.581h-1.47Zm0-.99h1.484c.44 0 .775-.104 1.006-.31.231-.208.347-.506.347-.897 0-.384-.117-.69-.352-.92-.234-.23-.556-.348-.967-.354h-1.518v2.48Zm9.311.458h-2.92v2.188h3.414v.986h-4.649v-7.11h4.614v.997h-3.377v1.963h2.92v.976Zm6.949 2.251c-.257.335-.614.59-1.07.762a4.37 4.37 0 0 1-1.552.259c-.596 0-1.123-.135-1.582-.406a2.7 2.7 0 0 1-1.062-1.16c-.25-.502-.379-1.089-.389-1.76v-.556c0-1.074.258-1.913.772-2.517.514-.604 1.232-.906 2.153-.906.791 0 1.42.195 1.885.586.465.39.745.954.84 1.69h-1.212c-.137-.857-.633-1.285-1.49-1.285-.553 0-.974.2-1.262.598-.288.399-.437.984-.446 1.756v.547c0 .768.162 1.364.485 1.79.324.424.774.636 1.35.636.632 0 1.081-.143 1.348-.43v-1.391h-1.465v-.938h2.696v2.725Z'
        }
      />
      <path fill={'#4C8B07'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Loader2Icon,
  MessagesSquare,
  ThumbsDownIcon,
  ThumbsUpIcon
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import {
  fetchDocumentById,
  fetchKnowledgeSearch,
  fetchSearchSuggestions,
  submitFeedback,
  SupportQuery
} from '@/api/SupportService'
import { cn } from '@/utils/classnames'
import { useDebounce } from '@/hooks/useDebounce'
import { useToast } from '@/hooks/useToast'
import { Button, Input, Modal, Overlay, Textarea } from '@/components/base'
import { HubSpotContactForm } from '@/components/form'
import { CollapseIcon, EmailIcon, ExpandIcon, SearchIcon, XIcon } from '@/components/icon'
import JsonViewer from '@/components/JsonViewer'
import LiveChatModal from '@/components/LiveChatModal'

interface HelpCenterProps {
  isSupportOpen: boolean
  setIsSupportOpen: (isOpen: boolean) => void
}

export default function HelpCenter({ isSupportOpen, setIsSupportOpen }: HelpCenterProps) {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isPreLiveChatFormOpen, setIsPreLiveChatFormOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [isContactUsOpen, setIsContactUsOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const expandedRef = useRef<HTMLButtonElement>(null)
  const collapseRef = useRef<HTMLButtonElement>(null)
  const keyword = useDebounce(searchValue, 500)
  const { data = [], isLoading } = useSWR(keyword.length > 2 && [SupportQuery.search, keyword], fetchKnowledgeSearch)
  const { data: searchSuggestions = [] } = useSWR([SupportQuery.searchSuggestions], fetchSearchSuggestions)
  const { data: knowledgeBase, isLoading: isKnowledgeBaseLoading } = useSWR(
    documentId && [SupportQuery.documentById, documentId],
    fetchDocumentById
  )

  const handleClose = () => {
    setIsExpanded(false)
    setIsSupportOpen(false)
    setDocumentId('')
    setSearchValue('')
  }

  const handleLiveChatOpen = () => {
    setIsPreLiveChatFormOpen(true)
    setIsSupportOpen(false)
  }

  return (
    <>
      {isSupportOpen &&
        (isExpanded ? (
          <Overlay className={'grid place-items-center'}>
            <div className={'fixed left-0 top-0 z-50 h-screen w-screen rounded bg-white'}>
              <div className={'flex items-center justify-between gap-x-8 border-b-2 border-b-grey/40 px-4 py-5'}>
                <Button
                  ref={collapseRef}
                  className={'rounded bg-transparent hover:bg-grey/20'}
                  onClick={() => setIsExpanded(false)}
                >
                  <CollapseIcon size={22} fill={'black'} />
                </Button>
                <p className={'text-sm font-medium uppercase text-black'}>{t('account:HelpCenter')}</p>
                <Button className={'rounded bg-grey/60 hover:bg-grey/80'} onClick={handleClose}>
                  <XIcon size={22} fill={'gray'} />
                </Button>
              </div>
              {knowledgeBase ? (
                <div className={'mx-auto h-[calc(100vh-66px)] w-screen overflow-auto p-4 md:px-20 lg:px-60'}>
                  <JsonViewer data={knowledgeBase} />
                  <FeedbackSection
                    documentId={documentId}
                    isExpanded={isExpanded}
                    handleContactUsOpen={() => setIsContactUsOpen(true)}
                    handleLiveChatOpen={handleLiveChatOpen}
                  />
                </div>
              ) : isKnowledgeBaseLoading ? (
                <div className={'flex h-full items-center justify-center'}>
                  <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
                </div>
              ) : (
                <div className={'flex flex-col gap-y-3 px-4 py-5'}>
                  <p className={'text-xl/7 font-medium text-black'}>{t('nav:HowCanWeHelpYou')}</p>
                  <SupportFormSelector
                    isLoading={isLoading}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    data={data}
                    inputRef={inputRef}
                    isExpanded={isExpanded}
                    expandedRef={expandedRef}
                    collapseRef={collapseRef}
                    setDocumentId={setDocumentId}
                  />
                </div>
              )}
            </div>
            <ContactUsModal isOpen={isContactUsOpen} onClose={() => setIsContactUsOpen(false)} />
          </Overlay>
        ) : (
          <motion.div
            className={
              'fixed inset-x-4 top-[76px] z-50 ml-auto max-h-[calc(100vh-100px)] w-[calc(100%-32px)] max-w-[380px] rounded-lg bg-background shadow-md'
            }
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 100 }}
            transition={{ type: 'spring', bounce: 0.15, duration: 0.3 }}
          >
            <div className={'rounded bg-background shadow-lg'}>
              <div className={'flex items-center justify-between border-b border-b-grey/40 px-4 py-5'}>
                <Button
                  ref={expandedRef}
                  className={'rounded bg-transparent hover:bg-grey/20'}
                  onClick={() => setIsExpanded(true)}
                >
                  <ExpandIcon size={24} className={'text-white'} fill={'#BDBEC8'} />
                </Button>
                <div>
                  <p className={'text-sm font-medium uppercase text-[#BDBEC8]'}>{t('account:HelpCenter')}</p>
                </div>
                <Button className={'rounded bg-grey/20 hover:bg-grey/40'} onClick={handleClose}>
                  <XIcon />
                </Button>
              </div>
              <div className={'flex flex-col gap-y-3 border-b border-b-grey/40 px-4 py-5'}>
                <p className={'text-xl/7 font-medium text-white'}>{t('nav:HowCanWeHelpYou')}</p>
                <SupportFormSelector
                  data={data}
                  isLoading={isLoading}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  inputRef={inputRef}
                  isExpanded={isExpanded}
                  expandedRef={expandedRef}
                  collapseRef={collapseRef}
                  setDocumentId={setDocumentId}
                />
              </div>
              <div className={'flex flex-col gap-y-3 px-4 py-5'}>
                {knowledgeBase ? (
                  <div>
                    <Button
                      onClick={() => {
                        setSearchValue('')
                        setDocumentId('')
                      }}
                    >
                      <ChevronLeftIcon size={20} />
                      <span className={'ml-1 text-sm/5 font-medium uppercase'}>{t('nav:commonQuestions')}</span>
                    </Button>
                    <div className={'mt-2 h-[360px] grow overflow-y-auto text-white'}>
                      <JsonViewer data={knowledgeBase} />
                      <FeedbackSection
                        documentId={documentId}
                        isExpanded={isExpanded}
                        handleContactUsOpen={() => setIsContactUsOpen(true)}
                        handleLiveChatOpen={handleLiveChatOpen}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p className={'text-sm/5 font-bold uppercase text-white'}>{t('nav:commonQuestions')}</p>
                    <div className={'flex flex-col gap-y-2'}>
                      {searchSuggestions?.map((question, index) => (
                        <Button
                          key={index}
                          className={
                            'flex w-full justify-between bg-white/10 p-3 text-start text-base/5 font-medium text-[#BDBEC8] hover:bg-white/20'
                          }
                          onClick={() => {
                            setSearchValue(question.title || '')
                            setDocumentId(question.id || '')
                          }}
                        >
                          <div className={'truncate'}>{question.title}</div>
                          <div className={'w-4'}>
                            <ChevronRightIcon className={'text-grey'} size={16} />
                          </div>
                        </Button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <ContactUsModal isOpen={isContactUsOpen} onClose={() => setIsContactUsOpen(false)} />
          </motion.div>
        ))}
      <LiveChatModal isOpen={isPreLiveChatFormOpen} setIsOpen={setIsPreLiveChatFormOpen} />
    </>
  )
}

interface SupportFormSelectorProps {
  data: any[]
  searchValue: string
  isExpanded: boolean
  isLoading: boolean
  inputRef: React.RefObject<HTMLInputElement>
  expandedRef: React.RefObject<HTMLButtonElement>
  collapseRef: React.RefObject<HTMLButtonElement>
  setSearchValue: (value: string) => void
  setDocumentId: (value: string) => void
}
const SupportFormSelector = ({
  data,
  searchValue,
  isExpanded,
  isLoading,
  inputRef,
  expandedRef,
  collapseRef,
  setSearchValue,
  setDocumentId
}: SupportFormSelectorProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const searchRef = useRef<HTMLDivElement>(null)

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const keyword = e.target.value
      setSearchValue(keyword)
      setIsOpen(keyword.length > 0)
    },
    [setSearchValue]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        ![searchRef.current, expandedRef.current, collapseRef.current].some(
          (element) => element?.contains(event.target as Node)
        )
      ) {
        setIsOpen(false)
        setSearchValue('')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [collapseRef, expandedRef, setIsOpen, setSearchValue])

  return (
    <div ref={searchRef} className={'relative'}>
      <div
        className={cn(
          'relative flex h-10 w-full items-center self-center  p-2 transition-opacity duration-300',
          isExpanded ? 'rounded bg-grey/40' : 'rounded-sm bg-[#171a29]'
        )}
      >
        <div className={'absolute left-2 flex'}>
          <Button>
            <SearchIcon className={isExpanded ? 'text-black/60' : 'text-[#BDBEC8]'} strokeWidth={3} size={16} />
          </Button>
        </div>
        <Input
          ref={inputRef}
          className={cn(
            'ml-7 h-8 w-full rounded-none bg-transparent p-0 text-base/5 focus:ring-0',
            isExpanded ? 'text-black' : 'text-white'
          )}
          value={searchValue}
          onChange={handleInputChange}
          placeholder={t('nav:SearchTopics')}
          onFocus={() => setIsOpen(true)}
        />
      </div>
      {isOpen && searchValue && (
        <menu
          ref={menuRef}
          className={cn(
            'absolute top-12  w-full overflow-y-auto py-2 text-sm',
            isExpanded
              ? 'rounded-md border-2 border-grey/40 bg-white'
              : 'rounded-sm border-grey bg-[#2A2D3C] shadow-2xl'
          )}
          onClick={() => setIsOpen(false)}
        >
          {data.length > 0 ? (
            <div className={'h-80 overflow-y-auto'}>
              {data.map((item, index) => (
                <SearchItem
                  key={index}
                  value={item.document.title}
                  isExpanded={isExpanded}
                  keyword={searchValue}
                  setDocumentId={() => setDocumentId(item.document.id)}
                />
              ))}
            </div>
          ) : (
            searchValue && (
              <div
                className={cn(
                  'whitespace-pre-wrap p-4 text-center font-medium leading-8 ',
                  isExpanded ? 'text-lg text-black' : 'text-white'
                )}
              >
                {isLoading ? (
                  <Loader2Icon className={'mx-auto h-8 w-8 animate-spin text-primary'} />
                ) : (
                  t('nav:KnowledgeSearchNoResults')
                )}
              </div>
            )
          )}
        </menu>
      )}
    </div>
  )
}

export interface SearchItemProps {
  value: string
  keyword: string
  isExpanded: boolean
  setDocumentId: () => void
}

const SearchItem = ({ value, keyword, isExpanded, setDocumentId }: SearchItemProps) => {
  const highlightKeyword = (text: string, keyword: string) => {
    if (!keyword.trim()) return text
    const regex = new RegExp(`(${keyword})`, 'gi')
    return text.replace(regex, '<strong>$1</strong>')
  }

  return (
    <Button
      variant={'unstyled'}
      className={cn(
        'group flex w-full items-center justify-start gap-2 rounded-none bg-transparent px-4 py-3 text-start',
        isExpanded ? 'hover:bg-grey/20' : 'hover:bg-grey/30'
      )}
      onClick={setDocumentId}
    >
      <div className={'w-4'}>
        <SearchIcon className={isExpanded ? 'text-black/60' : 'text-[#BDBEC8]'} strokeWidth={3} size={16} />
      </div>
      <div
        className={cn('group bg-transparent', isExpanded ? 'text-black' : 'text-white')}
        dangerouslySetInnerHTML={{ __html: highlightKeyword(value, keyword) }}
      />
    </Button>
  )
}

interface FeedbackSectionProps {
  documentId: string
  isExpanded: boolean
  handleContactUsOpen: () => void
  handleLiveChatOpen: () => void
}
const FeedbackSection = ({ documentId, isExpanded, handleContactUsOpen, handleLiveChatOpen }: FeedbackSectionProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [feedbackType, setFeedbackType] = useState<boolean>(true)
  const [comment, setComment] = useState('')
  const handleSubmitFeedback = async () => {
    try {
      setIsLoading(true)
      await submitFeedback([SupportQuery.submitFeedback, documentId, feedbackType, comment])
      setIsSubmitted(true)
      toast({ variant: 'success', description: t('nav:submittedSuccessfully') })
    } catch (error) {
      toast({ variant: 'error', description: t('nav:submittedFailed') })
      console.error('submitFeedback error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={cn('flex flex-col', isExpanded && 'mb-14')}>
      {!isSubmitted && (
        <>
          <div className={'flex gap-4 p-4'}>
            <Button
              variant={'unstyled'}
              className={'group py-2'}
              onClick={() => {
                handleSubmitFeedback()
                setFeedbackType(true)
              }}
            >
              <div className={cn('rounded-full p-2.5 group-hover:bg-primary', isExpanded ? 'bg-black' : 'bg-grey/80')}>
                <ThumbsUpIcon className={'text-white'} size={16} />
              </div>
              <p
                className={cn(
                  'ml-2 text-sm font-medium',
                  isExpanded ? 'text-[#3A3A3A]' : 'text-white group-hover:text-primary'
                )}
              >
                {t('nav:Helpful')}
              </p>
            </Button>
            <Button variant={'unstyled'} className={'group py-2'} onClick={() => setFeedbackType(!feedbackType)}>
              <div
                className={cn(
                  'rounded-full p-2.5',
                  isExpanded ? 'bg-black group-hover:bg-black/60' : 'bg-grey/80 group-hover:bg-grey/60'
                )}
              >
                <ThumbsDownIcon className={'text-white'} size={16} />
              </div>
              <p
                className={cn(
                  'ml-2 text-sm font-medium',
                  isExpanded ? 'text-[#3A3A3A] group-hover:text-black/60' : 'text-white group-hover:text-primary'
                )}
              >
                {t('nav:NotHelpful')}
              </p>
            </Button>
          </div>

          {!feedbackType && (
            <div className={'mb-4 px-4'}>
              <p className={cn('text-sm/6', isExpanded ? 'text-black/60' : 'text-white')}>
                {t('nav:PleaseShareYourFeedback')}
              </p>
              <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
              <div className={'flex w-full justify-end'}>
                <Button
                  variant={'solid'}
                  className={'group relative mt-2 px-4 py-1'}
                  onClick={handleSubmitFeedback}
                  disabled={isLoading}
                >
                  <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
                  <span className={'group-disabled:opacity-0'}>{t('Submit')}</span>
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <div className={'border-t-2 border-t-grey/50 px-4 pb-5 pt-4'}>
        <h2 className={cn('text-xl font-medium', isExpanded ? 'text-black' : 'text-white')}>
          {t('nav:CantFindWhatYourLookingFor')}
        </h2>
        <p className={cn('text-sm', isExpanded ? 'text-black/80' : 'text-white')}>{t('nav:ReachOutToUs')}</p>
        <div className={'flex gap-4 pt-4'}>
          <Button
            variant={'unstyled'}
            className={cn(
              'flex items-center gap-x-2 rounded bg-primary text-white',
              isExpanded ? 'px-4 py-2' : 'p-3 text-sm font-bold'
            )}
            onClick={handleLiveChatOpen}
          >
            <MessagesSquare size={isExpanded ? 20 : 14} />
            <span className={cn(isExpanded ? 'text-sm' : 'text-xs')}>{t('nav:ChatWithUs')}</span>
          </Button>
          <Button
            variant={'unstyled'}
            className={cn(
              'flex items-center gap-x-2 rounded bg-primary text-white',
              isExpanded ? 'px-4 py-2' : 'p-3 text-sm font-bold'
            )}
            onClick={handleContactUsOpen}
          >
            <EmailIcon size={isExpanded ? 20 : 14} strokeWidth={1.5} />
            <span className={cn(isExpanded ? 'text-sm' : 'text-xs')}>{t('nav:SubmitAContactForm')}</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

interface ContactUsModalProps {
  isOpen: boolean
  onClose: () => void
}
const ContactUsModal = ({ isOpen, onClose }: ContactUsModalProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const formRef = useRef<HTMLDivElement>(null)

  const handleFormReady = useCallback(() => {
    setIsLoading(false)
  }, [])

  //loading hubspot form when open modal
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      window.addEventListener('message', (event) => {
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
          handleFormReady()
        }
      })
    }

    return () => {
      window.removeEventListener('message', handleFormReady)
    }
  }, [isOpen, handleFormReady])

  return (
    <>
      {isOpen && (
        <Modal className={'max-w-[600px]'}>
          <div className={'relative pt-5'}>
            <Button className={'absolute right-4 top-4 rounded bg-grey/40 hover:bg-grey/60'} onClick={onClose}>
              <XIcon size={28} fill={'#A5A5A5'} />
            </Button>
            <div className={'p-6'}>
              {isLoading && (
                <div className={'flex h-64 items-center justify-center'}>
                  <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
                </div>
              )}
              <div ref={formRef} className={isLoading ? 'invisible h-0' : 'visible'}>
                <HubSpotContactForm
                  region={'eu1'}
                  portalId={'143983585'}
                  formId={'e2fc6950-4be9-4b94-b113-7e33c89c3017'}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

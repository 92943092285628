import { useEffect, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { TimeZone } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { Removal, RemovalReason } from 'core/remodel/types/wineAndSprits'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { makeOptions, startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, FormDatePicker, FormSelect, FormTextarea, SelectItem } from '@/components/base'

export type RemovalValues = Removal

const getDefaultValues = (timeZone: TimeZone): Partial<RemovalValues> => ({
  reason: RemovalReason.DrankFromMyCellar,
  date: startOfDayInTz(new Date(), timeZone),
  notes: ''
})

interface RemovalFormProps {
  values?: RemovalValues
  onSubmit: SubmitHandler<RemovalValues>
  onCancel: () => void
}

export function RemovalForm({ values, onSubmit, onCancel }: RemovalFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(() => getDefaultValues(preferences.timeZone), [preferences.timeZone])
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset
  } = useForm<RemovalValues>({ defaultValues })
  const removalReasonOptions = makeOptions(
    Object.values(RemovalReason),
    (key) => `collectables:RemovalReasonOptions.${key}`
  )

  useEffect(() => {
    if (values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [values, reset])

  return (
    <form className={'space-y-4'} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        control={control}
        name={'reason'}
        label={t('collectables:RemovalReasonOptions.RemovalReason')}
        rules={{ required: true }}
      >
        {removalReasonOptions.map(({ label, value }) => (
          <SelectItem key={value} value={value}>
            {label}
          </SelectItem>
        ))}
      </FormSelect>
      <FormDatePicker
        control={control}
        name={'date'}
        label={t('collectables:RemovalReasonOptions.RemovalDate')}
        format={preferences.dateFormat}
        timeZone={preferences.timeZone}
      />
      <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
      <fieldset className={'flex items-center justify-end gap-x-2 [&>button]:flex-1'} disabled={isSubmitting}>
        <Button variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button className={'group relative'} variant={'solid'} size={'md'} type={'submit'} disabled={!isValid}>
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{t('Remove')}</span>
        </Button>
      </fieldset>
    </form>
  )
}

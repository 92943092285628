import { useEffect, useMemo } from 'react'
import { areIntervalsOverlapping } from 'date-fns'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Action } from 'core/remodel/types/actions'
import { ActionType } from 'core/remodel/types/actions/base'
import { RentInfo } from 'core/remodel/types/actions/rentInfo'
import { Currency, TimeZone } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fetchActions, propertyQuery } from '@/api/PropertyService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatNumber, makeOptions, startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  BooleanPicker,
  Button,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormPercentInput,
  FormPriceInput,
  FormTextarea
} from '@/components/base'
import { ActionDetails } from '@/components/Actions'
import UploadFiles from '@/components/UploadFiles'

export type RentOutValues = RentInfo.CreateFields
export const yesNoTypeValues = ['Yes', 'No']

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<RentOutValues> => ({
  id: '',
  tenantId: '',
  propertyManager: '',
  introducingAgent: '',
  startDate: startOfDayInTz(new Date(), timeZone),
  endDate: startOfDayInTz(new Date(), timeZone),
  securityDeposit: { currency, value: 0 },
  incomeAmount: { currency, value: 0 },
  incomeTaxLiabilityPercentage: 0,
  taxWithheldAtSource: true,
  managementFeesAmount: { currency, value: 0 },
  managementFeesPaidByTenant: true,
  governmentRatesAmount: { currency, value: 0 },
  governmentRatesPaidByTenant: true,
  file: []
})

interface RentOutFormProps {
  assetId: string | null
  mode?: 'Create' | 'Edit'
  values?: RentOutValues
  onSubmit: SubmitHandler<RentOutValues>
  onCancel: () => void
}

export function RentOutForm({ assetId, mode = 'Create', values, onCancel, onSubmit }: RentOutFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  // form
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setValue,
    watch
  } = useForm<RentOutValues>({ values, defaultValues })
  // preferences and options
  const yesNoOptions = makeOptions(yesNoTypeValues, (key) => `YesNoOptions.${key}`).map(({ label, value }) => ({
    label,
    value: value === 'Yes'
  }))
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })
  const { data: actions } = useSWR(assetId && [propertyQuery.action, assetId], fetchActions(database!))

  const [startDate, endDate, incomeAmount, managementFeesAmount, governmentRatesAmount] = watch([
    'startDate',
    'endDate',
    'incomeAmount',
    'managementFeesAmount',
    'governmentRatesAmount'
  ])
  const accumulatedAmount = {
    currency: incomeAmount?.currency,
    value: incomeAmount.value + (managementFeesAmount?.value ?? 0) + (governmentRatesAmount?.value ?? 0)
  }

  const isDatesValid = useMemo(() => {
    const editFilter = (action: ActionDetails) => action.id !== values?.id
    const filteredActions = mode == 'Edit' ? (actions ?? []).filter(editFilter) : actions
    return startDate <= endDate && !rentOutDatesOverlap(startDate, endDate, filteredActions)
  }, [startDate, endDate, actions, mode, values?.id])
  const isIncomeAmountValid = incomeAmount !== undefined && incomeAmount.value > 0

  useEffect(() => {
    setValue('managementFeesAmount.currency', incomeAmount.currency)
    setValue('governmentRatesAmount.currency', incomeAmount.currency)
    setValue('securityDeposit.currency', incomeAmount.currency)
  }, [incomeAmount.currency, setValue])

  return (
    <form className={'flex h-[95vh] flex-col gap-y-4 bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-y-4 overflow-y-auto pt-4'}>
        <div className={'flex flex-1 flex-col px-4'}>
          <FormCreatableAutocomplete
            control={control}
            name={'tenantId'}
            label={t('Field.TenantName')}
            placeholder={t('SearchOrCreate')}
            options={contactOptions}
            onCreate={addNewContact}
            isLoading={loadingContacts}
            errorClassName={'text-right'}
            rules={{ required: t('validation:RentOutName') }}
          />
        </div>
        <div className={'flex flex-col gap-x-4 px-4 md:flex-row'}>
          <div className={'flex flex-1 flex-col gap-y-4'}>
            <FormCreatableAutocomplete
              control={control}
              name={'propertyManager'}
              label={t('Field.PropertyManager')}
              placeholder={t('SearchOrCreate')}
              options={contactOptions}
              onCreate={addNewContact}
              isLoading={loadingContacts}
            />
            <FormDatePicker
              control={control}
              name={'startDate'}
              label={t('Field.StartDate')}
              format={preferences.dateFormat}
              timeZone={preferences.timeZone}
              errorClassName={'text-right'}
              rules={{
                required: true,
                validate: {
                  datesOverlap: (date) => isDatesValid || t('validation:RentOutDates')
                }
              }}
            />
            <FormPriceInput
              control={control}
              name={{ currency: 'securityDeposit.currency', value: 'securityDeposit.value' }}
              label={t('Field.SecurityDeposit')}
              format={preferences.numberFormat}
              digits={2}
              disabled={{ currency: true }}
            />
            <FormPercentInput
              control={control}
              name={'incomeTaxLiabilityPercentage'}
              label={t('Field.IncomeTaxLiabilityPercentage')}
              defaultValue={0}
            />
            <FormPriceInput
              control={control}
              name={{ currency: 'managementFeesAmount.currency', value: 'managementFeesAmount.value' }}
              label={t('Field.ManagementFeesAmount')}
              format={preferences.numberFormat}
              digits={2}
              disabled={{ currency: true }}
            />
            <FormPriceInput
              control={control}
              name={{ currency: 'governmentRatesAmount.currency', value: 'governmentRatesAmount.value' }}
              label={t('Field.GovernmentRatesAmount')}
              format={preferences.numberFormat}
              digits={2}
              disabled={{ currency: true }}
            />
            <UploadFiles assetId={assetId} control={control} name={'file'} />
            <FormTextarea control={control} name={'notes'} label={t('properties:Field.RentalNotes')} />
          </div>
          <div className={'flex flex-1 flex-col gap-y-4'}>
            <FormCreatableAutocomplete
              control={control}
              name={'introducingAgent'}
              label={t('Field.IntroducingAgent')}
              placeholder={t('SearchOrCreate')}
              options={contactOptions}
              onCreate={addNewContact}
              isLoading={loadingContacts}
            />
            <FormDatePicker
              control={control}
              name={'endDate'}
              label={t('Field.EndDate')}
              format={preferences.dateFormat}
              timeZone={preferences.timeZone}
              errorClassName={'text-right'}
              rules={{
                required: true,
                validate: {
                  datesOverlap: (date) => isDatesValid || t('validation:RentOutDates')
                }
              }}
            />
            <FormPriceInput
              control={control}
              name={{ currency: 'incomeAmount.currency', value: 'incomeAmount.value' }}
              label={t('Field.IncomeAmount')}
              errorClassName={'text-right'}
              rules={{
                value: {
                  required: true,
                  validate: {
                    greaterThanZero: (value) => isIncomeAmountValid || t('validation:RentOutIncomeAmount')
                  }
                }
              }}
              format={preferences.numberFormat}
              digits={2}
            />
            <BooleanPicker
              control={control}
              name={'taxWithheldAtSource'}
              label={t('Field.TaxWithheldAtSource')}
              options={yesNoOptions}
            />
            <BooleanPicker
              control={control}
              name={'managementFeesPaidByTenant'}
              label={t('Field.ManagementFeesPaidByTenant')}
              options={yesNoOptions}
            />
            <BooleanPicker
              control={control}
              name={'governmentRatesPaidByTenant'}
              label={t('Field.GovernmentRatesPaidByTenant')}
              options={yesNoOptions}
            />
            <div className={'flex flex-col gap-y-1'}>
              <label className={'text-xs text-[#414554]'}>{t('Field.AccumulatedValue')}</label>
              <div className={'rounded border border-primary-hover bg-primary p-2'}>
                <p className={'text-right text-sm text-white'}>
                  {`${accumulatedAmount.currency} ${formatNumber(accumulatedAmount.value, preferences.numberFormat, {
                    isNegativeSign: true,
                    digits: 2
                  })}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          disabled={!isValid}
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}

export const rentOutActionTypeGuard = (action: Action): action is RentInfo => action.actionType === ActionType.RentOut

export const rentOutDatesOverlap = (d1: Date, d2: Date, actions: Action[] = []) => {
  if (d1 > d2) return true // d1 is after d2 => invalid
  const rentOutDates = actions
    .filter(rentOutActionTypeGuard)
    .map((action) => [action.startDate, action.endDate] as [Date, Date])
  return rentOutDates.some(([startDate, endDate]) =>
    areIntervalsOverlapping({ start: d1, end: d2 }, { start: startDate, end: endDate }, { inclusive: true })
  )
}
export const isCurrentlyRentedOut = (actions: Action[] = [], timeZone: TimeZone) => {
  const now = startOfDayInTz(new Date(), timeZone)
  return actions.filter(rentOutActionTypeGuard).some((action) => {
    const startDate = startOfDayInTz(new Date(action.startDate), timeZone)
    const endDate = startOfDayInTz(new Date(action.endDate), timeZone)
    return startDate <= now && now <= endDate
  })
}

import { Command, Event } from "../types/properties/command";
import { PropertyStateWriter } from "../types/properties/propertyStateWriter";
import {
  OwnershipType,
  PropertyUtils,
  RentalDetail,
} from "../types/properties";
import { Property } from "../types/properties/property";
import { Configuration } from "../types/properties/configuration";
import { OwnerDetail } from "../types/properties/ownerDetail";
import {
  OtherRoom,
  OtherRoomType,
  Bedroom,
  Bathroom,
  CarPark,
} from "../types/properties/room";
import { AssetType, Currency, LocationType, Optional } from "../types/common";

import {
  AggregateRoot,
  ClientRepo,
  Repo,
  buildUpdateGroupCommand,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import {
  AllowedDecimalPlaces,
  UpdateObject,
  addDecimal,
  calculatePercentage,
} from "../utils";
import {
  AsyncTask,
  AsyncTaskExecutor,
  IAsyncTaskExecutor,
} from "../types/asyncTask";
import { Valuation } from "../types/actions/valuation";
import { BelongingsRepo } from "./belongings";
import { ArtsRepo } from "./arts";
import {
  LocationItem,
  RoleToAsset,
  RelationSearchKeyword,
  toKeywordWithId,
} from "../types/relations";
import { WineAndSpiritsRepo } from "./wineAndSprits";
import { DataPoisoned, InvalidInput } from "../types/error";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { InsuranceRepo } from "./insurance";
import { GroupsRepo, GroupUpdater } from "./groups";
import { Encrypted, EncryptionManager } from "./encryption";
import { ExchangeRate } from "../database/exchangeRate";
import {
  BreakdownItem,
  PropertyAssetsBreakdown,
  PropertySummary,
} from "../types/propertySummary";
import {
  actionRefFromAssetDocRef,
  buildAddActionCommand,
  buildAddValuationCommand,
  buildDeleteActionCommand,
  buildDeleteSoldInfoCommand,
  buildDeleteValuationCommand,
  buildMarkAsSoldCommand,
  buildUpdateActionCommand,
  buildUpdateSoldInfoCommand,
  buildUpdateValuationCommand,
} from "./actions";
import { SoldInfo } from "../types/actions/soldInfo";
import { Offer } from "../types/actions/offer";
import { Action, OfferBundle, RentBundle } from "../types/actions";
import { ActionType } from "../types/actions/base";
import { DbSharedFields } from "../types/database";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  DocumentReference,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import {
  getFirestoreCollection,
  validateWithGroups,
  ValidationGroup,
} from "../decorators";
import { TypeResult } from "../types/typeVersion";
import Decimal from "decimal.js";
import { BelongingSummary } from "../types/belongingSummary";
import { ArtSummary } from "../types/artSummary";
import { WineSummary } from "../types/wineSummary";
import { CashAndBankingRepo, getAssetLiabilities } from "./cashAndBanking";
import { RentInfo } from "../types/actions/rentInfo";
import { SupportLiabilityType } from "../types/cashAndBankingSummary";
import { LocationInfo } from "../types/relations/locationInfo";

export class PropertiesRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }
  async getSyncedSummary(currency?: Currency) {
    const summary = (
      await this.summaryManager.get(AssetType.Property).syncAndGetData()
    ).summary;

    this.exRate.checkInitialized();
    const exRate = await this.exRate.getToTargetExchangeRates(
      currency || this.exRate.BaseCurrency!
    );
    return PropertySummary.toDisplayAndDecrypted(
      summary,
      exRate,
      this.Encryption.current
    );
  }

  private actionDocRef(id: string, valuationId: string) {
    return CoreFirestore.docFromCollection(
      this.refs.currentRefs.getActionCollectionRef(AssetType.Property, id),
      valuationId
    );
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<Encrypted<Property>, Command, Event>,
    isDelete?: boolean
  ) {
    const property = ar.state();
    const data = ar.relatedUpdates() as PropertyUtils.RelatedUpdates;
    const repoAndAggregates: PropertyUtils.RelatedAggregates = {};
    let groupUpdater: Optional<GroupUpdater> = undefined;
    if (data.addedGroupIds || data.removedGroupIds) {
      groupUpdater = new GroupUpdater(
        this.refs.currentRefs,
        (data.addedGroupIds || []).concat(data.removedGroupIds || [])
      );
      await groupUpdater.read(transaction);
    }
    if (data.addedInsuranceIds || data.removedInsuranceIds) {
      repoAndAggregates.insurance = {
        repo: await InsuranceRepo.newRepo(this.refs.currentRefs, transaction),
        aggregates: [],
      };
      if (data.addedInsuranceIds) {
        for (const insuranceId of data.addedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              property.id,
              AssetType.Property
            )
          );
        }
      }
      if (data.removedInsuranceIds) {
        for (const insuranceId of data.removedInsuranceIds) {
          repoAndAggregates.insurance!.aggregates.push(
            await InsuranceRepo.newArAndUpdateInsured(
              this.refs.currentRefs,
              transaction,
              this.refs.selfRefs.userId,
              insuranceId,
              property.id,
              AssetType.Property,
              false
            )
          );
        }
      }
    }
    if (isDelete) {
      repoAndAggregates.cashAndBanking = {
        repo: await CashAndBankingRepo.newRepo(
          this.refs.currentRefs,
          transaction
        ),
        aggregates: await CashAndBankingRepo.newArAndUpdateAllocatedLiability(
          this.refs.currentRefs,
          transaction,
          this.refs.selfRefs.userId,
          property.id
        ),
      };
    }
    // * writes
    if (data.addedGroupIds && groupUpdater) {
      for (const groupId of data.addedGroupIds) {
        groupUpdater.addOneItemToGroup(
          transaction,
          groupId,
          property.id,
          AssetType.Property,
          property.subtype!
        );
      }
    }
    if (data.removedGroupIds && groupUpdater) {
      for (const groupId of data.removedGroupIds) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, property.id);
      }
    }
    if (repoAndAggregates.insurance) {
      const insuranceData = repoAndAggregates.insurance;
      insuranceData.aggregates.forEach((aggregate) => {
        insuranceData.repo.commitWithState(aggregate);
      });
    }
    if (repoAndAggregates.cashAndBanking) {
      const accountData = repoAndAggregates.cashAndBanking;
      accountData.aggregates.forEach((aggregate) => {
        accountData.repo.commitWithState(aggregate);
      });
    }

    if (data.setActions) {
      data.setActions.forEach((action) => {
        const docRef = this.actionDocRef(property.id, action.id);
        action.ownerId = this.refs.currentRefs.userId;
        transaction.set(docRef, action);
      });
    }
    if (data.removedActionIds) {
      data.removedActionIds.forEach((id) => {
        const docRef = this.actionDocRef(property.id, id);
        transaction.delete(docRef);
      });
    }
  }

  async getAllRaw(): Promise<Property[]> {
    const collectionRef = getFirestoreCollection(Property);

    return await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) =>
        PropertyUtils.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getAll(): Promise<Property[]> {
    const collectionRef = getFirestoreCollection(Property);
    const properties = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) =>
        PropertyUtils.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
    return properties.sort(
      (a, b) => b.updateAt.getTime() - a.updateAt.getTime()
    );
  }

  async getAllWithItems(): Promise<PropertyUtils.WithItems[]> {
    const allItems = await this.syncAndGetAllSummaryItems();
    const properties = await this.getAll();
    return Promise.all(
      properties.map(async (p) => {
        const items = await this.getUnsoldItems(
          new SyncAllItemsGetter(allItems),
          p.id
        );
        items.forEach(LocationItem.convertDate);
        return { ...p, items };
      })
    );
  }

  async getByIds(ids: string[]): Promise<Property[]> {
    const result = await getAssetsByIds<Encrypted<Property>>(
      this.refs.currentRefs,
      AssetType.Property,
      ids
    );
    return Promise.all(
      result.map((v) =>
        PropertyUtils.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getById(id: string): Promise<Property> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    return CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) =>
        PropertyUtils.decryptAndConvertDate(v, this.Encryption.current)
      );
  }

  /**
   * Get properties with items; IT CAN BE EXPENSIVE, so use it only when `items` field is necessary
   * Or use getByIds to get `Property[]`
   * It is still possible `items` will be outdated
   */
  async getByIdsWithItems(ids: string[]): Promise<PropertyUtils.WithItems[]> {
    const result = await getAssetsByIds<Encrypted<Property>>(
      this.refs.currentRefs,
      AssetType.Property,
      ids
    );
    const allItems = await CoreFirestore.runTransaction((tx) =>
      this.keepSummarySyncedInTransaction(tx).then((v) => {
        v.setStates.forEach((v) => v());
        return v.items;
      })
    );
    return Promise.all(
      result.map(async (v) => {
        const decrypted = await PropertyUtils.decryptAndConvertDate(
          v,
          this.Encryption.current
        );
        const items = await this.getUnsoldItems(
          new SyncAllItemsGetter(allItems),
          decrypted.id
        );
        items.forEach(LocationItem.convertDate);
        return { ...decrypted, items };
      })
    );
  }

  /**
   * Get property with items; IT CAN BE EXPENSIVE, so use it only when `items` field is necessary
   * Or use getById to get `Property`
   * It is still possible `items` will be outdated
   */
  async getByIdWithItems(id: string): Promise<PropertyUtils.WithItems> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    return CoreFirestore.runTransaction(async (transaction) => {
      const property = await transaction
        .get(docRef)
        .then(checkAndGetData)
        .then((v) =>
          PropertyUtils.decryptAndConvertDate(v, this.Encryption.current)
        );
      const result = PropertyUtils.assureVersion(property);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();

      const asyncAllItemsGetter = new AsyncAllItemsGetter(() =>
        this.keepSummarySyncedInTransaction(transaction)
      );
      const items = await this.getUnsoldItems(asyncAllItemsGetter, id);
      asyncAllItemsGetter.setStates.forEach((v) => v());
      items.forEach(LocationItem.convertDate);
      return { ...property, items };
    });
  }

  async getAssetsBreakdown(
    propertyId: string
  ): Promise<PropertyAssetsBreakdown> {
    const [cashAndBankingSummary, liabilityRelations, allItems] =
      await Promise.all([
        this.summaryManager
          .get(AssetType.CashAndBanking)
          .syncAndGetData()
          .then((v) => v.summary),
        CoreFirestore.getDocsFromCollection(
          this.refs.currentRefs.Relations,
          CoreFirestore.where(
            "keyword",
            "==",
            RelationSearchKeyword.LiabilityAllocated
          )
        ).then(getQueriedData),
        this.syncAndGetAllSummaryItems(),
      ]);

    this.exRate.checkInitialized();
    const currency = this.exRate.BaseCurrency as Currency;
    const exRate = await this.exRate.getToTargetExchangeRates(currency);

    const assetsBreakdownMap: {
      assets: { [label: string]: BreakdownItem };
      liabilities: { [label: string]: BreakdownItem };
    } = { assets: {}, liabilities: {} };
    let assetsOfAllItems = new Decimal(0);
    let liabilitiesOfAllItems = new Decimal(0);

    // get items under property
    const items = await this.getUnsoldItems(
      new SyncAllItemsGetter(allItems),
      propertyId
    );

    // assets breakdown
    items.map((item) => {
      const label =
        item.assetType === AssetType.OtherCollectables
          ? item.subtype
          : item.assetType;
      const breakdownItem: BreakdownItem = {
        label,
        value: {
          currency,
          value: new Decimal(item.value.value)
            .mul(this.exRate.getToBaseExchangeRate(item.value.currency).rate)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .toNumber(),
        },
        // compute later
        percentage: 0,
      };
      if (assetsBreakdownMap.assets[breakdownItem.label]) {
        assetsBreakdownMap.assets[breakdownItem.label].value.value =
          new Decimal(
            assetsBreakdownMap.assets[breakdownItem.label].value.value
          )
            .add(breakdownItem.value.value)
            .toDecimalPlaces(AllowedDecimalPlaces)
            .toNumber();
      } else {
        assetsBreakdownMap.assets[breakdownItem.label] = breakdownItem;
      }
      assetsOfAllItems = assetsOfAllItems.add(breakdownItem.value.value);
    });

    // liabilities breakdown
    const assetLiabilities = await getAssetLiabilities(
      cashAndBankingSummary,
      liabilityRelations,
      exRate,
      Object.keys(allItems) as SupportLiabilityType[]
    );
    items.forEach((v) => {
      if (!assetLiabilities[v.assetType])
        throw new Error(`assetLiabilities not found: ${v.assetType}`);
      if (!assetLiabilities[v.assetType]![v.assetId]) return;
      const label =
        v.assetType === AssetType.OtherCollectables ? v.subtype : v.assetType;
      const breakdownItem: BreakdownItem = {
        label,
        value: assetLiabilities[v.assetType]![v.assetId],
        // compute later
        percentage: 0,
      };
      if (assetsBreakdownMap.liabilities[breakdownItem.label]) {
        assetsBreakdownMap.liabilities[breakdownItem.label].value.value =
          new Decimal(
            assetsBreakdownMap.liabilities[breakdownItem.label].value.value
          )
            .add(breakdownItem.value.value)
            .toNumber();
      } else {
        assetsBreakdownMap.liabilities[breakdownItem.label] = breakdownItem;
      }
      liabilitiesOfAllItems = liabilitiesOfAllItems.add(
        breakdownItem.value.value
      );
    });

    // compute percentage
    Object.values(assetsBreakdownMap.assets).map((v) => {
      v.percentage = calculatePercentage(v.value.value, assetsOfAllItems);
    });
    Object.values(assetsBreakdownMap.liabilities).map((v) => {
      v.percentage = calculatePercentage(v.value.value, liabilitiesOfAllItems);
    });

    const assetsBreakdown: PropertyAssetsBreakdown = {
      assets: Object.values(assetsBreakdownMap.assets).sort(
        (a, b) => b.value.value - a.value.value
      ),
      liabilities: Object.values(assetsBreakdownMap.liabilities).sort(
        (a, b) => b.value.value - a.value.value
      ),
    };
    return assetsBreakdown;
  }

  private async syncAndGetAllSummaryItems() {
    const [otherCollectables, belonging, art, wine] = await Promise.all([
      this.summaryManager
        .get(AssetType.OtherCollectables)
        .syncAndGetData()
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.Belonging)
        .syncAndGetData()
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.Art)
        .syncAndGetData()
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.WineAndSpirits)
        .syncAndGetData()
        .then((v) => v.summary.wines),
    ]);
    return {
      [AssetType.OtherCollectables]: otherCollectables,
      [AssetType.Belonging]: belonging,
      [AssetType.Art]: art,
      [AssetType.WineAndSpirits]: wine,
    };
  }

  private async forceSyncAndGetAllSummaryItems() {
    const [otherCollectables, belonging, art, wine] = await Promise.all([
      this.summaryManager
        .get(AssetType.OtherCollectables)
        .syncAndGetData(true)
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.Belonging)
        .syncAndGetData(true)
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.Art)
        .syncAndGetData(true)
        .then((v) => v.summary.items),
      this.summaryManager
        .get(AssetType.WineAndSpirits)
        .syncAndGetData(true)
        .then((v) => v.summary.wines),
    ]);
    return {
      [AssetType.OtherCollectables]: otherCollectables,
      [AssetType.Belonging]: belonging,
      [AssetType.Art]: art,
      [AssetType.WineAndSpirits]: wine,
    };
  }

  private async keepSummarySyncedInTransaction(
    transaction: Transaction,
    skipIfRecentlySynced = false
  ) {
    const setStates = (
      await Promise.all([
        this.summaryManager
          .get(AssetType.Art)
          .keepSyncedInTransaction(transaction, skipIfRecentlySynced),
        this.summaryManager
          .get(AssetType.OtherCollectables)
          .keepSyncedInTransaction(transaction, skipIfRecentlySynced),
        this.summaryManager
          .get(AssetType.Belonging)
          .keepSyncedInTransaction(transaction, skipIfRecentlySynced),
        this.summaryManager
          .get(AssetType.WineAndSpirits)
          .keepSyncedInTransaction(transaction, skipIfRecentlySynced),
      ])
    ).flatMap((v) => v);
    return {
      items: {
        [AssetType.Art]: this.summaryManager.get(AssetType.Art).getData()
          .summary!.items,
        [AssetType.OtherCollectables]: this.summaryManager
          .get(AssetType.OtherCollectables)
          .getData().summary!.items,
        [AssetType.Belonging]: this.summaryManager
          .get(AssetType.Belonging)
          .getData().summary!.items,
        [AssetType.WineAndSpirits]: this.summaryManager
          .get(AssetType.WineAndSpirits)
          .getData().summary!.wines,
      },
      setStates,
    };
  }

  private async getUnsoldItems<T extends GetAllItems>(
    allItemsGetter: T,
    propertyId: string
  ): Promise<LocationItem[]> {
    const relations = await CoreFirestore.getDocsFromCollection(
      this.refs.currentRefs.Relations,
      CoreFirestore.orderBy(
        toKeywordWithId(RelationSearchKeyword.PropertyAsset, propertyId),
        "desc"
      )
    ).then(getQueriedData);
    const currency = this.exRate.BaseCurrency as Currency;
    if (relations.length == 0) {
      return [];
    }
    const allItems = await allItemsGetter.getAllItems();

    const result: { [id: string]: LocationItem } = {};
    relations.forEach((relation) => {
      if (relation.assetType == AssetType.WineAndSpirits) {
        const { id: purchaseId, secondaryId: wineId } = relation;
        if (!wineId) throw new DataPoisoned("wineId not found in relation");
        const bottles =
          relation[propertyId].relations[RoleToAsset.AssetLocation]?.bottles;
        if (!bottles) throw new DataPoisoned("bottles not found in relation");
        const wine = allItems[AssetType.WineAndSpirits][wineId];
        if (!wine) {
          throw new Error("Wine not found");
        }
        const purchase = wine.purchases.find(
          (purchase) => purchase.id === purchaseId
        );
        if (!purchase) {
          throw new Error("Purchase not found");
        }

        const item: LocationItem = {
          assetId: wineId,
          assetType: AssetType.WineAndSpirits,
          subtype: wine.subtype,
          bottles: bottles.map(({ bottleId }) => ({
            purchaseId,
            bottleId,
          })),
          value: {
            currency,
            value: new Decimal(bottles.length)
              .mul(purchase.valuePerBottle.value)
              .mul(
                this.exRate.getToBaseExchangeRate(
                  purchase.valuePerBottle.currency
                ).rate
              )
              .toDecimalPlaces(AllowedDecimalPlaces)
              .toNumber(),
          },
          updateAt: wine.updateAt,
        };
        if (result[wineId]) {
          result[wineId].bottles!.push(...item.bottles!);
          result[wineId].value.value = addDecimal(
            result[wineId].value.value,
            item.value.value
          );
        } else {
          result[wineId] = item;
        }
      } else {
        const { id: assetId } = relation;
        const assetType = relation.assetType as LocationItem.SupportedAssetType;
        const asset = allItems[assetType][assetId];
        const location =
          relation[propertyId].relations[RoleToAsset.AssetLocation]?.location;
        if (!asset || !location) {
          throw new Error("Asset or location not found");
        }
        if ((<ArtSummary["items"][string]>asset).sold) return;
        const item: LocationItem = {
          assetId: assetId,
          assetType,
          subtype: asset.subtype,
          value: asset.value,
          updateAt: asset.updateAt,
          sold: false,
        };
        if (location.roomId) {
          item.roomId = location.roomId;
        }
        result[assetId] = item;
      }
    });

    return Object.values(result);
  }

  async add(req: PropertyUtils.CreateFields) {
    await CoreFirestore.runTransaction(async (transaction) => {
      const newDocRef = this.refs.currentRefs.getAssetDocRef<
        Encrypted<Property>
      >(AssetType.Property, req.id);

      await transaction.get(newDocRef).then(checkDuplicated);
      const repo = await this.newRepo(transaction);
      await this.addInRepo(req, repo);
    });
  }

  async newRepo(tx: Transaction) {
    return (await PropertiesRepo.newRepo(
      this.refs.currentRefs,
      tx
    )) as ClientRepo<Encrypted<Property>, Command, Event>;
  }

  async addInRepo(
    req: PropertyUtils.CreateFields,
    repo: ClientRepo<Encrypted<Property>, Command, Event>
  ) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      req.id
    );

    const property = PropertyUtils.fromCreate(
      req,
      this.refs.currentRefs.userId
    );
    validateWithGroups(property, Property, [ValidationGroup.OnCreate]);

    const valuation = await Valuation.getSystemCreationEncrypted(
      CoreFirestore.genAssetId(),
      this.refs.currentRefs.userId,
      property.value,
      this.Encryption.current
    );

    const ar = PropertyUtils.newARWithState(
      PropertyUtils.defaultStateValue(),
      []
    );
    const encrypted = await PropertyUtils.encrypt(
      property,
      this.Encryption.current
    );
    ar.handle(
      Command.createAsset(this.refs.selfRefs.userId, encrypted, valuation)
    );
    const events = ar.applyAllChanges();
    await this.handleRelatedAggregates(repo.transaction, ar);
    //commit
    repo.manualCommit(ar, events);
  }

  async update(req: Property) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      req.id
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = PropertyUtils.assureVersion(
        currentUndecrypted as Property
      );
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const currentData = await PropertyUtils.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const {
        updates: propertyUpdate,
        metadata: {
          addedToGroup,
          removedFromGroup,
          newImages,
          removedImages,
          newMainImage,
        },
      } = PropertyUtils.intoUpdate(currentData, req);

      validateWithGroups(propertyUpdate, Property, [ValidationGroup.OnUpdate]);

      //#HACK: We need to add this hack to so that summary and class-trasformer can know which type the Property.detail actually is according to Property.ownershipType
      if (!propertyUpdate.ownershipType) {
        propertyUpdate.ownershipType = currentData.ownershipType;
      }

      const encryptedUpdate = await this.Encryption.current.encryptObject(
        propertyUpdate,
        Property
      );
      /* #NOTE
       ** for this case, if `notes` field is not updated (== undefined), there will be no EncryptionFieldKey and IVSaltFieldKey field in encryptedUpdate
       ** if there are more than one fields like `notes`, ex: Belonging type has `notes` and `attributeNotes`, then we need to add more logic here to handle them
       **    => for example, if the updateObject only update `notes` field for this time,
       **       we need to manually assign back the `attributeNotes` from currentData to updateObject before calling encryptObject(),
       **       so that the new `_encrypted` data will contains both the lastest `notes` and `attributeNotes`
       */

      //#NOTE: undefined => unchanged , null => delete removable field in firestore

      /* #NOTE
      ** OwnershipType unchanged and detail unchanged => do nothing
      ** OwnershipType changed, detail must changed
           => when OwnerType == Rent, encryptedUpdate.detail is already looks like what we want
      */

      //#HACK: deal with detail when OwnerType == Own
      if (
        propertyUpdate.ownershipType === OwnershipType.Own ||
        //#NOTE: OwnershipType unchanged but detail changed
        (propertyUpdate.ownershipType == undefined &&
          currentData.ownershipType == OwnershipType.Own)
      ) {
        encryptedUpdate.detail = propertyUpdate.detail;
      }

      const ar = PropertyUtils.newARWithState(currentUndecrypted, []);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup,
          newImages,
          newMainImage,
          removedImages
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  //#NOTE for other asset to add new room on asset creation
  async addRoom(
    propertyId: string,
    roomName: string,
    position: string
  ): Promise<string> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );
    const newRoomId = CoreFirestore.genAssetId();

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      let update: UpdateObject<Encrypted<Property>>;

      if (currentUndecrypted.configuration) {
        update = { configuration: { ...currentUndecrypted.configuration } };
      } else {
        update = {
          configuration: Configuration.defaultValue<Encrypted<Configuration>>(),
        };
      }

      const encryptedRoom = await this.Encryption.current.encryptObject(
        {
          id: newRoomId,
          name: roomName,
          position: [position],
          otherRoomType: OtherRoomType.OtherRoom,
        },
        OtherRoom
      );

      //#HACK: use otherRoom!
      update.configuration!.otherRoom!.push(encryptedRoom);

      const ar = PropertyUtils.newARWithState(currentUndecrypted, []);
      ar.handle(Command.updateAsset(this.refs.selfRefs.userId, update));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });

    return newRoomId;
  }

  private relocateAssets(
    items: LocationItem[],
    fromId: string,
    newLocation: LocationInfo.Encrypted
  ): Promise<void>[] {
    const result: Promise<void>[] = [];
    for (const item of items) {
      switch (item.assetType) {
        case AssetType.Belonging:
        case AssetType.OtherCollectables:
          result.push(
            BelongingsRepo.relocate(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              item.assetId,
              item.assetType,
              fromId,
              newLocation
            )
          );
          break;
        case AssetType.Art:
          result.push(
            ArtsRepo.relocate(
              this.refs.currentRefs,
              this.refs.selfRefs.userId,
              item.assetId,
              fromId,
              newLocation
            )
          );
          break;
        case AssetType.WineAndSpirits: {
          if (!item.bottles || item.bottles.length == 0) break;
          const purchaseIds = new Set<string>(
            item.bottles.map((v) => v.purchaseId)
          );
          purchaseIds.forEach((purchaseId) => {
            result.push(
              WineAndSpiritsRepo.relocate(
                this.refs.currentRefs,
                this.refs.selfRefs.userId,
                item.assetId,
                purchaseId,
                newLocation,
                fromId
              )
            );
          });
          break;
        }
        default:
          throw new Error("AssetType not supported");
      }
    }
    return result;
  }

  private doDeleteOrArchive(
    docRef: DocumentReference<Encrypted<Property>>,
    archive: boolean,
    doSync: boolean,
    fromId?: string,
    newLocation?: LocationInfo.Encrypted
  ): AsyncTask {
    return AsyncTask.retry(async () => {
      if (doSync) await this.forceSyncAndGetAllSummaryItems();
      const result = await CoreFirestore.runTransaction(async (transaction) => {
        const currentData = await transaction.get(docRef).then(checkAndGetData);
        const result = PropertyUtils.assureVersion(currentData);
        if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
        const repo = await PropertiesRepo.newRepo(
          this.refs.currentRefs,
          transaction
        );
        const asyncAllItemsGetter = new AsyncAllItemsGetter(() =>
          this.keepSummarySyncedInTransaction(transaction)
        );
        const items = await this.getUnsoldItems(
          asyncAllItemsGetter,
          currentData.id
        );
        //missing part, probably added in the relocating process, so the amount should be small
        if (items.length > 0) {
          if (!newLocation || !fromId)
            throw new InvalidInput("There are still items in the property");
          if (newLocation.locationType === LocationType.MyProperty) {
            await PropertiesRepo.checkActiveAndGet(
              this.refs.currentRefs,
              transaction,
              newLocation.locationId
            );
          }
          return items;
        } else {
          const ar = PropertyUtils.newARWithState(currentData, items);
          if (archive)
            ar.handle(Command.archiveAsset(this.refs.selfRefs.userId));
          else ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
          const events = ar.applyAllChanges();
          await this.handleRelatedAggregates(transaction, ar, !archive);
          asyncAllItemsGetter.setStates.forEach((v) => v());
          //commit
          repo.manualCommit(ar, events);
          return undefined;
        }
      });
      if (result) {
        for (const promise of this.relocateAssets(
          result,
          fromId!,
          newLocation!
        )) {
          await promise;
        }
        return true;
      } else {
        return false;
      }
    });
  }

  private async relocateAndDoTask(
    id: string,
    archive: boolean,
    relocateTo?: LocationInfo
  ) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    const items = await CoreFirestore.runTransaction(async (transaction) => {
      const asyncAllItemsGetter = new AsyncAllItemsGetter(() =>
        this.keepSummarySyncedInTransaction(transaction)
      );
      const result = await this.getUnsoldItems(asyncAllItemsGetter, id);
      asyncAllItemsGetter.setStates.forEach((v) => v());
      return result;
    });

    const encryptedRelocateTo = relocateTo
      ? await LocationInfo.encrypt(relocateTo, this.Encryption.current)
      : undefined;
    if (items.length == 0)
      return new AsyncTaskExecutor([
        this.doDeleteOrArchive(
          docRef,
          archive,
          items.length > 0,
          id,
          encryptedRelocateTo
        ),
      ]);
    else {
      if (!encryptedRelocateTo) throw new Error("relocateTo is not defined");
      return new AsyncTaskExecutor([
        ...this.relocateAssets(items, id, encryptedRelocateTo).map((v) =>
          AsyncTask.once(async () => v)
        ),
        this.doDeleteOrArchive(
          docRef,
          archive,
          items.length > 0,
          id,
          encryptedRelocateTo
        ),
      ]);
    }
  }

  async delete(
    id: string,
    relocateTo?: LocationInfo
  ): Promise<IAsyncTaskExecutor> {
    return this.relocateAndDoTask(id, false, relocateTo);
  }

  async archive(
    id: string,
    relocateTo?: LocationInfo
  ): Promise<IAsyncTaskExecutor> {
    return this.relocateAndDoTask(id, true, relocateTo);
  }

  async restoreArchived(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(Command.restoreArchivedAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      repo.manualCommit(ar, events);
    });
  }

  async getActionById(assetId: string, actionId: string): Promise<Action> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );
    const actionCollectionRef = actionRefFromAssetDocRef(docRef);
    const actionDocRef = CoreFirestore.docFromCollection(
      actionCollectionRef,
      actionId
    );
    return await CoreFirestore.getDoc(actionDocRef)
      .then(checkAndGetData)
      .then((v) => Action.decryptAndConvertDate(v, this.Encryption.current));
  }

  async getAllActions(
    assetId: string,
    actionType?: ActionType
  ): Promise<Action[]> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );
    const actionCollectionRef = actionRefFromAssetDocRef(docRef);
    const constraints = [
      CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId),
    ];
    if (actionType)
      constraints.push(CoreFirestore.where("actionType", "==", actionType));
    const actions = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          actionCollectionRef,
          ...constraints
        ).then(getQueriedData)
      ).map((v) => Action.decryptAndConvertDate(v, this.Encryption.current))
    );
    return actions.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  //#NOTE updateValuation is actually an addValuation
  async addValuation(assetId: string, createFields: Valuation.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const command = await buildAddValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        createFields.updateValue
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateValuation(assetId: string, updateFields: Valuation.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const { command, valuation } = await buildUpdateValuationCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        updateFields.id,
        updateFields,
        this.Encryption.current,
        currentData.valueSourceId
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: {
          [updateFields.id]: valuation,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteValuation(assetId: string, valuationId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const command = await buildDeleteValuationCommand(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        valuationId,
        this.Encryption.current
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async markAsSold(propertyId: string, createFields: SoldInfo.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const command = await buildMarkAsSoldCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateSoldInfo(propertyId: string, updateFields: SoldInfo.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const { command, soldInfo } = await buildUpdateSoldInfoCommand(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        updateFields,
        this.Encryption.current
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: {
          [updateFields.id]: soldInfo,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteSoldInfo(propertyId: string, soldInfoId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const { command, soldInfo } = await buildDeleteSoldInfoCommand(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        soldInfoId
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: {
          [soldInfoId]: soldInfo,
        },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async addOffer(assetId: string, createFields: Offer.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const command = await buildAddActionCommand<OfferBundle>(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        Offer.fromCreate,
        Offer.validateEncryptedPart,
        Offer.encrypt
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateOffer(assetId: string, updateFields: Offer.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();

      const { command, action } = await buildUpdateActionCommand<OfferBundle>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        updateFields,
        this.Encryption.current,
        ActionType.AddOffer,
        Offer.encryptedKeysArray,
        Offer.validateEncryptedPart,
        Offer.encryptPartial,
        Offer.decryptAndConvertDate,
        Offer.intoUpdate
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: { [updateFields.id]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async deleteOffer(assetId: string, actionId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const { command, action } = await buildDeleteActionCommand<OfferBundle>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        actionId,
        ActionType.AddOffer,
        this.Encryption.current,
        Offer.decrypt
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: { [actionId]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  // NOTE remember to remove the action that is updating
  checkRentOutDateOverlap(
    startDate: Date,
    endDate: Date,
    existingActions: RentInfo[]
  ) {
    let overlap = false;
    for (const existingAction of existingActions) {
      if (
        startDate <= existingAction.endDate &&
        endDate >= existingAction.startDate
      ) {
        overlap = true;
        break;
      }
    }
    return overlap;
  }

  async rentOut(assetId: string, createFields: RentInfo.CreateFields) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const allRentOutActions = (await this.getAllActions(
        assetId,
        ActionType.RentOut
      )) as RentInfo[];
      if (
        this.checkRentOutDateOverlap(
          createFields.startDate,
          createFields.endDate,
          allRentOutActions
        )
      ) {
        throw new InvalidInput("New rent out date overlap with existing ones");
      }
      const command = await buildAddActionCommand<RentBundle>(
        docRef,
        transaction,
        this.refs.currentRefs.userId,
        this.refs.selfRefs.userId,
        createFields,
        this.Encryption.current,
        RentInfo.fromCreate,
        RentInfo.validateEncryptedPart,
        RentInfo.encrypt
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, []);
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async updateRentInfo(assetId: string, updateFields: RentInfo.Update) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const allRentOutActions = (
        await this.getAllActions(assetId, ActionType.RentOut)
      ).filter((a) => a.id !== updateFields.id) as RentInfo[];
      if (
        this.checkRentOutDateOverlap(
          updateFields.startDate,
          updateFields.endDate,
          allRentOutActions
        )
      ) {
        throw new InvalidInput("New rent out date overlap with existing ones");
      }
      const { command, action } = await buildUpdateActionCommand<RentBundle>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        updateFields,
        this.Encryption.current,
        ActionType.RentOut,
        RentInfo.encryptedKeysArray,
        RentInfo.validateEncryptedPart,
        RentInfo.encryptPartial,
        RentInfo.decryptAndConvertDate,
        RentInfo.intoUpdate
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: { [updateFields.id]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  // #NOTE rentInfo can not be deleted now. May need this function in the future.
  async deleteRentInfo(assetId: string, actionId: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      assetId
    );

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const { command, action } = await buildDeleteActionCommand<RentBundle>(
        docRef,
        transaction,
        this.refs.selfRefs.userId,
        actionId,
        ActionType.RentOut,
        this.Encryption.current,
        RentInfo.decrypt
      );

      const repo = await PropertiesRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const ar = PropertyUtils.newARWithState(currentData, [], {
        actions: { [actionId]: action },
      });
      ar.handle(command);
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async getAssetsRoomFilterInfo(
    propertyId: string,
    keyword?: string,
    limit?: number
  ) {
    // check rooms in property and get room name
    const property = await this.getById(propertyId);
    //#QUESTION should we include car park here?
    const allRooms: (Bedroom | Bathroom | OtherRoom | CarPark)[] = [];
    if (property.configuration) {
      allRooms.push(
        ...property.configuration.bedroom,
        ...property.configuration.bathroom,
        ...property.configuration.otherRoom,
        ...property.configuration.carPark
      );
    }

    // get roomIds
    const allItems = await this.syncAndGetAllSummaryItems();
    const items = await this.getUnsoldItems(
      new SyncAllItemsGetter(allItems),
      propertyId
    );
    const roomIds = new Set<string>();
    items.forEach((item) => {
      if (item.roomId) roomIds.add(item.roomId);
    });

    // filter by keyword
    const result: { id: string; name: string }[] = [];
    Array.from(roomIds).forEach((id) => {
      const idx = allRooms.findIndex((v) => v.id == id);
      if (idx == -1) throw new DataPoisoned("Room id not found");
      const name = allRooms[idx].name;
      allRooms.splice(idx, 1);
      if (keyword && !name.toLowerCase().includes(keyword.toLowerCase()))
        return;
      result.push({ id, name });
    });
    result.sort((a, b) => a.name.localeCompare(b.name));
    return limit !== undefined && result.length > limit
      ? result.slice(0, limit)
      : result;
  }
}

export namespace PropertiesRepo {
  export async function checkActiveAndGet(
    refs: Refs,
    transaction: Transaction,
    propertyId: string
  ) {
    const propertyRef = refs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );
    const property = await transaction.get(propertyRef).then(checkAndGetData);
    if (property.closedWith || property.archived || property.readonly)
      throw new InvalidInput("Property is closed or archived or readonly");
    return property;
  }

  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<Encrypted<Property>, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.Property,
      new PropertyStateWriter(getFirestoreCollection(Property), refs.Relations)
    );
  }

  export async function newArAndUpdateInsurance(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    propertyId: string,
    insuranceId: string,
    isCreate: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const ar = PropertyUtils.newARWithState(currentData, []);
    ar.handle(
      isCreate
        ? Command.addInsurance(executerId, insuranceId)
        : Command.removeInsurance(executerId, insuranceId)
    );
    return ar;
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    propertyId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      propertyId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = PropertyUtils.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
    const ar = PropertyUtils.newARWithState(currentData, []);
    const currentGroupIds = ar.state().groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }

  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();

      const repo = await newRepo(refs.currentRefs, transaction);
      const ar = PropertyUtils.newARWithState(currentUndecrypted, []);
      const currentGroupIds = ar.state().groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Encrypted<Property>>(
      AssetType.Property,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = PropertyUtils.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) PropertyUtils.handleOutDated();
      const ownerDetail = <OwnerDetail>currentUndecrypted.detail;
      const encryptedUpdate: UpdateObject<Encrypted<Property>> = {
        ownershipType: currentUndecrypted.ownershipType,
        detail: currentUndecrypted.detail,
      };
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Seller:
            if (contactId !== ownerDetail.acquisition?.sellerId)
              throw new DataPoisoned("sellerId mismatch");
            (<OwnerDetail>encryptedUpdate.detail).acquisition.sellerId = null!;
            break;
          case RoleToAsset.Landlord:
            if (
              contactId !== (<RentalDetail>currentUndecrypted.detail).landlordId
            )
              throw new DataPoisoned("landlordId mismatch");
            (<RentalDetail>encryptedUpdate.detail).landlordId = null!;
            break;
          case RoleToAsset.Shareholder: {
            if (!ownerDetail.ownership)
              throw new DataPoisoned("ownership not found");
            const updateShareholder = ownerDetail.ownership.shareholder.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateShareholder.length ===
              ownerDetail.ownership.shareholder.length
            )
              throw new DataPoisoned("shareholderId not found");
            (<OwnerDetail>encryptedUpdate.detail).ownership = {
              myOwnership: ownerDetail.ownership.myOwnership,
              shareholder: updateShareholder,
            };
            break;
          }
          case RoleToAsset.Beneficiary: {
            if (!ownerDetail.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = ownerDetail.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (updateBeneficiary.length === ownerDetail.beneficiary.length)
              throw new DataPoisoned("beneficiaryId not found");
            (<OwnerDetail>encryptedUpdate.detail).beneficiary =
              updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction);
      const ar = PropertyUtils.newARWithState(currentUndecrypted, []);
      ar.handle(Command.updateAsset(executerId, encryptedUpdate));
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

type AllItems = {
  [AssetType.OtherCollectables]: BelongingSummary["items"];
  [AssetType.Belonging]: BelongingSummary["items"];
  [AssetType.Art]: ArtSummary["items"];
  [AssetType.WineAndSpirits]: WineSummary["wines"];
};
interface GetAllItems {
  getAllItems(): Promise<AllItems>;
}

class SyncAllItemsGetter implements GetAllItems {
  private inner: AllItems;
  constructor(data: AllItems) {
    this.inner = data;
  }

  async getAllItems(): Promise<AllItems> {
    return this.inner;
  }
}

class AsyncAllItemsGetter implements GetAllItems {
  items: undefined | AllItems;
  setStates: (() => void)[] = [];
  fn: () => Promise<{ items: AllItems; setStates: (() => void)[] }>;
  promise: undefined | Promise<void> = undefined;

  constructor(
    fn: () => Promise<{ items: AllItems; setStates: (() => void)[] }>
  ) {
    this.fn = fn;
  }

  async getAllItems(): Promise<AllItems> {
    if (!this.items) {
      if (this.promise === undefined) {
        this.promise = this.fn().then(({ items, setStates }) => {
          this.promise = undefined;
          this.items = items;
          this.setStates = setStates;
        });
      }
      await this.promise;
    }

    return this.items!;
  }
}

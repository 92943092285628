import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/classnames'

enum Strength {
  Strong = 'Strong',
  Medium = 'Medium',
  Weak = 'Weak'
}

type Config = {
  key: Strength
  color: string
  level: number
}

const configMap: Record<Strength, Config> = {
  [Strength.Weak]: { key: Strength.Weak, color: '#f87171', level: 1 },
  [Strength.Medium]: { key: Strength.Medium, color: '#fbbf24', level: 2 },
  [Strength.Strong]: { key: Strength.Strong, color: '#4ade80', level: 3 }
}

interface PasswordStrengthBarProps {
  className?: string
  password: string
}

export function PasswordStrengthBar({ className, password }: PasswordStrengthBarProps) {
  const { t } = useTranslation()

  const config: Config = useMemo(() => {
    const lengthCriteria = password.replace(/\s/g, '').length >= 12
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /[0-9]/.test(password)
    const hasSpecialCharacter = /[^\w\s]/.test(password)

    const characterTypesCount = [hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter].filter(Boolean).length

    if (lengthCriteria && characterTypesCount >= 3) {
      return configMap[Strength.Strong]
    } else if (lengthCriteria && characterTypesCount >= 2) {
      return configMap[Strength.Medium]
    } else {
      return configMap[Strength.Weak]
    }
  }, [password])

  return (
    password && (
      <div className={cn('mb-5 flex flex-col gap-y-5', className)}>
        {/* bar */}
        <div className={'grid basis-2 grid-cols-3 gap-x-1 overflow-hidden rounded'}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} style={{ backgroundColor: index < config.level ? config.color : 'white' }} />
          ))}
        </div>
        {/* hint */}
        <div className={'flex flex-row items-center gap-x-2'}>
          <span className={'text-sm text-white'}>{t('validation:PasswordStrength')}</span>
          <span className={'font-bold'} style={{ color: config.color }}>
            {t(`validation:${config.key}`)}
          </span>
        </div>
      </div>
    )
  )
}

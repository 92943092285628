import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%237C98FF%22%2F%3E%3Cpath%20d%3D%22M9.296%2027.374c0-.375-.132-.664-.396-.867-.264-.203-.74-.408-1.427-.615-.687-.207-1.234-.438-1.64-.692-.778-.488-1.166-1.125-1.166-1.91%200-.687.28-1.254.84-1.699.561-.445%201.288-.668%202.183-.668.594%200%201.123.11%201.588.328.465.219.83.53%201.096.935.265.404.398.852.398%201.344H9.295c0-.445-.14-.794-.418-1.045-.28-.252-.68-.378-1.199-.378-.484%200-.86.103-1.128.31-.267.207-.401.496-.401.867%200%20.313.145.574.434.783.289.209.765.412%201.43.609.663.197%201.197.422%201.599.674.402.252.697.54.885.867.187.326.28.708.28%201.145%200%20.711-.272%201.277-.816%201.697-.545.42-1.285.63-2.218.63a4.168%204.168%200%200%201-1.702-.343c-.518-.229-.92-.544-1.207-.946a2.358%202.358%200%200%201-.431-1.407h1.482c0%20.485.16.86.48%201.125.321.266.78.399%201.378.399.515%200%20.903-.105%201.163-.314.26-.209.39-.485.39-.829Zm5.789.34%202.168-6.674h1.628l-3.07%208.531h-1.435l-3.059-8.53h1.623l2.145%206.673Zm11.296.75c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715%200-1.348-.161-1.898-.486a3.24%203.24%200%200%201-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725%201.479-1.087%202.584-1.087.95%200%201.703.234%202.262.703.558.469.894%201.145%201.008%202.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664%200-1.169.239-1.514.717-.346.479-.525%201.18-.536%202.107v.656c0%20.922.194%201.638.583%202.147.388.51.928.765%201.62.765.757%200%201.297-.172%201.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%231F42C3%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileSvgIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#7C98FF'}
      />
      <path
        d={
          'M9.296 27.374c0-.375-.132-.664-.396-.867-.264-.203-.74-.408-1.427-.615-.687-.207-1.234-.438-1.64-.692-.778-.488-1.166-1.125-1.166-1.91 0-.687.28-1.254.84-1.699.561-.445 1.288-.668 2.183-.668.594 0 1.123.11 1.588.328.465.219.83.53 1.096.935.265.404.398.852.398 1.344H9.295c0-.445-.14-.794-.418-1.045-.28-.252-.68-.378-1.199-.378-.484 0-.86.103-1.128.31-.267.207-.401.496-.401.867 0 .313.145.574.434.783.289.209.765.412 1.43.609.663.197 1.197.422 1.599.674.402.252.697.54.885.867.187.326.28.708.28 1.145 0 .711-.272 1.277-.816 1.697-.545.42-1.285.63-2.218.63a4.168 4.168 0 0 1-1.702-.343c-.518-.229-.92-.544-1.207-.946a2.358 2.358 0 0 1-.431-1.407h1.482c0 .485.16.86.48 1.125.321.266.78.399 1.378.399.515 0 .903-.105 1.163-.314.26-.209.39-.485.39-.829Zm5.789.34 2.168-6.674h1.628l-3.07 8.531h-1.435l-3.059-8.53h1.623l2.145 6.673Zm11.296.75c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715 0-1.348-.161-1.898-.486a3.24 3.24 0 0 1-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725 1.479-1.087 2.584-1.087.95 0 1.703.234 2.262.703.558.469.894 1.145 1.008 2.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664 0-1.169.239-1.514.717-.346.479-.525 1.18-.536 2.107v.656c0 .922.194 1.638.583 2.147.388.51.928.765 1.62.765.757 0 1.297-.172 1.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#1F42C3'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import { Contact } from 'core/remodel/types/contact'
import { OwnerDetail, Property, RentalDetail } from 'core/remodel/types/properties'
import * as PropertyNamespace from 'core/remodel/types/properties'

export function isOwner(
  type: PropertyNamespace.OwnershipType,
  detail: RentalDetail | OwnerDetail
): detail is OwnerDetail {
  if (type === PropertyNamespace.OwnershipType.Own) return true
  return false
}

export function isRental(
  type: PropertyNamespace.OwnershipType,
  detail: RentalDetail | OwnerDetail
): detail is RentalDetail {
  if (type === PropertyNamespace.OwnershipType.Rent) return true
  return false
}

export function isContact(data: Contact | Property): data is Contact {
  if (!('assetType' in data)) return true
  return false
}

export function isProperty(data: Contact | Property): data is Property {
  if ('assetType' in data) return true
  return false
}

export function isOwnerDetail(data: RentalDetail | OwnerDetail): data is OwnerDetail {
  if ('acquisition' in data) return true
  return false
}
export function isRentalDetail(data: RentalDetail | OwnerDetail): data is RentalDetail {
  if ('landlordId' in data) return true
  return false
}

export const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult =>
  input.status === 'rejected'

export const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> =>
  input.status === 'fulfilled'

import { Amount, AssetType, Currency } from "./common";

export const assetTypeWithSubtype = [
  AssetType.Art,
  AssetType.WineAndSpirits,
  AssetType.OtherCollectables,
  AssetType.Property,
  AssetType.Belonging,
];

export interface ComparativeNetWorthReport {
  myFinances: NetWorthReportDetails &
    SubtypeDetails<
      | AssetType.CashAndBanking
      | AssetType.TraditionalInvestments
      | AssetType.OtherInvestment
      | AssetType.Cryptocurrency
      | AssetType.Insurance
    >;
  myCollectables: NetWorthReportDetails &
    SubtypeDetails<
      AssetType.Art | AssetType.WineAndSpirits | AssetType.OtherCollectables,
      NetWorthReportDetails & SubtypeDetails
    >;
  myProperties: NetWorthReportDetails & {
    properties: ({ name: string } & NetWorthReportDetails)[];
  };
  myBelongings: NetWorthReportDetails & SubtypeDetails;
  total: NetWorthReportDetails;
}

export type SubtypeDetails<
  Type extends string = string,
  Details = NetWorthReportDetails
> = {
  subtype: {
    [key in Type]: Details;
  };
};

export interface NetWorthReportDetails {
  original: {
    purchasePrice: Amount;
    liabilities: Amount;
    netInvestment: Amount;
  };
  current: {
    assets: Amount;
    liabilities: Amount;
    netValue: Amount;
  };
  netChange: {
    amountChange: Amount;
    percentageChange: number;
  };
}

export function defaultNetWorthReportDetails(
  currency: Currency
): NetWorthReportDetails {
  return {
    original: {
      purchasePrice: { currency, value: 0 },
      liabilities: { currency, value: 0 },
      netInvestment: { currency, value: 0 },
    },
    current: {
      assets: { currency, value: 0 },
      liabilities: { currency, value: 0 },
      netValue: { currency, value: 0 },
    },
    netChange: {
      amountChange: { currency, value: 0 },
      percentageChange: 0,
    },
  };
}

export function defaultComparativeNetWorthReport(
  currency: Currency
): ComparativeNetWorthReport {
  return {
    myFinances: {
      ...defaultNetWorthReportDetails(currency),
      subtype: {
        [AssetType.CashAndBanking]: defaultNetWorthReportDetails(currency),
        [AssetType.TraditionalInvestments]:
          defaultNetWorthReportDetails(currency),
        [AssetType.OtherInvestment]: defaultNetWorthReportDetails(currency),
        [AssetType.Cryptocurrency]: defaultNetWorthReportDetails(currency),
        [AssetType.Insurance]: defaultNetWorthReportDetails(currency),
      },
    },
    myCollectables: {
      ...defaultNetWorthReportDetails(currency),
      subtype: {
        [AssetType.Art]: {
          ...defaultNetWorthReportDetails(currency),
          subtype: {},
        },
        [AssetType.WineAndSpirits]: {
          ...defaultNetWorthReportDetails(currency),
          subtype: {},
        },
        [AssetType.OtherCollectables]: {
          ...defaultNetWorthReportDetails(currency),
          subtype: {},
        },
      },
    },
    myProperties: {
      ...defaultNetWorthReportDetails(currency),
      properties: [],
    },
    myBelongings: {
      ...defaultNetWorthReportDetails(currency),
      subtype: {},
    },
    total: defaultNetWorthReportDetails(currency),
  };
}

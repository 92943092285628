import { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { Category, TransactionType, type AccountTransaction } from 'core/remodel/types/cashAndBanking'
import { Amount, Currency, TimeZone } from 'core/remodel/types/common'
import { creditCategoryOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchAllAssetsOptions } from '@/api/CommonService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fallbackCurrency } from '@/constants/preference'
import type { Option } from '@/types/common'
import { cn } from '@/utils/classnames'
import { formatNumber, startOfDayInTz } from '@/utils/formatter'
import { useDebounce } from '@/hooks/useDebounce'
import { useAuthStore } from '@/store/authStore'
import {
  Autocomplete,
  Button,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormPriceInput,
  FormSelect,
  SelectItem
} from '@/components/base'

export type CreditValues = AccountTransaction

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<CreditValues> => ({
  transactionType: TransactionType.Credit,
  accountId: '',
  subAccountId: undefined,
  amount: { currency, value: 0 },
  amountInAccountCurrency: { currency, value: 0 },
  category: Category.BalanceUpdate,
  date: startOfDayInTz(new Date(), timeZone),
  relatedAsset: undefined
})

interface CreditFormProps {
  accountId?: string
  accountName: string
  defaultCurrency?: Currency
  subAccountOptions?: Option[]
  mode?: 'Create' | 'Edit'
  values?: CreditValues
  onSubmit: SubmitHandler<CreditValues>
}

export function CreditForm({
  accountId,
  accountName,
  defaultCurrency,
  subAccountOptions,
  mode = 'Create',
  values,
  onSubmit
}: CreditFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    resetField,
    setValue
  } = useForm<CreditValues>({ defaultValues })
  const relatedAssetId = watch('relatedAsset.assetId')
  const subAccountId = watch('subAccountId')
  const value = watch('amount.value')
  const currency = watch('amount.currency')
  const accountCurrency = watch('amountInAccountCurrency.currency')
  // preferences and options
  const assetOptionsSWR = useSWR([commonQuery.assetList], fetchAllAssetsOptions(database!))
  const { data: assetOptions, isLoading: loadingAssetOptions } = assetOptionsSWR
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })
  const [convertedAmountState, setConvertedAmountState] = useState<Amount | null>(null)

  const debouncedValue = useDebounce(value, 300)
  const convertAmount = useCallback(async () => {
    if (debouncedValue <= 0) return null
    const amount: Amount = { value: debouncedValue, currency }
    const targetCurrency = subAccountId
      ? (subAccountOptions?.find((option) => option.value === subAccountId)?.label as Currency)
      : accountCurrency
    if (currency === targetCurrency) return amount
    const baseAmount = database!.ExRate.amountToBase(amount)
    if (targetCurrency === baseAmount.currency) return baseAmount
    const { amount: convertedAmount } = await database!.ExRate.amountToCurrencyWithData(baseAmount, targetCurrency)
    return convertedAmount
  }, [currency, debouncedValue, database, subAccountId, subAccountOptions, accountCurrency])

  useEffect(() => {
    const updateConvertedAmount = async () => {
      try {
        const amount = await convertAmount()
        if (amount) {
          setConvertedAmountState(amount)
          setValue('amountInAccountCurrency.value', amount.value)
          setValue('amountInAccountCurrency.currency', amount.currency)
        } else {
          setConvertedAmountState(null)
        }
      } catch (error) {
        console.error('Error converting amount:', error)
        setConvertedAmountState(null)
      }
    }

    updateConvertedAmount()
  }, [convertAmount, setValue])

  // populate data for `create` mode
  useEffect(() => {
    if (mode === 'Create') {
      if (accountId) resetField('accountId', { defaultValue: accountId })
      if (defaultCurrency) {
        const subAccountId = subAccountOptions?.find((option) => option.label === defaultCurrency)?.value
        resetField('amount.currency', { defaultValue: defaultCurrency })
        resetField('amountInAccountCurrency.currency', { defaultValue: defaultCurrency })
        resetField('subAccountId', { defaultValue: subAccountId })
      }
    }
  }, [accountId, defaultCurrency, mode, resetField, subAccountOptions])

  // populate data for `edit` mode
  useEffect(() => {
    if (mode === 'Edit' && values) reset(values, { keepDirtyValues: true })
  }, [mode, reset, values])

  useEffect(() => {
    if (subAccountId && subAccountOptions) {
      const currency = subAccountOptions.find((option) => option.value === subAccountId)?.label
      setValue('amountInAccountCurrency.currency', currency as Currency)
      setValue('amount.currency', currency as Currency)
    }
  }, [setValue, subAccountId, subAccountOptions])

  return (
    <form className={'flex h-[400px] flex-col justify-between'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'grid grid-cols-2 gap-4'}>
        <div className={'flex flex-col gap-4'}>
          <div className={'grid grid-cols-1 gap-y-1'}>
            <label className={'text-xs text-[#414554]'}>{t('finances:Field.ToAccount')}</label>
            <div className={'flex h-[38px] items-center rounded border bg-grey-input px-3 text-sm opacity-50'}>
              {accountName}
            </div>
          </div>
          {subAccountOptions && (
            <FormSelect control={control} name={'subAccountId'} label={t('finances:Field.Subaccount')}>
              {subAccountOptions.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {`${accountName} (${label})`}
                </SelectItem>
              ))}
            </FormSelect>
          )}
          <FormCreatableAutocomplete
            control={control}
            name={'toAccount.accountId'}
            label={t('Field.Seller')}
            placeholder={t('SearchOrCreate')}
            options={contactOptions}
            onCreate={addNewContact}
            isLoading={loadingContacts}
          />
          <FormPriceInput
            control={control}
            name={{ currency: 'amount.currency', value: 'amount.value' }}
            label={t('finances:Field.Amount')}
            format={preferences.numberFormat}
            digits={2}
            allowNegative={false}
            rules={{
              value: {
                required: true,
                validate: { greaterThanZero: (value) => value > 0 }
              }
            }}
          />
          <FormPriceInput
            className={cn(currency !== accountCurrency ? 'grid' : 'hidden')}
            control={control}
            name={{ currency: 'amountInAccountCurrency.currency', value: 'amountInAccountCurrency.value' }}
            label={t('finances:Field.AmountInAccountCurrency')}
            format={preferences.numberFormat}
            disabled={{ currency: true, value: true }}
          />
        </div>
        <div className={'flex flex-col gap-4'}>
          <FormDatePicker
            control={control}
            name={'date'}
            label={t('Date')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
          />
          <div className={'flex flex-col gap-y-1'}>
            <label className={'text-xs text-text'} htmlFor={'related-asset'}>
              {t('finances:Field.RelatedAsset')}
            </label>
            <Autocomplete
              inputId={'related-asset'}
              value={assetOptions?.find((option) => option.value.assetId === relatedAssetId)}
              onChange={(option) => option && setValue('relatedAsset', option.value)}
              options={assetOptions}
              isLoading={loadingAssetOptions}
              isMulti={false}
              components={{ IndicatorsContainer: () => null }}
              placeholder={''}
            />
          </div>
          <FormSelect control={control} name={'category'} label={t('finances:Field.Category')}>
            {creditCategoryOptions.map(({ label, value }) => (
              <SelectItem key={value} value={value}>
                {t(`finances:CreditCategory.${label}`)}
              </SelectItem>
            ))}
          </FormSelect>
          <div className={'grid grid-cols-1 gap-y-1'}>
            <label className={'text-xs text-[#414554]'}>{t('finances:Field.TotalAmount')}</label>
            <div className={'h-[38px] rounded bg-primary px-3 text-right text-sm leading-[38px] text-white'}>
              {`${currency} ${formatNumber(value, preferences.numberFormat, { digits: 2 })}`}
            </div>
          </div>
          {/* FIXME amountInAccountCurrency */}
          {currency !== accountCurrency && (
            <div className={'grid grid-cols-1 gap-y-1'}>
              <label className={'text-xs text-[#414554]'}>{t('finances:Field.TotalAmountInAccountCurrency')}</label>
              <div className={'flex h-[38px] items-center rounded border border-primary text-sm'}>
                <div className={'flex-1 px-3'}>
                  {convertedAmountState
                    ? `1 ${currency} = ${formatNumber(convertedAmountState.value / value, preferences.numberFormat, {
                        digits: 4
                      })} ${convertedAmountState.currency}`
                    : 'N/A'}
                </div>
                <div className={'h-full flex-1 bg-primary px-3 text-right leading-[38px] text-white'}>
                  {convertedAmountState
                    ? `${convertedAmountState.currency} ${formatNumber(
                        convertedAmountState.value,
                        preferences.numberFormat,
                        {
                          digits: 2
                        }
                      )}`
                    : `${accountCurrency} -`}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <fieldset className={'flex justify-end gap-2'} disabled={isSubmitting}>
        <Button
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
          disabled={!isValid}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}

export function CreditFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

import { useEffect, useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import banks from 'core/remodel/types/banks.json'
import { Account } from 'core/remodel/types/cashAndBanking'
import { CreditCardType, type CreditCard } from 'core/remodel/types/cashAndBanking/creditCard'
import {
  AssetType,
  AttachmentKind,
  Currency,
  CustomizedType,
  Period,
  periodValues,
  TimeZone
} from 'core/remodel/types/common'
import { CountryOptions, creditCardTypeValues } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatNumber, makeOptions, sortAlphabetically, startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormMonthPicker,
  FormNumberInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, ChevronDownIcon, PlusIcon, StarIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type CreditCardValues = CreditCard

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<CreditCardValues> => ({
  subtype: Account.Type.CreditCardAccount,
  assetType: AssetType.CashAndBanking,
  // primary details
  name: '',
  value: { currency, value: 0 },
  creditLimit: { currency, value: 0 },
  country: '',
  institution: '',
  cardType: CreditCardType.VISA,
  cardLastFourDigits: '',
  validateFrom: startOfDayInTz(new Date(), timeZone),
  notes: '',
  // attributes
  expiresEnd: startOfDayInTz(new Date(), timeZone),
  nameOnCard: '',
  statementFrequency: { period: Period.Month, num: 1 },
  paymentDate: startOfDayInTz(new Date(), timeZone),
  groupIds: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

interface CreditCardFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string
  values?: CreditCardValues
  onSubmit: SubmitHandler<CreditCardValues>
  onCancel: () => void
}

export function CreditCardForm({ mode = 'Create', assetId, values, onSubmit, onCancel }: CreditCardFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm<CreditCardValues>({ defaultValues })
  const country = watch('country')
  const value = watch('value.value')
  const expiresEnd = watch('expiresEnd')
  const currency = watch('value.currency')
  const creditLimit = watch('creditLimit.value')
  const availableCredit = creditLimit - value
  // preferences and options
  const institutionSWR = useSWR([typeQuery.institution, CustomizedType.BankOrInstitution], fetchCustomTypes(database!))
  const { data: institutions, isValidating: loadingInstitutions } = institutionSWR
  const institutionOptions = useMemo(() => {
    const base = banks
      .filter((bank) => bank.country === country)
      .map((bank) => ({ label: bank.name, value: bank.name }))
    const added = institutions?.map((item) => ({ label: item, value: item })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [country, institutions])
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  const creditCardTypeOptions = makeOptions(creditCardTypeValues, (key) => `finances:CreditCardTypeOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [{ key: 'attributes', label: t('Attributes') }]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  useEffect(() => {
    setValue('creditLimit.currency', currency)
  }, [currency, setValue])

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form
        className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormInput
                  control={control}
                  name={'name'}
                  label={t('finances:Field.CardDescription')}
                  placeholder={t('finances:Placeholder.CardDescription')}
                  rules={{ required: true }}
                />
                <FormPriceInput
                  control={control}
                  name={{ currency: 'value.currency', value: 'value.value' }}
                  label={t('finances:Field.Balance')}
                  format={preferences.numberFormat}
                  digits={2}
                  disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
                />
                <FormPriceInput
                  control={control}
                  name={{ currency: 'creditLimit.currency', value: 'creditLimit.value' }}
                  label={t('finances:Field.CreditLimit')}
                  format={preferences.numberFormat}
                  digits={2}
                  disabled={{ currency: true }}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormAutocomplete
                  control={control}
                  name={'country'}
                  label={t('Field.Country')}
                  options={CountryOptions}
                  placeholder={t('Placeholder.Select')}
                />
                <FormCreatableAutocomplete
                  control={control}
                  name={'institution'}
                  label={t('finances:Field.BankInstitution')}
                  options={institutionOptions}
                  onCreate={async (name) => await addCustomType(database!, CustomizedType.BankOrInstitution, name)}
                  placeholder={t('SearchOrCreate')}
                  isLoading={loadingInstitutions}
                  rules={{ required: true }}
                />
                <div className={'flex gap-x-1'}>
                  <FormSelect
                    className={'flex-1'}
                    control={control}
                    name={'cardType'}
                    label={t('finances:Field.CardType')}
                  >
                    {creditCardTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <div className={'grid basis-[100px] grid-cols-1 gap-y-1'}>
                    <label className={'flex items-center space-x-1 text-xs text-[#414554]'}>
                      <span>{t('finances:Field.Last4digits')}</span>
                      <span className={'text-error'}>{'*'}</span>
                    </label>
                    <FormInput
                      control={control}
                      name={'cardLastFourDigits'}
                      maxLength={4}
                      rules={{
                        required: true,
                        validate: { length: (v) => v.length === 4 }
                      }}
                    />
                  </div>
                </div>
                <FormDatePicker
                  control={control}
                  name={'validateFrom'}
                  label={t('finances:Field.ValidFrom')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                  disabled={mode === 'Edit'}
                  rules={{
                    validate: {
                      earlierThanExpiresEnd: (v) =>
                        v.getFullYear() <= expiresEnd.getFullYear() && v.getMonth() <= expiresEnd.getMonth()
                    }
                  }}
                />
              </div>
            </div>

            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.attributes'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <FormMonthPicker control={control} name={'expiresEnd'} label={t('finances:Field.ExpiresEnd')} />
                {/* available credit = creditLimit - value */}
                <div className={'grid grid-cols-1 gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.AvailableCredit')}</label>
                  <div className={'flex h-[38px] flex-row gap-0.5 opacity-50'}>
                    <div className={'inline-flex h-[38px] w-20 items-center rounded border bg-grey-input pl-3 text-sm'}>
                      <span>{currency}</span>
                      <ChevronDownIcon className={'ml-auto mr-2 text-primary'} size={20} />
                    </div>
                    <div className={'flex-1 rounded border bg-grey-input px-3 text-right text-sm leading-[38px]'}>
                      {formatNumber(availableCredit, preferences.numberFormat, { digits: 2, isNegativeSign: true })}
                    </div>
                  </div>
                </div>
                <FormInput control={control} name={'nameOnCard'} label={t('finances:Field.NameOnCard')} />
              </div>
              <div className={'flex flex-col gap-4'}>
                <div className={'grid grid-cols-1 gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.StatementFrequencyDay')}</label>
                  <div className={'grid grid-cols-2 gap-x-1'}>
                    <FormNumberInput
                      control={control}
                      name={'statementFrequency.num'}
                      format={preferences.numberFormat}
                    />
                    <FormSelect control={control} name={'statementFrequency.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                  </div>
                </div>
                <FormDatePicker
                  control={control}
                  name={'paymentDate'}
                  label={t('finances:Field.PaymentDate')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                />
              </div>
            </div>

            <div className={'col-span-2'}>
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Field.Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            <AttachmentPanel
              assetId={assetId}
              control={control}
              name={{ mainImage: 'mainImage', attachments: 'attachments' }}
              widgetOptions={[AttachmentKind.PrimaryDetails]}
            />
          </TabPanel.Section>
        </div>

        <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function CreditCardFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

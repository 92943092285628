'use client'

import { useToast } from '@/hooks/useToast'
import { Toast, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/base'
import { CheckCircleIcon, CircleAlert } from '@/components/icon'

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ variant, id, title, description, action, ...props }) {
        return (
          <Toast key={id} variant={variant} {...props}>
            <div className={'flex items-center gap-2'}>
              {variant === 'error' && <CircleAlert size={20} className={'shrink-0'} />}
              {variant === 'success' && <CheckCircleIcon size={20} className={'shrink-0'} />}
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            {/* <ToastClose /> */}
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}

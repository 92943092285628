import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23A7B0CD%22%2F%3E%3Cpath%20d%3D%22m7.004%2028.043%201.807-5.561h1.358l-2.56%207.109H6.415l-2.55-7.11h1.353l1.787%205.562Zm7.681-.283c0-.313-.11-.553-.33-.723-.22-.169-.616-.34-1.188-.512-.573-.173-1.03-.365-1.368-.577-.647-.407-.971-.937-.971-1.591%200-.573.233-1.045.7-1.416.468-.372%201.074-.557%201.82-.557.494%200%20.935.091%201.322.273.388.183.692.442.914.78.22.336.332.71.332%201.12h-1.23c0-.371-.117-.662-.35-.872-.233-.21-.566-.315-.999-.315-.403%200-.716.087-.94.259-.222.173-.334.413-.334.723%200%20.26.12.477.361.652.241.174.638.343%201.192.507.553.165.998.352%201.333.562.335.21.58.45.737.723.156.271.234.59.234.954%200%20.593-.227%201.064-.68%201.414-.455.35-1.07.525-1.849.525-.514%200-.987-.096-1.418-.286-.432-.19-.767-.453-1.006-.789a1.965%201.965%200%200%201-.359-1.171h1.235c0%20.403.134.716.4.937.268.221.65.332%201.148.332.43%200%20.753-.087.97-.261a.843.843%200%200%200%20.324-.691Zm2.32%201.831v-7.11h2.1c.627%200%201.185.14%201.671.42.487.28.864.678%201.13%201.192.268.514.401%201.103.401%201.767v.357c0%20.674-.134%201.266-.403%201.777a2.829%202.829%200%200%201-1.147%201.182c-.497.277-1.065.415-1.707.415h-2.046Zm1.235-6.113v5.127h.805c.648%200%201.146-.203%201.492-.608.347-.406.523-.987.53-1.746v-.395c0-.772-.168-1.361-.503-1.768-.335-.407-.822-.61-1.46-.61h-.864Zm7.744%201.572%201.494-2.568h1.426l-2.149%203.525%202.198%203.584h-1.44l-1.529-2.607-1.533%202.607h-1.436l2.202-3.584-2.153-3.525h1.426l1.494%202.568Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%237481AB%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileVsdxIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#A7B0CD'}
      />
      <path
        d={
          'm7.004 28.043 1.807-5.561h1.358l-2.56 7.109H6.415l-2.55-7.11h1.353l1.787 5.562Zm7.681-.283c0-.313-.11-.553-.33-.723-.22-.169-.616-.34-1.188-.512-.573-.173-1.03-.365-1.368-.577-.647-.407-.971-.937-.971-1.591 0-.573.233-1.045.7-1.416.468-.372 1.074-.557 1.82-.557.494 0 .935.091 1.322.273.388.183.692.442.914.78.22.336.332.71.332 1.12h-1.23c0-.371-.117-.662-.35-.872-.233-.21-.566-.315-.999-.315-.403 0-.716.087-.94.259-.222.173-.334.413-.334.723 0 .26.12.477.361.652.241.174.638.343 1.192.507.553.165.998.352 1.333.562.335.21.58.45.737.723.156.271.234.59.234.954 0 .593-.227 1.064-.68 1.414-.455.35-1.07.525-1.849.525-.514 0-.987-.096-1.418-.286-.432-.19-.767-.453-1.006-.789a1.965 1.965 0 0 1-.359-1.171h1.235c0 .403.134.716.4.937.268.221.65.332 1.148.332.43 0 .753-.087.97-.261a.843.843 0 0 0 .324-.691Zm2.32 1.831v-7.11h2.1c.627 0 1.185.14 1.671.42.487.28.864.678 1.13 1.192.268.514.401 1.103.401 1.767v.357c0 .674-.134 1.266-.403 1.777a2.829 2.829 0 0 1-1.147 1.182c-.497.277-1.065.415-1.707.415h-2.046Zm1.235-6.113v5.127h.805c.648 0 1.146-.203 1.492-.608.347-.406.523-.987.53-1.746v-.395c0-.772-.168-1.361-.503-1.768-.335-.407-.822-.61-1.46-.61h-.864Zm7.744 1.572 1.494-2.568h1.426l-2.149 3.525 2.198 3.584h-1.44l-1.529-2.607-1.533 2.607h-1.436l2.202-3.584-2.153-3.525h1.426l1.494 2.568Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#7481AB'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import {
  CoreFirestore,
  FullMetadata,
  UploadResult,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { getStorageLimitPath, getAttachmentCategoryPath } from "../refPaths";
import { FullRefs } from "../refs";
import { Attachment, AttachmentCategory } from "../types/common";
import { StorageLimit } from "../types/storage";
import { EncryptionManager } from "./encryption";

export class Attachments {
  protected readonly refs: FullRefs;
  protected readonly encryption: EncryptionManager;

  protected readonly userId: string;

  constructor(userId: string, refs: FullRefs, encryption: EncryptionManager) {
    this.refs = refs;
    this.encryption = encryption;
    this.userId = userId;
  }

  /***** Attachment *****/
  upload(
    assetIdOrCategory: string | AttachmentCategory,
    attachmentKey: string,
    data: Blob | Uint8Array | ArrayBuffer,
    contentType: string,
    iv: Uint8Array
  ): Promise<UploadResult> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetIdOrCategory,
      attachmentKey
    );
    const encrypted = this.encryption.current
      .encryptBytes(data, iv)
      .then((encryptedData) => {
        console.log(encryptedData);
        return CoreFirestore.uploadBytes(storageRef, encryptedData, {
          contentType,
        });
      });

    return encrypted;
  }

  getUrl(
    assetIdOrCategory: string | AttachmentCategory,
    attachmentKey: string
  ): Promise<string> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetIdOrCategory,
      attachmentKey
    );
    return CoreFirestore.getDownloadURL(storageRef);
  }

  getMeta(
    assetIdOrCategory: string | AttachmentCategory,
    attachmentKey: string
  ): Promise<FullMetadata> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetIdOrCategory,
      attachmentKey
    );
    return CoreFirestore.getMetadata(storageRef);
  }

  delete(
    assetIdOrCategory: string | AttachmentCategory,
    attachmentKey: string
  ): Promise<void> {
    const storageRef = this.refs.currentRefs.getStorageRef(
      assetIdOrCategory,
      attachmentKey
    );
    return CoreFirestore.deleteObject(storageRef);
  }

  /***** no assets associated *****/
  // below only store attachment info in firestore, still needs to upload to storage using methods above
  async addToCategory(category: AttachmentCategory, attachment: Attachment) {
    const docRef = CoreFirestore.doc<Attachment.Encrypted>(
      `${getAttachmentCategoryPath(this.refs.currentRefs.userId, category)}/${
        attachment.key
      }`
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(docRef).then(checkDuplicated);
      const encryptedAttachment = await Attachment.encrypt(
        attachment,
        this.encryption.current
      );
      transaction.set(docRef, encryptedAttachment);
    });
  }

  async getCategoryFiles(category: AttachmentCategory) {
    const colRef = CoreFirestore.collection<Attachment.Encrypted>(
      getAttachmentCategoryPath(this.refs.currentRefs.userId, category)
    );
    const data = await CoreFirestore.getDocsFromCollection(colRef).then(
      getQueriedData
    );
    return await Promise.all(
      Object.values(data).map((attachment) =>
        Attachment.decrypt(attachment, this.encryption.current)
      )
    );
  }

  async removeFromCategory(
    category: AttachmentCategory,
    attachmentKey: string
  ) {
    const docRef = CoreFirestore.doc<Attachment.Encrypted>(
      `${getAttachmentCategoryPath(
        this.refs.currentRefs.userId,
        category
      )}/${attachmentKey}`
    );
    CoreFirestore.deleteDoc(docRef);
  }

  async updateCategoryFileInfo(
    category: AttachmentCategory,
    attachment: Attachment
  ) {
    const docRef = CoreFirestore.doc<Attachment.Encrypted>(
      `${getAttachmentCategoryPath(this.refs.currentRefs.userId, category)}/${
        attachment.key
      }`
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(docRef).then(checkAndGetData);
      const newEncryptedAttachment = await Attachment.encrypt(
        attachment,
        this.encryption.current
      );
      transaction.set(docRef, newEncryptedAttachment);
    });
  }

  /***** Storage Limit *****/
  async getStorageLimit() {
    const docRef = CoreFirestore.doc<StorageLimit>(
      getStorageLimitPath(this.userId)
    );
    return CoreFirestore.getDoc(docRef).then(checkAndGetData);
  }

  // TODO revisit
  // async addToFavorite(
  //   fileAssetType: FileAssetType,
  //   assetId: string,
  //   attachmentKey: string
  // ) {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   await CoreFirestore.runTransaction(async (transaction) => {
  //     const currentData = await transaction
  //       .get(favoritePath)
  //       .then((data) => data.data());
  //     if (currentData) {
  //       transaction.update(favoritePath, {
  //         ...currentData,
  //         [assetId]: {
  //           ...currentData[assetId],
  //           [attachmentKey]: null,
  //         },
  //       });
  //     } else {
  //       transaction.set(favoritePath, {
  //         [assetId]: {
  //           [attachmentKey]: null,
  //         },
  //       });
  //     }
  //   });
  // }

  // TODO delete from favorite when attachment deleted
  // async removeFromFavorite(
  //   fileAssetType: FileAssetType,
  //   assetId: string,
  //   attachmentKey: string
  // ) {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   await CoreFirestore.runTransaction(async (transaction) => {
  //     const currentData = await transaction
  //       .get(favoritePath)
  //       .then(checkAndGetData);
  //     delete currentData[assetId][attachmentKey];
  //     if (Object.keys(currentData[assetId]).length === 0) {
  //       transaction.update(favoritePath, {
  //         [assetId]: CoreFirestore.deleteField(),
  //       });
  //     } else {
  //       transaction.update(favoritePath, {
  //         [assetId]: currentData[assetId],
  //       });
  //     }
  //   });
  // }

  // async listFavorite(fileAssetType: FileAssetType): Promise<{
  //   [assetId: string]: string[]; // attachmentKeys
  // }> {
  //   const favoritePath =
  //     this.refs.currentRefs.getFavoriteFileDocRef(fileAssetType);
  //   const data = await CoreFirestore.getDoc(favoritePath).then(checkAndGetData);
  //   const result: { [assetId: string]: string[] } = {};
  //   Object.entries(data).map(([assetId, v]) => {
  //     result[assetId] = Object.keys(v);
  //   });
  //   return result;
  // }
}

import Decimal from "decimal.js";
import { Encrypted } from "../database/encryption";
import { EncryptionFieldKey } from "../encryption/utils";
import { CompDifference, OmitKeys, buildArrayUpdate } from "../utils";
import { Action } from "./actions";
import { ActionType } from "./actions/base";
import { Consignment } from "./actions/consignment";
import { Exhibition } from "./actions/exhibition";
import { Literature } from "./actions/literature";
import { Offer } from "./actions/offer";
import {
  AuctionSoldDetail,
  DirectSoldDetail,
  SaleType,
} from "./actions/soldInfo";
import { Activity, ActivityKind, activitySortKeyMap } from "./activities";
import { Amount, AssetV2, Owner } from "./common";
import { AssetType } from "./enums";
import {
  ActionEvent,
  EventBase,
  SellEvent,
  SharedEvent,
  ValuationEvent,
} from "./event";
import { Event as WineEvent } from "./wineAndSprits";

export const ACTIVITY_MISSING_VALUE = <any>undefined;
export function genActivityLogs<
  EventKind extends
    | SharedEvent.Kind
    | ValuationEvent.Kind
    | SellEvent.Kind
    | ActionEvent.Kind,
  Event extends EventBase,
  T extends AssetV2.Encrypted
>(
  needProcessEventKinds: EventKind[],
  inputEvent: Event,
  assetType: AssetType,
  assetId: string,
  ownerId: string,
  time: Date,
  primaryDetailKeys?: readonly string[],
  attributeKeys?: readonly string[]
) {
  if (!needProcessEventKinds.includes(inputEvent.kind as EventKind)) return [];
  const baseFields: OmitKeys<Activity.Base, "activityKind"> = {
    assetId,
    ownerId,
    sortKey: 0,
    executerId: inputEvent.executerId,
    time,
  };
  let activities: Encrypted<Activity>[] = [];
  switch (inputEvent.kind) {
    // shared events
    case SharedEvent.Kind.AssetCreated: {
      const event = inputEvent as unknown as SharedEvent.AssetCreated<T>;
      const activity: Encrypted<Activity> = {
        ...baseFields,
        activityKind: ActivityKind.AssetCreated,
        detail: {
          assetName: event.asset.name,
          value: event.asset.value,
        },
      };
      activities = [activity];
      break;
    }
    case SharedEvent.Kind.AssetArchived:
    case SharedEvent.Kind.AssetDeleted: {
      const activity: Encrypted<Activity> = {
        ...baseFields,
        activityKind: ActivityKind.AssetDeleted,
      };
      activities = [activity];
      break;
    }
    case SharedEvent.Kind.AssetUpdated: {
      if (!primaryDetailKeys || !attributeKeys) {
        throw new Error("primaryDetailKeys and attributeKeys is required");
      }
      const event = inputEvent as unknown as SharedEvent.AssetUpdated<T>;
      // primary details
      // #NOTE temp remove
      // if (event.locationPrimaryDetailsUpdated) {
      //   activities = [
      //     {
      //       ...baseFields,
      //       activityKind: ActivityKind.AssetPrimaryDetailsEdited,
      //     },
      //   ];
      // } else {
      //   for (const key of Object.keys(event.asset)) {
      //     if (primaryDetailKeys.includes(<any>key)) {
      //       activities = [
      //         {
      //           ...baseFields,
      //           activityKind: ActivityKind.AssetPrimaryDetailsEdited,
      //         },
      //       ];
      //       break;
      //     }
      //   }
      // }
      // attributes
      for (const key of Object.keys(event.asset)) {
        if (attributeKeys.includes(<any>key)) {
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetAttributesEdited,
            },
          ];
          break;
        }
      }
      break;
    }
    case SharedEvent.Kind.ValueUpdated: {
      const event = inputEvent as unknown as SharedEvent.ValueUpdated;
      if (event.previous && event.current) {
        if (event.revertedFromId) {
          if (!event.valuationName)
            throw new Error("valuationName is required");
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetValuationRemoved,
              detail: {
                value: event.current,
                valuation: {
                  valuationId: event.revertedFromId,
                  [EncryptionFieldKey]: event.valuationName[EncryptionFieldKey],
                },
              },
            },
          ];
        } else if (event.valuationId) {
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetValuationCreated,
              detail: {
                previous: event.previous,
                current: event.current,
              },
            },
          ];
        } else {
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.ValueUpdated,
              detail: {
                previous: event.previous,
                current: event.current,
              },
            },
          ];
        }
      }
      break;
    }
    case SharedEvent.Kind.ShareholderUpdated: {
      const event = inputEvent as unknown as SharedEvent.ShareholderUpdated;
      const idToPrevious: { [id: string]: Owner } = {};
      event.previous?.shareholder.forEach((v) => {
        idToPrevious[v.contactId] = v;
      });
      const result = buildArrayUpdate(
        event.previous?.shareholder,
        event.current?.shareholder,
        (value, array) => {
          const current = array.find((a) => a.contactId === value.contactId);
          if (!current) return CompDifference.NotFound;
          if (current.percent !== value.percent) {
            return CompDifference.changed;
          }
          return CompDifference.Same;
        }
      );
      if (result) {
        let purchaseDate: Date | undefined;
        if (assetType === AssetType.WineAndSpirits) {
          purchaseDate = (<WineEvent.ShareholderUpdated>event).logInfo
            .purchaseDate;
        }
        if (result.removed.length > 0) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetShareholderRemoved,
            detail: {
              shareholder: result.removed.map((v) => v.contactId),
              purchaseDate,
            },
          });
        }
        if (
          event.previous?.myOwnership &&
          event.current?.myOwnership &&
          event.previous.myOwnership !== event.current.myOwnership
        ) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetShareholderEdited,
            detail: {
              myOwnership: {
                previousPercent: event.previous.myOwnership,
                currentPercent: event.current.myOwnership,
              },
              purchaseDate,
            },
          });
        }
        for (const v of result.changed) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetShareholderEdited,
            detail: {
              shareholder: {
                contactId: v.contactId,
                previousPercent: idToPrevious[v.contactId].percent,
                currentPercent: v.percent,
              },
              purchaseDate,
            },
          });
        }
        if (result.added.length > 0) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetShareholderCreated,
            detail: {
              shareholder: result.added.map((v) => {
                return {
                  contactId: v.contactId,
                  percent: v.percent,
                };
              }),
              purchaseDate,
            },
          });
        }
      }
      break;
    }
    case SharedEvent.Kind.BeneficiaryUpdated: {
      const event = inputEvent as unknown as SharedEvent.BeneficiaryUpdated;
      const idToPrevious: { [id: string]: Owner } = {};
      event.previous?.forEach((v) => {
        idToPrevious[v.contactId] = v;
      });
      const result = buildArrayUpdate(
        event.previous,
        event.current,
        (value, array) => {
          const current = array.find((a) => a.contactId === value.contactId);
          if (!current) return CompDifference.NotFound;
          if (current.percent !== value.percent) {
            return CompDifference.changed;
          }
          return CompDifference.Same;
        }
      );
      if (result) {
        let purchaseDate: Date | undefined;
        if (assetType === AssetType.WineAndSpirits) {
          purchaseDate = (<WineEvent.BeneficiaryUpdated>event).logInfo
            .purchaseDate;
        }
        if (result.removed.length > 0) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetBeneficiariesRemoved,
            detail: {
              beneficiary: result.removed.map((v) => v.contactId),
              purchaseDate,
            },
          });
        }
        for (const v of result.changed) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetBeneficiariesEdited,
            detail: {
              contactId: v.contactId,
              previousPercent: idToPrevious[v.contactId].percent,
              currentPercent: v.percent,
              purchaseDate,
            },
          });
        }
        if (result.added.length > 0) {
          activities.push({
            ...baseFields,
            activityKind: ActivityKind.AssetBeneficiariesCreated,
            detail: {
              beneficiary: result.added.map((v) => {
                return {
                  contactId: v.contactId,
                  percent: v.percent,
                };
              }),
              purchaseDate,
            },
          });
        }
      }
      break;
    }
    case SharedEvent.Kind.LocationUpdated: {
      const event = inputEvent as unknown as SharedEvent.LocationUpdated;
      if (
        event.previous &&
        event.previous.locationId !== event.current.locationId
      ) {
        const activity: Encrypted<Activity> = {
          ...baseFields,
          activityKind: ActivityKind.AssetLocationEdited,
          detail: {
            previous: {
              locationType: event.previous.locationType,
              locationId: event.previous.locationId,
            },
            current: {
              locationType: event.current.locationType,
              locationId: event.current.locationId,
            },
          },
        };
        activities = [activity];
      } else if (!event.previous) {
        const activity: Encrypted<Activity> = {
          ...baseFields,
          activityKind: ActivityKind.AssetLocationCreated,
          detail: {
            locationType: event.current.locationType,
            locationId: event.current.locationId,
          },
        };
        activities = [activity];
      }
      break;
    }
    case SharedEvent.Kind.ImageAdded: {
      const event = inputEvent as unknown as SharedEvent.ImageAdded;
      activities = [
        {
          ...baseFields,
          activityKind: ActivityKind.AssetPhotoAdded,
          detail: {
            number: event.images.length,
          },
        },
      ];
      break;
    }
    case SharedEvent.Kind.ImageRemoved: {
      const event = inputEvent as unknown as SharedEvent.ImageRemoved;
      activities = [
        {
          ...baseFields,
          activityKind: ActivityKind.AssetPhotoRemoved,
          detail: {
            number: event.images.length,
          },
        },
      ];
      break;
    }
    case SharedEvent.Kind.MainImageSet: {
      const event = inputEvent as unknown as SharedEvent.MainImageSet;
      if (event.previous && event.current) {
        activities = [
          {
            ...baseFields,
            activityKind: ActivityKind.AssetMainPhotoChanged,
          },
        ];
      }
      break;
    }
    case SharedEvent.Kind.GroupsUpdated: {
      const event = inputEvent as unknown as SharedEvent.GroupsUpdated;
      for (const groupId of event.addIds) {
        activities.push({
          ...baseFields,
          activityKind: ActivityKind.AssetAddedToGroup,
          detail: {
            groupId,
          },
        });
      }
      for (const groupId of event.removedIds) {
        activities.push({
          ...baseFields,
          activityKind: ActivityKind.AssetRemovedFromGroup,
          detail: {
            groupId,
          },
        });
      }
      break;
    }
    // action events
    case SellEvent.Kind.SoldInfoAdded: {
      const event = inputEvent as unknown as SellEvent.SoldInfoAdded;
      let salePrice: Amount | undefined;
      if (event.data.detail.saleType === SaleType.DirectSale) {
        const detail = event.data.detail as DirectSoldDetail;
        salePrice = {
          currency: detail.salePrice.currency,
          value: new Decimal(detail.salePrice.value)
            .add(event.data.taxPayable.value)
            .sub(detail.discount?.value || 0)
            .toNumber(),
        };
      } else {
        const detail = event.data.detail as AuctionSoldDetail;
        salePrice = {
          currency: detail.totalHammerPrice.currency,
          value: new Decimal(detail.totalHammerPrice.value)
            .add(event.data.taxPayable.value)
            .toNumber(),
        };
      }
      activities = [
        {
          ...baseFields,
          activityKind: ActivityKind.AssetSoldInfoCreated,
          detail: {
            actionId: event.data.id,
            assetName: event.logInfo.assetName,
            saleType: event.data.detail.saleType,
            salePrice,
          },
        },
      ];
      break;
    }
    case SellEvent.Kind.SoldInfoUpdated: {
      const event = inputEvent as unknown as SellEvent.SoldInfoUpdated;
      activities = [
        {
          ...baseFields,
          activityKind: ActivityKind.AssetSoldInfoEdited,
          detail: {
            actionId: event.id,
            title: event.update.title || event.logInfo.currentTitle,
          },
        },
      ];
      break;
    }
    case SellEvent.Kind.SoldInfoDeleted: {
      const event = inputEvent as unknown as SellEvent.SoldInfoDeleted;
      activities = [
        {
          ...baseFields,
          activityKind: ActivityKind.AssetSoldInfoRemoved,
          detail: {
            title: event.logInfo.title,
          },
        },
      ];
      break;
    }
    case ActionEvent.Kind.ActionAdded: {
      const event =
        inputEvent as unknown as ActionEvent.ActionAdded<Action.Encrypted>;
      switch (event.data.actionType) {
        case ActionType.AddOffer:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetOfferCreated,
              detail: {
                actionId: event.data.id,
                type: event.data.type,
                offerNumber: event.data.offerNumber,
                buyer: event.data.buyer,
              },
            },
          ];
          break;
        case ActionType.AddConsignment:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetConsignmentCreated,
              detail: {
                actionId: event.data.id,
                price: event.data.price,
                consignee: event.data.consignee,
              },
            },
          ];
          break;
        case ActionType.AddLiterature:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetLiteratureCreated,
              detail: {
                actionId: event.data.id,
                title: event.data.title,
              },
            },
          ];
          break;
        case ActionType.AddExhibition:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetExhibitionCreated,
              detail: {
                actionId: event.data.id,
                title: event.data.title,
              },
            },
          ];
          break;
      }
      break;
    }
    case ActionEvent.Kind.ActionUpdated: {
      const event =
        inputEvent as unknown as ActionEvent.ActionUpdated<Action.Update>;
      switch (event.actionType) {
        case ActionType.AddOffer:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetOfferEdited,
              detail: {
                actionId: event.id,
                offerNumber:
                  (<Offer.Encrypted>event.update).offerNumber || event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddConsignment:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetConsignmentEdited,
              detail: {
                actionId: event.id,
                consignee:
                  (<Consignment.Encrypted>event.update).consignee ||
                  event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddLiterature:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetLiteratureEdited,
              detail: {
                actionId: event.id,
                title:
                  (<Literature.Encrypted>event.update).title || event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddExhibition:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetExhibitionEdited,
              detail: {
                actionId: event.id,
                title:
                  (<Exhibition.Encrypted>event.update).title || event.logInfo,
              },
            },
          ];
          break;
      }
      break;
    }
    case ActionEvent.Kind.ActionDeleted: {
      const event = inputEvent as unknown as ActionEvent.ActionDeleted;
      switch (event.actionType) {
        case ActionType.AddOffer:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetOfferRemoved,
              detail: {
                offerNumber: event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddConsignment:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetConsignmentRemoved,
              detail: {
                consignee: event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddLiterature:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetLiteratureRemoved,
              detail: {
                title: event.logInfo,
              },
            },
          ];
          break;
        case ActionType.AddExhibition:
          activities = [
            {
              ...baseFields,
              activityKind: ActivityKind.AssetExhibitionRemoved,
              detail: {
                title: event.logInfo,
              },
            },
          ];
          break;
      }
      break;
    }
  }
  return activities.map((activity) => ({
    ...activity,
    sortKey: activitySortKeyMap[activity.activityKind!],
  }));
}

export function addLocationDataToDetail<
  T extends Encrypted<{
    room: { roomId: string; position: string };
  }>
>(
  detail: T,
  wineLocation?: {
    roomId: string;
    position?: string;
  }
) {
  if (wineLocation) {
    const { roomId, position } = wineLocation;
    if (!detail.room) detail.room = { roomId };
    if (position) detail!.room.position = position;
  }
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23B1B1B1%22%2F%3E%3Cpath%20d%3D%22M12.014%2022.235h-2.66v7.336h-1.47v-7.336H5.246V21.04h6.767v1.195Zm4.055%201.887%201.793-3.082h1.71l-2.577%204.23%202.636%204.301h-1.728l-1.834-3.128-1.84%203.128h-1.723l2.643-4.3-2.584-4.23h1.711l1.793%203.081Zm10.822-1.887h-2.66v7.336h-1.47v-7.336h-2.637V21.04h6.767v1.195Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23907D7D%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileTxtIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#B1B1B1'}
      />
      <path
        d={
          'M12.014 22.235h-2.66v7.336h-1.47v-7.336H5.246V21.04h6.767v1.195Zm4.055 1.887 1.793-3.082h1.71l-2.577 4.23 2.636 4.301h-1.728l-1.834-3.128-1.84 3.128h-1.723l2.643-4.3-2.584-4.23h1.711l1.793 3.081Zm10.822-1.887h-2.66v7.336h-1.47v-7.336h-2.637V21.04h6.767v1.195Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#907D7D'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

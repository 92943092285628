import { mutate, unstable_serialize } from 'swr'

import type { Database } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import type { Belonging } from 'core/remodel/types/belongings'
import { AssetType, AttachmentKind, Currency, LocationType } from 'core/remodel/types/common'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import type { LocationSummary } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isProperty } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type { BelongingValues, ValuationValues } from '@/components/form'

export const belongingQuery = {
  summary: 'belonging-summary',
  list: 'belonging-list',
  info: 'belonging-info',
  filter: 'belonging-filter',
  location: 'belonging-location',
  action: 'belonging-action'
} as const

const mutateBelonging = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(belongingQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.Belonging, id)))
}

export function fetchBelongingSummary(database: Database) {
  return async ([_key, currency]: [typeof belongingQuery.summary, Currency?]) => {
    const [summary, typeLiabilities] = await Promise.all([
      database.belonging.getSyncedSummary(currency),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.Belonging], currency)
    ])
    const { Belonging: liabilities } = typeLiabilities

    if (!liabilities) return summary

    const netValue = { ...summary.netValue, value: summary.netValue.value - liabilities.value }

    return { ...summary, liabilities, netValue }
  }
}

export type BelongingExtra = Belonging & {
  extraData?: {
    locationName: string
    roomName: string | null
  }
}

export function fetchBelongings(database: Database) {
  return async ([_key, query]: [typeof belongingQuery.list, Record<string, any>]) => {
    const { list: listRaw, totalCount } = await database.getAssetsWithQueryV2<Belonging>(
      AssetType.Belonging,
      undefined,
      query
    )

    const list = await Promise.all(
      listRaw.map(async (belonging): Promise<BelongingExtra> => {
        try {
          const { locationType, locationId, roomId } = belonging.location
          let extraData: BelongingExtra['extraData']
          switch (locationType) {
            case LocationType.MyProperty: {
              const property = await database.property.getById(locationId)
              const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
              const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
              extraData = {
                locationName: property.name,
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
            case LocationType.Address:
            case LocationType.NewAddress: {
              const contact = await database.Account.getContact(locationId)
              const rooms = contact.room ?? []
              extraData = {
                locationName: getFullName(contact),
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
          }
          return { ...belonging, extraData }
        } catch {
          return belonging
        }
      })
    )

    return { list, totalCount }
  }
}

export function fetchBelongingInfo(database: Database) {
  return async ([_key, id]: [typeof belongingQuery.info, string]) => {
    return await database.belonging.getById(id)
  }
}

export function fetchBelongingFilter(database: Database) {
  return async ([_key]: [typeof belongingQuery.filter]) => {
    const { subtype, value, locationId, purchaseAt } = await database.getFilterInfo(AssetType.Belonging)

    const responses = await Promise.allSettled([
      ...locationId.map((id) => database.property.getById(id)),
      ...locationId.map((id) => database.Account.getContact(id))
    ])
    const location = responses.filter(isFulfilled).map(({ value }) => ({
      label: isProperty(value) ? value.name : getFullName(value),
      value: value.id
    }))
    return { subtype, value, location, purchaseAt }
  }
}

export function fetchBelongingLocation(database: Database) {
  return async ([_key, currency]: [typeof belongingQuery.location, Currency?]): Promise<LocationSummary[]> => {
    const infos = (await database.belonging.getSyncedLocation(currency)).filter((info) => info.itemNumber > 0)
    const locations = await Promise.all(
      infos.map((info) => {
        return info.locationType === LocationType.MyProperty
          ? database.property.getById(info.id)
          : database.Account.getContact(info.id)
      })
    )

    return infos.map((info, index) => {
      const location = locations[index]
      return isProperty(location)
        ? {
            item: { id: info.id, latLng: location.location.center },
            info: {
              name: location.name,
              type: info.locationType,
              address: location.location.address,
              imageId: location.mainImage,
              number: info.itemNumber,
              value: info.value
            }
          }
        : {
            item: { id: info.id, latLng: location.center },
            info: {
              name: getFullName(location),
              type: info.locationType,
              address: location.address,
              imageId: undefined,
              number: info.itemNumber,
              value: info.value
            }
          }
    })
  }
}

export async function addBelonging(database: Database, id: string, data: BelongingValues, query: Record<string, any>) {
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address
  await database.belonging.add({ ...data, id })
  await delay()
  mutateBelonging(id)
}

export async function updateBelonging(database: Database, id: string, data: BelongingValues) {
  const { attachments: newAttach = [] } = data
  const { attachments: oldAttach = [] } = await database.belonging.getById(id)
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address

  await database.belonging.update({ ...data, id })

  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          if (contentType.startsWith('image/')) {
            // remove thumbnails
            Promise.all(
              Object.values(ImageSizes).map((size) =>
                database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
              )
            )
          }
          database.Attachments.delete(id, key).catch((e) => console.log(e))
        } catch (error) {
          console.log(error)
        }
      })
  )

  await delay()
  mutateBelonging(id)
}

export async function relocateBelongings(
  database: Database,
  ids: string[],
  newLocation: LocationInfo,
  params: any,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address

  for (const id of ids) {
    const belongings = await database.belonging.getById(id)
    const oldLocation = belongings.location
    if (oldLocation.locationId === newLocation.locationId) continue

    await database.belonging.update({ ...belongings, location: newLocation, id })
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateBelonging()
}

export async function deleteBelonging(database: Database, id: string) {
  await database.belonging.delete(id)
  await delay()
  mutateBelonging(id)
}

export async function deleteBelongings(
  database: Database,
  ids: string[],
  params: any,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  for (const id of ids) {
    await database.belonging.delete(id)
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateBelonging()
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const belonging = await database.belonging.getById(id)
  const oldAttach = belonging.attachments ?? []
  const newAttach = [
    ...images,
    ...(belonging.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]
  await database.belonging.update({ ...belonging, attachments: newAttach, mainImage: images[0]?.key })

  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        // remove thumbnails
        Promise.all(
          Object.values(ImageSizes).map((size) =>
            database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
          )
        )
        database.Attachments.delete(id, key).catch((e) => console.log(e))
      })
  )

  await delay()
  mutateBelonging(id)
}

// actions
export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof belongingQuery.action, string]) => {
    const raw = await database.belonging.getAllActions(id)
    const actions: ActionDetails[] = []
    for (const action of raw) {
      switch (action.actionType) {
        case ActionType.AddValuation:
          try {
            if (!action.valuedById) {
              actions.push(action)
              break
            }
            const valueBy = await database.Account.getContact(action.valuedById)
            const valueByName = getFullName(valueBy)
            actions.push({ ...action, valueByName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, valuedById: '', valueByName: 'DeletedContact' })
          }
          break
        default:
          actions.push(action)
      }
    }
    return actions
  }
}

export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.belonging.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateBelonging(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.belonging.updateValuation(id, data)
  await delay()
  mutateBelonging(id)
}

export async function deleteValuation(database: Database, id: string, valuationId: string) {
  await database.belonging.deleteValuation(id, valuationId)
  await delay()
  mutateBelonging(id)
}

import { endOfMonth, startOfMonth, subMonths } from 'date-fns'
import { mutate, unstable_serialize } from 'swr'

import type { Database } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import type { Belonging, OtherCollectable } from 'core/remodel/types/belongings'
import { AssetType, AttachmentKind, Currency, LocationType } from 'core/remodel/types/common'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import { LocationSummary, SummaryStatistic } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isProperty } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type { OtherCollectableValues, ValuationValues } from '@/components/form'

export const otherQuery = {
  summary: 'other-summary',
  list: 'other-list',
  info: 'other-info',
  filter: 'other-filter',
  action: 'other-action',
  location: 'location'
} as const

const mutateOtherCollectable = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(otherQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.OtherCollectables, id)))
}

export function fetchOtherSummary(database: Database) {
  return async ([_key, currency]: [typeof otherQuery.summary, Currency?]) => {
    const [summary, typeLiabilities, filterInfo] = await Promise.all([
      database.otherCollectable.getSyncedSummary(currency),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.OtherCollectables], currency),
      database.getFilterInfo(AssetType.OtherCollectables)
    ])
    const { OtherCollectables: liabilities } = typeLiabilities
    const { subtype, brand } = filterInfo

    const lastMonth = subMonths(new Date(), 1)
    const statistics: SummaryStatistic[] = [
      {
        key: 'Collectables',
        value: summary.totalNumber,
        query: []
      },
      {
        key: 'Purchased',
        value: summary.purchasedLM,
        query: [
          ['purchaseDateLowerBound', startOfMonth(lastMonth).toISOString()],
          ['purchaseDateUpperBound', endOfMonth(lastMonth).toISOString()]
        ]
      },
      {
        key: 'Types',
        value: summary.typesNumber,
        query: subtype.map((item) => ['subtype', item])
      },
      {
        key: 'Brand',
        value: summary.brandNumber,
        query: brand.map((item) => ['brand', item])
      },
      {
        key: 'Wishlist',
        value: 0,
        // TODO art wishlist
        query: []
      }
    ].map(({ query, ...item }) => ({ ...item, queryString: new URLSearchParams(query).toString() }))

    if (!liabilities) return { ...summary, statistics }

    const netValue = { ...summary.netValue, value: summary.netValue.value - liabilities.value }

    return { ...summary, liabilities, netValue, statistics }
  }
}

export type OtherCollectableExtra = OtherCollectable & {
  extraData?: {
    locationName: string
    roomName: string | null
  }
}

export function fetchOthers(database: Database) {
  return async ([_key, query]: [typeof otherQuery.list, Record<string, any>]) => {
    const { list: listRaw, totalCount } = await database.getAssetsWithQueryV2<OtherCollectable>(
      AssetType.OtherCollectables,
      undefined,
      query
    )

    const list = await Promise.all(
      listRaw.map(async (other): Promise<OtherCollectableExtra> => {
        try {
          const { locationType, locationId, roomId } = other.location
          let extraData: OtherCollectableExtra['extraData']
          switch (locationType) {
            case LocationType.MyProperty: {
              const property = await database.property.getById(locationId)
              const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
              const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
              extraData = {
                locationName: property.name,
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
            case LocationType.Address:
            case LocationType.NewAddress: {
              const contact = await database.Account.getContact(locationId)
              const rooms = contact.room ?? []
              extraData = {
                locationName: getFullName(contact),
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
          }
          return { ...other, extraData }
        } catch {
          return other
        }
      })
    )

    return { list, totalCount }
  }
}

export function fetchOtherInfo(database: Database) {
  return async ([_key, id]: [typeof otherQuery.info, string]) => {
    return await database.otherCollectable.getById(id)
  }
}

export function fetchOtherFilter(database: Database) {
  return async ([_key]: [typeof otherQuery.filter]) => {
    const { subtype, value, locationId, brand, name, purchaseAt } = await database.getFilterInfo(
      AssetType.OtherCollectables
    )

    const responses = await Promise.allSettled([
      ...locationId.map((id) => database.property.getById(id)),
      ...locationId.map((id) => database.Account.getContact(id))
    ])
    const location = responses.filter(isFulfilled).map(({ value }) => ({
      label: isProperty(value) ? value.name : getFullName(value),
      value: value.id
    }))
    return { subtype, value, location, brand, name, purchaseAt }
  }
}

export function fetchOtherLocation(database: Database) {
  return async ([_key, currency]: [typeof otherQuery.location, Currency?]): Promise<LocationSummary[]> => {
    const infos = await database.otherCollectable.getSyncedLocation(currency)
    const locations = await Promise.all(
      infos.map((info) => {
        return info.locationType === LocationType.MyProperty
          ? database.property.getById(info.id)
          : database.Account.getContact(info.id)
      })
    )
    return infos.map((info, index) => {
      const location = locations[index]
      return isProperty(location)
        ? {
            item: { id: info.id, latLng: location.location.center },
            info: {
              name: location.name,
              type: info.locationType,
              address: location.location.address,
              imageId: location.mainImage,
              number: info.itemNumber,
              value: info.value
            }
          }
        : {
            item: { id: info.id, latLng: location.center },
            info: {
              name: getFullName(location),
              type: info.locationType,
              address: location.address,
              imageId: undefined,
              number: info.itemNumber,
              value: info.value
            }
          }
    })
  }
}

export async function addOtherCollectable(database: Database, id: string, data: OtherCollectableValues) {
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address
  await database.otherCollectable.add({ ...data, id })
  await delay()
  mutateOtherCollectable(id)
}

export async function updateOtherCollectable(database: Database, id: string, data: Belonging) {
  const { attachments: newAttach = [] } = data
  const { attachments: oldAttach = [] } = await database.otherCollectable.getById(id)
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address
  await database.otherCollectable.update({ ...data, id })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          if (contentType.startsWith('image/')) {
            // remove thumbnails
            Promise.all(
              Object.values(ImageSizes).map((size) =>
                database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
              )
            )
          }
          database.Attachments.delete(id, key).catch((e) => console.log(e))
        } catch (error) {
          console.log(error)
        }
      })
  )
  await delay()
  mutateOtherCollectable(id)
}

export async function relocateOtherCollectables(
  database: Database,
  ids: string[],
  newLocation: LocationInfo,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address
  for (const id of ids) {
    const otherCollectables = await database.otherCollectable.getById(id)
    const oldLocation = otherCollectables.location
    if (oldLocation.locationId === newLocation.locationId) continue

    await database.otherCollectable.update({ ...otherCollectables, location: newLocation, id })
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateOtherCollectable()
}

export async function deleteOtherCollectable(database: Database, id: string) {
  await database.otherCollectable.delete(id)
  await delay()
  mutateOtherCollectable(id)
}

export async function deleteOtherCollectables(
  database: Database,
  ids: string[],
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  for (const id of ids) {
    await database.otherCollectable.delete(id)
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateOtherCollectable()
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const other = await database.otherCollectable.getById(id)
  const oldAttach = other.attachments ?? []
  const newAttach = [
    ...images,
    ...(other.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]
  await database.otherCollectable.update({
    ...other,
    attachments: newAttach,
    mainImage: images[0]?.key
  })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        // remove thumbnails
        Promise.all(
          Object.values(ImageSizes).map((size) =>
            database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
          )
        )
        database.Attachments.delete(id, key).catch((e) => console.log(e))
      })
  )
  await delay()
  mutateOtherCollectable(id)
}

// actions
export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof otherQuery.action, string]) => {
    const raw = await database.otherCollectable.getAllActions(id)
    const actions: ActionDetails[] = []
    for (const action of raw) {
      switch (action.actionType) {
        case ActionType.AddValuation:
          try {
            if (!action.valuedById) {
              actions.push(action)
              break
            }
            const valueBy = await database.Account.getContact(action.valuedById)
            const valueByName = getFullName(valueBy)
            actions.push({ ...action, valueByName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, valuedById: '', valueByName: 'DeletedContact' })
          }
          break
        default:
          actions.push(action)
      }
    }
    return actions
  }
}

export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.otherCollectable.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateOtherCollectable(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.otherCollectable.updateValuation(id, data)
  await delay()
  mutateOtherCollectable(id)
}

export async function deleteValuation(database: Database, id: string, valuationId: string) {
  await database.otherCollectable.deleteValuation(id, valuationId)
  await delay()
  mutateOtherCollectable(id)
}

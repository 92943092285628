import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  AppCheck,
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
} from "firebase/app-check";
import {
  initializeRecaptchaConfig,
  ActionCodeInfo,
  Auth,
  AuthErrorCodes,
  GoogleAuthProvider,
  MultiFactorResolver,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  UserCredential,
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getAuth,
  getMultiFactorResolver,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  type ApplicationVerifier,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import {
  trace,
  getPerformance,
  type FirebasePerformance,
  PerformanceTrace,
} from "firebase/performance";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
  type Analytics,
} from "firebase/analytics";
import {
  connectStorageEmulator,
  getStorage,
  type FirebaseStorage,
} from "firebase/storage";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
  type RemoteConfig,
} from "firebase/remote-config";
import { CoreAuth, CoreFirestore } from "./coreFirebase";
import { ClientAuthImpl, ClientFirestoreImpl } from "./firebaseClientImpl";
import { Database as EsDatabase } from "./remodel/database";
import { ExchangeRateSource } from "./remodel/database/exchangeRate";
import { Params } from "./remodel/types/database";
import { EncryptionLib } from "./remodel/types/encryptionLib";

export type Environment = "development" | "staging" | "prod" | "infradev";
export class FirebaseV2 {
  protected readonly firestore: Firestore;
  protected readonly appcheck: AppCheck | undefined;
  protected readonly storage: FirebaseStorage;
  protected readonly auth: Auth;
  protected readonly projectId: string;
  protected readonly encryptionLib: EncryptionLib;
  protected readonly params: Params;
  protected readonly isDevelopment: boolean;
  protected readonly performance: FirebasePerformance | undefined;
  protected readonly analytics: Analytics | undefined;
  protected readonly remoteConfig: RemoteConfig | undefined;
  protected resolver: MultiFactorResolver | undefined;
  protected email: string | undefined;

  constructor(
    env: Environment,
    encryptionLib: EncryptionLib,
    appConfig: FirebaseOptions,
    params: Params
  ) {
    this.isDevelopment = env === "development";
    this.projectId = appConfig.projectId ?? "";
    const firebaseApp = initializeApp(appConfig);
    initializeFirestore(firebaseApp, {
      ignoreUndefinedProperties: true,
    });

    // Export firestore incase we need to access it directly
    this.firestore = getFirestore(firebaseApp);
    this.storage = getStorage(firebaseApp);
    this.auth = getAuth(firebaseApp);
    setPersistence(this.auth, browserSessionPersistence);
    this.encryptionLib = encryptionLib;
    this.params = {
      ...params,
      emulatorEndpoint: params.emulatorEndpoint || "127.0.0.1",
    };

    CoreFirestore.setup(new ClientFirestoreImpl(this.firestore, this.storage));
    CoreAuth.setup(new ClientAuthImpl());
    this.onAuthChanged(() => {});

    console.log(`initializing environment: ${env}`);
    switch (env) {
      case "development": {
        // start emulator
        const emulatorUrl = this.params.emulatorEndpoint!;
        connectFirestoreEmulator(this.firestore, emulatorUrl, 8080);
        connectAuthEmulator(this.auth, `http://${emulatorUrl}:9099`, {
          disableWarnings: true,
        });
        connectStorageEmulator(this.storage, emulatorUrl, 9199);
        break;
      }

      case "staging":
      case "infradev":
      case "prod": {
        if (!process.env.NEXT_PUBLIC_RECAPTCHA_KEY_WEB) {
          console.error("staging environment missing recaptcha key");
        }

        // performance monitoring
        if (typeof window !== "undefined") {
          this.performance = getPerformance(firebaseApp);
          this.analytics = getAnalytics(firebaseApp);
          this.remoteConfig = getRemoteConfig(firebaseApp);
          this.remoteConfig.defaultConfig = { maintenance: false };
          this.remoteConfig.settings = {
            fetchTimeoutMillis: 60 * 1_000, // 1 minute
            minimumFetchIntervalMillis: 5 * 60 * 1_000, // 5 minutes
          };
          this.appcheck = initializeAppCheck(firebaseApp, {
            provider: new ReCaptchaEnterpriseProvider(
              process.env.NEXT_PUBLIC_RECAPTCHA_KEY_WEB!
            ),
            isTokenAutoRefreshEnabled: true,
          });

          initializeRecaptchaConfig(this.auth)
            .then(() => {
              console.log(
                `Recaptcha Enterprise Config Initialization successful.`
              );
            })
            .catch((error) => {
              console.error(
                `Recaptcha Enterprise Config Initialization failed with ${error}`
              );
            });
        }
        break;
      }
    }
  }

  async checkIsMaintained(): Promise<boolean> {
    if (!this.remoteConfig) throw new Error("RemoteConfig not initialized");
    await fetchAndActivate(this.remoteConfig);
    const key = "maintenance";
    const isMaintained = getValue(this.remoteConfig, key).asBoolean();
    return isMaintained;
  }

  logEvent(event: string, params?: Record<string, unknown>): void {
    if (this.analytics) {
      logEvent(this.analytics, event, params);
    }
  }

  setAnalyticsUserId(userId: string | null): void {
    if (this.analytics) {
      setUserId(this.analytics, userId);
    }
  }

  setAnalyticsUserProperties(params: Record<string, unknown>): void {
    if (this.analytics) {
      setUserProperties(this.analytics, params, { global: true });
    }
  }

  trace(name: string): PerformanceTrace | undefined {
    if (this.performance) {
      return trace(this.performance!, name);
    }
  }

  //#NOTE add any function that needs firestore, storage or auth
  async newDatabase(rateSource?: ExchangeRateSource): Promise<EsDatabase> {
    const currentUser = this.auth.currentUser!;
    return new EsDatabase(
      currentUser.uid,
      this.encryptionLib,
      this.params,
      this.auth,
      rateSource
    );
  }

  async checkUserExist(email: string): Promise<boolean> {
    const signInMethods = await fetchSignInMethodsForEmail(this.auth, email);
    return signInMethods.length > 0;
  }

  async signIn(email: string, password: string): Promise<UserCredential> {
    try {
      return await signInWithEmailAndPassword(this.auth, email, password);
    } catch (e: any) {
      if (e.code === AuthErrorCodes.MFA_REQUIRED) {
        this.resolver = getMultiFactorResolver(this.auth, e);
        this.email = email;
      }
      throw e;
    }
  }

  async signInWithSms(
    verificationId: string,
    verificationCode: string
  ): Promise<UserCredential> {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    const userCred = await this.resolver!.resolveSignIn(multiFactorAssertion);
    this.resolver = undefined;
    return userCred;
  }

  async signInWithBackupCode(backupCode: string): Promise<UserCredential> {
    const token = await this.consumeBackupCode(backupCode);
    return await signInWithCustomToken(this.auth, token);
  }

  getMultiFactorResolver(): MultiFactorResolver | undefined {
    return this.resolver;
  }

  generateRecaptcha(elementId: string): RecaptchaVerifier {
    return new RecaptchaVerifier(this.auth, elementId, { size: "invisible" });
  }

  async sendVerificationCode(
    options: PhoneInfoOptions,
    verifier: ApplicationVerifier
  ): Promise<string> {
    const phoneAuthProvider = new PhoneAuthProvider(this.auth);
    return phoneAuthProvider.verifyPhoneNumber(options, verifier);
  }

  /**
   * Consume backup code
   * @see `cloudfunctions/src/backupCode.ts` - `consumeBackupCode`
   */
  async consumeBackupCode(backupCode: string): Promise<string> {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");

    const url = `${endpoint}/consumeBackupCode`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: this.email, backupCode }),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
    const data = await resp.json();
    return data.customToken;
  }

  // check is new user
  async OAuthChecker(userCred: UserCredential): Promise<void> {
    const info = getAdditionalUserInfo(userCred);
    console.log("OAuthChecker info", info);
    if (info?.isNewUser) {
      const { user } = userCred;
      console.log("OAuthChecker new user", user);
      const database = await this.newDatabase();
      await database.initDefaultDocs({
        email: user.email!,
        name: user.displayName!,
      });
    }
  }

  async signInWithGoogle(): Promise<UserCredential> {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      const userCred = await signInWithPopup(this.auth, provider);
      this.OAuthChecker(userCred);
      return userCred;
    } catch (e: any) {
      if (e.code === AuthErrorCodes.MFA_REQUIRED) {
        this.resolver = getMultiFactorResolver(this.auth, e);
        this.email = e.customData._serverResponse.email;
      }
      throw e;
    }
  }

  async signInWithApple(): Promise<UserCredential> {
    try {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("name");
      provider.addScope("email");
      const userCred = await signInWithPopup(this.auth, provider);
      this.OAuthChecker(userCred);
      return userCred;
    } catch (e: any) {
      if (e.code === AuthErrorCodes.MFA_REQUIRED) {
        this.resolver = getMultiFactorResolver(this.auth, e);
        this.email = e.customData._serverResponse.email;
      }
      throw e;
    }
  }

  async signOut(): Promise<void> {
    await this.auth.signOut();
  }

  async getOobCode(
    email: string,
    requestType: ActionCodeInfo["operation"]
  ): Promise<string> {
    if (!this.isDevelopment) throw "Not in development mode.";

    const emulatorUrl = this.params.emulatorEndpoint!;
    const resp = await fetch(
      `http://${emulatorUrl}:9099/emulator/v1/projects/myassets-tests/oobCodes`
    );
    const { oobCodes } = await resp.json();
    const { oobCode } = oobCodes.find(
      (x: any) => x.email === email && x.requestType === requestType
    );
    if (!oobCode) throw new Error("Not found matched oobCode.");
    return oobCode;
  }

  async sendEmailWithActionLink(
    email: string,
    mode: "resetPassword" | "verifyEmail",
    callbackUrl: string,
    name?: string
  ) {
    const endpoint = this.params.cfEndpoint;
    if (!endpoint) throw new Error("API endpoint not found");

    const payload: Record<string, any> = { email, mode, url: callbackUrl };
    if (mode === "verifyEmail") {
      if (!name) throw new Error("Missing name for mode verifyEmail");
      payload.name = name;
    }
    const url = `${endpoint}/sendEmailWithActionLink`;
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    const resp = await fetch(url, config);
    if (resp.status !== 200) throw new Error(resp.statusText);
  }

  async signUp(
    email: string,
    password: string,
    name: string
  ): Promise<UserCredential> {
    const userCred = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const { user } = userCred;
    await updateProfile(user, { displayName: name });
    const database = await this.newDatabase();
    await database.initDefaultDocs({ email, name });
    await this.sendEmailWithActionLink(
      email,
      "verifyEmail",
      `${origin}/auth/proxy/`,
      name
    );

    try {
      const oobCode = await this.getOobCode(email, "VERIFY_EMAIL");
      const origin = !window ? "" : `${window.location.origin}`;
      const link = `${origin}/auth/proxy/?mode=verifyEmail&oobCode=${oobCode}`;
      console.log("Verify Link", link);
    } catch (e) {
      e instanceof Error && console.log(e.message);
    }

    return userCred;
  }

  async resend(email: string, name: string): Promise<void> {
    await this.sendEmailWithActionLink(
      email!,
      "verifyEmail",
      `${origin}/auth/proxy/`,
      name
    );
  }

  async checkCodeValid(oobCode: string): Promise<ActionCodeInfo> {
    return await checkActionCode(this.auth, oobCode);
  }

  async verifyEmail(oobCode: string): Promise<void> {
    await applyActionCode(this.auth, oobCode);
    this.auth.currentUser?.reload();
  }

  async forgot(email: string): Promise<void> {
    await this.sendEmailWithActionLink(
      email,
      "resetPassword",
      `${origin}/auth/proxy/`
    );

    try {
      const oobCode = await this.getOobCode(email, "PASSWORD_RESET");
      const origin = !window ? "" : `${window.location.origin}`;
      const link = `${origin}/auth/proxy/?mode=resetPassword&oobCode=${oobCode}`;
      console.log("Verify Link", link);
    } catch (e) {
      e instanceof Error && console.log(e.message);
    }
  }

  async reset(oobCode: string, newPassword: string): Promise<void> {
    await confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  onAuthChanged(callback: (user: User | null) => void) {
    return onAuthStateChanged(this.auth, (user) => {
      this.setAnalyticsUserId(user?.uid ?? null);
      return callback(user);
    });
  }

  async verifyDelegateInvite(
    inviteId: string,
    email: string
  ): Promise<boolean> {
    const emailSafe = encodeURIComponent(email ?? "");
    const resp = await fetch(
      `${this.params.cfEndpoint}/delegateVerifyInvite?inviteId=${inviteId}&email=${emailSafe}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resp.status !== 200) throw new Error(resp.statusText);

    const { registered } = await resp.json();
    return registered;
  }
}

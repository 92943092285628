import { Encryption } from "../../database/encryption";
import {
  IsStringNotEmpty,
  Min,
  IsOptional,
  IsEnum,
  IsString,
} from "../../decorators";
import { NotEncrypted } from "../../decorators/annotations";
import {
  EncryptedType,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../encryption/utils";

export class Room {
  @NotEncrypted
  @IsString()
  id!: string;

  @NotEncrypted
  @IsString({ each: true })
  position!: string[];

  @IsStringNotEmpty({ message: "Name is required" })
  name!: string;

  @NotEncrypted
  @Min(0, { message: "Size cannot be negative" })
  @IsOptional()
  size?: number;
}

export namespace Room {
  export type EncryptedKeys = "name";
  export const encryptedKey: EncryptedKeys = "name";
  export type Encrypted = EncryptedType<Room, EncryptedKeys>;
  export type EncryptedPart = Pick<Room, EncryptedKeys>;
  export async function encrypt<T extends Room>(
    input: T,
    encryption: Encryption
  ): Promise<EncryptedType<T, EncryptedKeys>> {
    return fullObjectEncryption(input, [encryptedKey], encryption);
  }
  export const decrypt: <T extends Room>(
    input: EncryptedType<T, EncryptedKeys>,
    encryption: Encryption
  ) => Promise<T> = fullObjectDecryption;
}

export class Bedroom extends Room {
  @NotEncrypted
  @Min(0, { message: "KingBedNum cannot be negative" })
  kingBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "BunkBedNum cannot be negative" })
  bunkBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "QueenBedNum cannot be negative" })
  queenBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "CotBedNum cannot be negative" })
  cotBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "DoubleBedNum cannot be negative" })
  doubleBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "CouchBedNum cannot be negative" })
  couchBedNum!: number;

  @NotEncrypted
  @Min(0, { message: "SingleBedNum cannot be negative" })
  singleBedNum!: number;
}

export enum BathroomType {
  Bathroom = "Bathroom",
  EnSuite = "EnSuite",
  Shower = "Shower",
  Powder = "Powder",
  WC = "WC",
}

export class Bathroom extends Room {
  @NotEncrypted
  @IsEnum(BathroomType, { message: "Invalid bathroomType" })
  bathroomType!: BathroomType;
}

export const bathroomTypeValues = Object.values(BathroomType);

export enum OtherRoomType {
  Attic = "Attic",
  Ballroom = "Ballroom",
  Bar = "Bar",
  Basement = "Basement",
  BootRoom = "BootRoom",
  Cellar = "Cellar",
  Cloakroom = "Cloakroom",
  Conservatory = "Conservatory",
  DanceYogaRoom = "DanceYogaRoom",
  DiningRoom = "DiningRoom",
  DrawingRoom = "DrawingRoom",
  DressingRoom = "DressingRoom",
  ElectricalPlantRoom = "ElectricalPlantRoom",
  Garden = "Garden",
  GamesRoom = "GamesRoom",
  Genkan = "Genkan",
  GymFitnessRoom = "GymFitnessRoom",
  Hall = "Hall",
  Kitchen = "Kitchen",
  Landing = "Landing",
  Larder = "Larder",
  LaundryRoomUtility = "LaundryRoomUtility",
  Library = "Library",
  LivingRoom = "LivingRoom",
  MovieTheatre = "MovieTheatre",
  MusicRoom = "MusicRoom",
  Office = "Office",
  Pantry = "Pantry",
  Parlour = "Parlour",
  SaunaSteam = "SaunaSteam",
  Snug = "Snug",
  SpareRoom = "SpareRoom",
  Stairwell = "Stairwell",
  StoreBoxRoom = "StoreBoxRoom",
  SwimmingPool = "SwimmingPool",
  WineCellar = "WineCellar",
  Workshop = "Workshop",
  OtherRoom = "OtherRoom",
}

export class OtherRoom extends Room {
  @NotEncrypted
  @IsEnum(OtherRoomType, { message: "Invalid otherRoomType" })
  otherRoomType!: OtherRoomType;
}

export const otherRoomTypeValues = Object.values(OtherRoomType);

export class CarPark extends Room {
  @NotEncrypted
  @Min(0, { message: "ParkingLotNum cannot be negative" })
  parkingLotNum!: number;
}

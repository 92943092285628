import { useEffect, useMemo } from 'react'
import add from 'date-fns/add'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import banks from 'core/remodel/types/banks.json'
import { Account } from 'core/remodel/types/cashAndBanking'
import type { Mortgage } from 'core/remodel/types/cashAndBanking/mortgage'
import {
  AssetType,
  AttachmentKind,
  Currency,
  CustomizedType,
  Period,
  periodValues,
  TimeZone
} from 'core/remodel/types/common'
import { CountryOptions } from 'core/remodel/types/options'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addCustomType, fetchCustomTypes, typeQuery } from '@/api/CommonService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fetchPropertyOptions, propertyQuery } from '@/api/PropertyService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { formatDate, makeOptions, sortAlphabetically, startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormPriceInput,
  FormSelect,
  FormTextarea,
  Input,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type MortgageValues = Mortgage

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<MortgageValues> => ({
  subtype: Account.Type.MortgageAccount,
  assetType: AssetType.CashAndBanking,
  // primary details
  name: '',
  value: { currency, value: 0 },
  initialAmount: { currency, value: 0 },
  linkToPropertyId: '',
  country: '',
  institution: '',
  startDate: startOfDayInTz(new Date(), timeZone),
  notes: '',
  // attributes
  fixedPeriod: { period: Period.Year, num: 1 },
  term: { period: Period.Month, num: 0 },
  accountNumber: '',
  interest: { period: Period.Year, num: 0 },
  groupIds: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

interface MortgageFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string
  values?: MortgageValues
  onCancel: () => void
  onSubmit: SubmitHandler<MortgageValues>
}

export function MortgageForm({ mode = 'Create', assetId, values, onCancel, onSubmit }: MortgageFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(
    () => getDefaultValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    watch,
    setValue
  } = useForm<MortgageValues>({ defaultValues })
  const country = watch('country')
  const currency = watch('value.currency')
  const interestPeriod = watch('interest.period')
  const interestNum = watch('interest.num')
  const startDate = watch('startDate')
  const termPeriod = watch('term.period')
  const termNum = watch('term.num')
  const dueDate = useMemo(() => {
    if (isNaN(termNum)) return
    switch (termPeriod) {
      case Period.Day:
        return add(startDate, { days: termNum })
      case Period.Week:
        return add(startDate, { weeks: termNum })
      case Period.Month:
        return add(startDate, { months: termNum })
      case Period.Quarter:
        return add(startDate, { months: termNum * 3 })
      case Period.Year:
        return add(startDate, { years: termNum })
    }
  }, [termNum, termPeriod, startDate])
  const annual = useMemo(() => {
    if (isNaN(interestNum)) return

    let result: number
    switch (interestPeriod) {
      case Period.Day:
        result = 365 * interestNum
        break
      case Period.Week:
        result = 52 * interestNum
        break
      case Period.Month:
        result = 12 * interestNum
        break
      case Period.Quarter:
        result = 4 * interestNum
        break
      case Period.Year:
        result = 1 * interestNum
        break
      default:
        return
    }

    return Number(result.toFixed(3))
  }, [interestNum, interestPeriod])
  // preferences and options
  const institutionSWR = useSWR([typeQuery.institution, CustomizedType.BankOrInstitution], fetchCustomTypes(database!))
  const { data: institutions, isValidating: loadingInstitutions } = institutionSWR
  const institutionOptions = useMemo(() => {
    const base = banks
      .filter((bank) => bank.country === country)
      .map((bank) => ({ label: bank.name, value: bank.name }))
    const added = institutions?.map((item) => ({ label: item, value: item })) ?? []
    return sortAlphabetically({ data: [...base, ...added], selector: (d) => d.label, locale: 'en' })
  }, [country, institutions])
  const propertyOptionsSWR = useSWR([propertyQuery.options], fetchPropertyOptions(database!))
  const { data: propertyOptions, isLoading: loadingProperties } = propertyOptionsSWR
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  const periodOptions = makeOptions(periodValues, (key) => `PeriodOptions.${key}`)
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [{ key: 'attributes', label: t('Attributes') }]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [mode, values, reset])

  useEffect(() => {
    setValue('initialAmount.currency', currency)
  }, [currency, setValue])

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[600px] grow flex-col'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput
                control={control}
                name={'name'}
                label={t('finances:Field.AccountName')}
                rules={{ required: true }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'value.currency', value: 'value.value' }}
                label={t('finances:Field.OutstandingAmount')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
              />
              <FormPriceInput
                control={control}
                name={{ currency: 'initialAmount.currency', value: 'initialAmount.value' }}
                label={t('finances:Field.InitialAmount')}
                format={preferences.numberFormat}
                digits={2}
                disabled={{ currency: true, value: mode === 'Edit' }}
              />
              <FormAutocomplete
                control={control}
                name={'linkToPropertyId'}
                label={t('finances:Field.LinkToProperty')}
                options={propertyOptions}
                isLoading={loadingProperties}
                placeholder={t('TypeToSearch')}
              />
            </div>
            <div className={'flex flex-col gap-4'}>
              <FormAutocomplete
                control={control}
                name={'country'}
                label={t('Field.Country')}
                options={CountryOptions}
                placeholder={t('Placeholder.Select')}
              />
              <FormCreatableAutocomplete
                control={control}
                name={'institution'}
                label={t('finances:Field.BankInstitution')}
                options={institutionOptions}
                onCreate={async (name) => await addCustomType(database!, CustomizedType.BankOrInstitution, name)}
                placeholder={t('SearchOrCreate')}
                isLoading={loadingInstitutions}
                rules={{ required: true }}
              />
              <FormDatePicker
                control={control}
                name={'startDate'}
                label={t('Field.StartDate')}
                format={preferences.dateFormat}
                timeZone={preferences.timeZone}
                disabled={mode === 'Edit'}
              />
            </div>
            <div className={'col-span-2'}>
              <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.attributes'}>
            <div className={'grid grid-cols-1 gap-2 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <div className={'grid grid-cols-1 gap-y-1'}>
                  <label className={'text-xs text-[#414554]'}>{t('finances:Field.FixedPeriod')}</label>
                  <div className={'grid grid-cols-2 gap-x-1'}>
                    <FormNumberInput
                      control={control}
                      name={'fixedPeriod.num'}
                      format={preferences.numberFormat}
                      placeholder={'000'}
                    />
                    <FormSelect control={control} name={'fixedPeriod.period'}>
                      {periodOptions.map(({ label, value }) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      ))}
                    </FormSelect>
                  </div>
                </div>
                <div className={'flex gap-x-1'}>
                  <div className={'grid grid-cols-1 gap-y-1'}>
                    <label className={'text-xs text-[#414554]'}>{t('finances:Field.Term')}</label>
                    <div className={'flex gap-x-1'}>
                      <FormSelect className={'shrink-0 basis-24'} control={control} name={'term.period'}>
                        {periodOptions.map(({ label, value }) => (
                          <SelectItem key={value} value={value}>
                            {label}
                          </SelectItem>
                        ))}
                      </FormSelect>
                      <FormNumberInput
                        control={control}
                        name={'term.num'}
                        format={preferences.numberFormat}
                        placeholder={'000'}
                      />
                    </div>
                  </div>
                  <div className={'grid shrink-0 basis-24 grid-cols-1 gap-y-1 text-xs'}>
                    <label className={'text-[#414554]'}>{t('finances:Field.MaturityDate')}</label>
                    <div className={'flex h-[38px] items-center justify-center rounded bg-primary text-sm text-white'}>
                      {dueDate ? formatDate(dueDate, preferences.dateFormat) : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormInput control={control} name={'accountNumber'} label={t('finances:Field.AccountNumber')} />
                <div className={'flex gap-x-1'}>
                  <div className={'grid grid-cols-1 gap-y-1'}>
                    <label className={'text-xs text-[#414554]'}>{t('finances:Field.InterestRate')}</label>
                    <div className={'flex gap-x-1'}>
                      <FormSelect className={'shrink-0 basis-24'} control={control} name={'interest.period'}>
                        {periodOptions.map(({ label, value }) => (
                          <SelectItem key={value} value={value}>
                            {label}
                          </SelectItem>
                        ))}
                      </FormSelect>
                      <Input
                        className={'h-full border text-right'}
                        value={`${interestNum}%`}
                        maxLength={4}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value.replace('%', ''))
                          setValue('interest.num', !isNaN(value) ? value : 0)
                        }}
                      />
                    </div>
                  </div>
                  <div className={'grid shrink-0 basis-20 grid-cols-1 gap-y-1 text-xs'}>
                    <label className={'text-[#414554]'}>{t('finances:Field.Annual')}</label>
                    <div className={'flex h-[38px] items-center justify-end rounded bg-primary px-2 text-white'}>
                      {`${annual}%`}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={'col-span-2'}>
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Field.Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            <AttachmentPanel
              assetId={assetId}
              control={control}
              name={{ mainImage: 'mainImage', attachments: 'attachments' }}
              widgetOptions={[AttachmentKind.PrimaryDetails]}
            />
          </TabPanel.Section>
        </div>

        <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
          <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button
            className={'group relative min-w-[130px]'}
            variant={'solid'}
            size={'md'}
            type={'submit'}
            disabled={!isValid}
          >
            {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
            <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
          </Button>
        </fieldset>
      </form>
    </TabPanel>
  )
}

export function MortgageFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

import i18n from '@/utils/i18n'

function getMessage(key: string, options: Record<string, any> = {}) {
  return i18n?.t(`validation:${key}`, options) as string
}

export const validation = {
  required: {
    message: () => getMessage('Required')
  },
  requiredName: {
    message: () => getMessage('RequiredName')
  },
  requiredEmail: {
    message: () => getMessage('RequiredEmail')
  },
  requiredPassword: {
    message: () => getMessage('RequiredPassword')
  },
  requiredConfirmPassword: {
    message: () => getMessage('RequiredConfirmPassword')
  },
  requiredNewPassword: {
    message: () => getMessage('RequiredNewPassword')
  },
  requiredConfirmNewPassword: {
    message: () => getMessage('RequiredConfirmNewPassword')
  },
  requiredImage: {
    message: () => getMessage('RequiredImage')
  },
  uppercase: {
    pattern: /^(?=.*[A-Z])/,
    message: () => getMessage('Uppercase')
  },
  lowercase: {
    pattern: /^(?=.*[a-z])/,
    message: () => getMessage('Lowercase')
  },
  whiteSpace: {
    pattern: /^\S*$/,
    message: () => getMessage('WhiteSpace')
  },
  numericCharacter: {
    pattern: /^(?=.*[0-9])/,
    message: () => getMessage('NumericCharacter')
  },
  specialCharacters: {
    pattern: /^(?=.*[!-/|:-@|[-`|{-~])/,
    message: () => getMessage('SpecialCharacters')
  },
  NoSpecialCharacters: {
    message: () => getMessage('NoSpecialCharacters')
  },
  name: {
    pattern: /^(?![ .,'-0-9]+$)[A-Za-z0-9 .,'-]+$/,
    message: () => getMessage('ValidName')
  },
  email: {
    pattern: /^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
    message: () => getMessage('Email')
  },
  PasswordLengthLimit: {
    pattern: (minLength: number, maxLength: number) => new RegExp(`^.{${minLength},${maxLength}}$`),
    message: (minLength: number, maxLength: number) => getMessage('PasswordLength', { minLength, maxLength })
  },
  maxLength: {
    message: (length: number) => getMessage('MaxLength', { length })
  },
  minLength: {
    message: (length: number) => getMessage('MinLength', { length })
  },
  PasswordMinLength: {
    message: (length: number) => getMessage('PasswordMinLength', { length })
  },
  PasswordMaxLength: {
    message: (length: number) => getMessage('PasswordMaxLength', { length })
  },
  max: {
    message: (value: number) => getMessage('Max', { value })
  },
  min: {
    message: (value: number) => getMessage('Min', { value })
  },
  lessOrEqual: {
    message: (compared: string) => getMessage('LessOrEqual', { compared })
  },
  greaterOrEqual: {
    message: (compared: string) => getMessage('GreaterOrEqual', { compared })
  },
  decimalDigits: {
    pattern: (count: number) => new RegExp(`\\.\\d{${count + 1},}$`),
    message: (count: number) => getMessage('DecimalDigits', { count })
  },
  greaterThanZero: {
    pattern: (value: string) => parseFloat(value) > 0,
    message: () => getMessage('GreaterThanZero')
  },
  validNumber: {
    pattern: /^\d*\.?\d*$/,
    message: () => getMessage('ValidNumber')
  },
  minimumGrams: {
    pattern: (value: string, min: number) => parseFloat(value) >= min,
    message: (value: string) => getMessage('MinimumGrams', { value })
  },
  maximumGrams: {
    pattern: (value: string, max: number) => parseFloat(value) <= max,
    message: (value: string) => getMessage('MaximumGrams', { value })
  },
  confirmPassword: {
    pattern: (password: string, confirmPassword: string) => password === confirmPassword,
    message: () => getMessage('ConfirmPassword')
  },
  checkCharacters: {
    pattern: (value: string) => /^[a-zA-Z0-9]*$/.test(value),
    message: () => getMessage('CheckCharacters')
  }
}

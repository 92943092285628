import type { ActionCodeInfo, ApplicationVerifier, MultiFactorResolver, PhoneInfoOptions, User } from 'firebase/auth'
import { mutate } from 'swr'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import type { Database } from 'core/remodel/database'
import { PermissionCategory } from 'core/remodel/refPaths'
import type { WithId } from 'core/remodel/types/common'
import { PermissionLevel, type Delegate } from 'core/remodel/types/delegates'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { delegateQuery, fetchDelegatesOf } from '@/api/DelegateService'
import { isMatchedEnv } from '@/constants/site'
import type { PopupMethod } from '@/types/common'
import { firebase } from '@/utils/firebase'
import type { ForgotValues, LoginValues, ResetValues, SignUpValues } from '@/components/form'

interface PermissionsState {
  delegatorId: string | null
  activeList: WithId<Delegate.EncryptedDelegator>[]
  // methods
  changeToSelf: () => Promise<void>
  changeToDelegate: (delegatorId: string) => Promise<void>
  canView: (kind: ViewPermissionKind) => boolean
  canEdit: (kind: PermissionCategory) => boolean
  canCreate: (kind: PermissionCategory) => boolean
  canDelete: (kind: PermissionCategory) => boolean
}

type AuthStatus =
  | 'uninitialized'
  | 'initializing'
  | 'failed'
  | 'ready'
  | 'unverified'
  | 'closing'
  | 'unauthenticated'
  | 'sessionExpired'

interface AuthState {
  status: AuthStatus
  error: string | null
  isSetupDone: boolean
  database: Database | null
  permissions: PermissionsState
  // login methods
  login: (values: LoginValues) => Promise<User>
  loginWithPopup: (method: PopupMethod) => Promise<User>
  loginWithSms: (verificationId: string, verificationCode: string) => Promise<User>
  loginWithBackupCode: (backupCode: string) => Promise<User>
  // methods
  logout: (expired: boolean) => Promise<void>
  signUp: (values: SignUpValues) => Promise<User>
  resend: (email: string, name: string) => Promise<void>
  forgot: (values: ForgotValues) => Promise<void>
  reset: (values: ResetValues) => Promise<void>
  checkCodeValid: (oobCode: string) => Promise<ActionCodeInfo>
  checkUserExist: (email: string) => Promise<boolean>
  verifyEmail: (oobCode: string) => Promise<void>
  verifyInvite: (inviteId: string, email: string) => Promise<boolean>
  getMultiFactorResolver: () => MultiFactorResolver | undefined
  generateRecaptcha: (elementId: string) => ApplicationVerifier
  sendVerificationCode: (options: PhoneInfoOptions, verifier: ApplicationVerifier) => Promise<string>
}

export const useAuthStore = create<AuthState>()(
  immer((set, get) => ({
    status: 'uninitialized',
    error: null,
    isSetupDone: false,
    database: null,
    permissions: {
      delegatorId: null,
      activeList: [],
      changeToSelf: async () => {
        const { status, database } = get()

        if (!database) return
        if (!['initializing', 'ready'].includes(status)) return

        const delegatesOf = await fetchDelegatesOf(database)([delegateQuery.of])
        const activeList = delegatesOf.filter(({ status }) => status === 'active')

        sessionStorage.removeItem(delegateStorageKey(database.userId))
        await database.switchToDelegate(null)
        await clearCache()
        set((state) => {
          state.isSetupDone = false
          state.permissions.delegatorId = null
          state.permissions.activeList = activeList
        })
      },
      changeToDelegate: async (delegatorId) => {
        const { status, database } = get()

        if (!database) return
        if (!['initializing', 'ready'].includes(status)) return

        const delegatesOf = await fetchDelegatesOf(database)([delegateQuery.of])
        const activeList = delegatesOf.filter(({ status }) => status === 'active')
        const delegate = activeList.find(({ id }) => id === delegatorId)

        if (delegate) {
          sessionStorage.setItem(delegateStorageKey(database.userId), JSON.stringify(delegatorId))
          await database.switchToDelegate({ id: delegatorId, permissions: delegate })
          await clearCache()
          set((state) => {
            state.isSetupDone = false
            state.permissions.delegatorId = delegatorId
            state.permissions.activeList = activeList
          })
        } else {
          set((state) => {
            state.isSetupDone = false
            state.permissions.activeList = activeList
          })
          throw new Error('Invalid delegate')
        }
      },
      canView: (kind) => {
        const { status, permissions } = get()
        const delegator = permissions.activeList.find(({ id }) => id === permissions.delegatorId)

        if (status !== 'ready') return false
        if (permissions.delegatorId === null) return true
        if (delegator) return checkViewPermission(kind, delegator)
        return false
      },
      canEdit: (kind) => {
        const { status, permissions } = get()
        const delegator = permissions.activeList.find(({ id }) => id === permissions.delegatorId)

        if (status !== 'ready') return false
        if (permissions.delegatorId === null) return true
        if (delegator) return delegator[kind] > PermissionLevel.View
        return false
      },
      canCreate: (kind) => {
        const { status, permissions } = get()
        const delegator = permissions.activeList.find(({ id }) => id === permissions.delegatorId)

        if (status !== 'ready') return false
        if (permissions.delegatorId === null) return true
        if (delegator) return delegator[kind] > PermissionLevel.Edit
        return false
      },
      canDelete: (kind) => {
        const { status, permissions } = get()
        const delegator = permissions.activeList.find(({ id }) => id === permissions.delegatorId)

        if (status !== 'ready') return false
        if (permissions.delegatorId === null) return true
        if (delegator) return delegator[kind] > PermissionLevel.Create
        return false
      }
    },
    // login methods
    login: async ({ email, password }) => {
      const { user } = await firebase.signIn(email, password)
      return user
    },
    loginWithPopup: async (method) => {
      switch (method) {
        case 'Google': {
          const { user } = await firebase.signInWithGoogle()
          return user
        }
        case 'Apple': {
          const { user } = await firebase.signInWithApple()
          return user
        }
      }
    },
    loginWithSms: async (verificationId, verificationCode) => {
      const { user } = await firebase.signInWithSms(verificationId, verificationCode)
      return user
    },
    loginWithBackupCode: async (backupCode) => {
      const { user } = await firebase.signInWithBackupCode(backupCode)
      return user
    },
    logout: async (expired) => {
      // set sessionExpired
      if (expired) set({ status: 'sessionExpired' })
      // clean session
      if (get().database) sessionStorage.removeItem(delegateStorageKey(get().database!.userId))

      await firebase.signOut()
      await mutate(() => true, undefined, { revalidate: true })
    },
    signUp: async ({ email, password, name }) => {
      const { user } = await firebase.signUp(email, password, name)
      return user
    },
    resend: (email, name) => firebase.resend(email, name),
    forgot: ({ email }) => firebase.forgot(email),
    reset: ({ oobCode, newPassword }) => firebase.reset(oobCode, newPassword),
    checkCodeValid: (oobCode) => firebase.checkCodeValid(oobCode),
    checkUserExist: (email) => firebase.checkUserExist(email),
    verifyEmail: (oobCode) => firebase.verifyEmail(oobCode),
    verifyInvite: (inviteId, email) => firebase.verifyDelegateInvite(inviteId, email),
    getMultiFactorResolver: () => firebase.getMultiFactorResolver(),
    generateRecaptcha: (elementId) => firebase.generateRecaptcha(elementId),
    sendVerificationCode: (options, verifier) => firebase.sendVerificationCode(options, verifier)
  }))
)

firebase.onAuthChanged(async (user) => {
  if (user) {
    // HACK print user id
    console.log(`User ID: ${user?.uid}`)

    try {
      // initialize database
      const database = await firebase.newDatabase()
      await database.prepareData()
      useAuthStore.setState({ status: 'initializing', database })
      const { closeAccountData } = await fetchPreferences(database)([userQuery.preferences])

      if (closeAccountData?.requestDate) {
        // TODO: HACK calling this here to initialize the database
        const { changeToSelf } = useAuthStore.getState().permissions
        await changeToSelf()
        useAuthStore.setState({ status: 'closing' })
      } else if (!user.emailVerified) {
        useAuthStore.setState({ status: 'unverified' })
      } else {
        const { delegatorId, changeToSelf, changeToDelegate } = useAuthStore.getState().permissions
        const sessionId = sessionStorage.getItem(delegateStorageKey(user.uid))
        const delegator = sessionId ? (JSON.parse(sessionId) as string) : null

        delegator ? await changeToDelegate(delegator) : await changeToSelf()
        // NOTE need to check
        firebase.setAnalyticsUserProperties({ delegatorId })
        useAuthStore.setState({ status: 'ready' })

        if (typeof window !== 'undefined') {
          try {
            ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
              try {
                ;(window as any).Genesys('command', 'Launcher.hide')
                ;(window as any).Genesys('command', 'Messenger.close')
              } catch (e) {
                console.warn('Failed to show Genesys chat:', e)
              }
            })
          } catch (e) {
            console.warn('Failed to subscribe to Genesys Launcher:', e)
          }
        }
      }
    } catch (e: any) {
      useAuthStore.setState({
        status: 'failed',
        error: e.toString(),
        database: null
      })
    }
  } else {
    useAuthStore.setState((state) => {
      if (state.status !== 'sessionExpired') state.status = 'unauthenticated'
      state.database = null
      state.permissions.delegatorId = null
      state.permissions.activeList = []
    })

    if (typeof window !== 'undefined') {
      try {
        ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
          try {
            ;(window as any).Genesys('command', 'Launcher.hide')
            ;(window as any).Genesys('command', 'Messenger.close')
          } catch (e) {
            console.warn('Failed to show Genesys chat:', e)
          }
        })
      } catch (e) {
        console.warn('Failed to subscribe to Genesys Launcher:', e)
      }
    }
  }
})

// utils
function delegateStorageKey(userId: string): string {
  return `${userId}:delegateId`
}

async function clearCache() {
  const queries = [...Object.values(userQuery), ...Object.values(delegateQuery)]
  await mutate((key) => Array.isArray(key) && !queries.includes(key[0]), undefined, { revalidate: true })
}

type ViewPermissionKind =
  | `${PermissionCategory}`
  | 'GlobalDashboard'
  | 'MyFinance'
  | 'MyCollectables'
  | 'Groups'
  | 'DocumentVault'
  | 'Wishlist'

function checkViewPermission(kind: ViewPermissionKind, delegator: WithId<Delegate.EncryptedDelegator>) {
  switch (true) {
    // case ['GlobalDashboard', 'Groups'].includes(kind):
    case kind === 'GlobalDashboard':
      return (
        Math.max(
          delegator.Finance,
          delegator.Property,
          delegator.Art,
          delegator.WineAndSpirits,
          delegator.OtherCollectables,
          delegator.Belonging
        ) > PermissionLevel.None
      )
    case kind === 'Groups':  // HACK to hide the groups menu
      return false
    case kind === 'MyFinance':
      return Math.max(delegator.Finance, delegator.Insurance) > PermissionLevel.None
    case kind === 'MyCollectables':
      return Math.max(delegator.Art, delegator.WineAndSpirits, delegator.OtherCollectables) > PermissionLevel.None
    case kind === 'DocumentVault':
      return true
    case kind === 'Wishlist':
      return false
    case Object.values(PermissionCategory).includes(kind as PermissionCategory):
      return delegator[kind as PermissionCategory] > PermissionLevel.None
    default:
      throw Error('Invalid view permission kind')
  }
}

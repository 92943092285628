import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23EB447C%22%2F%3E%3Cpath%20d%3D%22M7.493%2026.519v3.17H6.01v-8.532h3.264c.953%200%201.71.248%202.27.745.561.496.841%201.152.841%201.968%200%20.836-.274%201.487-.823%201.951-.549.465-1.317.698-2.306.698H7.493Zm0-1.19h1.781c.527%200%20.93-.124%201.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H7.493v2.976Zm6.187%204.36v-8.532h2.52c.754%200%201.423.168%202.007.504a3.436%203.436%200%200%201%201.356%201.43c.32.617.48%201.324.48%202.121v.428c0%20.808-.16%201.52-.483%202.133a3.395%203.395%200%200%201-1.377%201.418c-.596.332-1.278.498-2.048.498H13.68Zm1.483-7.336v6.152h.966c.778%200%201.374-.243%201.79-.73.417-.486.628-1.184.636-2.094v-.475c0-.926-.2-1.633-.603-2.12-.403-.49-.987-.733-1.752-.733h-1.037Zm11.273%203.72h-3.433v3.616H21.52v-8.532h5.42v1.196h-3.937v2.537h3.433v1.183Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23C0265A%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FilePdfIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#EB447C'}
      />
      <path
        d={
          'M7.493 26.519v3.17H6.01v-8.532h3.264c.953 0 1.71.248 2.27.745.561.496.841 1.152.841 1.968 0 .836-.274 1.487-.823 1.951-.549.465-1.317.698-2.306.698H7.493Zm0-1.19h1.781c.527 0 .93-.124 1.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H7.493v2.976Zm6.187 4.36v-8.532h2.52c.754 0 1.423.168 2.007.504a3.436 3.436 0 0 1 1.356 1.43c.32.617.48 1.324.48 2.121v.428c0 .808-.16 1.52-.483 2.133a3.395 3.395 0 0 1-1.377 1.418c-.596.332-1.278.498-2.048.498H13.68Zm1.483-7.336v6.152h.966c.778 0 1.374-.243 1.79-.73.417-.486.628-1.184.636-2.094v-.475c0-.926-.2-1.633-.603-2.12-.403-.49-.987-.733-1.752-.733h-1.037Zm11.273 3.72h-3.433v3.616H21.52v-8.532h5.42v1.196h-3.937v2.537h3.433v1.183Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#C0265A'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import { convertAllTimestamp } from "../../coreFirebase";
import {
  AssetType,
  Currency,
  DateFormat,
  Language,
  NumberFormat,
  Optional,
  TimeZone,
} from "./common";

// This is a Firebase Auth Type, not stored in firestore anywhere
export interface Profile {
  name: string;
  email: string;
  photo?: string;
}

export interface Preferences {
  baseCurrency: Optional<Currency>;
  baseLanguage: Language;
  timeZone: TimeZone;
  dateFormat: DateFormat;
  numberFormat: NumberFormat;
  notification: {
    updateEmail: boolean;
    tip: boolean;
    delegatesLogIn: boolean;
  };
  ivSalt: string;
  remindMFA?: Date;
  closeAccountData?: {
    requestDate: Date;
    // download date
    userProfile?: Date;
    addressBook?: Date;
    [AssetType.BankOrInstitution]?: Date;
    [AssetType.CashAndBanking]?: Date;
    [AssetType.TraditionalInvestments]?: Date;
    [AssetType.OtherInvestment]?: Date;
    [AssetType.Cryptocurrency]?: Date;
    [AssetType.Insurance]?: Date;
    [AssetType.Property]?: Date;
    [AssetType.Art]?: Date;
    [AssetType.WineAndSpirits]?: Date;
    [AssetType.WinePurchases]?: Date;
    [AssetType.OtherCollectables]?: Date;
    [AssetType.Belonging]?: Date;
  };
}
export type DownloadableAssetType = AssetType | "userProfile" | "addressBook";

export interface Notification {
  id: string; // Unique identifier for the notification
  title: string; // Title of the notification
  message: string; // Message content of the notification
  link?: string; // Optional link associated with the notification
  action?: string; // Optional action associated with the notification
  read: boolean; // Indicates whether the notification has been read
  timestamp: Date; // Timestamp of when the notification was created
}

/* Default instance */
export const defaultPreferences: Preferences = {
  baseCurrency: undefined,
  baseLanguage: Language.EN,
  timeZone: (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ??
    TimeZone.America_New_York) as TimeZone,
  dateFormat: DateFormat.MMDDYYYY,
  numberFormat: NumberFormat.Comma,
  notification: {
    updateEmail: false,
    tip: false,
    delegatesLogIn: false,
  },
  ivSalt: "",
};

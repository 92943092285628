import { useEffect } from 'react'
import useSWR, { SWRConfiguration } from 'swr'

import { isMatchedEnv } from '@/constants/site'
import { firebase } from '@/utils/firebase'
import { MaintenancePage } from '@/components/MaintenancePage'

interface MaintenanceProviderProps {
  children?: React.ReactNode | React.ReactNode[] | undefined
}

const config: SWRConfiguration = {
  refreshInterval: 1000 * 60 * 60 // reload every hour
}

export function MaintenanceProvider({ children }: MaintenanceProviderProps) {
  const { data: isMaintained = false, error } = useSWR(
    isMatchedEnv(['development']) ? null : 'isMaintained', // skip in development
    () => firebase.checkIsMaintained(),
    config
  )

  useEffect(() => {
    error && console.log('Failed to check maintenance status', error)
  }, [error])

  return isMaintained ? <MaintenancePage /> : children
}

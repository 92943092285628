import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20fill%3D%22%237ED321%22%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M9.503%2021.04h1.476v5.983c0%20.816-.255%201.464-.765%201.945-.51.48-1.182.72-2.018.72-.89%200-1.578-.224-2.063-.673-.484-.45-.726-1.074-.726-1.875h1.476c0%20.453.113.794.337%201.022.225.229.55.343.976.343.398%200%20.716-.132.952-.395.236-.264.355-.628.355-1.093V21.04Zm4.652%205.362v3.17h-1.483V21.04h3.264c.953%200%201.71.248%202.27.744.561.496.841%201.153.841%201.97%200%20.835-.274%201.485-.823%201.95-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527%200%20.93-.124%201.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.425h-1.821v2.977Zm12.726%203.252c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715%200-1.348-.161-1.898-.486a3.24%203.24%200%200%201-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725%201.479-1.087%202.584-1.087.95%200%201.703.234%202.262.703.558.469.894%201.145%201.008%202.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664%200-1.169.239-1.514.717-.346.479-.525%201.18-.536%202.107v.656c0%20.922.194%201.638.583%202.147.388.51.928.765%201.62.765.757%200%201.297-.172%201.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z%22%2F%3E%3Cpath%20fill%3D%22%234C8B07%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileJpgIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        fill={'#7ED321'}
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
      />
      <path
        fill={'#FFF'}
        d={
          'M9.503 21.04h1.476v5.983c0 .816-.255 1.464-.765 1.945-.51.48-1.182.72-2.018.72-.89 0-1.578-.224-2.063-.673-.484-.45-.726-1.074-.726-1.875h1.476c0 .453.113.794.337 1.022.225.229.55.343.976.343.398 0 .716-.132.952-.395.236-.264.355-.628.355-1.093V21.04Zm4.652 5.362v3.17h-1.483V21.04h3.264c.953 0 1.71.248 2.27.744.561.496.841 1.153.841 1.97 0 .835-.274 1.485-.823 1.95-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527 0 .93-.124 1.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.425h-1.821v2.977Zm12.726 3.252c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715 0-1.348-.161-1.898-.486a3.24 3.24 0 0 1-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725 1.479-1.087 2.584-1.087.95 0 1.703.234 2.262.703.558.469.894 1.145 1.008 2.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664 0-1.169.239-1.514.717-.346.479-.525 1.18-.536 2.107v.656c0 .922.194 1.638.583 2.147.388.51.928.765 1.62.765.757 0 1.297-.172 1.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z'
        }
      />
      <path fill={'#4C8B07'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%238E9BCB%22%2F%3E%3Cpath%20d%3D%22m9.17%2027.714%202.168-6.674h1.63l-3.072%208.531H8.462l-3.06-8.53h1.624l2.144%206.673Zm9.217-.34c0-.375-.132-.664-.395-.867-.264-.203-.74-.408-1.427-.615-.687-.207-1.234-.438-1.64-.692-.778-.488-1.167-1.125-1.167-1.91%200-.687.28-1.254.841-1.699.56-.445%201.288-.668%202.183-.668.594%200%201.123.11%201.588.328.465.219.83.53%201.095.935.266.404.399.852.399%201.344h-1.478c0-.445-.14-.794-.419-1.045-.279-.252-.678-.378-1.198-.378-.484%200-.86.103-1.128.31-.267.207-.401.496-.401.867%200%20.313.144.574.433.783.29.209.766.412%201.43.609.664.197%201.197.422%201.6.674.402.252.697.54.884.867.188.326.282.708.282%201.145%200%20.711-.273%201.277-.818%201.697-.545.42-1.284.63-2.217.63a4.168%204.168%200%200%201-1.703-.343c-.517-.229-.92-.544-1.207-.946a2.358%202.358%200%200%201-.43-1.407h1.482c0%20.485.16.86.48%201.125.32.266.78.399%201.378.399.515%200%20.903-.105%201.163-.314.26-.209.39-.485.39-.829Zm2.783%202.197v-8.53h2.52c.754%200%201.423.167%202.007.503a3.436%203.436%200%200%201%201.356%201.43c.32.617.48%201.324.48%202.12v.429c0%20.808-.16%201.52-.483%202.132a3.395%203.395%200%200%201-1.377%201.418c-.595.332-1.278.498-2.047.498H21.17Zm1.483-7.336v6.153h.967c.777%200%201.374-.243%201.79-.73.416-.486.628-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.121-.402-.488-.986-.733-1.752-.733h-1.037Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%235F6DA2%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileVsdIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#8E9BCB'}
      />
      <path
        d={
          'm9.17 27.714 2.168-6.674h1.63l-3.072 8.531H8.462l-3.06-8.53h1.624l2.144 6.673Zm9.217-.34c0-.375-.132-.664-.395-.867-.264-.203-.74-.408-1.427-.615-.687-.207-1.234-.438-1.64-.692-.778-.488-1.167-1.125-1.167-1.91 0-.687.28-1.254.841-1.699.56-.445 1.288-.668 2.183-.668.594 0 1.123.11 1.588.328.465.219.83.53 1.095.935.266.404.399.852.399 1.344h-1.478c0-.445-.14-.794-.419-1.045-.279-.252-.678-.378-1.198-.378-.484 0-.86.103-1.128.31-.267.207-.401.496-.401.867 0 .313.144.574.433.783.29.209.766.412 1.43.609.664.197 1.197.422 1.6.674.402.252.697.54.884.867.188.326.282.708.282 1.145 0 .711-.273 1.277-.818 1.697-.545.42-1.284.63-2.217.63a4.168 4.168 0 0 1-1.703-.343c-.517-.229-.92-.544-1.207-.946a2.358 2.358 0 0 1-.43-1.407h1.482c0 .485.16.86.48 1.125.32.266.78.399 1.378.399.515 0 .903-.105 1.163-.314.26-.209.39-.485.39-.829Zm2.783 2.197v-8.53h2.52c.754 0 1.423.167 2.007.503a3.436 3.436 0 0 1 1.356 1.43c.32.617.48 1.324.48 2.12v.429c0 .808-.16 1.52-.483 2.132a3.395 3.395 0 0 1-1.377 1.418c-.595.332-1.278.498-2.047.498H21.17Zm1.483-7.336v6.153h.967c.777 0 1.374-.243 1.79-.73.416-.486.628-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.121-.402-.488-.986-.733-1.752-.733h-1.037Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#5F6DA2'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

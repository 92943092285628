import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { validation } from '@/constants/validation'
import type { PopupMethod } from '@/types/common'
import { cn } from '@/utils/classnames'
import { Button, FormCheckbox, FormInput } from '@/components/base'
import { SupportIcon } from '@/components/icon'
import { PasswordStrengthBar } from '@/components/PasswordStrengthBar'

export type SignUpValues = {
  name: string
  email: string
  password: string
  confirmPassword: string
  receiveUpdates: boolean
}

const defaultValues: SignUpValues = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
  receiveUpdates: false
}

interface SignUpFormProps {
  values?: SignUpValues
  onSubmit: SubmitHandler<SignUpValues>
  onPopupLogin: (method: PopupMethod) => Promise<void>
}

export function SignUpForm({ values, onSubmit, onPopupLogin }: SignUpFormProps) {
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting }
  } = useForm<SignUpValues>({ defaultValues, values, mode: 'onBlur' })
  const isDelegate = !!values

  return (
    <form className={'w-full max-w-[440px] rounded border border-grey/50 p-[50px]'} onSubmit={handleSubmit(onSubmit)}>
      {/* Title */}
      <h1 className={'my-5 text-[30px] font-bold leading-[35px] text-white'}>
        {!isDelegate ? t('auth:CreateYourAccount') : 'Create password'}
      </h1>

      {/* Support */}
      <Button asChild={true} className={'absolute right-4 top-4'}>
        <a
          href={'https://myassets.com/contact-us'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          title={t('auth:ContactUs')}
        >
          <SupportIcon className={'transition-colors hover:text-primary'} />
        </a>
      </Button>

      {/* Name */}
      {!isDelegate && (
        <FormInput
          className={'mb-5'}
          control={control}
          name={'name'}
          type={'text'}
          placeholder={t('auth:Field.Name')}
          maxLength={34}
          displayErrorMode={'tooltip'}
          rules={{
            required: validation.requiredName.message(),
            validate: {
              checkName: (value) => validation.name.pattern.test(value) || validation.name.message()
            }
          }}
        />
      )}

      {/* Email */}
      {!isDelegate && (
        <FormInput
          className={'mb-5'}
          control={control}
          name={'email'}
          type={'email'}
          placeholder={t('auth:Field.Email')}
          displayErrorMode={'tooltip'}
          rules={{
            required: validation.requiredEmail.message(),
            validate: {
              checkEmail: (value) => validation.email.pattern.test(value) || validation.email.message()
            }
          }}
        />
      )}

      {/* Password */}
      <FormInput
        className={'mb-5'}
        control={control}
        name={'password'}
        type={'password'}
        placeholder={t('auth:Field.Password')}
        displayErrorMode={'tooltip'}
        rules={{
          required: validation.requiredPassword.message(),
          validate: {
            checkMinLength: (value) => value.trim().length >= 12 || validation.PasswordMinLength.message(12),
            checkMaxLength: (value) => value.trim().length <= 128 || validation.PasswordMaxLength.message(128)
          }
        }}
      />

      {/* Confirm Password */}
      <FormInput
        className={'mb-5'}
        control={control}
        name={'confirmPassword'}
        type={'password'}
        placeholder={t('auth:Field.ConfirmPassword')}
        displayErrorMode={'tooltip'}
        rules={{
          required: validation.requiredConfirmPassword.message(),
          validate: {
            confirmPassword: (value) =>
              validation.confirmPassword.pattern(watch('password'), value!) || validation.confirmPassword.message()
          }
        }}
      />

      <PasswordStrengthBar password={watch('password')} />

      {/* Agree to receive news and updates */}
      <FormCheckbox
        className={'mb-4'}
        labelClassName={'text-white'}
        control={control}
        name={'receiveUpdates'}
        label={t('auth:ReceiveNews')}
      />

      {/* Terms and Privacy policy */}
      <p className={'mb-4 text-xs text-white'}>
        <Trans
          t={t}
          i18nKey={'auth:AcceptTerms'}
          components={[
            <a
              key={'terms-and-conditions'}
              className={'text-primary underline hover:text-secondary-hover'}
              href={'https://myassets.com/terms-and-conditions'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            />,
            <a
              key={'privacy-policy'}
              className={'text-primary underline hover:text-secondary-hover'}
              href={'https://myassets.com/privacy-policy'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            />
          ]}
        />
      </p>

      <Button className={'group mb-4 w-full'} variant={'solid'} size={'md'} type={'submit'} disabled={isSubmitting}>
        {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
        <span className={cn({ 'opacity-0': isSubmitting })}>{t('auth:SignUp')}</span>
      </Button>

      {/* Go Login */}
      <Link className={'mb-5 block text-center text-sm text-primary hover:text-secondary-hover'} href={'/auth/login'}>
        {t('auth:SignInNow')}
      </Link>

      <div className={'mb-7 flex items-center gap-3'}>
        <div className={'h-px w-full bg-grey'} />
        <span className={'shrink-0 text-sm font-light text-grey'}>{t('auth:OrSignUpWith')}</span>
        <div className={'h-px w-full bg-grey'} />
      </div>

      <SocialLoginSection onPopupLogin={onPopupLogin} />
    </form>
  )
}

function SocialLoginSection({ onPopupLogin }: Pick<SignUpFormProps, 'onPopupLogin'>) {
  const { t } = useTranslation()
  const { handleSubmit, formState } = useForm()
  const [currentMethod, setCurrentMethod] = useState<PopupMethod | null>(null)
  const methods = [
    { key: 'Google' as PopupMethod, icon: '/images/auth/google.svg' },
    { key: 'Apple' as PopupMethod, icon: '/images/auth/apple.svg' }
  ]

  const onClick = async (method: PopupMethod) => {
    setCurrentMethod(method)
    await handleSubmit(async () => await onPopupLogin(method))()
    setCurrentMethod(null)
  }

  return (
    <fieldset
      className={'flex h-10 items-center gap-x-2 [&>button]:h-full [&>button]:flex-1'}
      disabled={formState.isSubmitting}
    >
      {methods.map(({ key, icon }) => (
        <Button key={key} className={'group relative border-gray-200'} size={'sm'} onClick={() => onClick(key)}>
          {currentMethod === key && (
            <Loader2Icon className={'absolute animate-spin opacity-0 group-disabled:opacity-100'} />
          )}
          <Image
            className={cn(currentMethod === key && 'group-disabled:opacity-0')}
            src={icon}
            alt={key}
            width={24}
            height={24}
          />
          <span className={cn('whitespace-nowrap', currentMethod === key && 'group-disabled:opacity-0')}>
            {t('auth:SignUpWithPlatform', { platform: key })}
          </span>
        </Button>
      ))}
    </fieldset>
  )
}

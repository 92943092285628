import { InvalidInput } from "../../error";
import {
  CompDifference,
  OmitKeys,
  UpdateObject,
  buildArrayUpdate,
  validateStringNotEmpty,
} from "../../../utils";
import { Encryption } from "../../../database/encryption";
import { NotEncrypted } from "../../../decorators/annotations";
import type { Optional, Deletable, AssetV2 } from "../../common";
import {
  EncryptedType,
  fullObjectDecryption,
  fullObjectEncryption,
} from "../../../encryption/utils";
import {
  IsString,
  IsStringNotEmpty,
  IsEnum,
  IsOptionalOnUpdate,
} from "../../../decorators";
import { AttachmentKind } from "./type";

export const IVAttachmentFileSaltFieldKey = "_ivSaltAttachmentFile";
export const IVAttachmentFileSaltFieldDefaultValue = "";

export class Attachment {
  @NotEncrypted
  @IsOptionalOnUpdate()
  @IsStringNotEmpty({ message: "Attachment key should not be empty" })
  key!: string;

  @NotEncrypted
  @IsEnum(AttachmentKind)
  @IsOptionalOnUpdate()
  type!: AttachmentKind;

  @NotEncrypted
  @IsString()
  @IsOptionalOnUpdate()
  _ivSaltAttachmentFile!: string;
  // HACK, mobile can't resolve the variable key with class-validator
  // [IVAttachmentFileSaltFieldKey]!: string;

  @IsStringNotEmpty({ message: "Attachment name should not be empty" })
  name!: string;

  static encryptedKeyArray: readonly (keyof Attachment.EncryptedPart)[] = [
    "name",
  ];

  static async encrypt(
    rawData: Attachment,
    encryption: Encryption
  ): Promise<Attachment.Encrypted> {
    return fullObjectEncryption(
      rawData,
      Attachment.encryptedKeyArray,
      encryption
    );
  }
  static async encryptArray(
    input: Optional<Attachment[]>,
    encryption: Encryption
  ): Promise<Optional<Attachment.Encrypted[]>> {
    if (input)
      return Promise.all(input.map((v) => Attachment.encrypt(v, encryption)));
    else return undefined;
  }

  static decrypt(
    data: EncryptedType<Attachment, Attachment.EncryptedKey>,
    encryption: Encryption
  ): Promise<Attachment> {
    return fullObjectDecryption(data, encryption);
  }
  static async decryptArray(
    input: Optional<Attachment.Encrypted[]>,
    encryption: Encryption
  ): Promise<Optional<Attachment[]>> {
    if (input)
      return Promise.all(input.map((v) => Attachment.decrypt(v, encryption)));
    else return undefined;
  }

  //#TODO: Replace this by using validateWithGroups
  static validateEncryptedPart(data: Attachment.EncryptedPart) {
    if (!validateStringNotEmpty(data.name)) {
      throw new InvalidInput("Attachment name should not be empty");
    }
  }

  //#TODO: Need to combine with validateEncryptedPart, and remove this function
  static validateEncryptedObj(input: Attachment.Validate[]) {
    input.forEach((attachment, idx) => {
      if (attachment.key === "") {
        throw new InvalidInput("Attachment key should not be empty");
      }
      if (input.findIndex((a) => a.key === attachment.key) !== idx) {
        throw new InvalidInput("Attachment key should be unique");
      }
    });
  }

  static equal(a: Attachment, b: Attachment): boolean {
    return a.key === b.key && a.type === b.type && a.name === b.name;
  }

  static intoUpdate(
    current: Optional<Attachment[]>,
    update: Optional<Attachment[]>
  ): Deletable<
    Optional<{
      removed: Attachment[];
      added: Attachment[];
      changed: Attachment[];
    }>
  > {
    return buildArrayUpdate(current, update, (value, array) => {
      const maybeAttachment = array.find(
        (attachment) => attachment.key === value.key
      );
      if (maybeAttachment) {
        if (Attachment.equal(maybeAttachment, value)) {
          return CompDifference.Same;
        } else {
          return CompDifference.changed;
        }
      } else {
        return CompDifference.NotFound;
      }
    });
  }

  static compareUpdate<T extends Pick<AssetV2, "attachments">>(
    current: T,
    update: T
  ): {
    attachments: UpdateObject<Pick<AssetV2, "attachments">>["attachments"];
    newImages: string[];
    removedImages: string[];
  } {
    let result: UpdateObject<Pick<AssetV2, "attachments">>["attachments"] =
      undefined;
    const attachmentsDifference = Attachment.intoUpdate(
      current.attachments,
      update.attachments
    );
    const newImages: string[] = [];
    const removedImages: string[] = [];
    if (attachmentsDifference !== undefined) {
      if (attachmentsDifference === null) {
        result = null;
      } else {
        result = update.attachments;
        attachmentsDifference.added.forEach((attachment) => {
          if (attachment.type === AttachmentKind.AssetImage) {
            newImages.push(attachment.key);
          }
        });
        attachmentsDifference.changed.forEach((attachment) => {
          if (attachment.type === AttachmentKind.AssetImage) {
            const currentAttachment = current.attachments!.find(
              (v) => v.key === attachment.key
            )!;
            if (currentAttachment.type !== AttachmentKind.AssetImage) {
              newImages.push(attachment.key);
            }
          }
        });
        attachmentsDifference.removed.forEach((attachment) => {
          if (attachment.type === AttachmentKind.AssetImage) {
            removedImages.push(attachment.key);
          }
        });
      }
    }
    return { attachments: result, newImages, removedImages };
  }
}

export namespace Attachment {
  export type EncryptedKey = "name";
  export type Encrypted = EncryptedType<Attachment, EncryptedKey>;
  export type EncryptedPart = Pick<Attachment, EncryptedKey>;
  export type Validate = OmitKeys<Attachment, EncryptedKey>;
}

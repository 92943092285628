import Decimal from "decimal.js";
import { CoreFirestore, getQueriedData } from "../../coreFirebase";
import { Refs } from "../refs";
import { Account } from "../types/cashAndBanking";
import { Loan } from "../types/cashAndBanking/loan";
import { Mortgage } from "../types/cashAndBanking/mortgage";
import { Amount, Currency } from "../types/common";
import { AssetType } from "../types/enums";
import { DataPoisoned } from "../types/error";
import {
  ComparativeNetWorthReport,
  NetWorthReportDetails,
  SubtypeDetails,
  assetTypeWithSubtype,
  defaultComparativeNetWorthReport,
  defaultNetWorthReportDetails,
} from "../types/reports";
import { SummaryManager } from "../types/summaryManager";
import { Wine, WinePricingMethod } from "../types/wineAndSprits";
import {
  AllowedDecimalPlaces,
  addDecimal,
  mulAmount,
  subDecimal,
} from "../utils";
import { ExchangeRate } from "./exchangeRate";
import { PriceSource } from "./priceSource";
import { HoldingType } from "../types/traditionalInvestments";
import { OwnershipType } from "../types/properties";
import { LifeInsuranceType } from "../types/insurance";

function toDecimalPlacesNumber(value: Decimal) {
  return value.toDecimalPlaces(AllowedDecimalPlaces).toNumber();
}

type SharedItems = {
  [id: string]: {
    name?: string; // for property
    subtype?: string;
    purchasePrice: Amount;
    value: Amount;
    ownedPercentage: number;
    sold?: boolean;
    archived?: boolean;
  };
};

function computePriceAndAssets<T extends SharedItems>(
  items: T,
  exRate: ExchangeRate,
  details: NetWorthReportDetails & SubtypeDetails,
  currency: Currency,
  computeSubtypes: boolean
) {
  const result = Object.values(items).reduce(
    (acc, item) => {
      // #NOTE sold and archived items are not included
      if (item.sold || item.archived) return acc;
      const purchasePrice = new Decimal(item.purchasePrice.value)
        .mul(exRate.getToBaseExchangeRate(item.purchasePrice.currency).rate)
        .mul(item.ownedPercentage)
        .div(100);
      const assets = new Decimal(item.value.value)
        .mul(exRate.getToBaseExchangeRate(item.value.currency).rate)
        .mul(item.ownedPercentage)
        .div(100);

      if (!computeSubtypes)
        return {
          totalPurchasePrice: purchasePrice.add(acc.totalPurchasePrice),
          totalAssets: assets.add(acc.totalAssets),
          subtypes: acc.subtypes,
        };

      // subtypes
      if (!item.subtype) throw new Error("Subtype not found");
      if (!acc.subtypes[item.subtype]) {
        acc.subtypes[item.subtype] = {
          totalPurchasePrice: purchasePrice,
          totalAssets: assets,
        };
      } else {
        acc.subtypes[item.subtype].totalPurchasePrice =
          acc.subtypes[item.subtype].totalPurchasePrice.add(purchasePrice);
        acc.subtypes[item.subtype].totalAssets =
          acc.subtypes[item.subtype].totalAssets.add(assets);
      }
      return {
        totalPurchasePrice: purchasePrice.add(acc.totalPurchasePrice),
        totalAssets: assets.add(acc.totalAssets),
        subtypes: acc.subtypes,
      };
    },
    {
      totalPurchasePrice: new Decimal(0),
      totalAssets: new Decimal(0),
      subtypes: {} as {
        [subtype: string]: {
          totalPurchasePrice: Decimal;
          totalAssets: Decimal;
        };
      },
    }
  );
  details.original.purchasePrice.value = toDecimalPlacesNumber(
    result.totalPurchasePrice
  );
  details.current.assets.value = toDecimalPlacesNumber(result.totalAssets);

  if (!computeSubtypes) return;
  // subtypes
  for (const subtype in result.subtypes) {
    if (!details.subtype[subtype])
      details.subtype[subtype] = defaultNetWorthReportDetails(currency);
    details.subtype[subtype]!.original.purchasePrice.value =
      toDecimalPlacesNumber(result.subtypes[subtype].totalPurchasePrice);
    details.subtype[subtype]!.current.assets.value = toDecimalPlacesNumber(
      result.subtypes[subtype].totalAssets
    );
  }
}

function sumSubtypeDetailsToTarget(
  target: NetWorthReportDetails,
  subtypes: NetWorthReportDetails[]
) {
  const totalResult = subtypes.reduce(
    (acc, v) => {
      const totalPurchasePrice = acc.totalPurchasePrice.add(
        v.original.purchasePrice.value
      );
      const totalOriginalLiabilities = acc.totalOriginalLiabilities.add(
        v.original.liabilities.value
      );
      const totalAssets = acc.totalAssets.add(v.current.assets.value);
      const totalLiabilities = acc.totalLiabilities.add(
        v.current.liabilities.value
      );
      return {
        totalPurchasePrice,
        totalOriginalLiabilities,
        totalAssets,
        totalLiabilities,
      };
    },
    {
      totalPurchasePrice: new Decimal(0),
      totalOriginalLiabilities: new Decimal(0),
      totalAssets: new Decimal(0),
      totalLiabilities: new Decimal(0),
    }
  );
  target.original.purchasePrice.value = toDecimalPlacesNumber(
    totalResult.totalPurchasePrice
  );
  target.original.liabilities.value = toDecimalPlacesNumber(
    totalResult.totalOriginalLiabilities
  );
  target.current.assets.value = toDecimalPlacesNumber(totalResult.totalAssets);
  target.current.liabilities.value = toDecimalPlacesNumber(
    totalResult.totalLiabilities
  );
}

function computePercentageChange(original: number, current: number): number {
  if (original == 0 && current == 0) return 0;
  if (original == 0) return NaN;
  const percentageChange = new Decimal(current)
    .div(original)
    .sub(1)
    .mul(100)
    .toDecimalPlaces(AllowedDecimalPlaces)
    .toNumber();
  // if (original < 0) return -percentageChange
  return percentageChange;
}

function computeNetValueAndPercentageChange({
  original,
  current,
  netChange,
}: NetWorthReportDetails) {
  original.netInvestment.value = addDecimal(
    original.purchasePrice.value,
    original.liabilities.value
  );
  current.netValue.value = addDecimal(
    current.assets.value,
    current.liabilities.value
  );

  netChange.amountChange.value = subDecimal(
    current.netValue.value,
    original.netInvestment.value
  );
  netChange.percentageChange = computePercentageChange(
    original.netInvestment.value,
    current.netValue.value
  );
}

/**
 * According to the requirements, the report is computed as follows:
 * - CashAndBanking: includes all kinds of accounts, `liabilities` is the leftover after allocating to assets
 * - Insurance: only include `whole of life` insurance
 * - Property: only include `owned` property
 * sold / archived assets are ignored
 */
export async function computeComparativeNetWorthReport(
  summaryManager: SummaryManager,
  exRate: ExchangeRate,
  refs: Refs,
  priceSource: PriceSource,
  // for removing coming soon assetTypes computation
  excludeAssetTypes: (
    | AssetType.TraditionalInvestments
    | AssetType.OtherInvestment
    | AssetType.Cryptocurrency
    | AssetType.Insurance
    | AssetType.WineAndSpirits
  )[]
): Promise<ComparativeNetWorthReport> {
  const [
    CashAndBankingSummary,
    TISummary,
    OISummary,
    cryptoSummary,
    insuranceSummary,
    artSummary,
    wineSummary,
    otherCollectablesSummary,
    propertySummary,
    belongingSummary,
  ] = await Promise.all([
    summaryManager
      .get(AssetType.CashAndBanking)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.TraditionalInvestments)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.OtherInvestment)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Cryptocurrency)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Insurance)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Art)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.WineAndSpirits)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.OtherCollectables)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Property)
      .syncAndGetData()
      .then((v) => v.summary),
    summaryManager
      .get(AssetType.Belonging)
      .syncAndGetData()
      .then((v) => v.summary),
  ]);

  const currency = exRate.BaseCurrency as Currency;
  const result: ComparativeNetWorthReport =
    defaultComparativeNetWorthReport(currency);

  // *** Loop summary to get purchasePrice and assets ***

  // cash and banking
  // #NOTE: cash and banking does not have purchase price
  const cashAndBankingResult = Object.values(
    CashAndBankingSummary.accounts
  ).reduce(
    (acc, account) => {
      // needs allocation, compute later
      if (
        account.subtype === Account.Type.LoanAccount ||
        account.subtype === Account.Type.MortgageAccount
      ) {
        return acc;
      }

      const value = account.subAccounts
        .reduce((innerAcc, subAccount) => {
          return new Decimal(subAccount.balance.value)
            .mul(exRate.getToBaseExchangeRate(subAccount.balance.currency).rate)
            .add(innerAcc);
        }, new Decimal(0))
        .mul(account.ownedPercentage)
        .div(100);

      return account.subtype === Account.Type.CreditCardAccount
        ? {
            creditCardAccountLiabilities: value.add(
              acc.creditCardAccountLiabilities
            ),
            totalAssets: acc.totalAssets,
          }
        : {
            creditCardAccountLiabilities: acc.creditCardAccountLiabilities,
            totalAssets: value.add(acc.totalAssets),
          };
    },
    {
      creditCardAccountLiabilities: new Decimal(0),
      totalAssets: new Decimal(0),
    }
  );
  result.myFinances.subtype[AssetType.CashAndBanking].current.assets.value =
    toDecimalPlacesNumber(cashAndBankingResult.totalAssets);

  // traditional investments
  if (!excludeAssetTypes.includes(AssetType.TraditionalInvestments)) {
    const stockPriceMap = await priceSource.getStockPrice(
      Object.values(TISummary.portfolio).flatMap((portfolio) => {
        return Object.values(portfolio.holdings).map((holding) => {
          return holding.holdingName;
        });
      })
    );
    const TIResult = Object.values(TISummary.portfolio).reduce(
      (acc, item) => {
        const { purchasePrice, assets } = Object.values(item.holdings).reduce(
          (innerAcc, holding) => {
            const purchasePrice = new Decimal(holding.purchasePrice.value).mul(
              exRate.getToBaseExchangeRate(holding.purchasePrice.currency).rate
            );
            const assets =
              holding.holdingType === HoldingType.Holding
                ? new Decimal(holding.unit)
                    .mul(stockPriceMap[holding.holdingName].value)
                    .mul(
                      exRate.getToBaseExchangeRate(
                        stockPriceMap[holding.holdingName].currency
                      ).rate
                    )
                : new Decimal(holding.unit).mul(
                    exRate.getToBaseExchangeRate(holding.currency!).rate
                  );
            return {
              purchasePrice: purchasePrice.add(innerAcc.purchasePrice),
              assets: assets.add(innerAcc.assets),
            };
          },
          {
            purchasePrice: new Decimal(0),
            assets: new Decimal(0),
          }
        );
        return {
          totalPurchasePrice: purchasePrice
            .mul(item.ownedPercentage)
            .div(100)
            .add(acc.totalPurchasePrice),
          totalAssets: assets
            .mul(item.ownedPercentage)
            .div(100)
            .add(acc.totalAssets),
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalAssets: new Decimal(0),
      }
    );
    result.myFinances.subtype[
      AssetType.TraditionalInvestments
    ].original.purchasePrice.value = toDecimalPlacesNumber(
      TIResult.totalPurchasePrice
    );
    result.myFinances.subtype[
      AssetType.TraditionalInvestments
    ].current.assets.value = toDecimalPlacesNumber(TIResult.totalAssets);
  }

  // other investments
  if (!excludeAssetTypes.includes(AssetType.OtherInvestment)) {
    computePriceAndAssets(
      OISummary.items,
      exRate,
      {
        ...result.myFinances.subtype[AssetType.OtherInvestment],
        subtype: {},
      },
      currency,
      false
    );
  }

  // cryptocurrency
  if (!excludeAssetTypes.includes(AssetType.Cryptocurrency)) {
    const cryptoPriceMap = await priceSource.getCryptoPrice(
      Object.values(cryptoSummary.accounts).flatMap((account) =>
        Object.keys(account.coins)
      )
    );
    const cryptoResult = Object.values(cryptoSummary.accounts).reduce(
      (acc, account) => {
        const { purchasePrice, assets } = Object.entries(account.coins).reduce(
          (innerAcc, [coinName, coin]) => {
            const purchasePrice = new Decimal(coin.investedValue.value).mul(
              exRate.getToBaseExchangeRate(coin.investedValue.currency).rate
            );
            const assets = new Decimal(coin.unit)
              .mul(cryptoPriceMap[coinName].value)
              .mul(
                exRate.getToBaseExchangeRate(cryptoPriceMap[coinName].currency)
                  .rate
              );
            return {
              purchasePrice: purchasePrice.add(innerAcc.purchasePrice),
              assets: assets.add(innerAcc.assets),
            };
          },
          {
            purchasePrice: new Decimal(0),
            assets: new Decimal(0),
          }
        );
        return {
          totalPurchasePrice: purchasePrice
            .mul(account.ownedPercentage)
            .div(100)
            .add(acc.totalPurchasePrice),
          totalAssets: assets
            .mul(account.ownedPercentage)
            .div(100)
            .add(acc.totalAssets),
        };
      },
      {
        totalPurchasePrice: new Decimal(0),
        totalAssets: new Decimal(0),
      }
    );
    result.myFinances.subtype[
      AssetType.Cryptocurrency
    ].original.purchasePrice.value = toDecimalPlacesNumber(
      cryptoResult.totalPurchasePrice
    );
    result.myFinances.subtype[AssetType.Cryptocurrency].current.assets.value =
      toDecimalPlacesNumber(cryptoResult.totalAssets);
  }

  // insurance
  if (!excludeAssetTypes.includes(AssetType.Insurance)) {
    const insuranceItems: SharedItems = {};
    Object.entries(insuranceSummary.insurance).forEach(([id, item]) => {
      // #NOTE: only `whole of life` has surrender value, which means it is includes in `assets`
      if (item.insuranceType !== LifeInsuranceType.WholeOfLife) return;
      insuranceItems[id] = {
        purchasePrice: item.premium,
        value: item.value,
        ownedPercentage: 100,
      };
    });
    computePriceAndAssets(
      insuranceItems,
      exRate,
      { ...result.myFinances.subtype[AssetType.Insurance], subtype: {} },
      currency,
      false
    );
  }

  // art
  computePriceAndAssets(
    artSummary.items,
    exRate,
    result.myCollectables.subtype[AssetType.Art],
    currency,
    true
  );

  // wine
  const wineItems: SharedItems = {};
  if (!excludeAssetTypes.includes(AssetType.WineAndSpirits)) {
    Object.entries(wineSummary.wines).forEach(([id, wine]) => {
      const { purchasePrice, assets } = Object.values(wine.purchases).reduce(
        (innerAcc, purchase) => {
          const price =
            purchase.pricingMethod === WinePricingMethod.Lot
              ? purchase.price
              : {
                  currency: purchase.price.currency,
                  value: mulAmount(
                    purchase.price.value,
                    purchase.bottleCount.bottles + purchase.bottleCount.consumed
                  ),
                };
          const value: Amount = {
            currency: purchase.valuePerBottle.currency,
            value: mulAmount(
              purchase.valuePerBottle.value,
              purchase.bottleCount.bottles
            ),
          };
          const purchasePrice = new Decimal(price.value)
            .mul(exRate.getToBaseExchangeRate(price.currency).rate)
            .mul(purchase.ownedPercentage)
            .div(100)
            .add(innerAcc.purchasePrice);
          const assets = new Decimal(value.value)
            .mul(exRate.getToBaseExchangeRate(value.currency).rate)
            .mul(purchase.ownedPercentage)
            .div(100)
            .add(innerAcc.assets);
          return { purchasePrice, assets };
        },
        { purchasePrice: new Decimal(0), assets: new Decimal(0) }
      );

      wineItems[id] = {
        subtype: wine.subtype,
        purchasePrice: {
          currency,
          value: toDecimalPlacesNumber(purchasePrice),
        },
        value: {
          currency,
          value: toDecimalPlacesNumber(assets),
        },
        ownedPercentage: 100,
      };
    });
    computePriceAndAssets(
      wineItems,
      exRate,
      result.myCollectables.subtype[AssetType.WineAndSpirits],
      currency,
      true
    );
  }

  // other collectables
  computePriceAndAssets(
    otherCollectablesSummary.items,
    exRate,
    result.myCollectables.subtype[AssetType.OtherCollectables],
    currency,
    true
  );

  // properties
  const ownedPropertyItems: SharedItems = {};
  Object.entries(propertySummary.properties).forEach(([id, item]) => {
    // #NOTE: rent property should not be counted
    if (item.ownershipType === OwnershipType.Rent) return;
    ownedPropertyItems[id] = {
      name: item.name,
      // NOTE: property wants to list all properties, so use `id` as subtype for processing
      subtype: id,
      purchasePrice: item.purchasePrice as Amount,
      value: item.value as Amount,
      ownedPercentage: item.ownedPercentage!,
      sold: item.sold,
      archived: item.archived,
    };
  });
  const propertyResultMap: NetWorthReportDetails & SubtypeDetails = {
    ...defaultNetWorthReportDetails(currency),
    subtype: {},
  };
  computePriceAndAssets(
    ownedPropertyItems,
    exRate,
    propertyResultMap,
    currency,
    true
  );

  // belongings
  computePriceAndAssets(
    belongingSummary.items,
    exRate,
    result.myBelongings,
    currency,
    true
  );

  // *** Query all loan / mortgage. Get liabilities and allocation ***
  const loans = await CoreFirestore.getDocsFromCollection<Loan>(
    refs.getAssetCollectionRef(AssetType.CashAndBanking),
    CoreFirestore.where("subtype", "==", Account.Type.LoanAccount),
    CoreFirestore.where("ownerId", "==", refs.userId)
  ).then(getQueriedData);
  const mortgages = await CoreFirestore.getDocsFromCollection<Mortgage>(
    refs.getAssetCollectionRef(AssetType.CashAndBanking),
    CoreFirestore.where("subtype", "==", Account.Type.MortgageAccount),
    CoreFirestore.where("ownerId", "==", refs.userId)
  ).then(getQueriedData);
  const accounts = [...loans, ...mortgages];

  const assetTypeWithSubtypeItems: { [assetType: string]: SharedItems } = {
    [AssetType.Art]: artSummary.items,
    [AssetType.WineAndSpirits]: wineItems,
    [AssetType.OtherCollectables]: otherCollectablesSummary.items,
    [AssetType.Property]: ownedPropertyItems,
    [AssetType.Belonging]: belongingSummary.items,
  };
  const liabilities = accounts.reduce(
    (acc, account) => {
      if (account.closedWith) return acc;
      const accountInitialAmount = new Decimal(account.initialAmount.value)
        .mul(exRate.getToBaseExchangeRate(account.initialAmount.currency).rate)
        .mul(-1);
      const accountValue = new Decimal(account.value.value).mul(
        exRate.getToBaseExchangeRate(account.value.currency).rate
      );
      if (account.subtype == Account.Type.LoanAccount) {
        // allocated liabilities
        let leftPercentage = 100;
        account.allocations.forEach((alloc) => {
          const assetId =
            alloc.assetType === AssetType.WineAndSpirits
              ? Wine.checkAndBuildWineId(refs.userId, alloc.assetId)
              : alloc.assetId;
          // #NOTE: ignore archived / sold / rent
          if (
            !assetTypeWithSubtypeItems[alloc.assetType][assetId] || // no such asset (or the property is rent)
            assetTypeWithSubtypeItems[alloc.assetType][assetId].sold ||
            assetTypeWithSubtypeItems[alloc.assetType][assetId].archived
          ) {
            return;
          }
          const initialLiability = accountInitialAmount
            .mul(alloc.percent)
            .div(100);
          const currentLiability = accountValue.mul(alloc.percent).div(100);
          acc[alloc.assetType].totalInitialLiability =
            acc[alloc.assetType].totalInitialLiability.add(initialLiability);
          acc[alloc.assetType].totalCurrentLiability =
            acc[alloc.assetType].totalCurrentLiability.add(currentLiability);
          leftPercentage -= alloc.percent;
          if (assetTypeWithSubtype.includes(alloc.assetType)) {
            const subtype =
              assetTypeWithSubtypeItems[alloc.assetType][assetId].subtype!;
            if (!acc[alloc.assetType].subtypes![subtype]) {
              acc[alloc.assetType].subtypes![subtype] = {
                totalInitialLiability: initialLiability,
                totalCurrentLiability: currentLiability,
              };
            } else {
              acc[alloc.assetType].subtypes![subtype].totalInitialLiability =
                acc[alloc.assetType].subtypes![
                  subtype
                ].totalInitialLiability.add(initialLiability);
              acc[alloc.assetType].subtypes![subtype].totalCurrentLiability =
                acc[alloc.assetType].subtypes![
                  subtype
                ].totalCurrentLiability.add(currentLiability);
            }
          }
        });
        // Assign left percentage to cash and banking
        if (leftPercentage < 0)
          throw new DataPoisoned("Left percentage is negative");
        else if (leftPercentage > 0) {
          acc[AssetType.CashAndBanking].totalInitialLiability = acc[
            AssetType.CashAndBanking
          ].totalInitialLiability.add(
            accountInitialAmount.mul(leftPercentage).div(100)
          );
          acc[AssetType.CashAndBanking].totalCurrentLiability = acc[
            AssetType.CashAndBanking
          ].totalCurrentLiability.add(
            accountValue.mul(leftPercentage).div(100)
          );
        }
      } else if (account.subtype == Account.Type.MortgageAccount) {
        // #NOTE: ignore rent / sold / archived properties
        if (
          account.linkToPropertyId &&
          ownedPropertyItems[account.linkToPropertyId] &&
          !ownedPropertyItems[account.linkToPropertyId].sold &&
          !ownedPropertyItems[account.linkToPropertyId].archived
        ) {
          acc[AssetType.Property].totalInitialLiability =
            acc[AssetType.Property].totalInitialLiability.add(
              accountInitialAmount
            );
          acc[AssetType.Property].totalCurrentLiability =
            acc[AssetType.Property].totalCurrentLiability.add(accountValue);
          // subtypes
          const subtype = ownedPropertyItems[account.linkToPropertyId].subtype!;
          if (!acc[AssetType.Property].subtypes![subtype]) {
            acc[AssetType.Property].subtypes![subtype] = {
              totalInitialLiability: accountInitialAmount,
              totalCurrentLiability: accountValue,
            };
          } else {
            acc[AssetType.Property].subtypes![subtype].totalInitialLiability =
              acc[AssetType.Property].subtypes![
                subtype
              ].totalInitialLiability.add(accountInitialAmount);
            acc[AssetType.Property].subtypes![subtype].totalCurrentLiability =
              acc[AssetType.Property].subtypes![
                subtype
              ].totalCurrentLiability.add(accountValue);
          }
        } else {
          acc[AssetType.CashAndBanking].totalInitialLiability =
            acc[AssetType.CashAndBanking].totalInitialLiability.add(
              accountInitialAmount
            );
          acc[AssetType.CashAndBanking].totalCurrentLiability =
            acc[AssetType.CashAndBanking].totalCurrentLiability.add(
              accountValue
            );
        }
      }
      return acc;
    },
    {
      [AssetType.CashAndBanking]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability:
          cashAndBankingResult.creditCardAccountLiabilities,
      },
      [AssetType.Art]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
      [AssetType.WineAndSpirits]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
      [AssetType.Property]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
      [AssetType.Belonging]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
      [AssetType.OtherCollectables]: {
        totalInitialLiability: new Decimal(0),
        totalCurrentLiability: new Decimal(0),
        subtypes: {},
      },
    } as {
      [key: string]: {
        totalInitialLiability: Decimal;
        totalCurrentLiability: Decimal;
        subtypes?: {
          [key: string]: {
            totalInitialLiability: Decimal;
            totalCurrentLiability: Decimal;
          };
        };
      };
    }
  );

  // *** Assign liabilities to result ***
  Object.entries(liabilities).forEach(([assetType, v]) => {
    const totalInitialLiability = toDecimalPlacesNumber(
      v.totalInitialLiability
    );
    const totalCurrentLiability = toDecimalPlacesNumber(
      v.totalCurrentLiability
    );

    let target: NetWorthReportDetails;
    switch (assetType) {
      case AssetType.CashAndBanking:
        target = result.myFinances.subtype[assetType];
        break;
      case AssetType.Art:
      case AssetType.WineAndSpirits:
      case AssetType.OtherCollectables:
        target = result.myCollectables.subtype[assetType];
        break;
      case AssetType.Property:
        target = propertyResultMap;
        break;
      case AssetType.Belonging:
        target = result.myBelongings;
        break;
      default:
        throw new Error(`Unknown asset type ${assetType}`);
    }
    target.original.liabilities.value = totalInitialLiability;
    target.current.liabilities.value = totalCurrentLiability;

    // *** Assign liabilities to subtype ***
    if (v.subtypes) {
      const targetWithSubtype = target as NetWorthReportDetails &
        SubtypeDetails;
      Object.keys(v.subtypes).forEach((subtype) => {
        if (!targetWithSubtype.subtype[subtype])
          targetWithSubtype.subtype[subtype] =
            defaultNetWorthReportDetails(currency);
        targetWithSubtype.subtype[subtype]!.original.liabilities.value =
          toDecimalPlacesNumber(v.subtypes![subtype].totalInitialLiability);
        targetWithSubtype.subtype[subtype]!.current.liabilities.value =
          toDecimalPlacesNumber(v.subtypes![subtype].totalCurrentLiability);
      });
    }
  });

  // transfer properties from map to array
  result.myProperties = {
    original: propertyResultMap.original,
    current: propertyResultMap.current,
    netChange: propertyResultMap.netChange,
    properties: Object.entries(propertyResultMap.subtype).map(([id, v]) => ({
      name: ownedPropertyItems[id].name!,
      original: v.original,
      current: v.current,
      netChange: v.netChange,
    })),
  };

  // myFinances
  sumSubtypeDetailsToTarget(
    result.myFinances,
    Object.values(result.myFinances.subtype)
  );
  // myCollectables
  sumSubtypeDetailsToTarget(
    result.myCollectables,
    Object.values(result.myCollectables.subtype)
  );
  // total
  sumSubtypeDetailsToTarget(result.total, [
    result.myFinances,
    result.myCollectables,
    result.myProperties,
    result.myBelongings,
  ]);

  // *** Compute net value and change ***
  [
    // myFinances
    result.myFinances,
    ...Object.values(result.myFinances.subtype),
    // myCollectables
    result.myCollectables,
    ...Object.values(result.myCollectables.subtype),
    ...Object.values(result.myCollectables.subtype[AssetType.Art].subtype),
    ...Object.values(
      result.myCollectables.subtype[AssetType.WineAndSpirits].subtype
    ),
    ...Object.values(
      result.myCollectables.subtype[AssetType.OtherCollectables].subtype
    ),
    // myProperties
    result.myProperties,
    ...result.myProperties.properties,
    // myBelongings
    result.myBelongings,
    ...Object.values(result.myBelongings.subtype),
    // total
    result.total,
  ].map((v) => computeNetValueAndPercentageChange(v));

  return result;
}

import { mutate } from 'swr'

import type { Database } from 'core/remodel/database'
import { Art } from 'core/remodel/types/arts'
import { BelongingsUtils } from 'core/remodel/types/belongings'
import { Account } from 'core/remodel/types/cashAndBanking'
import { CurrentAccount } from 'core/remodel/types/cashAndBanking/currentAccount'
import { SavingAccount } from 'core/remodel/types/cashAndBanking/savingAccount'
import { AssetType, Currency, MultiCurrencyAmount, type Amount } from 'core/remodel/types/common'
import type { Groupable, GroupInfo, GroupItem, GroupWithItems } from 'core/remodel/types/groups'
import type { GroupableAsset } from '@/types/common'
import { delay } from '@/utils/delay'
import { getTimestamp } from '@/utils/getTimeStamp'
import { isFulfilled } from '@/utils/predicate'
import type { CreateGroupValues } from '@/pages/groups/summary/create'

export const groupQuery = {
  options: 'group-options',
  list: 'group-list',
  info: 'group-info',
  item: 'group-item',
  items: 'group-items',
  assets: 'group-assets'
} as const

export function fetchGroupOptions(database: Database) {
  return async ([_key]: [typeof groupQuery.options]) => {
    const list = await database.group.getAllInfo()
    return list.map((group) => ({ label: group.name, value: group.id }))
  }
}

export function fetchGroups(database: Database) {
  return async ([_key, currency]: [typeof groupQuery.list, Currency?]) => {
    const rawGroups = await database.group.getAll()
    const values: Record<string, Amount> = {}
    const assets: Record<string, GroupableAsset[]> = {}

    const targetExRateMap = currency && (await database.ExRate.getToTargetExchangeRates(currency))
    const toTargetAmount = (amount: Amount): Amount => {
      if (!currency || !targetExRateMap) return database.ExRate.amountToBase(amount)
      return { currency, value: targetExRateMap.rates[amount.currency].rate * amount.value }
    }
    const multiToTargetAmount = (amounts: MultiCurrencyAmount): Amount => {
      const totalValue = Object.entries(amounts).reduce((acc, [key, value]) => {
        acc += toTargetAmount({ currency: key as Currency, value }).value
        return acc
      }, 0)
      return { currency: currency ?? database.ExRate.BaseCurrency!, value: totalValue }
    }

    for (const group of rawGroups) {
      if (group.info.isDraft) {
        const items = await getGroupDraftInfo(database, group)

        const totalAmount = multiToTargetAmount(group.info.draftInfo?.assets ?? {})
        values[group.info.id] = totalAmount
        assets[group.info.id] = items
      } else {
        const result = await Promise.allSettled(group.items.map((item) => getGroupItemInfo(database, item)))
        const fulfilledItems = result.filter(isFulfilled).map((item) => item.value)

        const items = fulfilledItems.map((item) => ({ ...item, value: toTargetAmount(item.value) }))
        const totalValue = items.reduce((acc, curr) => acc + curr.value.value, 0)
        values[group.info.id] = { value: totalValue, currency: currency ?? database.ExRate.BaseCurrency! }
        assets[group.info.id] = items.sort((a, b) => getTimestamp(b.updateAt) - getTimestamp(a.updateAt))
      }
    }
    const groups = rawGroups
    return { groups, values, assets }
  }
}

export function fetchGroupsByIds(database: Database) {
  return async ([_key, ids]: [typeof groupQuery.list, string[]]) => {
    return await database.group.getByIds(ids)
  }
}

export function fetchGroupInfosByIds(database: Database) {
  return async ([_key, ids]: [typeof groupQuery.list, string[]]) => {
    return await database.group.getGroupInfoByIds(ids)
  }
}

export function fetchGroup(database: Database) {
  return async ([_key, id]: [typeof groupQuery.info, string]) => {
    const group = await database.group.getById(id)
    // FIXME: getGroupItemInfo().updateAt turned into FirestoreTimestamp type with unknown reason
    // It's still in normal Date type inside getGroupItemInfo()
    if (group.info.isDraft) {
      const items = await getGroupDraftInfo(database, group)

      return { group: group.info, itemsMin: group.items, items }
    } else {
      const itemsResponses = await Promise.allSettled(group.items.map((item) => getGroupItemInfo(database, item)))
      const items = itemsResponses.filter(isFulfilled).map(({ value }) => value)

      return { group: group.info, itemsMin: group.items, items }
    }
  }
}

export const groupableAssetTypes = [
  AssetType.CashAndBanking,
  AssetType.TraditionalInvestments,
  AssetType.Insurance,
  AssetType.Property,
  AssetType.Art,
  AssetType.OtherCollectables,
  AssetType.Belonging
]

export function fetchGroupableAssets(database: Database) {
  return async ([_key, query]: [typeof groupQuery.assets, Record<string, any>]) => {
    const queryWithCategory: Record<string, any> = {
      category: groupableAssetTypes,
      ...query
    }
    const { list, totalCount } = await database.getAssets(queryWithCategory)
    return {
      list: list.map((item) => {
        return {
          assetId: item.id,
          assetType: item.assetType as Groupable,
          subtype: item.subtype,
          name: item.name,
          // FIXME
          value: 'currency' in item.value ? (item.value as Amount) : { currency: Currency.USD, value: 0 },
          updateAt: item.updateAt
        } as GroupableAsset
      }),
      totalCount
    }
  }
}

export function fetchGroupItem(database: Database) {
  return async ([_key, item]: [typeof groupQuery.item, GroupItem]) => {
    return await getGroupItemInfo(database, item)
  }
}

async function getGroupItemInfo(database: Database, item: GroupItem): Promise<GroupableAsset> {
  const { assetId, assetType, subtype } = item
  switch (assetType) {
    case AssetType.CashAndBanking: {
      const account = await database.cashAndBanking.getAccountById(assetId, subtype as Account.Type)
      const isMultiCurrency = subtype == Account.Type.SavingAccount || subtype == Account.Type.CurrentAccount
      const balanceList: Amount[] = isMultiCurrency
        ? (account as SavingAccount | CurrentAccount).subAccounts.map((account) => account.balance)
        : [account.value]
      const baseCurrency = database.ExRate.BaseCurrency!
      const value: Amount = {
        currency: baseCurrency,
        value: balanceList.reduce((acc, curr) => database.ExRate.amountToBase(curr).value + acc, 0)
      }
      const updateAt = account.updateAt
      // NOTE: updateAt now is a Date type instead of FirestoreTimestamp
      return { ...item, name: account.name, mainImage: account.mainImage, value, updateAt }
    }
    case AssetType.TraditionalInvestments: {
      const portfolio = await database.traditionalInvestment.getPortfolioById(assetId)
      // FIXME get portfolio value
      const value = database.ExRate.amountToBase({ currency: Currency.USD, value: 0 })
      const updateAt = portfolio.updateAt
      return { ...item, name: portfolio.name, mainImage: portfolio.mainImage, value, updateAt }
    }
    case AssetType.OtherInvestment: {
      const investment = await database.otherInvestment.getById(assetId)
      const value = database.ExRate.amountToBase(investment.value)
      const updateAt = investment.updateAt
      return { ...item, name: investment.name, mainImage: investment.mainImage, value, updateAt }
    }
    case AssetType.Insurance: {
      const insurance = await database.insurance.getById(assetId)
      const value = database.ExRate.amountToBase(insurance.value)
      const updateAt = insurance.updateAt
      return { ...item, name: insurance.name, mainImage: insurance.mainImage, value, updateAt }
    }
    case AssetType.Property: {
      const property = await database.property.getById(assetId)
      const value = database.ExRate.amountToBase(property.value)
      const updateAt = property.updateAt
      return { ...item, name: property.name, mainImage: property.mainImage, value, updateAt }
    }
    case AssetType.Art: {
      const art = await database.art.getById(assetId)
      const value = database.ExRate.amountToBase(art.value)
      const updateAt = art.updateAt
      return { ...item, name: art.name, mainImage: art.mainImage, value, updateAt }
    }
    case AssetType.OtherCollectables: {
      const other = await database.otherCollectable.getById(assetId)
      const value = database.ExRate.amountToBase(other.value)
      const updateAt = other.updateAt
      return { ...item, name: other.name, mainImage: other.mainImage, value, updateAt }
    }
    case AssetType.Belonging: {
      const belonging = await database.belonging.getById(assetId)
      const value = database.ExRate.amountToBase(belonging.value)
      const updateAt = belonging.updateAt
      return { ...item, name: belonging.name, mainImage: belonging.mainImage, value, updateAt }
    }
  }
}

async function getGroupDraftInfo(database: Database, group: GroupWithItems): Promise<GroupableAsset[]> {
  if (group.items.length === 0) return []
  let groupDrafts: Art.Encrypted[] | BelongingsUtils.DraftDisplay[]
  const assetType = group.items[0].assetType

  switch (assetType) {
    case AssetType.Art:
      groupDrafts = await database.art.getAllEncryptedDraftStates()
      break
    case AssetType.OtherCollectables:
      groupDrafts = await database.otherCollectable.getAllEncryptedDraftStates()
      break
    case AssetType.Belonging:
      groupDrafts = await database.belonging.getAllEncryptedDraftStates()
    default:
      groupDrafts = await database.belonging.getAllEncryptedDraftStates()
  }

  const items = group.items.map((item): GroupableAsset => {
    const { name = '', value, updateAt = new Date() } = groupDrafts.find((draft) => item.assetId === draft.id) ?? {}
    return {
      ...item,
      name,
      updateAt,
      value: database.ExRate.amountToBase({
        currency: value?.currency ?? database.ExRate.BaseCurrency!,
        value: value?.value ?? 0
      }),
      mainImage: ''
    }
  })
  return items
}

export async function addGroup(database: Database, data: CreateGroupValues, newItems: GroupItem[]) {
  const group = { id: database.genAssetId(), ...data }
  await database.group.add(group, newItems)
  await delay()
  mutate([groupQuery.list])
}

export async function updateGroup(
  database: Database,
  data: GroupInfo,
  itemsAdded: GroupItem[],
  itemsRemoved: GroupItem[],
  currency?: Currency
) {
  await database.group.update(data, itemsAdded, itemsRemoved)
  await delay()
  mutate([groupQuery.info, data.id])
  currency && mutate([groupQuery.list, currency]) // #NOTE: update group list only on groups/summary/index page
}

export async function deleteGroup(database: Database, id: string) {
  const group = await database.group.getById(id)
  if (group.info.isDraft) {
    // If all drafts are removed, the group will be automatically deleted
    // Group with drafts can't be directly
    const assetType = group.items[0].assetType
    switch (assetType) {
      case AssetType.Art: {
        for (const { assetId } of group.items) {
          await database.art.deleteDraft(assetId)
        }
        break
      }
      case AssetType.OtherCollectables: {
        for (const { assetId } of group.items) {
          await database.otherCollectable.deleteDraft(assetId)
        }
        break
      }
      case AssetType.Belonging: {
        for (const { assetId } of group.items) {
          await database.belonging.deleteDraft(assetId)
        }
        break
      }
    }
  } else {
    const task = await database.group.delete(id)
    await task.run()
  }

  await delay()
  mutate([groupQuery.list])
}

import '@/utils/i18n'
import '@/constants/globals.css'

import { Fragment } from 'react'
import type { NextComponentType } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { combineProviders } from 'react-combine-provider'
import TagManager from 'react-gtm-module'
import { SWRConfig } from 'swr'

import { roboto } from '@/constants/fonts'
import { buildId, endpoint, environment, isMatchedEnv } from '@/constants/site'
import { usePageInfo } from '@/hooks/usePageInfo'
import { usePreviousRoute } from '@/hooks/usePreviousRoute'
import { MaintenanceProvider } from '@/store/maintenance'
import { MapProvider } from '@/store/map'
import { TooltipProvider } from '@/components/base'
import LanguageSync from '@/components/LanguageSync'
import Layout from '@/components/Layout'
import ProtectedPage from '@/components/ProtectedPage'
import { ReCaptcha } from '@/components/ReCaptcha'
import { Toaster } from '@/components/Toaster'
import { RouterTracking, Tracking } from '@/components/Tracking'

// FIXME: complex import cycle, it must be the last import
import { trackSWRMiddleware } from '../hooks/useTracking'

console.log(JSON.stringify({ environment, endpoint, buildId }, null, 2))

if (isMatchedEnv(['staging', 'prod']) && typeof window !== 'undefined')
  TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID! })

const CombineProvider = combineProviders([MapProvider, TooltipProvider, ReCaptcha, MaintenanceProvider])

export default function App({ Component, pageProps }: AppProps) {
  const { previous } = usePreviousRoute()
  const { isAuthRoute } = Component as NextComponentType & { isAuthRoute: boolean }
  const Protector = isAuthRoute ? Fragment : ProtectedPage
  const { title, desc } = usePageInfo()

  return (
    <>
      <RouterTracking />
      <Head>
        <title>{title}</title>
        <meta name={'description'} content={desc} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
        <style
          dangerouslySetInnerHTML={{
            __html: `
              :root { --roboto: ${roboto.style.fontFamily}; }
              .grecaptcha-badge { visibility: hidden; }
            `
          }}
        />
      </Head>

      <CombineProvider>
        <Protector>
          <Layout>
            <SWRConfig value={{ use: [trackSWRMiddleware], keepPreviousData: true }}>
              <Tracking name={'App'} trackChildren={false}>
                <Component from={previous} {...pageProps} />
              </Tracking>
            </SWRConfig>
          </Layout>
        </Protector>
        <LanguageSync />
      </CombineProvider>

      <Toaster />
    </>
  )
}

import { GenesysClient } from 'core/genesys'
import { KnowledgeDocumentData } from '@/types/helpCenter'

export const SupportQuery = {
  search: 'support-search',
  searchSuggestions: 'support-search-suggestions',
  documentById: 'support-document-by-id',
  submitFeedback: 'support-submit-feedback'
} as const

let client: GenesysClient | null = null

async function getGenesysClient(): Promise<GenesysClient> {
  if (!client) {
    client = new GenesysClient(
      process.env.NEXT_PUBLIC_GENESYS_API_DEPLOYMENT_ID!,
      process.env.NEXT_PUBLIC_GENESYS_API_CUSTOMER_ID!
    )
    await client.generateSession()
  }
  return client
}

export async function fetchSearchSuggestions() {
  function shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    return shuffled
  }

  const client = await getGenesysClient()
  const { entities } = await client.mostCommonArticles(1000)
  if (!entities || !Array.isArray(entities)) throw new Error('...')
  const firstId = '94ac37a7-05ce-477b-ae8c-ea55640f06d7'
  const firstItem = entities.find(({ id }) => id === firstId)
  const restItems = shuffleArray(entities.filter(({ id }) => id !== firstId)).slice(0, 4)
  return [firstItem!, ...restItems]
}

export async function fetchKnowledgeSearch([_key, keyword]: [typeof SupportQuery.search, string]) {
  try {
    const client = await getGenesysClient()
    const data = await client.search(keyword, () => {})
    return data.results
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

export async function fetchDocumentById([_key, documentId]: [typeof SupportQuery.documentById, string]) {
  try {
    const client = await getGenesysClient()
    const data = await client.getArticle(documentId)
    return data as KnowledgeDocumentData
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

export async function submitFeedback([_key, documentId, positive, comment]: [
  typeof SupportQuery.submitFeedback,
  string,
  boolean,
  string
]) {
  try {
    const client = await getGenesysClient()
    await client.reviewArticle(documentId, positive, comment)
  } catch (error) {
    console.error('Genesys Error:', error)
    throw error
  }
}

import type { PhoneMultiFactorInfo } from 'firebase/auth'
import { mutate } from 'swr'
import { SWRSubscription, SWRSubscriptionOptions } from 'swr/subscription'

import type { Database } from 'core/remodel/database'
import { Optional } from 'core/remodel/types/common'
import type { Preferences } from 'core/remodel/types/user'
import { delay } from '@/utils/delay'
import type { PhoneFormValues, ProfileFormValues } from '@/pages/account/profile/personal'
import type { PreferenceValues } from '@/pages/account/profile/preferences'

export const userQuery = {
  profile: 'profile',
  preferences: 'preferences',
  currentPreferences: 'currentPreferences',
  subscribeCurrentPreferences: 'subscribeCurrentPreferences',
  subscription: 'subscription-info',
  history: 'subscription-history',
  multiFactorSession: 'multi-factor-session',
  multiFactorList: 'multi-factor-list',
  backupCodes: 'backup-codes',
  hasPassword: 'has-password'
} as const

/* Profile */
export function fetchProfile(database: Database) {
  return async ([_key]: [typeof userQuery.profile]) => {
    return await database.Account.getUser()
  }
}

export async function updateUser(database: Database, data: ProfileFormValues) {
  await database.Account.updateUser(data)
  await delay()
  mutate([userQuery.profile])
}

export async function updatePassword(database: Database, password: string) {
  await database.Account.updateUserPassword(password)
}

export async function uploadAvatar(database: Database, data: Blob | Uint8Array | ArrayBuffer, base64IV: string) {
  const iv = database.Encryption.current.convertBase64ToIVSalt(base64IV)
  await database!.Account.uploadUserPhoto(data, iv)
  await delay()
  mutate([userQuery.profile])
}

export async function deleteAvatar(database: Database) {
  await database!.Account.deleteUserPhoto()
  await delay()
  mutate([userQuery.profile])
}

/* Preference */
export function fetchPreferences(database: Database) {
  return async ([_key]: [typeof userQuery.preferences]) => {
    return await database.Account.getPreferences()
  }
}

/**
 * This is a hack that takes in the delegator id to sidestep
 * SWR's cache.  This should only be needed in the global dashboard since
 * that is the only page where there is no page transition when switching
 * to a delegate.
 *
 * Consider moving reading and updating preferences to AuthStore
 */
export function fetchCurrentPreferencesCache(database: Database) {
  return async ([_key, _delegatorId]: [typeof userQuery.currentPreferences, string | null]) => {
    return await database.Account.getCurrentPreferences()
  }
}

export function fetchCurrentPreferences(database: Database) {
  return async ([_key]: [typeof userQuery.currentPreferences]) => {
    return await database.Account.getCurrentPreferences()
  }
}

export function subscribeCurrentPreferences(
  database: Database
): SWRSubscription<typeof userQuery.subscribeCurrentPreferences> {
  return (
    _key: typeof userQuery.subscribeCurrentPreferences,
    { next }: SWRSubscriptionOptions<Optional<Preferences>>
  ) => {
    let unsubscribe = database.Account.currentPreferencesSnapshot((data) => next(null, data))
    return () => {
      unsubscribe && unsubscribe()
    }
  }
}

export async function updatePreferences(database: Database, data: PreferenceValues) {
  await database.Account.updatePreferences(data)
  await delay()
  mutate([userQuery.preferences])
}

export async function updateRemindMFA(database: Database, date: Date | undefined) {
  await database.Account.updatePreferences({ remindMFA: date })
  await delay()
  mutate([userQuery.preferences])
}

export async function updateNotification(database: Database, data: Preferences['notification']) {
  await database.Account.updatePreferences({ notification: data })
  await delay()
  mutate([userQuery.preferences])
}

/* Subscription */
export function fetchSubscription(database: Database) {
  return async ([_key]: [typeof userQuery.subscription]) => {
    const { subscription } = await database.Account.getSubscription()
    if (!subscription) throw new Error('Empty subscription')
    return subscription
  }
}

/* Security */
export function fetchMultiFactorSession(database: Database) {
  return async ([_key]: [typeof userQuery.multiFactorSession]) => {
    return await database.Account.getMultiFactorSession()
  }
}

export function fetchMultiFactorList(database: Database) {
  return async ([_key]: [typeof userQuery.multiFactorList]) => {
    const list = await database.Account.getMultiFactorList()
    const phoneList = (list as PhoneMultiFactorInfo[]).filter(({ phoneNumber }) => Boolean(phoneNumber))
    return phoneList
  }
}

export async function enrollPrimaryMultiFactorSms(database: Database, data: PhoneFormValues) {
  const { verificationId, verificationCode, phoneNumber } = data
  const [prevPrimary, _prevSecondary] = await fetchMultiFactorList(database)([userQuery.multiFactorList])
  await database.Account.enrollMultiFactorSms(verificationId, verificationCode)
  if (prevPrimary) {
    await database.Account.unenrollMultiFactor(prevPrimary)
    await database.reorderMFA(phoneNumber)
  }
  await delay()
  mutate([userQuery.multiFactorList])
}

export async function enrollSecondaryMultiFactorSms(database: Database, data: PhoneFormValues) {
  const { verificationId, verificationCode } = data
  const [prevPrimary, prevSecondary] = await fetchMultiFactorList(database)([userQuery.multiFactorList])
  if (!prevPrimary) throw new Error('Primary phone not found')
  await database.Account.enrollMultiFactorSms(verificationId, verificationCode)
  if (prevSecondary) await database.Account.unenrollMultiFactor(prevSecondary)
  await delay()
  mutate([userQuery.multiFactorList])
}

export function fetchBackupCodes(database: Database) {
  return async ([_key]: [typeof userQuery.backupCodes]) => {
    return await database.getBackupCodes()
  }
}

export async function generateBackupCodes(database: Database) {
  await database.createBackupCodes()
  await delay()
  mutate([userQuery.backupCodes])
}

export function fetchHasPassword(database: Database) {
  return async ([_key]: [typeof userQuery.hasPassword]) => {
    return await database.Account.hasPasswordProvider()
  }
}

export async function reauthenticatePassword(database: Database, password: string) {
  await database.Account.reauthenticatePassword(password)
}

export async function closeUserAccount(database: Database) {
  await database.closeAccount()
}

/*
export function fetchBackupInfo(database: Database) {
  return async ([_key]: [typeof userQuery.backupInfo]) => {
    return await database.getBackupMFAInfo()
  }
}

interface BackupInfo {
  email?: string
  phone?: string
}

export async function setBackupInfo(database: Database, info: BackupInfo) {
  const { backupEmail, backupPhone } = await database.getBackupMFAInfo()
  await database.setBackupMFAInfo(info.email ?? backupEmail, info.phone ?? backupPhone)
}
*/

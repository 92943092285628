import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23FF8257%22%2F%3E%3Cpath%20d%3D%22M14.786%2027.702h-3.305l-.691%201.987H9.249l3.222-8.532h1.33l3.229%208.532h-1.547l-.697-1.987Zm-2.889-1.195h2.473l-1.237-3.54-1.236%203.54Zm7.477%203.182H17.95v-6.34h1.424v6.34Zm-1.512-7.987c0-.218.07-.4.208-.545.139-.144.337-.216.595-.216.257%200%20.457.072.597.216a.75.75%200%200%201%20.211.545c0%20.215-.07.394-.21.536-.141.143-.34.214-.598.214-.258%200-.456-.071-.595-.214a.738.738%200%200%201-.208-.536Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23CD4F23%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileAiIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#FF8257'}
      />
      <path
        d={
          'M14.786 27.702h-3.305l-.691 1.987H9.249l3.222-8.532h1.33l3.229 8.532h-1.547l-.697-1.987Zm-2.889-1.195h2.473l-1.237-3.54-1.236 3.54Zm7.477 3.182H17.95v-6.34h1.424v6.34Zm-1.512-7.987c0-.218.07-.4.208-.545.139-.144.337-.216.595-.216.257 0 .457.072.597.216a.75.75 0 0 1 .211.545c0 .215-.07.394-.21.536-.141.143-.34.214-.598.214-.258 0-.456-.071-.595-.214a.738.738 0 0 1-.208-.536Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#CD4F23'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Loader2Icon } from 'lucide-react'
import { Path, useFieldArray, useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { AttachmentKind, Currency, LocationType, TimeZone, type Attachment } from 'core/remodel/types/common'
import { AcquisitionType, acquisitionTypeValues } from 'core/remodel/types/common/acquisition'
import { bottleSizeOptions, locationTypeValues, wineTypeValues } from 'core/remodel/types/options'
import { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { defaultPreferences } from 'core/remodel/types/user'
import {
  BottleSize,
  OneBottleOfWine,
  WinePricingMethod,
  winePricingMethodValues,
  WineStatus,
  type WineCatalogue,
  type WinePurchase
} from 'core/remodel/types/wineAndSprits'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import {
  addContact,
  addContactPosition,
  addContactRoom,
  contactQuery,
  fetchContactOptions,
  fetchContactRoomOptions,
  fetchContactRoomPositionOptions
} from '@/api/ContactService'
import {
  addPropertyPosition,
  addPropertyRoom,
  fetchPropertyOptions,
  fetchPropertyPositionOptions,
  fetchPropertyRoomOptions,
  propertyQuery
} from '@/api/PropertyService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { makeOptions, startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import {
  Button,
  FormAutocomplete,
  FormCheckbox,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormPercentInput,
  FormPriceInput,
  FormQuantityInput,
  FormSelect,
  FormTextarea,
  Input,
  SelectItem
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AttachmentIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import NewAddress from '@/components/NewAddress'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type WineValues = {
  wine: WineCatalogue
  purchase: WinePurchase.CreateFields
  other: {
    number: number
    personalRefNo: string
    attributeNotes: string
  }
  mainImage?: string
  attachments: Attachment[]
  // #NOTE: add purchaseStatus and purchaseLocation for purchase level update (for all bottles)
  purchaseStatus?: Extract<WineStatus, 'Pending' | 'Delivered'>
  purchaseLocation?: LocationInfo
}

const emptyBottleLocation: LocationInfo = {
  locationId: '',
  locationType: LocationType.MyProperty,
  roomId: undefined,
  position: undefined,
  notes: undefined
}

const getDefaultValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): Partial<WineValues> => ({
  wine: {
    wineId: '',
    name: '',
    subtype: '-',
    producer: '',
    vintage: 1990,
    catalogueImage: undefined,
    country: undefined,
    masterVarietal: undefined,
    variety: undefined,
    drinkWindow: undefined,
    vineyard: undefined,
    region: undefined,
    subRegion: undefined,
    appellation: undefined,
    designation: undefined,
    proRating: undefined
  },
  purchase: {
    id: '',
    wineId: '',
    purchaseDate: startOfDayInTz(new Date(), timeZone),
    deliveryDate: startOfDayInTz(new Date(), timeZone),
    bottleSize: BottleSize['750ml'],
    pricingMethod: WinePricingMethod.Bottle,
    price: { currency, value: 0 },
    totalCost: { currency, value: 0 },
    valuePerBottle: { currency, value: 0 },
    acquisition: {
      acquisitionType: AcquisitionType.Direct,
      auctionDetail: {
        hammerPrice: { currency, value: 0 },
        taxPayable: { currency, value: 0 },
        buyerPremium: { currency, value: 0 }
      },
      otherCost: { currency, value: 0 },
      priceAsValue: false,
      sellerId: ''
    },
    ownership: { myOwnership: 100, shareholder: [] },
    beneficiary: [],
    bottles: [
      {
        bottleId: '', // generated when addPurchase
        location: emptyBottleLocation,
        status: WineStatus.Pending
      }
    ]
  },
  other: {
    number: 1,
    personalRefNo: '',
    attributeNotes: ''
  },
  mainImage: undefined,
  attachments: [],
  purchaseStatus: WineStatus.Pending,
  purchaseLocation: emptyBottleLocation
})

interface WineFormProps {
  mode?: 'Create' | 'Edit'
  assetId: string | null
  wine?: WineCatalogue
  values?: WineValues
  onSubmit: SubmitHandler<WineValues>
  onCancel: () => void
}

export function WineForm({ mode = 'Create', assetId, wine, values, onSubmit, onCancel }: WineFormProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  // Initialize form values on Edit mode
  const isFormEdit = mode === 'Edit' && values
  const defaultValues = useMemo(() => {
    if (isFormEdit) {
      const checkBottles = values.purchase.bottles
      const defaultPurchaseStatus = isSamePurchaseStatus(checkBottles) ? checkBottles[0].status : undefined // undefined means some bottles may be drank and not is allowed to updated bottles status
      const defaultPurchaseLocation = isSamePurchaseLocation(checkBottles) ? checkBottles[0].location : undefined // undefined means it is not allowed to updated bottles location
      return { ...values, purchaseLocation: defaultPurchaseLocation, purchaseStatus: defaultPurchaseStatus }
    }
    return getDefaultValues(preferences.baseCurrency, preferences.timeZone)
  }, [isFormEdit, preferences.baseCurrency, preferences.timeZone, values])
  const isStatusLock = defaultValues.purchaseStatus === undefined
  const isLocationLock = defaultValues.purchaseLocation === undefined
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    watch,
    reset,
    setValue
  } = useForm<WineValues>({ defaultValues })
  // Watch form fields
  const price = watch('purchase.price.value')
  const pricingMethod = watch('purchase.pricingMethod')
  const acquisitionType = watch('purchase.acquisition.acquisitionType')
  const numberOfBottles = watch('other.number')
  const [purchaseLocationType, purchaseLocationId, purchaseRoomId] = watch([
    'purchaseLocation.locationType',
    'purchaseLocation.locationId',
    'purchaseLocation.roomId'
  ])
  const [hammerPrice, otherCost, taxPayable, buyerPremium] = watch([
    'purchase.acquisition.auctionDetail.hammerPrice.value',
    'purchase.acquisition.otherCost.value',
    'purchase.acquisition.auctionDetail.taxPayable.value',
    'purchase.acquisition.auctionDetail.buyerPremium.value'
  ])
  const [myOwnership, shareholder, beneficiary] = watch([
    'purchase.ownership.myOwnership',
    'purchase.ownership.shareholder',
    'purchase.beneficiary'
  ])
  const totalCostValue = watch('purchase.totalCost.value')
  const totalCostCurrency = watch('purchase.totalCost.currency')
  // Derived values
  const isProperty = purchaseLocationType === LocationType.MyProperty
  const isAddress = purchaseLocationType === LocationType.Address
  const isNewAddress = purchaseLocationType === LocationType.NewAddress
  const isDirect = acquisitionType === AcquisitionType.Direct
  const isLotPricing = pricingMethod === WinePricingMethod.Lot

  // Field arrays
  const shareholderArray = useFieldArray({
    control,
    name: 'purchase.ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'purchase.beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  // Controlled arrays
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary![i] }))

  // Calculated values
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])

  // Fetch data for options
  const { data: propertyOptions, isLoading: loadingProperties } = useSWR(
    [propertyQuery.options],
    fetchPropertyOptions(database!)
  )
  const { data: contactOptions, isValidating: loadingContacts } = useSWR(
    [contactQuery.options],
    fetchContactOptions(database!)
  )
  const { data: propertyRoomOptions, isValidating: loadingPropertyRooms } = useSWR(
    [propertyQuery.rooms, purchaseLocationId],
    fetchPropertyRoomOptions(database!)
  )
  const { data: propertyPositionOptions, isValidating: loadingPropertyPositions } = useSWR(
    [propertyQuery.positions, purchaseLocationId, purchaseRoomId],
    fetchPropertyPositionOptions(database!)
  )
  const { data: contactRoomOptions, isValidating: loadingContactRooms } = useSWR(
    [contactQuery.rooms, purchaseLocationId],
    fetchContactRoomOptions(database!)
  )
  const { data: contactPositionOptions, isValidating: loadingContactPositions } = useSWR(
    [contactQuery.positions, purchaseLocationId, purchaseRoomId],
    fetchContactRoomPositionOptions(database!)
  )

  // Create options
  const roomOptions = isProperty ? propertyRoomOptions : contactRoomOptions
  const loadingRooms = isProperty ? loadingPropertyRooms : loadingContactRooms
  const positionOptions = isProperty ? propertyPositionOptions : contactPositionOptions
  const loadingPositions = isProperty ? loadingPropertyPositions : loadingContactPositions

  const acquisitionTypeOptions = makeOptions(acquisitionTypeValues, (key) => `AcquisitionTypeOptions.${key}`)
  const locationTypeOptions = makeOptions(locationTypeValues, (key) => `LocationTypeOptions.${key}`)
  const winePricingMethodOptions = makeOptions(
    winePricingMethodValues,
    (key) => `collectables:WinePricingMethodOptions.${key}`
  )
  const wineStatusOptions = makeOptions(
    [WineStatus.Pending, WineStatus.Delivered],
    (key) => `collectables:WineStatusOptions.${key}`
  )
  const subtypeOptions = wineTypeValues.map((value) => ({
    label: t(`collectables:WineTypeOptions.${value}`),
    value
  }))

  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'acquisition', label: t('Acquisition') },
        { key: 'location', label: t('Location') },
        { key: 'attributes', label: t('Attributes') },
        { key: 'ownership', label: t('Ownership') }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  const addRoom = async (roomName: string) => {
    switch (purchaseLocationType) {
      case LocationType.MyProperty:
        const PropertyRoomId = await addPropertyRoom(database!, purchaseLocationId, roomName)
        return PropertyRoomId
      case LocationType.Address:
      case LocationType.NewAddress:
        const ContactRoomId = await addContactRoom(database!, purchaseLocationId, roomName)
        return ContactRoomId
    }
  }

  const addPosition = async (positionName: string) => {
    switch (purchaseLocationType) {
      case LocationType.MyProperty:
        await addPropertyPosition(database!, purchaseLocationId, purchaseRoomId!, positionName)
        return positionName
      case LocationType.Address:
      case LocationType.NewAddress:
        await addContactPosition(database!, purchaseLocationId, purchaseRoomId!, positionName)
        return positionName
    }
  }

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  const resetPurchaseLocation = useCallback(
    (resetId: boolean) => {
      if (!isLocationLock) {
        resetId && setValue('purchaseLocation.locationId', '')
        setValue('purchaseLocation.roomId', undefined)
        setValue('purchaseLocation.position', undefined)
        setValue('purchaseLocation.notes', undefined)
      }
    },
    [setValue, isLocationLock]
  )

  useEffect(() => {
    if (wine) {
      reset((values) => ({ ...values, wine: { ...values.wine, ...wine } }))
    }
  }, [wine, reset])

  const handleFieldValueChange = useCallback(
    (fieldName: string, value: number | WinePricingMethod) => {
      let quantity = 1
      const roundTo2 = (value: number) => Math.round(value * 100) / 100

      switch (fieldName) {
        case 'purchase.price.value':
          quantity = isLotPricing ? numberOfBottles : 1
          setValue('purchase.valuePerBottle.value', roundTo2((value as number) / quantity))
          break
        case 'other.number':
          quantity = isLotPricing ? (value as number) : 1
          isLotPricing && setValue('purchase.valuePerBottle.value', roundTo2(price / quantity))
          break
        case 'purchase.pricingMethod':
          quantity = (value as WinePricingMethod) === WinePricingMethod.Lot ? numberOfBottles : 1
          setValue('purchase.valuePerBottle.value', roundTo2(price / quantity))
          break
        // TODO : check the requirement
        // case 'purchase.acquisition.priceAsValue':
        //   if (value) {
        //     setValue('purchase.valuePerBottle.value', totalCostValue)
        //     setValue('purchase.valuePerBottle.currency', totalCostCurrency)
        //   } else {
        //     const quantity = isLotPricing ? numberOfBottles : 1
        //     setValue('purchase.valuePerBottle.value', price / quantity)
        //   }
      }
    },
    [isLotPricing, numberOfBottles, price, setValue]
  )

  const handleFieldCurrencyChange = useCallback(
    (fieldName: Path<WineValues>, currency: Currency) => {
      let fieldsToUpdate: Path<WineValues>[] = []

      switch (fieldName) {
        case 'purchase.price.currency':
          fieldsToUpdate = [
            'purchase.valuePerBottle.currency',
            'purchase.acquisition.otherCost.currency',
            'purchase.totalCost.currency'
          ]
          break
        case 'purchase.acquisition.auctionDetail.hammerPrice.currency':
          fieldsToUpdate = [
            'purchase.acquisition.auctionDetail.taxPayable.currency',
            'purchase.acquisition.auctionDetail.buyerPremium.currency',
            'purchase.acquisition.otherCost.currency',
            'purchase.totalCost.currency'
          ]
          break
      }

      fieldsToUpdate.forEach((field) => setValue(field, currency))
    },
    [setValue]
  )

  useEffect(() => {
    const basePrice = isDirect ? price : hammerPrice
    const quantity = isLotPricing ? 1 : numberOfBottles
    const additionalCosts = isDirect ? otherCost : taxPayable + buyerPremium + otherCost
    const totalCost = basePrice * quantity + additionalCosts
    setValue('purchase.totalCost.value', totalCost)
  }, [
    price,
    numberOfBottles,
    hammerPrice,
    taxPayable,
    buyerPremium,
    otherCost,
    setValue,
    pricingMethod,
    acquisitionType,
    isDirect,
    isLotPricing
  ])

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  return (
    <>
      <TabPanel defaultValue={tabs[0].key}>
        {/* left */}
        <TabPanel.SideNav tabs={tabs} />
        {/* right */}
        <form
          className={'flex h-[95vh] grow flex-col justify-between gap-4 md:h-[700px]'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={'grow overflow-auto px-4 pt-4 '}>
            <TabPanel.Section value={'primary'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormInput
                    control={control}
                    name={'wine.producer'}
                    label={t('collectables:Field.Producer')}
                    rules={{ required: true }}
                    type={'text'}
                    disabled={true}
                  />
                  <FormInput
                    control={control}
                    name={'wine.name'}
                    label={t('collectables:Field.WineName')}
                    rules={{ required: true }}
                    type={'text'}
                    disabled={true}
                  />
                  {/* noticed usage: vintage(type number) on FormInput(default type string) */}
                  <FormInput
                    control={control}
                    name={'wine.vintage'}
                    label={t('collectables:Field.Vintage')}
                    rules={{ required: true }}
                    type={'text'}
                    disabled={true}
                  />
                  <FormSelect
                    control={control}
                    name={`purchaseStatus`}
                    label={t('Field.Status')}
                    disabled={isStatusLock}
                  >
                    {wineStatusOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormSelect
                    control={control}
                    name={`purchaseLocation.locationType`}
                    label={t('LocationType')}
                    onSelected={() => resetPurchaseLocation(true)}
                    disabled={isLocationLock}
                  >
                    {locationTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>

                  {isProperty && (
                    <FormAutocomplete
                      control={control}
                      name={'purchaseLocation.locationId'}
                      label={t('Field.LocationOfAsset')}
                      placeholder={t('TypeToSearch')}
                      options={propertyOptions}
                      isLoading={loadingProperties}
                      rules={{ required: true }}
                    />
                  )}
                  {isAddress && (
                    <FormAutocomplete
                      control={control}
                      name={'purchaseLocation.locationId'}
                      label={t('Field.LocationOfAsset')}
                      placeholder={t('TypeToSearch')}
                      options={contactOptions}
                      isLoading={loadingContacts}
                      rules={{ required: true }}
                    />
                  )}
                  {isNewAddress && (
                    <>
                      <Button
                        className={'group justify-start gap-x-3 justify-self-start'}
                        onClick={() => setIsOpen(true)}
                        disabled={purchaseLocationId.length > 0}
                      >
                        <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                          <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                        </div>
                        <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                          {t('CreateNewAddress')}
                        </span>
                      </Button>
                      {purchaseLocationId.length > 0 && (
                        <div className={'flex items-center justify-between'}>
                          <p className={'text-sm'}>
                            {contactOptions?.find(({ value }) => value === purchaseLocationId)?.label ?? '-'}
                          </p>
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => setValue('purchaseLocation.locationId', '')}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                  <FormInput
                    control={control}
                    name={'other.personalRefNo'}
                    label={t('Field.PersonalRefNo')}
                    type={'text'}
                    placeholder={assetId ?? ''}
                  />
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormDatePicker
                    control={control}
                    name={'purchase.purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  <FormDatePicker
                    control={control}
                    name={'purchase.deliveryDate'}
                    label={t('collectables:Field.DeliveryDate')}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  <FormSelect
                    control={control}
                    name={'purchase.pricingMethod'}
                    label={t('collectables:Field.PricingMethod')}
                    onChanged={(value) => handleFieldValueChange('purchase.pricingMethod', value)}
                  >
                    {winePricingMethodOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormAutocomplete
                    control={control}
                    name={'purchase.bottleSize'}
                    label={t('collectables:Field.BottleSize')}
                    options={bottleSizeOptions}
                  />
                  <FormQuantityInput
                    control={control}
                    name={'other.number'}
                    label={t('collectables:Field.NumberOfBottles')}
                    min={values?.other.number ?? 1}
                    max={500}
                    onChanged={(value) => handleFieldValueChange('other.number', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'purchase.price.currency', value: 'purchase.price.value' }}
                    label={
                      pricingMethod === WinePricingMethod.Bottle
                        ? t('collectables:WinePricingMethodOptions.Bottle')
                        : t('collectables:WinePricingMethodOptions.Lot')
                    }
                    rules={{
                      value: {
                        required: true,
                        validate: {
                          greaterThanZero: (value) => value > 0
                        }
                      }
                    }}
                    format={preferences.numberFormat}
                    digits={2}
                    onChangedCurrency={(value) => handleFieldCurrencyChange('purchase.price.currency', value)}
                    onChangedValue={(value) => handleFieldValueChange('purchase.price.value', value)}
                  />
                  <FormPriceInput
                    control={control}
                    name={{
                      currency: 'purchase.valuePerBottle.currency',
                      value: 'purchase.valuePerBottle.value'
                    }}
                    label={t('collectables:Field.ValuationPerBottle')}
                    info={t('UsedForValuation')}
                    format={preferences.numberFormat}
                    digits={2}
                  />
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.acquisition'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect
                    control={control}
                    name={'purchase.acquisition.acquisitionType'}
                    label={t('Field.AcquisitionType')}
                  >
                    {acquisitionTypeOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormDatePicker
                    control={control}
                    name={'purchase.purchaseDate'}
                    label={t('Field.PurchaseDate')}
                    format={preferences.dateFormat}
                    timeZone={preferences.timeZone}
                  />
                  <FormCreatableAutocomplete
                    control={control}
                    name={'purchase.acquisition.sellerId'}
                    label={t('Field.SellerRetailer')}
                    placeholder={t('TypeToSearchOrCreate')}
                    options={contactOptions}
                    onCreate={addNewContact}
                    isLoading={loadingContacts}
                  />
                  {isDirect ? (
                    <FormInput
                      control={control}
                      name={'purchase.acquisition.invoiceNumber'}
                      label={t('Field.InvoiceNumber')}
                      type={'text'}
                    />
                  ) : (
                    <>
                      <FormInput
                        control={control}
                        name={'purchase.acquisition.auctionDetail.lotNumber'}
                        label={t('Field.LotNumber')}
                        type={'text'}
                      />
                      <FormInput
                        control={control}
                        name={'purchase.acquisition.auctionDetail.paddleNumber'}
                        label={t('Field.PaddleNumber')}
                        type={'text'}
                      />
                    </>
                  )}
                </div>
                <div className={'flex flex-col gap-4'}>
                  <FormSelect
                    control={control}
                    name={'purchase.pricingMethod'}
                    label={t('collectables:Field.PricingMethod')}
                  >
                    {winePricingMethodOptions.map(({ label, value }) => (
                      <SelectItem key={value} value={value}>
                        {label}
                      </SelectItem>
                    ))}
                  </FormSelect>
                  <FormQuantityInput
                    control={control}
                    name={'other.number'}
                    label={t('collectables:Field.NumberOfBottles')}
                    min={values?.other.number ?? 1}
                    max={500}
                    onChanged={(value) => handleFieldValueChange('other.number', value)}
                  />
                  {isDirect ? (
                    <FormPriceInput
                      control={control}
                      name={{ currency: 'purchase.price.currency', value: 'purchase.price.value' }}
                      label={
                        pricingMethod === WinePricingMethod.Bottle
                          ? t('collectables:WinePricingMethodOptions.Bottle')
                          : t('collectables:WinePricingMethodOptions.Lot')
                      }
                      format={preferences.numberFormat}
                      digits={2}
                      onChangedCurrency={(value) => handleFieldCurrencyChange('purchase.price.currency', value)}
                      onChangedValue={(value) => handleFieldValueChange('purchase.price.value', value)}
                    />
                  ) : (
                    <>
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'purchase.acquisition.auctionDetail.hammerPrice.currency',
                          value: 'purchase.acquisition.auctionDetail.hammerPrice.value'
                        }}
                        label={t('Field.HammerPrice')}
                        format={preferences.numberFormat}
                        digits={2}
                        onChangedCurrency={(value) =>
                          handleFieldCurrencyChange('purchase.acquisition.auctionDetail.hammerPrice.currency', value)
                        }
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'purchase.acquisition.auctionDetail.taxPayable.currency',
                          value: 'purchase.acquisition.auctionDetail.taxPayable.value'
                        }}
                        label={t('Field.TaxPayable')}
                        format={preferences.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                      <FormPriceInput
                        control={control}
                        name={{
                          currency: 'purchase.acquisition.auctionDetail.buyerPremium.currency',
                          value: 'purchase.acquisition.auctionDetail.buyerPremium.value'
                        }}
                        label={t('Field.BuyerPremium')}
                        format={preferences.numberFormat}
                        digits={2}
                        disabled={{ currency: true }}
                      />
                    </>
                  )}
                  <FormPriceInput
                    control={control}
                    name={{
                      currency: 'purchase.acquisition.otherCost.currency',
                      value: 'purchase.acquisition.otherCost.value'
                    }}
                    label={t('Field.OtherCosts')}
                    format={preferences.numberFormat}
                    digits={2}
                    disabled={{ currency: true }}
                  />
                  <FormPriceInput
                    control={control}
                    name={{ currency: 'purchase.totalCost.currency', value: 'purchase.totalCost.value' }}
                    label={t('Field.TotalPrice')}
                    format={preferences.numberFormat}
                    digits={2}
                    disabled={{ currency: true, value: true }}
                    allowNegative={true}
                  />
                  <FormCheckbox
                    control={control}
                    name={'purchase.acquisition.priceAsValue'}
                    label={t('collectables:Field.SetTotalPriceAsAssetValue')}
                    // onChanged={onChangedSetTotalPriceAsValueCheck}
                  />
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.location'}>
              <div className={'flex flex-col gap-4'}>
                <FormSelect
                  control={control}
                  name={`purchaseLocation.locationType`}
                  label={t('Field.LocationType')}
                  onSelected={() => {
                    resetPurchaseLocation(true)
                  }}
                  disabled={isLocationLock}
                >
                  {locationTypeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </FormSelect>
                {isProperty && (
                  <FormAutocomplete
                    control={control}
                    name={'purchaseLocation.locationId'}
                    label={t('Field.LocationOfAsset')}
                    placeholder={t('TypeToSearch')}
                    options={propertyOptions}
                    isLoading={loadingProperties}
                    rules={{ required: true }}
                    onChanged={() => () => resetPurchaseLocation(false)}
                  />
                )}
                {isAddress && (
                  <FormAutocomplete
                    control={control}
                    name={'purchaseLocation.locationId'}
                    label={t('Field.LocationOfAsset')}
                    placeholder={t('TypeToSearch')}
                    options={contactOptions}
                    isLoading={loadingContacts}
                    rules={{ required: true }}
                    onChanged={() => resetPurchaseLocation(false)}
                  />
                )}
                {isNewAddress && (
                  <>
                    <Button
                      className={'group justify-start gap-x-3 justify-self-start'}
                      onClick={() => setIsOpen(true)}
                      disabled={purchaseLocationId.length > 0}
                    >
                      <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                        <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                      </div>
                      <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                        {t('CreateNewAddress')}
                      </span>
                    </Button>
                    {purchaseLocationId.length > 0 && (
                      <div className={'flex items-center justify-between'}>
                        <p className={'text-sm'}>
                          {contactOptions?.find(({ value }) => value === purchaseLocationId)?.label ?? '-'}
                        </p>
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => setValue('purchaseLocation.locationId', '')}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </div>
                    )}
                  </>
                )}

                <FormDatePicker
                  control={control}
                  name={'purchase.deliveryDate'}
                  label={t('collectables:Field.DeliveryDate')}
                  format={preferences.dateFormat}
                  timeZone={preferences.timeZone}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormCreatableAutocomplete
                  control={control}
                  name={'purchaseLocation.roomId'}
                  label={t('collectables:RoomOrBin')}
                  options={roomOptions}
                  onCreate={(name) => addRoom(name)}
                  placeholder={t('SearchOrCreate')}
                  isLoading={loadingRooms}
                  isDisabled={!purchaseLocationId}
                  onChanged={() => setValue('purchaseLocation.position', '')}
                />
                <FormCreatableAutocomplete
                  control={control}
                  name={'purchaseLocation.position'}
                  label={t('collectables:PositionOrShelf')}
                  options={positionOptions}
                  onCreate={(name) => addPosition(name)}
                  placeholder={t('SearchOrCreate')}
                  isLoading={loadingPositions}
                  isDisabled={!purchaseRoomId}
                />
              </div>
              <FormTextarea
                className={'md:col-span-2'}
                control={control}
                name={'purchaseLocation.notes'}
                label={t('Field.Notes')}
              />
            </TabPanel.Section>
            <TabPanel.Section value={'additional.attributes'}>
              <div className={'flex flex-col gap-4'}>
                <FormAutocomplete
                  control={control}
                  name={'wine.subtype'}
                  label={t('collectables:Field.WineType')}
                  options={subtypeOptions}
                  placeholder={t('collectables:Placeholder.SelectFromTheList')}
                  isDisabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.country'}
                  label={t('Field.Country')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.region'}
                  label={t('collectables:Field.Region')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.subRegion'}
                  label={t('collectables:Field.SubRegion')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.vineyard'}
                  label={t('collectables:Field.Vineyard')}
                  type={'text'}
                  disabled={true}
                />
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormInput
                  control={control}
                  name={'wine.masterVarietal'}
                  label={t('collectables:Field.MasterVarietal')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.variety'}
                  label={t('collectables:Field.Variety')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.appellation'}
                  label={t('collectables:Field.Appellation')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.designation'}
                  label={t('collectables:Field.Designation')}
                  type={'text'}
                  disabled={true}
                />
                <FormInput
                  control={control}
                  name={'wine.proRating'}
                  label={t('collectables:Field.ProRating')}
                  disabled={true}
                />
              </div>
              <div className={'md:col-span-2'}>
                <FormTextarea control={control} name={'other.attributeNotes'} label={t('Field.LotNumber')} />
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'additional.ownership'} className={'items-start'}>
              <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
                <div className={'space-y-4'}>
                  <div className={'grid gap-1'}>
                    <div className={'flex items-center justify-between pr-10'}>
                      <span className={'text-sm'}>{t('Field.Ownership')}</span>
                      {remainingOwnership === 0 && (
                        <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                      )}
                      {remainingOwnership > 0 && (
                        <span className={'text-xs text-[#d89423]'}>
                          {t('RemainingPercent', { percent: remainingOwnership })}
                        </span>
                      )}
                      {remainingOwnership < 0 && (
                        <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                      )}
                    </div>
                    <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <Input className={'h-[38px] border'} value={t('Field.MyOwnership')} disabled={true} />
                      <FormPercentInput control={control} name={`purchase.ownership.myOwnership`} />
                    </div>
                  </div>
                  <div className={'grid gap-1'}>
                    <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                    <ul className={'space-y-2 empty:hidden'}>
                      {shareholderArray.fields.map((shareholder, index) => (
                        <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                          <FormCreatableAutocomplete
                            control={control}
                            name={`purchase.ownership.shareholder.${index}.contactId`}
                            options={ShareholderUnselectContact(index)}
                            onCreate={addNewContact}
                            isLoading={loadingContacts}
                            placeholder={t('SearchOrCreate')}
                          />
                          <FormPercentInput
                            control={control}
                            name={`purchase.ownership.shareholder.${index}.percent`}
                          />
                          <Button
                            className={'rounded-full transition-colors hover:bg-grey/20'}
                            onClick={() => shareholderArray.remove(index)}
                          >
                            <XIcon className={'text-primary'} />
                          </Button>
                        </li>
                      ))}
                    </ul>
                    <Button
                      className={'group justify-start gap-x-3 justify-self-start py-2'}
                      onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                    >
                      <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                        <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                      </div>
                      <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                        {t('AddShareholders')}
                      </span>
                    </Button>
                  </div>
                </div>
                <div className={'space-y-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                    {remainingBeneficiary === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingBeneficiary > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingBeneficiary })}
                      </span>
                    )}
                    {remainingBeneficiary < 0 && (
                      <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>
                    )}
                  </div>
                  <ul className={'space-y-2 empty:hidden'}>
                    {beneficiaryArray.fields.map((beneficiary, index) => (
                      <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`purchase.beneficiary.${index}.contactId`}
                          options={beneficiaryUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`purchase.beneficiary.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => beneficiaryArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddBeneficiaries')}
                    </span>
                  </Button>
                </div>
              </div>
            </TabPanel.Section>
            <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
              {assetId && (
                <AttachmentPanel
                  assetId={assetId}
                  control={control}
                  name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                  widgetOptions={[
                    AttachmentKind.AssetImage,
                    AttachmentKind.PrimaryDetails,
                    AttachmentKind.Acquisition,
                    AttachmentKind.Location,
                    AttachmentKind.Attributes,
                    AttachmentKind.Ownership,
                    AttachmentKind.Beneficiaries
                  ]}
                />
              )}
            </TabPanel.Section>
          </div>
          <fieldset className={'flex justify-center gap-2 p-4 md:justify-end'} disabled={isSubmitting}>
            <Button
              id={'collectables_add_wine_cancel'}
              className={'min-w-[130px]'}
              variant={'outline'}
              size={'md'}
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              id={'collectables_add_wine_create'}
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid || !purchaseLocationId}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
            </Button>
          </fieldset>
        </form>
      </TabPanel>

      <NewAddress
        isOpen={isOpen}
        onSubmitted={(id) => {
          resetPurchaseLocation(false)
          setValue('purchaseLocation.locationId', id)
        }}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

export function WineFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

const isSamePurchaseLocation = (bottles: OneBottleOfWine[]) => {
  return bottles.every((bottle) => JSON.stringify(bottle.location) === JSON.stringify(bottles[0].location))
}

const isSamePurchaseStatus = (bottles: OneBottleOfWine[]) => {
  return bottles.every((bottle) => bottle.status === bottles[0].status) && bottles[0].status !== WineStatus.Consumed
}

import Image from 'next/image'
import { Trans, useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { fetchProfile, userQuery } from '@/api/AccountService'
import { useAuthStore } from '@/store/authStore'
import { Input, Modal } from '@/components/base'
import { LiveChatForm, LiveChatValues } from '@/components/form'

export default function LiveChatModal({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (value: boolean) => void }) {
  const database = useAuthStore((state) => state.database)
  const { t } = useTranslation()
  const { data: profile } = useSWR([userQuery.profile], fetchProfile(database!))

  const onSubmit = (data: LiveChatValues) => {
    const dataIncludeUsername = {
      username: profile?.name,
      email: profile?.email,
      category___postsale_contact_form: data.enquiryType,
      subject: data.subject,
      content: data.description,
      existingCustomer: true
    }
    if (typeof window !== 'undefined') {
      try {
        ;(window as any).Genesys('subscribe', 'Database.ready', function () {
          ;(window as any).Genesys('command', 'Database.set', {
            messaging: { customAttributes: dataIncludeUsername }
          })
        })
        ;(window as any).Genesys('subscribe', 'Launcher.ready', () => {
          try {
            ;(window as any).Genesys('command', 'Launcher.show')
            ;(window as any).Genesys('command', 'Messenger.open')
            ;(window as any).Genesys('subscribe', 'MessagingService.ready', () => {
              ;(window as any).Genesys('command', 'MessagingService.startConversation')
            })
            setIsOpen(false)
          } catch (e) {
            console.warn('Failed to subscribe to Genesys Database:', e)
          }
        })
      } catch (e) {
        console.warn('Failed to subscribe to Genesys Database:', e)
      }
    }
  }

  return (
    <>
      {isOpen && (
        <Modal className={'max-w-xl'} onBackdropClick={() => setIsOpen(false)}>
          <Modal.Header className={'justify-end bg-primary'}>
            <Modal.CloseButton className={'text-white'} onClose={() => setIsOpen(false)} />
          </Modal.Header>
          <div className={'rounded-lg bg-white p-5 shadow-lg'}>
            <div className={'flex items-start gap-x-2'}>
              <Image src={'/images/LiveChatBotIcon.svg'} alt={'logo'} width={40} height={40} />
              <div className={'flex w-full flex-col gap-y-2.5 text-[#5F5F5F]'}>
                <div className={' w-full space-y-2.5 rounded bg-[#F2F2F2] p-3 text-sm/4'}>
                  <Trans
                    t={t}
                    i18nKey={'liveChat:WelcomeMessage'}
                    values={{
                      name: profile?.name
                    }}
                    components={[<span key={0} className={'font-bold'} />]}
                  />
                  <div>{t('liveChat:BeforeWeDirectYouToOneOfOurAdvisors')}</div>
                </div>
                <div
                  className={
                    ' w-full rounded border-[1px] border-[#E0E0E0] bg-[#F2F2F2] p-2.5 shadow-black drop-shadow-md'
                  }
                >
                  <LiveChatForm onSubmit={onSubmit} />
                </div>
              </div>
            </div>

            <div className={'mb-3 mt-[calc(33vh-200px)]'}>
              <div className={' w-full space-y-1 rounded bg-[#F2F2F2] p-3 text-sm/4 text-[#5F5F5F]'}>
                <p className={'font-bold'}>{t('liveChat:OurChatSupportIsAvailableMondayToFriday')}</p>
                <div>{t('liveChat:UKTime1amTo530pm')}</div>
                <div>{t('liveChat:HKTime9amTo130am')}</div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

import Decimal from "decimal.js";
import { Deletable, Optional } from "./types/common";
import { CoreFirestore } from "../coreFirebase";
import { Annotations, Validations, getFieldsByDecorator } from "./decorators";

export const AllowedDecimalPlaces = 2;

export function addDecimal(
  number1: Decimal.Value,
  number2: Decimal.Value
): number {
  return new Decimal(number1).add(new Decimal(number2)).toNumber();
}

export function addDecimalMulti(
  number1: Decimal.Value,
  ...numbers: Decimal.Value[]
): number {
  return numbers
    .reduce((acc: Decimal, value: Decimal.Value) => {
      return acc.add(new Decimal(value));
    }, new Decimal(number1))
    .toNumber();
}

export function subDecimal(
  number1: Decimal.Value,
  number2: Decimal.Value
): number {
  return new Decimal(number1).sub(new Decimal(number2)).toNumber();
}

export function mulDecimal(
  number1: Decimal.Value,
  number2: Decimal.Value
): number {
  return new Decimal(number1).mul(new Decimal(number2)).toNumber();
}

export function mulAmount(
  number1: Decimal.Value,
  number2: Decimal.Value
): number {
  return new Decimal(number1)
    .mul(number2)
    .toDecimalPlaces(AllowedDecimalPlaces)
    .toNumber();
}

export function divDecimal(
  number1: Decimal.Value,
  number2: Decimal.Value
): number {
  if (new Decimal(number2).toNumber() === 0) {
    throw new Error("Divide by zero");
  }
  return new Decimal(number1).div(new Decimal(number2)).toNumber();
}

export function divDecimalRound(
  number1: Decimal.Value,
  number2: Decimal.Value,
  decimalPlace: number = AllowedDecimalPlaces
): number {
  if (new Decimal(number2).toNumber() === 0) {
    throw new Error("Divide by zero");
  }
  return new Decimal(number1)
    .div(new Decimal(number2))
    .toDecimalPlaces(decimalPlace)
    .toNumber();
}

export function calculatePercentage(
  part: Decimal.Value,
  total: Decimal.Value
): number {
  if (total === 0) return 0;
  return new Decimal(part)
    .mul(100)
    .div(total)
    .toDecimalPlaces(AllowedDecimalPlaces)
    .toNumber();
}

export function calculateOwnership(
  total: number | string,
  ownPercentage: Optional<number>
): number {
  return new Decimal(total)
    .mul(ownPercentage ?? 100)
    .div(100)
    .toDecimalPlaces(AllowedDecimalPlaces)
    .toNumber();
}

export function convertAllTimestamp(item: any) {
  const maybeConverted = CoreFirestore.checkAndConvertTimestampToDate(item);
  if (maybeConverted) {
    return maybeConverted;
  } else if (!item) {
    // undefined or null
    return item;
  } else if (item instanceof Array) {
    (item as Array<any>).forEach((value, index) => {
      item[index] = convertAllTimestamp(value);
    });
  } else if (typeof item === "object") {
    Object.entries(item).forEach(([key, value]) => {
      item[key] = convertAllTimestamp(value);
    });
  }
  return item;
}

/**
 * Splits an object into two parts: an object containing the specified keys and an object containing the remaining keys.
 *
 * @param {Source} obj - The object to be split.
 * @param {Keys[]} keys - An array of keys to be included in the first part of the split object.
 * @return {[Partial<Source>, Partial<Source>]} - Tuple of partial objects [chosenKeys, remainingKeys]
 */
export function splitObject<Source extends object>(
  obj: Source,
  keys: string[]
): [Partial<Source>, Partial<Source>] {
  return Object.keys(obj).reduce(
    (acc, key) => {
      if (keys.includes(key)) {
        acc[0][key as keyof Source] = obj[key as keyof Source];
      } else {
        acc[1][key as keyof Source] = obj[key as keyof Source];
      }
      return acc;
    },
    [{}, {}] as [Partial<Source>, Partial<Source>]
  );
}

//#NOTE this can only omit keys from object type
export type OmitKeys<T, K extends keyof T> = Omit<T, K>;
export type UpdateField<T> = undefined extends T
  ? Deletable<NonNullable<T>>
  : T;
export type RequireKeys<T, K extends keyof T> = T & Required<Pick<T, K>>;
//#NOTE this type should turn non optional field to optional(to tell update or no change),
// - and optional field to optional | null (to tell update, delete or no change)
// - this one will affect child object
export type UpdateObjectNested<T extends object> = {
  [Key in keyof T & string]?: T[Key] extends object
    ? undefined extends T[Key]
      ? Deletable<UpdateObjectNested<NonNullable<T[Key]>>>
      : UpdateObjectNested<T[Key]>
    : UpdateField<T[Key]>;
};

//#NOTE this type should turn non optional field to optional(to tell update or no change),
// - and optional field to optional | null (to tell update, delete or no change)
export type UpdateObject<T extends object> = {
  [Key in keyof T]?: UpdateField<T[Key]>;
};
export function applyUpdateToObject<T extends object>(
  currentData: T,
  update: UpdateObject<T>
) {
  Object.entries(update).forEach(([key, value]) => {
    if (value === null) {
      delete (<any>currentData)[key];
    } else if (value !== undefined) {
      (<any>currentData)[key] = deepCopy(value);
    }
  });
  return currentData;
}

export function checkFieldPath(fieldPath: string): boolean {
  const pattern = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
  return pattern.test(fieldPath);
}

type IsSimpleType<T, U = T> = T extends string | boolean | number ? U : never;
//  T extends object
//   ? never
//   : T extends Array<any>
//   ? never
//   : U;
type IsObject<T, U = T> = T extends object
  ? never
  : T extends Array<any>
  ? never
  : U;
type IsOptional<T, U = T, V = never> = undefined extends T ? U : V;

type IsSimpleTypeKey<T extends object, K extends keyof T> = IsSimpleType<
  T[K],
  K
>;
export function buildFieldUpdate<T extends object>(
  current: T,
  update: T,
  key: keyof T
): Optional<T[keyof T]> {
  if (current[key] === update[key]) return undefined;
  else return update[key];
}

type IsOptionalSimpleTypeKey<T extends object> = IsOptional<T[keyof T]>;
export function buildOptionalFieldUpdate<T extends object, K extends keyof T>(
  current: T,
  update: T,
  key: keyof T
): Deletable<Optional<T[keyof T]>> {
  if (update[key]) {
    if (current[key] === update[key]) return undefined;
    else return update[key];
  } else {
    if (current[key]) {
      return null;
    } else {
      return undefined;
    }
  }
}

export function buildUpdate<T extends object>(
  current: T,
  update: T,
  cls: new () => T
) {
  const simpleFields = getFieldsByDecorator(Annotations.SimpleUpdate, cls);
  const optionalFields = getFieldsByDecorator(Validations.isOptional, cls);

  //#NOTE: related to original NonOptionalSimpleTypeUpdatableKeys
  const nonOptionalSimpleTypeUpdatableFields = Object.keys(update).filter(
    (key) => simpleFields.includes(key) && !optionalFields.includes(key)
  );

  //#NOTE: related to original OptionalSimpleTypeUpdatableKeys
  const optionalSimpleTypeUpdatableFields = Object.keys(update).filter(
    (key) => simpleFields.includes(key) && optionalFields.includes(key)
  );

  return buildObjectUpdate(
    current,
    update,
    nonOptionalSimpleTypeUpdatableFields as (keyof T)[],
    optionalSimpleTypeUpdatableFields as (keyof T)[]
  );
}

export function buildObjectUpdate<T extends object>(
  current: T,
  update: T,
  keys: (keyof T)[],
  optionalKeys: (keyof T)[]
): UpdateObject<T> {
  const result: UpdateObject<T> = {};
  keys.forEach((key) => {
    const value = buildFieldUpdate(current, update, key);
    if (value !== undefined) result[<keyof T>key] = value;
  });
  optionalKeys.forEach((key) => {
    const value = buildOptionalFieldUpdate(current, update, key);
    if (value !== undefined)
      result[<keyof T>key] = value as NonNullable<UpdateObject<T>[keyof T]>;
  });

  return result;
}

export type ObjectKeysOf<T extends object> = {
  [Key in keyof T & string]: IsObject<T[Key], `${Key}`>;
}[keyof T & string] &
  keyof T;
export type SimpleTypeKeysOf<T extends object> = {
  [Key in keyof T & string]: IsOptional<
    T[Key],
    never,
    IsSimpleType<T[Key], `${Key}`>
  >;
}[keyof T & string] &
  keyof T;
export type OptionalSimpleTypeKeysOf<T extends object> = {
  [Key in keyof T & string]: IsOptional<
    T[Key],
    IsSimpleType<NonNullable<T[Key]>, `${Key}`>,
    never
  >;
}[keyof T & string] &
  keyof T;

export enum CompDifference {
  Same = "Same",
  changed = "changed",
  NotFound = "NotFound",
}
export namespace CompDifference {
  export function fromBoolean(value: boolean): CompDifference {
    return value ? CompDifference.Same : CompDifference.NotFound;
  }
}

export function buildArrayUpdate<T>(
  current: Optional<T[]>,
  update: Optional<T[]>,
  notFoundInArray: (value: T, array: T[]) => CompDifference
): Deletable<Optional<{ removed: T[]; added: T[]; changed: T[] }>> {
  if (update) {
    if (current) {
      const removed: T[] = [];
      const added: T[] = [];
      const changed: T[] = [];
      update.forEach((value) => {
        const diff = notFoundInArray(value, current);
        if (diff === CompDifference.NotFound) added.push(value);
        else if (diff === CompDifference.changed) changed.push(value);
      });
      current.forEach((value) => {
        if (notFoundInArray(value, update) === CompDifference.NotFound)
          removed.push(value);
      });
      return { removed, added, changed };
    } else {
      return { removed: [], added: update, changed: [] };
    }
  } else {
    if (current) {
      return null;
    } else {
      return undefined;
    }
  }
}

export function optionalUniqueArrayEqual<T>(
  a: Optional<IsSimpleType<T>[]>,
  b: Optional<IsSimpleType<T>[]>
): boolean {
  if (a && b) {
    if (a.length !== b.length) return false;
    else {
      return a.every((v) => b.includes(v));
    }
  } else return a === b;
}

export function optionalDateEqual(a: Optional<Date>, b: Optional<Date>) {
  if (a && b) return a.getTime() === b.getTime();
  else return a === b;
}

// Previous calendar month, So 1st to 31st of the previous month.
export function isPurchasedLM(purchaseDate: Date) {
  const now = new Date();
  const yearDifference = now.getFullYear() - purchaseDate.getFullYear();
  const monthDifference = now.getMonth() - purchaseDate.getMonth();
  return (
    (yearDifference == 0 && monthDifference == 1) ||
    (yearDifference == 1 && monthDifference == -11)
  );
}

// String enums are not reverse mapped, can not use `value in EnumType` to validate
export function validateValueInEnum<T extends object>(
  value: string,
  enumType: T
) {
  return Object.values(enumType).includes(value);
}

export function addCryptoUnit(a: string, b: string): string {
  return new Decimal(a).add(b).toString();
}
export function subCryptoUnit(a: string, b: string): string {
  return new Decimal(a).sub(b).toString();
}
export function calculateCryptoValueRaw(unit: string, price: number): Decimal {
  return new Decimal(unit).mul(price);
}
export function calculateCryptoValueAmount(
  unit: string,
  price: number
): Decimal {
  return new Decimal(unit).mul(price).toDecimalPlaces(AllowedDecimalPlaces);
}

export function removeItemsFromArray<T, U>(
  arr: T[],
  removing: U[],
  compare: (a: T, b: U) => boolean
) {
  const remove = [...removing];
  for (let i = 0; i < arr.length; i++) {
    const currentInsured = arr[i];
    const removeIdx = remove.findIndex((insured) =>
      compare(currentInsured, insured)
    );
    if (removeIdx != -1) {
      arr.splice(i, 1);
      remove.splice(removeIdx, 1);
      i--;
    }
  }
}

export function deepCopy<T>(obj: T): T {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as any;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => deepCopy(item)) as any;
  }
  const copiedObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copiedObj[key] = deepCopy(obj[key]);
      if (copiedObj[key] === null) delete copiedObj[key];
    }
  }
  return copiedObj as T;
}

type PromiseObject<T extends object> = {
  [Key in keyof T]: Optional<Promise<T[Key]>>;
};
export async function resolveObject<T extends object>(
  obj: PromiseObject<T>
): Promise<T> {
  return Promise.all(
    Object.entries(obj)
      .filter(([_, v]) => v !== undefined && v !== null)
      .map(async ([k, v]) => [k, await v])
  ).then(Object.fromEntries);
}

export type TypeKVPair<Key extends string, T> = {
  key: Key;
  value: T;
};
export type AssociatedType6<
  T1 extends TypeKVPair<any, any>,
  T2 extends TypeKVPair<any, any>,
  T3 extends TypeKVPair<any, any>,
  T4 extends TypeKVPair<any, any>,
  T5 extends TypeKVPair<any, any>,
  T6 extends TypeKVPair<any, any>
> = {
  [K in T1["key"]]: T1["value"];
} & {
  [K in T2["key"]]: T2["value"];
} & {
  [K in T3["key"]]: T3["value"];
} & {
  [K in T4["key"]]: T4["value"];
} & {
  [K in T5["key"]]: T5["value"];
} & {
  [K in T6["key"]]: T6["value"];
};

export function validateStringNotEmpty(input: Optional<string>): boolean {
  return input !== undefined && input.length > 0 && input.trim() !== "";
}

export function validateStringTooLong(input: string): boolean {
  const bytes = new Blob([input]).size;
  return bytes >= 10 * 1024 * 1024; // Maximum size of a string in firebase is 10MB
}

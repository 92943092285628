import { useMemo } from 'react'
import { useSearchParams } from 'next/navigation'

import { defaultRowPrePage } from '@/constants/pagination'

const notExtraKeys = ['limit', 'page', 'view']

export function usePagination(arrayKeys: string[] = []): Record<string, any> {
  const searchParams = useSearchParams()
  const limitRaw = parseInt(searchParams.get('limit') ?? '', 10)
  const limit = !isNaN(limitRaw) ? limitRaw : defaultRowPrePage
  const pageRaw = parseInt(searchParams.get('page') ?? '', 10)
  const page = !isNaN(pageRaw) ? pageRaw : 1
  const offset = limit * (page - 1)

  // append other filter keys
  const extraKeysObject = useMemo(() => {
    const extraKeys = Array.from(searchParams.entries())
      .filter(([key, value]) => !notExtraKeys.includes(key) && value !== '')
      .map(([key, _value]) => key)
    return [...new Set(extraKeys)].reduce((prev, key) => {
      const isArray = arrayKeys.includes(key)
      return { ...prev, [key]: isArray ? searchParams.getAll(key) : searchParams.get(key) }
    }, {})
  }, [arrayKeys, searchParams])

  return {
    limit,
    page,
    offset,
    ...extraKeysObject
  }
}

import {
  Insurance,
  Command,
  Event,
  InsuredAssetType,
  Insured,
} from "../types/insurance";
import {
  AggregateRoot,
  AssetRelationStateWriter,
  Repo,
  RepoAndAggregates,
  buildUpdateGroupCommand,
  newRepo as newAggregateRepo,
} from "../types/aggregate";
import { AssetType, Optional } from "../types/common";
import { EncryptionFieldKey } from "../encryption/utils";
import { UpdateObject } from "../utils";
import { ExchangeRate } from "./exchangeRate";
import { FullRefs, Refs, getAssetsByIds } from "../refs";
import { PropertiesRepo } from "./properties";
import { ArtsRepo } from "./arts";
import { BelongingsRepo } from "./belongings";
import { GroupsRepo, GroupUpdater } from "./groups";
import { EncryptionManager } from "./encryption";
import { RoleToAsset } from "../types/relations";
import { DataPoisoned } from "../types/error";
import { DbSharedFields } from "../types/database";
import { SummaryManager } from "../types/summaryManager";
import {
  CoreFirestore,
  Transaction,
  checkAndGetData,
  checkDuplicated,
  getQueriedData,
} from "../../coreFirebase";
import { TypeResult } from "../types/typeVersion";

export class InsuranceRepo {
  protected readonly refs: FullRefs;
  protected readonly exRate: ExchangeRate;
  protected readonly summaryManager: SummaryManager;

  readonly Encryption: EncryptionManager;

  constructor(shared: DbSharedFields) {
    this.exRate = shared.exRate;
    this.refs = shared.refs;
    this.Encryption = shared.encryption;
    this.summaryManager = shared.summaryManager;
  }

  /**
   * This function returns summary in original currency
   * One should get the exchange rate (e.g., getToTargetExchangeRate)
   * to translate to desired currency for display
   */
  async getSyncedSummary() {
    return (await this.summaryManager.get(AssetType.Insurance).syncAndGetData())
      .summary;
  }

  //#NOTE read before this function and set/update after this function
  private async handleRelatedAggregates(
    transaction: Transaction,
    ar: AggregateRoot<Insurance.Encrypted, Command, Event>
  ) {
    const insurance = ar.state();
    const data = ar.relatedUpdates() as Insurance.RelatedUpdates;
    let groupUpdater: Optional<GroupUpdater> = undefined;
    if (data.addedGroupIds || data.removedGroupIds) {
      groupUpdater = new GroupUpdater(
        this.refs.currentRefs,
        (data.addedGroupIds || []).concat(data.removedGroupIds || [])
      );
      await groupUpdater.read(transaction);
    }
    const aggregatedData: {
      [asset in InsuredAssetType]?: RepoAndAggregates<any, any, any>;
    } = {};
    if (data.addAssetInsured) {
      for (const { targetId, targetType } of data.addAssetInsured) {
        switch (targetType) {
          case AssetType.Property:
            if (!aggregatedData[AssetType.Property]) {
              aggregatedData[AssetType.Property] = {
                repo: await PropertiesRepo.newRepo(
                  this.refs.currentRefs,
                  transaction
                ),
                aggregates: [],
              };
            }
            aggregatedData[AssetType.Property].aggregates.push(
              await PropertiesRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                insurance.id
              )
            );
            break;
          case AssetType.Art:
            if (!aggregatedData[AssetType.Art]) {
              aggregatedData[AssetType.Art] = {
                repo: await ArtsRepo.newRepo(
                  this.refs.currentRefs,
                  transaction
                ),
                aggregates: [],
              };
            }
            aggregatedData[AssetType.Art].aggregates.push(
              await ArtsRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                insurance.id
              )
            );
            break;
          case AssetType.OtherCollectables:
          case AssetType.Belonging:
            if (!aggregatedData[targetType]) {
              aggregatedData[targetType] = {
                repo: await BelongingsRepo.newRepo(
                  this.refs.currentRefs,
                  transaction,
                  targetType
                ),
                aggregates: [],
              };
            }
            aggregatedData[targetType]!.aggregates.push(
              await BelongingsRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                targetType,
                insurance.id
              )
            );
            break;
        }
      }
    }
    if (data.removeAssetInsured) {
      for (const { targetId, targetType } of data.removeAssetInsured) {
        switch (targetType) {
          case AssetType.Property:
            if (!aggregatedData[AssetType.Property]) {
              aggregatedData[AssetType.Property] = {
                repo: await PropertiesRepo.newRepo(
                  this.refs.currentRefs,
                  transaction
                ),
                aggregates: [],
              };
            }
            aggregatedData[AssetType.Property].aggregates.push(
              await PropertiesRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                insurance.id,
                false
              )
            );
            break;
          case AssetType.Art:
            if (!aggregatedData[AssetType.Art]) {
              aggregatedData[AssetType.Art] = {
                repo: await ArtsRepo.newRepo(
                  this.refs.currentRefs,
                  transaction
                ),
                aggregates: [],
              };
            }
            aggregatedData[AssetType.Art].aggregates.push(
              await ArtsRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                insurance.id,
                false
              )
            );
            break;
          case AssetType.OtherCollectables:
          case AssetType.Belonging:
            if (!aggregatedData[targetType]) {
              aggregatedData[targetType] = {
                repo: await BelongingsRepo.newRepo(
                  this.refs.currentRefs,
                  transaction,
                  targetType
                ),
                aggregates: [],
              };
            }
            aggregatedData[targetType]!.aggregates.push(
              await BelongingsRepo.newArAndUpdateInsurance(
                this.refs.currentRefs,
                transaction,
                this.refs.selfRefs.userId,
                targetId,
                targetType,
                insurance.id,
                false
              )
            );
            break;
        }
      }
    }
    // * writes
    if (data.addedGroupIds && groupUpdater) {
      for (const groupId of data.addedGroupIds) {
        groupUpdater.addOneItemToGroup(
          transaction,
          groupId,
          insurance.id,
          AssetType.Insurance,
          insurance.subtype
        );
      }
    }
    if (data.removedGroupIds && groupUpdater) {
      for (const groupId of data.removedGroupIds) {
        groupUpdater.deleteOneItemFromGroup(transaction, groupId, insurance.id);
      }
    }
    for (const { aggregates, repo } of Object.values(aggregatedData)) {
      aggregates.forEach((aggregate) => {
        const events = aggregate.applyAllChanges();
        repo.manualCommit(aggregate, events);
      });
    }
  }

  //#NOTE not ordered currently
  async getAll(): Promise<Insurance[]> {
    const collectionRef =
      this.refs.currentRefs.getAssetCollectionRef<Insurance.Encrypted>(
        AssetType.Insurance
      );
    const assets = await Promise.all(
      (
        await CoreFirestore.getDocsFromCollection(
          collectionRef,
          CoreFirestore.where("ownerId", "==", this.refs.currentRefs.userId)
        ).then(getQueriedData)
      ).map((v) => Insurance.decryptAndConvertDate(v, this.Encryption.current))
    );
    return assets.sort((a, b) => b.updateAt.getTime() - a.updateAt.getTime());
  }

  async getByIds(ids: string[]): Promise<Insurance[]> {
    const result = await getAssetsByIds<Insurance.Encrypted>(
      this.refs.currentRefs,
      AssetType.Insurance,
      ids
    );
    return Promise.all(
      result.map((v) =>
        Insurance.decryptAndConvertDate(v, this.Encryption.current)
      )
    );
  }

  async getById(id: string): Promise<Insurance> {
    const docRef = this.refs.currentRefs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      id
    );
    return await CoreFirestore.getDoc(docRef)
      .then(checkAndGetData)
      .then((v) => Insurance.decryptAndConvertDate(v, this.Encryption.current));
  }

  async add(req: Insurance.CreateFields) {
    const newDocRef = this.refs.currentRefs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      req.id
    );
    Insurance.checkFormat(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      await transaction.get(newDocRef).then(checkDuplicated);

      const repo = await InsuranceRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const insurance = Insurance.fromCreate(req, this.refs.currentRefs.userId);
      Insurance.validateEncryptedPart(insurance, true);
      const ar = Insurance.newAggregateRoot(Insurance.defaultStateValue());
      const encrypted = await Insurance.encrypt(
        insurance,
        this.Encryption.current
      );
      ar.handle(Command.createAsset(this.refs.selfRefs.userId, encrypted));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async update(req: Insurance) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      req.id
    );
    Insurance.checkFormat(req);

    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Insurance.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Insurance.handleOutDated();

      const currentData = await Insurance.decryptAndConvertDate(
        currentUndecrypted,
        this.Encryption.current
      );
      const repo = await InsuranceRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );

      const {
        updates: insuranceUpdate,
        metadata: {
          addedToGroup,
          removedFromGroup,
          addedToInsured,
          removedFromInsured,
        },
      } = Insurance.intoUpdate(currentData, req);
      const encryptedFieldsUpdated = Insurance.encryptedKeysArray.reduce(
        (result, key) => result || Object.keys(insuranceUpdate).includes(key),
        false
      );

      const encryptedUpdate: UpdateObject<Insurance.Encrypted> =
        Insurance.removeEncryptedFields(insuranceUpdate);
      let encryptionPart: Optional<Insurance.EncryptedPart> = undefined;
      if (encryptedFieldsUpdated) {
        encryptionPart = {};
        if (insuranceUpdate.notes !== null) {
          encryptionPart.notes = insuranceUpdate.notes || currentData.notes;
        }
        if (insuranceUpdate.policyNumber !== null) {
          encryptionPart.policyNumber =
            insuranceUpdate.policyNumber || currentData.policyNumber;
        }
      }

      const newEncrypted = await Insurance.encryptUpdate(
        encryptionPart,
        insuranceUpdate.rider,
        insuranceUpdate.attachments,
        this.Encryption.current
      );
      encryptedUpdate[EncryptionFieldKey] = newEncrypted[EncryptionFieldKey];
      encryptedUpdate.rider = newEncrypted.rider;
      encryptedUpdate.attachments = newEncrypted.attachments;

      const ar = Insurance.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          this.refs.selfRefs.userId,
          encryptedUpdate,
          addedToGroup,
          removedFromGroup,
          undefined,
          undefined,
          undefined,
          undefined,
          addedToInsured,
          removedFromInsured
        )
      );
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }

  async delete(id: string) {
    const docRef = this.refs.currentRefs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Insurance.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Insurance.handleOutDated();
      const repo = await InsuranceRepo.newRepo(
        this.refs.currentRefs,
        transaction
      );
      const ar = Insurance.newAggregateRoot(currentData);
      ar.handle(Command.deleteAsset(this.refs.selfRefs.userId));
      const events = ar.applyAllChanges();
      await this.handleRelatedAggregates(transaction, ar);
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

export namespace InsuranceRepo {
  export async function newRepo(
    refs: Refs,
    transaction: Transaction
  ): Promise<Repo<Insurance.Encrypted, Command, Event>> {
    return newAggregateRepo(
      transaction,
      refs.userId,
      AssetType.Insurance,
      new AssetRelationStateWriter(
        refs.getAssetCollectionRef(AssetType.Insurance),
        refs.Relations
      )
    );
  }

  export async function newArAndUpdateInsured(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    insuranceId: string,
    assetId: string,
    assetType: InsuredAssetType,
    isCreate: boolean = true
  ): Promise<AggregateRoot<Insurance.Encrypted, Command, Event>> {
    const docRef = refs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      insuranceId
    );
    const currentUndecrypted = await transaction
      .get(docRef)
      .then(checkAndGetData);
    const result = Insurance.assureVersion(currentUndecrypted);
    if (result === TypeResult.DataOutDated) Insurance.handleOutDated();
    const ar = Insurance.newAggregateRoot(currentUndecrypted);
    ar.handle(
      isCreate
        ? Command.addInsured(executerId, [
            {
              targetId: assetId,
              targetType: assetType,
            },
          ])
        : Command.removeInsured(executerId, [
            {
              targetId: assetId,
              targetType: assetType,
            },
          ])
    );
    return ar;
  }

  export async function newArAndUpdateGroup(
    refs: Refs,
    transaction: Transaction,
    executerId: string,
    insuranceId: string,
    groupId: string,
    isAdd: boolean = true
  ) {
    const docRef = refs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      insuranceId
    );
    const currentData = await transaction.get(docRef).then(checkAndGetData);
    const result = Insurance.assureVersion(currentData);
    if (result === TypeResult.DataOutDated) Insurance.handleOutDated();
    const ar = Insurance.newAggregateRoot(currentData);
    const currentGroupIds = ar.state().groupIds;
    return buildUpdateGroupCommand(
      ar,
      Command.updateAsset,
      executerId,
      currentGroupIds,
      groupId,
      isAdd
    );
  }

  export async function removeGroup(
    refs: FullRefs,
    id: string,
    groupId: string
  ) {
    const docRef = refs.currentRefs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentData = await transaction.get(docRef).then(checkAndGetData);
      const result = Insurance.assureVersion(currentData);
      if (result === TypeResult.DataOutDated) Insurance.handleOutDated();

      const repo = await newRepo(refs.currentRefs, transaction);
      const ar = Insurance.newAggregateRoot(currentData);
      const currentGroupIds = ar.state().groupIds;
      const arToCommit = buildUpdateGroupCommand(
        ar,
        Command.updateAsset,
        refs.selfRefs.userId,
        currentGroupIds,
        groupId,
        false
      );

      const groupUpdater = new GroupUpdater(refs.currentRefs, [groupId]);
      await groupUpdater.read(transaction);

      //commit
      if (arToCommit) {
        const events = arToCommit.applyAllChanges();
        repo.manualCommit(arToCommit, events);
      }

      // delete from group
      groupUpdater.deleteOneItemFromGroup(transaction, groupId, id);
    });
  }

  export async function removeContactRelation(
    refs: Refs,
    executerId: string,
    id: string,
    contactId: string,
    roles: RoleToAsset[]
  ) {
    const docRef = refs.getAssetDocRef<Insurance.Encrypted>(
      AssetType.Insurance,
      id
    );
    await CoreFirestore.runTransaction(async (transaction) => {
      const currentUndecrypted = await transaction
        .get(docRef)
        .then(checkAndGetData);
      const result = Insurance.assureVersion(currentUndecrypted);
      if (result === TypeResult.DataOutDated) Insurance.handleOutDated();
      const encryptedUpdate: UpdateObject<Insurance.Encrypted> = {};
      const removedFromInsured: Insured[] = [];
      roles.map((role) => {
        switch (role) {
          case RoleToAsset.Broker:
            if (contactId !== currentUndecrypted.brokerId)
              throw new DataPoisoned("brokerId mismatch");
            encryptedUpdate.brokerId = null;
            break;
          case RoleToAsset.Specialist:
            if (contactId !== currentUndecrypted.specialistId)
              throw new DataPoisoned("specialistId mismatch");
            encryptedUpdate.specialistId = null;
            break;
          case RoleToAsset.Insured:
            if (!currentUndecrypted.insured)
              throw new DataPoisoned("insured not found");
            const updateInsured = currentUndecrypted.insured.filter(
              (s) => s.targetId !== contactId
            );
            if (updateInsured.length === currentUndecrypted.insured.length)
              throw new DataPoisoned("insuredId not found");
            encryptedUpdate.insured = updateInsured;
            removedFromInsured.push({
              targetId: contactId,
              targetType: "Person",
            });
            break;
          case RoleToAsset.Beneficiary: {
            if (!currentUndecrypted.beneficiary)
              throw new DataPoisoned("beneficiary not found");
            const updateBeneficiary = currentUndecrypted.beneficiary.filter(
              (s) => s.contactId !== contactId
            );
            if (
              updateBeneficiary.length === currentUndecrypted.beneficiary.length
            )
              throw new DataPoisoned("beneficiaryId not found");
            encryptedUpdate.beneficiary = updateBeneficiary;
            break;
          }
        }
      });

      const repo = await newRepo(refs, transaction);
      const ar = Insurance.newAggregateRoot(currentUndecrypted);
      ar.handle(
        Command.updateAsset(
          executerId,
          encryptedUpdate,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          removedFromInsured.length > 0 ? removedFromInsured : undefined
        )
      );
      const events = ar.applyAllChanges();
      //commit
      repo.manualCommit(ar, events);
    });
  }
}

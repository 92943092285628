import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%239B1945%22%2F%3E%3Cpath%20d%3D%22M5.01%2029.689v-8.532h2.52c.754%200%201.423.168%202.007.504a3.436%203.436%200%200%201%201.356%201.43c.32.617.48%201.324.48%202.121v.428c0%20.808-.16%201.52-.483%202.133a3.395%203.395%200%200%201-1.377%201.418c-.595.332-1.278.498-2.048.498H5.01Zm1.483-7.336v6.152h.967c.777%200%201.374-.243%201.79-.73.416-.486.628-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.12-.403-.49-.987-.733-1.752-.733H6.493Zm9.293%201.886%201.793-3.082h1.71l-2.577%204.23%202.636%204.302H17.62l-1.834-3.13-1.84%203.13h-1.723l2.643-4.301-2.584-4.23h1.71l1.794%203.081Zm9.574%201.834h-3.434v3.616h-1.482v-8.532h5.42v1.196h-3.938v2.537h3.434v1.183Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%237B0B31%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileDxfIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#9B1945'}
      />
      <path
        d={
          'M5.01 29.689v-8.532h2.52c.754 0 1.423.168 2.007.504a3.436 3.436 0 0 1 1.356 1.43c.32.617.48 1.324.48 2.121v.428c0 .808-.16 1.52-.483 2.133a3.395 3.395 0 0 1-1.377 1.418c-.595.332-1.278.498-2.048.498H5.01Zm1.483-7.336v6.152h.967c.777 0 1.374-.243 1.79-.73.416-.486.628-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.12-.403-.49-.987-.733-1.752-.733H6.493Zm9.293 1.886 1.793-3.082h1.71l-2.577 4.23 2.636 4.302H17.62l-1.834-3.13-1.84 3.13h-1.723l2.643-4.301-2.584-4.23h1.71l1.794 3.081Zm9.574 1.834h-3.434v3.616h-1.482v-8.532h5.42v1.196h-3.938v2.537h3.434v1.183Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#7B0B31'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import { mutate } from 'swr'
import { unstable_serialize } from 'swr/infinite'

import { Database } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import { SoldInfo } from 'core/remodel/types/actions/soldInfo'
import { Art } from 'core/remodel/types/arts'
import { Belonging, OtherCollectable } from 'core/remodel/types/belongings'
import { Amount, AssetType, AssetV2, AssetV2WithWineBottle, AttachmentKind, Currency } from 'core/remodel/types/common'
import { OtherRoomType, OwnerDetail, RentalDetail, type OtherRoom, type Property } from 'core/remodel/types/properties'
import type { LocationItem } from 'core/remodel/types/relations'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { RemovalReason, Wine, WinePurchase } from 'core/remodel/types/wineAndSprits'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import { LocatableAssets, PropertyAssetsItem } from '@/types/common'
import { convertCurrency } from '@/utils/currency'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isOwner } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type { OfferValues, PropertyValues, RentOutValues, SoldValues, ValuationValues } from '@/components/form'

export const propertyQuery = {
  options: 'property-options',
  summary: 'property-summary',
  list: 'property-list',
  info: 'property-info',
  infoWithItems: 'property-info-with-items',
  assets: 'property-assets',
  assetsQuery: 'property-assets-query',
  assetsFilter: 'property-assets-filter',
  filter: 'property-filter',
  rooms: 'property-rooms',
  allRooms: 'property-allRooms',
  positions: 'property-positions',
  action: 'property-action',
  soldInfo: 'property-sold-info',
  locatableAssets: 'property-locatable-assets',
  archive: 'archive',
  assetsBreakdown: 'assets-breakdown',
  assetsBreakdowns: 'assets-breakdowns'
} as const

const mutateProperty = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(propertyQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.Property, id)))
}

export function fetchPropertyOptions(database: Database) {
  return async ([_key]: [typeof propertyQuery.options]) => {
    const isLocatable = ({ archived, closedWith }: Property) => !archived && !closedWith
    const list = await database.property.getAllRaw()
    return list.filter(isLocatable).map((property) => ({ label: property.name, value: property.id }))
  }
}

export function fetchPropertySummary(database: Database) {
  return async ([_key, currency]: [typeof propertyQuery.summary, Currency?]) => {
    const [summary, allProperties, { Property: liabilities }, { Property: breakdownLiabilities }] = await Promise.all([
      database.property.getSyncedSummary(currency),
      database.property.getAll(),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.Property], currency),
      database.cashAndBanking.getAssetLiabilities([AssetType.Property], currency)
    ])
    // 1. preprocess summary
    const { assets, netValue, locations } = summary
    const properties = allProperties.filter(({ id }) => locations.some((loc) => loc.id === id))
    const newNetValue = liabilities ? { ...netValue, value: assets.value - liabilities.value } : netValue
    const updatedSummary = { ...summary, liabilities, netValue: newNetValue }

    // 2. preprocess breakdownLiabilities
    const breakdownLiabilitiesKeys = new Set(Object.keys(breakdownLiabilities ?? {}))
    const updateBreakdownLiabilities =
      breakdownLiabilities &&
      properties
        .filter(({ id }) => breakdownLiabilitiesKeys.has(id))
        .map(({ name, id }) => ({ name, value: breakdownLiabilities[id].value }))

    // 3. return no currency converted state if no currency passed in
    if (!currency) return { summary: updatedSummary, properties, breakdownLiabilities: updateBreakdownLiabilities }

    // 4. convert currency
    const targetExRateMap = currency && (await database.ExRate.getToTargetExchangeRates(currency))
    const propertiesWithCurrency = targetExRateMap
      ? convertCurrency(properties, targetExRateMap.rates, currency)
      : properties
    return {
      summary: updatedSummary,
      properties: propertiesWithCurrency,
      breakdownLiabilities: updateBreakdownLiabilities
    }
  }
}

export function fetchProperties(database: Database) {
  return async ([_key, query]: [typeof propertyQuery.list, Record<string, any>]) => {
    return await database.getAssetsWithQueryV2<Property>(AssetType.Property, undefined, query)
  }
}

export function fetchPropertyInfo(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.info, string]) => {
    const property = await database.property.getById(id)
    if (isOwner(property.ownershipType, property.detail)) {
      return property
    } else {
      const landlordId = property.detail.landlordId ?? ''
      if (!landlordId) return property
      try {
        // HACK: check if the contact exists
        await database.Account.getContact(landlordId)
      } catch (e) {
        console.log(e)
        delete property.detail.landlordId
      } finally {
        return property
      }
    }
  }
}

export function fetchPropertyAssetsBreakdown(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.assetsBreakdown, string]) => {
    return await database.property.getAssetsBreakdown(id)
  }
}

export function fetchPropertyAssetsBreakdowns(database: Database) {
  return async ([_key, ids]: [typeof propertyQuery.assetsBreakdowns, string[]]) => {
    if (!ids.length) return
    const promises = ids.map(async (id) => ({ id, breakdown: await database.property.getAssetsBreakdown(id) }))
    return (await Promise.allSettled(promises)).filter(isFulfilled).map(({ value }) => value)
  }
}

export function fetchPropertyInfoWithItems(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.infoWithItems, string]) => {
    const property = await database.property.getByIdWithItems(id)
    if (isOwner(property.ownershipType, property.detail)) {
      return property
    } else {
      const landlordId = property.detail.landlordId ?? ''
      if (!landlordId) return property
      try {
        // HACK: check if the contact exists
        await database.Account.getContact(landlordId)
      } catch (e) {
        console.log(e)
        delete property.detail.landlordId
      } finally {
        return property
      }
    }
  }
}

export function fetchPropertyAssetsWithId(database: Database) {
  return async ([_key, keyword, propertyId, query]: [
    typeof propertyQuery.assetsQuery,
    string,
    string,
    Record<string, any>
  ]) => {
    const assets = await database.getPropertyAssets<AssetV2WithWineBottle>(keyword, propertyId, query)
    const assetsInfos = assets.list.map((asset) => {
      switch (asset.assetType) {
        case AssetType.Art:
        case AssetType.OtherCollectables:
        case AssetType.Belonging: {
          const { id, name, assetType, subtype, mainImage, value, updateAt } = asset
          const baseValue = database.ExRate.amountToBase(value)
          return { id, name, assetType, subtype, mainImage, value: baseValue, updateAt }
        }
        case AssetType.WineAndSpirits: {
          const { id, wineId, purchaseId, name, assetType, subtype, mainImage, value, updateAt } = asset
          const bottlePriceBaseValue = database.ExRate.amountToBase(value)
          return { id, wineId, purchaseId, name, assetType, subtype, mainImage, value: bottlePriceBaseValue, updateAt }
        }
        default: {
          throw new Error('Unknown asset type')
        }
      }
    })
    return { totalCount: assets.totalCount, list: assetsInfos }
  }
}

export function fetchPropertyAssetsFilter(database: Database) {
  return async ([_key, propertyId, keyword]: [typeof propertyQuery.assetsFilter, string, string]) => {
    const { list } = await database.getPropertyAssets<AssetV2WithWineBottle>(keyword, propertyId)
    const assetType = Array.from(new Set(list.map((asset) => asset.assetType)))
    const roomOptions = await database.property.getAssetsRoomFilterInfo(propertyId, keyword)
    const room = roomOptions.map((option) => ({ label: option.name, value: option.id }))

    return { assetType, room }
  }
}

export function fetchPropertyAssets(database: Database) {
  return async ([_key, items]: [typeof propertyQuery.assets, LocationItem[]]) => {
    const assets = await Promise.all(
      items.map(async ({ assetId, assetType }): Promise<PropertyAssetsItem> => {
        switch (assetType) {
          case AssetType.Art: {
            const art = await database.art.getById(assetId)
            const { name, assetType, subtype, mainImage, value, updateAt } = art
            return { id: assetId, name, assetType, subtype, mainImage, value, updateAt }
          }
          case AssetType.WineAndSpirits: {
            const wine = await database.wine.getWineById(assetId)
            const { name, assetType, subtype, mainImage, value, updateAt } = wine
            const mockValue = { currency: Currency.USD, value: value.USD ?? 0 }
            return { id: assetId, name, assetType, subtype, mainImage, value: mockValue, updateAt }
          }
          case AssetType.OtherCollectables: {
            const other = await database.otherCollectable.getById(assetId)
            const { name, assetType, subtype, mainImage, value, updateAt } = other
            return { id: assetId, name, assetType, subtype, mainImage, value, updateAt }
          }
          case AssetType.Belonging: {
            const belonging = await database.belonging.getById(assetId)
            const { name, assetType, subtype, mainImage, value, updateAt } = belonging
            return { id: assetId, name, assetType, subtype, mainImage, value, updateAt }
          }
          default: {
            throw new Error('Asset type not expected')
          }
        }
      })
    )
    return assets
  }
}

export function fetchLocatableAssets(database: Database) {
  return async ([_key]: [typeof propertyQuery.locatableAssets]) => {
    const getQuery = (limit: number): Record<string, any> => ({ limit })

    const artsCount = await database.getAssetsWithQueryCounts(AssetType.Art, undefined)
    const allArts = await database.getAssetsWithQueryV2<Art>(AssetType.Art, undefined, getQuery(artsCount))
    const othersCount = await database.getAssetsWithQueryCounts(AssetType.OtherCollectables, undefined)
    const allOthers = await database.getAssetsWithQueryV2<OtherCollectable>(
      AssetType.OtherCollectables,
      undefined,
      getQuery(othersCount)
    )
    const belongingsCount = await database.getAssetsWithQueryCounts(AssetType.Belonging, undefined)
    const allBelongings = await database.getAssetsWithQueryV2<Belonging>(
      AssetType.Belonging,
      undefined,
      getQuery(belongingsCount)
    )
    const locatables = [...allArts.list, ...allBelongings.list, ...allOthers.list].map(
      (asset): LocatableAssets => ({
        assetId: asset.id,
        assetType: asset.assetType,
        subtype: asset.subtype,
        name: asset.name,
        value: 'currency' in asset.value ? (asset.value as Amount) : { currency: Currency.USD, value: 0 },
        updateAt: asset.updateAt,
        mainImage: asset.mainImage,
        sold: 'closedWith' in asset ? !!asset.closedWith : undefined
      })
    )

    const wines = await database.getAssetsWithQueryV2<Wine>(AssetType.WineAndSpirits, undefined)
    const allWines = await database.getAssetsWithQueryV2<Wine>(
      AssetType.WineAndSpirits,
      undefined,
      getQuery(wines.totalCount)
    )
    const purchases = allWines.list.flatMap((wine) =>
      (wine.purchases as WinePurchase[]).map((purchase) => ({
        subtype: wine.subtype,
        name: wine.name,
        value: purchase.valuePerBottle,
        mainImage: wine.mainImage,
        wineId: wine.id,
        purchaseId: purchase.id
      }))
    )
    const purchaseIds = purchases.map(({ purchaseId }) => purchaseId)
    // get bottle ids
    const purchasesWithBottles = await database.wine.getWinePurchasesByIds(purchaseIds)
    const bottles = purchasesWithBottles.flatMap((purchase, index) =>
      purchase.bottles
        .filter((bottle) => bottle.removal?.reason !== RemovalReason.DeleteAndExclude)
        .map(
          (bottle): LocatableAssets => ({
            ...purchases[index],
            assetType: AssetType.WineAndSpirits,
            updateAt: purchase.updateAt,
            assetId: bottle.bottleId
          })
        )
    )

    return [...locatables, ...bottles]
  }
}

export function fetchPropertyFilter(database: Database) {
  return async ([_key]: [typeof propertyQuery.filter]) => {
    const { subtype, value, purchaseAt } = await database.getFilterInfo(AssetType.Property)
    return { subtype, value, purchaseAt }
  }
}

// HACK
export function fetchPropertyRoomOptions(database: Database) {
  return async ([_key, assetId]: [typeof propertyQuery.rooms, string]) => {
    const property = await database.property.getById(assetId)
    if (!property.configuration) return []
    const { bedroom, bathroom, otherRoom, carPark } = property.configuration
    const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
    return rooms.map((room) => ({ label: room.name, value: room.id }))
  }
}

export function fetchAllRoomOptions(database: Database) {
  return async ([_key, locationIds]: [typeof propertyQuery.allRooms, string[]]) => {
    const contactResponses = await Promise.allSettled(locationIds.map((id) => database.Account.getContact(id)))
    const contactLocations = contactResponses.filter(isFulfilled).map(({ value }) => value)
    const contactRooms = contactLocations.flatMap((contact) => {
      if (!contact.room) return []
      const rooms = contact.room
      return rooms.map((room) => ({ label: room.name, value: room.id }))
    })
    const propertyResponses = await Promise.allSettled(locationIds.map((id) => database.property.getById(id)))
    const propertyLocations = propertyResponses.filter(isFulfilled).map(({ value }) => value)
    const propertyRooms = propertyLocations.flatMap((property) => {
      if (!property.configuration) return []
      const { bedroom, bathroom, otherRoom, carPark } = property.configuration
      const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
      return rooms.map((room) => ({ label: room.name, value: room.id }))
    })

    return [...contactRooms, ...propertyRooms]
  }
}

// HACK
export function fetchPropertyPositionOptions(database: Database) {
  return async ([_key, assetId, roomId]: [typeof propertyQuery.positions, string, string | undefined]) => {
    const property = await database.property.getById(assetId)
    if (!property.configuration) return []
    const { bedroom, bathroom, otherRoom, carPark } = property.configuration!
    const targetRoom = [bedroom, bathroom, otherRoom, carPark].flat().find((item) => item.id === roomId)
    if (!targetRoom) return []
    const positions = targetRoom.position.map((item) => ({ label: item, value: item }))
    return positions
  }
}

const cleanupFormData = (data: PropertyValues): PropertyValues => {
  const { detail, ownershipType, ...rest } = data
  const isOwnerDetail = isOwner(ownershipType, detail)

  const cleanedDetail: RentalDetail | OwnerDetail = isOwnerDetail
    ? {
        acquisition: detail.acquisition,
        ownership: detail.ownership,
        beneficiary: detail.beneficiary
      }
    : {
        agreementType: detail.agreementType,
        monthlyRental: detail.monthlyRental,
        securityDeposit: detail.securityDeposit,
        term: detail.term,
        landlordId: detail.landlordId,
        otherFees: detail.otherFees,
        notes: detail.notes
      }

  return { ...rest, detail: cleanedDetail, ownershipType }
}

export async function addProperty(database: Database, id: string, data: PropertyValues) {
  const cleanData = cleanupFormData(data)
  await database.property.add({ ...cleanData, id })
  await delay()
  mutateProperty()
}

export async function updateProperty(database: Database, id: string, data: PropertyValues) {
  const cleanData = cleanupFormData(data)

  const { attachments: newAttach = [] } = cleanData
  const { attachments: oldAttach = [] } = await database.property.getById(id)
  await database.property.update({ ...cleanData, id })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          if (contentType.startsWith('image/')) {
            // remove thumbnails
            Promise.all(
              Object.values(ImageSizes).map((size) =>
                database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
              )
            )
          }
          database.Attachments.delete(id, key)
        } catch (error) {
          console.log(error)
        }
      })
  )
  await delay()
  mutateProperty(id)
}

export async function deleteProperty(database: Database, id: string) {
  const task = await database.property.delete(id)
  await task.run()
  // HACK, it need more time to wait for the task to be done
  await delay(3000)
  mutateProperty(id)
}

// HACK
export async function addPropertyRoom(database: Database, id: string, roomName: string) {
  const property = await database.property.getById(id)
  if (!property.configuration) throw new Error('Property configuration not found')
  const newRoomId = database.genAssetId()
  const newRoom: OtherRoom = {
    id: newRoomId,
    name: roomName,
    otherRoomType: OtherRoomType.OtherRoom,
    position: []
  }
  const newValue: Property = {
    ...property,
    configuration: {
      ...property.configuration!,
      otherRoom: [...property.configuration!.otherRoom, newRoom]
    }
  }
  await database.property.update(newValue)
  await delay()
  mutateProperty(id)
  return newRoomId
}

// HACK
export async function addPropertyPosition(database: Database, id: string, roomId: string, positionName: string) {
  const property = await database.property.getById(id)
  const { bedroom, bathroom, otherRoom, carPark } = property.configuration!
  const rooms = { bedroom, bathroom, otherRoom, carPark }
  const targetRoomType = (Object.keys(rooms) as (keyof typeof rooms)[]).find((key) =>
    rooms[key].find((item) => item.id === roomId)
  )
  if (!targetRoomType) throw new Error('Room not found')
  const targetRooms = rooms[targetRoomType].map((item) =>
    item.id === roomId ? { ...item, position: [...item.position, positionName] } : item
  )
  const newValue: Property = {
    ...property,
    configuration: {
      ...property.configuration!,
      [targetRoomType]: targetRooms
    }
  }
  await database.property.update(newValue)
  await delay()
  mutateProperty(id)
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const property = await database.property.getById(id)
  const oldAttach = property.attachments ?? []
  const newAttach = [
    ...images,
    ...(property.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]
  await database.property.update({ ...property, attachments: newAttach, mainImage: images[0]?.key })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        // remove thumbnails
        Promise.all(
          Object.values(ImageSizes).map((size) =>
            database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
          )
        )
        database.Attachments.delete(id, key).catch((e) => console.log(e))
      })
  )
  await delay()
  mutateProperty(id)
}

// actions
export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof propertyQuery.action, string]) => {
    const raw = await database.property.getAllActions(id)
    const actions: ActionDetails[] = []
    for (const action of raw) {
      switch (action.actionType) {
        case ActionType.AddValuation:
          try {
            if (!action.valuedById) {
              actions.push(action)
              break
            }
            const valueBy = await database.Account.getContact(action.valuedById)
            const valueByName = getFullName(valueBy)
            actions.push({ ...action, valueByName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, valuedById: '', valueByName: 'DeletedContact' })
          }
          break
        case ActionType.AddOffer:
          try {
            const buyer = await database.Account.getContact(action.buyer)
            const buyerName = getFullName(buyer)
            actions.push({ ...action, buyerName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, buyer: '', buyerName: 'DeletedContact' })
          }
          break
        case ActionType.RentOut:
          const tenantId = action.tenantId
          try {
            const tenantName = getFullName(await database.Account.getContact(tenantId))
            actions.push({ ...action, tenantId, tenantName })
          } catch (error) {
            console.error('not able the get the nonexistent ContactId:', tenantId, error) // FIXME: handle when remove Contact
            actions.push({ ...action, tenantId: 'DeletedContact' }) // HACK
          }

          break
        default:
          actions.push(action)
      }
    }
    return actions
  }
}

export function fetchSoldInfo(database: Database) {
  return async ([_key, id, actionId]: [typeof propertyQuery.soldInfo, string, string]) => {
    return (await database.property.getActionById(id, actionId)) as SoldInfo
  }
}

export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.property.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.property.updateValuation(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteValuation(database: Database, id: string, valuationId: string) {
  await database.property.deleteValuation(id, valuationId)
  await delay()
  mutateProperty(id)
}

export async function addOffer(database: Database, id: string, data: OfferValues) {
  const actionId = database.genAssetId()
  await database.property.addOffer(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateOffer(database: Database, id: string, data: OfferValues) {
  await database.property.updateOffer(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteOffer(database: Database, id: string, actionId: string) {
  await database.property.deleteOffer(id, actionId)
  await delay()
  mutateProperty(id)
}

export async function rentOut(database: Database, id: string, data: RentOutValues) {
  const actionId = database.genAssetId()
  await database.property.rentOut(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateRentInfo(database: Database, id: string, data: RentOutValues) {
  await database.property.updateRentInfo(id, data)
  // TODO: update the attachment related to this action
  await delay()
  mutateProperty(id)
}

export async function deleteRentInfo(database: Database, id: string, actionId: string) {
  // TODO: update the attachment related to this action
  await database.property.deleteRentInfo(id, actionId)
  await delay()
  mutateProperty(id)
}

export async function markAsSold(database: Database, id: string, data: SoldValues) {
  const actionId = database.genAssetId()
  await database.property.markAsSold(id, { ...data, id: actionId })
  await delay()
  mutateProperty(id)
}

export async function updateSoldInfo(database: Database, id: string, data: SoldValues) {
  await database.property.updateSoldInfo(id, data)
  await delay()
  mutateProperty(id)
}

export async function deleteSoldInfo(database: Database, id: string, soldId: string) {
  await database.property.deleteSoldInfo(id, soldId)
  await delay()
  mutateProperty(id)
}

export async function archive(database: Database, executerId: string, relocateTo?: LocationInfo) {
  const archive = await database.property.archive(executerId, relocateTo)
  await archive.run()
  await delay()
  mutateProperty(executerId)
}

export async function restoreArchived(database: Database, executerId: string) {
  await database.property.restoreArchived(executerId)
  await delay()
  mutateProperty(executerId)
}

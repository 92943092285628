import { addYears, endOfMonth, format, startOfDay, startOfMonth, subMonths } from 'date-fns'
import { mutate, unstable_serialize } from 'swr'

import type { Database } from 'core/remodel/database'
import { ActionType } from 'core/remodel/types/actions/base'
import type { SoldInfo } from 'core/remodel/types/actions/soldInfo'
import type { Art } from 'core/remodel/types/arts'
import { AssetType, AttachmentKind, Currency, LocationType } from 'core/remodel/types/common'
import type { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { getKeyWithId } from '@/api/ActivityService'
import { AssociatedFile } from '@/api/CommonService'
import type { SummaryStatistic } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { isFulfilled, isProperty } from '@/utils/predicate'
import type { ActionDetails } from '@/components/Actions'
import type {
  ArtValues,
  ConsignmentValues,
  ExhibitionValues,
  LiteratureValues,
  OfferValues,
  SoldValues,
  ValuationValues
} from '@/components/form'

export const artQuery = {
  summary: 'art-summary',
  list: 'art-list',
  info: 'art-info',
  filter: 'art-filter',
  action: 'art-action',
  soldInfo: 'art-sold-info',
  artistList: 'art-artist-list',
  artistInfo: 'art-artist-info',
  artistExhibitions: 'art-artist-exhibitions',
  allAssetsArtists: 'art-all-assets-artists'
} as const

const mutateArt = (id?: string) => {
  mutate((key) => Array.isArray(key) && Object.values(artQuery).includes(key[0]))
  if (id) mutate(unstable_serialize(getKeyWithId(AssetType.Art, id)))
}

export function fetchArtSummary(database: Database) {
  return async ([_key, currency]: [typeof artQuery.summary, Currency?]) => {
    const [summary, typeLiabilities, filterInfo] = await Promise.all([
      database.art.getSyncedSummary(currency),
      database.cashAndBanking.getAssetTypeLiabilities([AssetType.Art], currency),
      database.getFilterInfo(AssetType.Art)
    ])
    const { Art: liabilities } = typeLiabilities
    const { subtype, brand } = filterInfo

    const lastMonth = subMonths(new Date(), 1)
    const statistics: SummaryStatistic[] = [
      {
        key: 'Arts',
        value: summary.totalNumber,
        query: []
      },
      {
        key: 'Purchased',
        value: summary.purchasedLM,
        query: [
          ['purchaseDateLowerBound', startOfMonth(lastMonth).toISOString()],
          ['purchaseDateUpperBound', endOfMonth(lastMonth).toISOString()]
        ]
      },
      {
        key: 'Types',
        value: summary.typesNumber,
        query: subtype.map((item) => ['subtype', item])
      },
      {
        key: 'Artists',
        value: summary.artistNumber,
        query: brand.map((item) => ['brand', item])
      },
      {
        key: 'Wishlist',
        value: 0,
        // TODO art wishlist
        query: []
      }
    ].map(({ query, ...item }) => ({ ...item, queryString: new URLSearchParams(query).toString() }))

    if (!liabilities) return { ...summary, statistics }

    const netValue = { ...summary.netValue, value: summary.netValue.value - liabilities.value }

    return { ...summary, liabilities, netValue, statistics }
  }
}

export type ArtExtra = Art & {
  extraData?: {
    locationName: string
    roomName: string | null
  }
}

export function fetchArts(database: Database) {
  return async ([_key, query]: [typeof artQuery.list, Record<string, any>]) => {
    const { list: listRaw, totalCount } = await database.getAssetsWithQueryV2<Art>(AssetType.Art, undefined, query)

    // get extra data (location name, room name)
    const list = await Promise.all(
      listRaw.map(async (art): Promise<ArtExtra> => {
        try {
          const { locationType, locationId, roomId } = art.location
          let extraData: ArtExtra['extraData']
          switch (locationType) {
            case LocationType.MyProperty: {
              const property = await database.property.getById(locationId)
              const { bedroom = [], bathroom = [], otherRoom = [], carPark = [] } = property.configuration ?? {}
              const rooms = [bedroom, bathroom, otherRoom, carPark].flat()
              extraData = {
                locationName: property.name,
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
            case LocationType.Address:
            case LocationType.NewAddress: {
              const contact = await database.Account.getContact(locationId)
              const rooms = contact.room ?? []
              extraData = {
                locationName: getFullName(contact),
                roomName: roomId ? rooms.find(({ id }) => id === roomId)?.name ?? null : null
              }
              break
            }
          }
          return { ...art, extraData }
        } catch {
          return art
        }
      })
    )

    return { list, totalCount }
  }
}

export function fetchArtInfo(database: Database) {
  return async ([_key, id]: [typeof artQuery.info, string]) => {
    return await database.art.getById(id)
  }
}

export function fetchArtFilter(database: Database) {
  return async ([_key]: [typeof artQuery.filter]) => {
    const { subtype, value, locationId, artStyle, brand, purchaseAt } = await database.getFilterInfo(AssetType.Art)

    const responses = await Promise.allSettled([
      ...locationId.map((id) => database.property.getById(id)),
      ...locationId.map((id) => database.Account.getContact(id))
    ])
    const location = responses.filter(isFulfilled).map(({ value }) => ({
      label: isProperty(value) ? value.name : getFullName(value),
      value: value.id
    }))
    return { subtype, value, location, artStyle, brand, purchaseAt }
  }
}

export async function addArt(database: Database, id: string, data: ArtValues) {
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address
  await database.art.add({ ...data, id })
  await delay()
  mutateArt()
}

export async function updateArt(database: Database, id: string, data: ArtValues) {
  const { attachments: newAttach = [] } = data
  const { attachments: oldAttach = [] } = await database.art.getById(id)
  const isNewAddress = data.location.locationType === LocationType.NewAddress
  if (isNewAddress) data.location.locationType = LocationType.Address
  await database.art.update({ ...data, id })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        try {
          const { contentType = '' } = await database.Attachments.getMeta(id, key)
          if (contentType.startsWith('image/')) {
            // remove thumbnails
            Promise.all(
              Object.values(ImageSizes).map((size) =>
                database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
              )
            )
          }
          database.Attachments.delete(id, key).catch((e) => console.log(e))
        } catch (error) {
          console.log(error)
        }
      })
  )
  await delay()
  mutateArt(id)
}

export async function relocateArts(
  database: Database,
  ids: string[],
  newLocation: LocationInfo,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address
  for (const id of ids) {
    const art = await database.art.getById(id)
    const oldLocation = art.location
    if (oldLocation.locationId === newLocation.locationId || art.closedWith) continue

    await database.art.update({ ...art, location: newLocation, id })
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateArt()
}

export async function deleteArt(database: Database, id: string) {
  await database.art.delete(id)
  await delay()
  mutateArt(id)
}

export async function deleteArts(database: Database, ids: string[], callback?: (percentage: number) => void) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: ids.length }

  for (const id of ids) {
    await database.art.delete(id)
    progress = { current: progress.current + 1, total: progress.total }
    callback && callback(Math.round((progress.current / progress.total) * 100))
  }

  await delay()
  mutateArt()
}

export async function updateAssetImages(database: Database, id: string, images: AssociatedFile[]) {
  const art = await database.art.getById(id)
  const oldAttach = art.attachments ?? []
  const newAttach = [
    ...images,
    ...(art.attachments?.filter((attach) => attach.type !== AttachmentKind.AssetImage) ?? [])
  ]
  await database.art.update({ ...art, attachments: newAttach, mainImage: images[0]?.key })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(async ({ key }) => {
        // remove thumbnails
        Promise.all(
          Object.values(ImageSizes).map((size) =>
            database.Attachments.delete(id, `${key}_${size}`).catch((e) => console.log(e))
          )
        )
        database.Attachments.delete(id, key).catch((e) => console.log(e))
      })
  )
  await delay()
  mutateArt(id)
}

// actions
export function fetchActions(database: Database) {
  return async ([_key, id]: [typeof artQuery.action, string]) => {
    const raw = await database.art.getAllActions(id)
    const actions: ActionDetails[] = []
    for (const action of raw) {
      switch (action.actionType) {
        case ActionType.AddValuation:
          try {
            if (!action.valuedById) {
              actions.push(action)
              break
            }
            const valueBy = await database.Account.getContact(action.valuedById)
            const valueByName = getFullName(valueBy)
            actions.push({ ...action, valueByName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, valuedById: '', valueByName: 'DeletedContact' })
          }
          break
        case ActionType.AddOffer:
          try {
            const buyer = await database.Account.getContact(action.buyer)
            const buyerName = getFullName(buyer)
            actions.push({ ...action, buyerName })
          } catch (e) {
            console.log(e)
            actions.push({ ...action, buyer: '', buyerName: 'DeletedContact' })
          }
          break
        case ActionType.AddConsignment:
          try {
            // HACK: check if the contact exists
            await database.Account.getContact(action.consignee)
            actions.push(action)
          } catch (e) {
            console.log(e)
            actions.push({ ...action, consignee: '' })
          }
          break
        default:
          actions.push(action)
      }
    }
    return actions
  }
}

export function fetchSoldInfo(database: Database) {
  return async ([_key, id, actionId]: [typeof artQuery.soldInfo, string, string]) => {
    return (await database.art.getActionById(id, actionId)) as SoldInfo
  }
}

export async function addValuation(database: Database, id: string, data: ValuationValues) {
  const actionId = database.genAssetId()
  await database.art.addValuation(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateValuation(database: Database, id: string, data: ValuationValues) {
  await database.art.updateValuation(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteValuation(database: Database, id: string, actionId: string) {
  await database.art.deleteValuation(id, actionId)
  await delay()
  mutateArt(id)
}

export async function addOffer(database: Database, id: string, data: OfferValues) {
  const actionId = database.genAssetId()
  await database.art.addOffer(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateOffer(database: Database, id: string, data: OfferValues) {
  await database.art.updateOffer(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteOffer(database: Database, id: string, actionId: string) {
  await database.art.deleteOffer(id, actionId)
  await delay()
  mutateArt(id)
}

export async function addConsignment(database: Database, id: string, data: ConsignmentValues) {
  const actionId = database.genAssetId()
  await database.art.addConsignment(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateConsignment(database: Database, id: string, data: ConsignmentValues) {
  await database.art.updateConsignment(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteConsignment(database: Database, id: string, actionId: string) {
  await database.art.deleteConsignment(id, actionId)
  await delay()
  mutateArt(id)
}

export async function addExhibition(database: Database, id: string, data: ExhibitionValues) {
  const actionId = database.genAssetId()
  await database.art.addExhibition(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateExhibition(database: Database, id: string, data: ExhibitionValues) {
  await database.art.updateExhibition(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteExhibition(database: Database, id: string, actionId: string) {
  await database.art.deleteExhibition(id, actionId)
  await delay()
  mutateArt(id)
}

export async function addLiterature(database: Database, id: string, data: LiteratureValues) {
  const actionId = database.genAssetId()
  await database.art.addLiterature(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateLiterature(database: Database, id: string, data: LiteratureValues) {
  await database.art.updateLiterature(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteLiterature(database: Database, id: string, actionId: string) {
  await database.art.deleteLiterature(id, actionId)
  await delay()
  mutateArt(id)
}

export async function markAsSold(database: Database, id: string, data: SoldValues) {
  const actionId = database.genAssetId()
  await database.art.markAsSold(id, { ...data, id: actionId })
  await delay()
  mutateArt(id)
}

export async function updateSoldInfo(database: Database, id: string, data: SoldValues) {
  await database.art.updateSoldInfo(id, data)
  await delay()
  mutateArt(id)
}

export async function deleteSoldInfo(database: Database, id: string, soldId: string) {
  await database.art.deleteSoldInfo(id, soldId)
  await delay()
  mutateArt(id)
}

// Artist
export function searchArtistsBasic(database: Database) {
  return async ([_key, keyword]: [typeof artQuery.artistList, string]) => {
    return await database.getArtistsWithKeyword(keyword)
  }
}
export function fetchArtistFull(database: Database) {
  return async ([_key, id]: [typeof artQuery.artistInfo, string]) => {
    const sourceIds = await getArtistSourceId(database, [id])
    if (sourceIds.length === 0) {
      console.error(`Artist not found id: ${id}`)
      return undefined
    }
    return await database.getArtistBySourceId(sourceIds[0])
  }
}

export function fetchArtistExhibitions(database: Database) {
  return async ([_key, ids, limit, offset]: [typeof artQuery.artistExhibitions, string[], number, number]) => {
    // artist id is not sourceId
    const sourceIds = (await getArtistSourceId(database, ids)).map((id) => parseInt(id, 10)).filter(Boolean)
    const now = new Date()
    const twoYearsAfter = startOfDay(addYears(now, 2))

    const formattedNow = format(now, 'yyyy-MM-dd')
    const formattedTwoYearsAfter = format(twoYearsAfter, 'yyyy-MM-dd')

    const exhibitions = await database.getArtistExhibitions(offset, limit, {
      artists: sourceIds,
      start_date: formattedNow,
      end_date: formattedTwoYearsAfter
    })

    return exhibitions
  }
}

async function getArtistSourceId(database: Database, ids: string[]) {
  const databaseArtistsPromises = ids.map((id) => database.getArtistById(id))
  const databaseArtists = await Promise.allSettled(databaseArtistsPromises)
  const sourceIds = databaseArtists
    .filter(isFulfilled)
    .map(({ value }) => value.sourceId)
    .filter((id) => id !== undefined)
  return sourceIds
}

export function getAllAssetsArtistIds(database: Database) {
  return async ([_key]: [typeof artQuery.allAssetsArtists]) => {
    const arts = await database.art.getAll()
    return arts.flatMap((art) => art.artistId || []).filter((id) => id !== '' && id !== '-')
  }
}

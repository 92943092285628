/**
 * @see https://docs.oneschema.co/reference/get-templates to get templates
 * @see https://myassets.oneschema.co/developer/external-api to get X-API-KEY
 *
 * Ask any Dev team member involved in OneSchema (Bulk Import) to get access of OneSchema
 * I think it's better to get templates by ourselves than to get by calling API from backend
 */

// const templateColumnsMap = <the result get from https://docs.oneschema.co/reference/get-templates>.map((temp: any) => {
//   const keyToLabel = Object.fromEntries(temp.columns.map((param) => [param.key, param.label]))
//   return { ...temp, columns: keyToLabel }
// })

export const templateColumnsMap = [
  {
    name: 'AET Wine v1.1',
    columns: {
      wineId: 'Wine ID *',
      status: 'Status *',
      bottle_size: 'Bottle Size *',
      location_type: 'Location Type',
      location_of_asset: 'Location of Asset',
      purchase_date: 'Purchase Date *',
      delivery_date: 'Delivery Date *',
      pricing_method: 'Pricing Method *',
      number_of_bottles: 'Number of Bottles *',
      currency: 'Currency *',
      bottle_price: 'Bottle Price *',
      valuation_per_bottle: 'Valuation per Bottle',
      country: 'Country',
      region: 'Region',
      subregion: 'SubRegion',
      master_varietal: 'Master Varietal',
      acquisition_type: 'Acquisition Type',
      sellerretailer: 'Seller/Retailer',
      lot_number: 'Lot Number',
      paddle_number: 'Paddle Number',
      invoice_number: 'Invoice Number',
      hammer_price: 'Hammer Price',
      tax_payable: 'Tax Payable',
      buyer_premium: 'Buyer Premium',
      other_costs: 'Other Costs',
      set_total_price_as_asset_value: 'Set Total Price as Asset Value',
      bin: 'Room/Bin',
      shelf: 'Position/Shelf',
      location_notes: 'Location Notes',
      attributes_notes: 'Attributes Notes',
      my_ownership_: 'My Ownership (%)',
      attachments: 'Attachments',
      appellation: 'Appellation'
    },
    template_key: 'AET_Wine_v1.1'
  },
  {
    name: 'Art_Import_v2',
    columns: {
      artist_name_: 'Artist Name',
      art_title_: 'Art Title',
      art_type_: 'Art Type',
      art_style: 'Art Style',
      medium: 'Medium',
      purchase_date: 'Purchase Date',
      number_of_items: 'Number of Items',
      currency_: 'Purchase Currency',
      item_price: 'Item Price',
      purchase_price_: 'Purchase Price',
      location_type: 'Location Type',
      location_of_asset: 'Location',
      valuation_currency: 'Valuation Currency',
      current_valuation_: 'Current Valuation',
      acquisition_type: 'Acquisition Type',
      initial_valuation_at_purchase: 'Initial Valuation at Purchase',
      discountprice_adjustment: 'Discount/Price Adjustment',
      other_costs: 'Other Costs (e.g. Shipping)',
      total_cost: 'Total Cost',
      use_total_cost_as_asset_value: 'Use Total Cost as Asset Value',
      auction_namenumber: 'Auction Name/Number',
      paddle_number: 'Paddle Number',
      lot_number: 'Lot Number',
      measurement: 'Measurement Units',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      weight: 'Weight',
      dimensions_summary: 'Dimensions Summary',
      edition_details: 'Edition',
      creationcompletion_year_date_executed_previosly: 'Creation/Completion Year',
      provenance: 'Provenance',
      geography: 'Geography',
      inscription: 'Inscription',
      description: 'Description',
      catalogue_text: 'Catalogue text',
      general_notes_unencrypted: 'Import Notes (unencrypted)',
      my_ownership_: 'My Ownership (%)'
    },
    template_key: 'Art_Import_v2'
  },
  {
    name: 'Other_Collectables_Import_v2',
    columns: {
      collectable_name: 'Collectable Model/Edition/Name',
      brand_manufacturer_: 'Brand/Manufacturer/Creator',
      collectable_type: 'Collectable Type',
      purchase_date: 'Purchase Date',
      number_of_items_: 'Number of Items',
      currency_: 'Purchase Currency',
      item_price_: 'Item Price',
      purchase_price_: 'Purchase Price',
      location_type: 'Location Type',
      location_of_asset: 'Location',
      valuation_currency: 'Valuation Currency',
      current_valuation_: 'Current Valuation',
      acquisition_type: 'Acquisition Type',
      initial_valuation_at_purchase: 'Initial Valuation at Purchase',
      discountprice_adjustment: 'Discount/Price Adjustment',
      other_costs: 'Other Costs (e.g. Shipping)',
      total_cost: 'Total Cost',
      use_total_cost_as_asset_value: 'Use Total Cost as Asset Value',
      auction_namenumber: 'Auction Name/Number',
      paddle_number: 'Paddle Number',
      lot_number: 'Lot Number',
      measurement: 'Measurement Units',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      weight: 'Weight',
      dimensions_summary: 'Dimensions Summary',
      edition_details: 'Edition',
      creationcompletion_year_date_executed_previosly: 'Creation/Completion Year',
      provenance: 'Provenance',
      geography: 'Geography',
      inscription: 'Inscription',
      description: 'Description',
      catalogue_text: 'Catalogue text',
      general_notes_unencrypted: 'Import Notes (unencrypted)',
      my_ownership_: 'My Ownership (%)'
    },
    template_key: 'Other_Collectables_Import_v2'
  },
  {
    name: 'MyBelongings_Import_v2',
    columns: {
      belonging_name_: 'Belonging Model/Edition/Name',
      brand_manufacturer_: 'Brand/Manufacturer/Creator',
      belonging_type_: 'Belonging Type',
      purchase_date: 'Purchase Date',
      number_of_items_: 'Number of Items',
      currency_: 'Purchase Currency',
      item_price_: 'Item Price',
      purchase_price_: 'Purchase Price',
      location_type: 'Location Type',
      location_of_asset: 'Location',
      valuation_currency: 'Valuation Currency',
      current_valuation_: 'Current Valuation',
      acquisition_type: 'Acquisition Type',
      initial_valuation_at_purchase: 'Initial Valuation at Purchase',
      discountprice_adjustment: 'Discount/Price Adjustment',
      other_costs: 'Other Costs (e.g. Shipping)',
      total_cost: 'Total Cost',
      use_total_cost_as_asset_value: 'Use Total Cost as Asset Value',
      auction_namenumber: 'Auction Name/Number',
      paddle_number: 'Paddle Number',
      lot_number: 'Lot Number',
      measurement: 'Measurement Units',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      weight: 'Weight',
      dimensions_summary: 'Dimensions Summary',
      edition_details: 'Edition',
      creationcompletion_year_date_executed_previosly: 'Creation/Completion Year',
      provenance: 'Provenance',
      geography: 'Geography',
      inscription: 'Inscription',
      description: 'Description',
      catalogue_text: 'Catalogue text',
      general_notes_unencrypted: 'Import Notes (unencrypted)',
      my_ownership_: 'My Ownership (%)'
    },
    template_key: 'MyBelongings_Import_v2'
  }
]

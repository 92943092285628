import { mutate } from 'swr'

import type { Database } from 'core/remodel/database'
import { AssetType, LocationType } from 'core/remodel/types/common'
import { LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { AssetItem } from '@/types/common'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import { isFulfilled } from '@/utils/predicate'
import type { ContactValues } from '@/components/form'

export const contactQuery = {
  options: 'contact-options',
  list: 'contact-list',
  info: 'contact-info',
  name: 'contact-name',
  rooms: 'contact-rooms',
  positions: 'contact-positions',
  infoWithItems: 'contact-info-with-items',
  locationWithAssets: 'location-with-assets',
  relocate: 'contact-relocate'
} as const

export function fetchContactOptions(database: Database) {
  return async ([_key]: [typeof contactQuery.options]) => {
    const list = await database.Account.listContact()
    return list.map((contact) => ({ label: getFullName(contact), value: contact.id }))
  }
}

export function fetchContact(database: Database) {
  return async ([_key, contactId]: [typeof contactQuery.info, string]) => {
    return await database.Account.getContact(contactId)
  }
}

export function fetchContacts(database: Database) {
  return async (_params: [typeof contactQuery.list]) => {
    const list = await database.Account.listContact()

    const sorted = list.toSorted((a, b) => {
      const fullNameA = getFullName(a)
      const fullNameB = getFullName(b)
      const checkIsStartsWithSpecialChar = (fullName: string) => /^(?!\S)|^[!@#$%^&*(),.?":{}\[\]|<>]/.test(fullName)

      if (checkIsStartsWithSpecialChar(fullNameA) && !checkIsStartsWithSpecialChar(fullNameB)) {
        return 1
      } else if (!checkIsStartsWithSpecialChar(fullNameA) && checkIsStartsWithSpecialChar(fullNameB)) {
        return -1
      } else {
        return fullNameA.localeCompare(fullNameB)
      }
    })

    return sorted
  }
}

export function fetchContactsByIds(database: Database) {
  return async ([_key, ids]: [typeof contactQuery.list, string[]]) => {
    return await Promise.all(ids.map((id) => database.Account.getContact(id)))
  }
}

export function fetchContactWithAssets(database: Database) {
  return async ([_key, contactId]: [typeof contactQuery.infoWithItems, string]) => {
    const data = await database.Account.getContactRelationsOfContact(contactId)
    return data
  }
}

export function fetchLocationWithAssets(database: Database) {
  return async ([_key, contactId]: [typeof contactQuery.locationWithAssets, string]) => {
    const { art, wine, otherCollectable, belonging } = await database.Account.getContactRelationsOfContact(contactId)

    const result = await Promise.allSettled([
      ...art.map(async ({ assetId, roles }): Promise<AssetItem> => {
        const { location } = await database.art.getById(assetId)
        if (location.locationId !== contactId) throw 'not match'
        return { assetId, roles, assetType: AssetType.Art }
      }),
      ...wine.map(async ({ wineId, roles }): Promise<AssetItem> => {
        return { assetId: wineId, roles, assetType: AssetType.WineAndSpirits }
      }),
      ...otherCollectable.map(async ({ assetId, roles }): Promise<AssetItem> => {
        const { location } = await database.otherCollectable.getById(assetId)
        if (location.locationId !== contactId) throw 'not match'
        return { assetId, roles, assetType: AssetType.OtherCollectables }
      }),
      ...belonging.map(async ({ assetId, roles }): Promise<AssetItem> => {
        const { location } = await database.belonging.getById(assetId)
        if (location.locationId !== contactId) throw 'not match'
        return { assetId, roles, assetType: AssetType.Belonging }
      })
    ])

    const LocationAssets = result.filter(isFulfilled).map(({ value }) => value)
    return LocationAssets
  }
}

export function fetchContactNameMap(database: Database) {
  return async ([_key, ids]: [typeof contactQuery.name, string[]]) => {
    const contacts = await Promise.all(ids.map((id) => database.Account.getContact(id)))
    const names = contacts.map((contact, index) => {
      const fullName = getFullName(contact)
      return [ids[index], fullName] as const
    })
    return Object.fromEntries(names)
  }
}

export async function addContact(database: Database, data: Omit<ContactValues, 'id'>) {
  const id = database.genAssetId()
  const contact = { ...data, id }

  await database.Account.addContact(contact)
  await delay()
  const queries = [[contactQuery.options], [contactQuery.list]]
  queries.forEach((query) => mutate(query))

  return id
}

export async function addContactRoom(database: Database, id: string, roomName: string) {
  const newRoomId = await database.Account.addRoom(id, roomName)
  await delay()
  const refreshKeys = [
    [contactQuery.info, id],
    [contactQuery.rooms, id]
  ]
  refreshKeys.forEach((key) => mutate(key))
  return newRoomId
}

// HACK
export function fetchContactRoomOptions(database: Database) {
  return async ([_key, contactId]: [typeof contactQuery.rooms, string]) => {
    const contact = await database.Account.getContact(contactId)
    if (!contact.room) return []
    const rooms = contact.room
    return rooms.map((room) => ({ label: room.name, value: room.id }))
  }
}

export async function addContactPosition(database: Database, id: string, roomId: string, positionName: string) {
  await database.Account.addPosition(id, roomId, positionName)
  await delay()
  const refreshKeys = [
    [contactQuery.info, id],
    [contactQuery.positions, id, roomId]
  ]
  refreshKeys.forEach((key) => mutate(key))
}

// HACK
export function fetchContactRoomPositionOptions(database: Database) {
  return async ([_key, contactId, roomId]: [typeof contactQuery.positions, string, string | undefined]) => {
    const contact = await database.Account.getContact(contactId)
    if (!contact.room) return []
    const targetRoom = contact.room.find((item) => item.id === roomId)
    if (!targetRoom) return []
    const positions = targetRoom.position.map((item) => ({ label: item, value: item }))
    return positions
  }
}

export async function updateContact(database: Database, id: string, data: ContactValues) {
  const contact = { ...data, id }
  await database.Account.updateContact(contact)

  await delay()
  const queries = [[contactQuery.options], [contactQuery.list], [contactQuery.info, id]]
  queries.forEach((query) => mutate(query))
}

export async function deleteContact(database: Database, contactId: string) {
  const task = await database.Account.deleteContact(contactId)
  await task.run()
  await delay()
  const queries = [[contactQuery.options], [contactQuery.list], [contactQuery.info, contactId]]
  queries.forEach((query) => mutate(query))
}
export async function canNotDeleteContact(database: Database, contactId: string) {
  try {
    await database.Account.deleteContact(contactId)
    return false // can delete contact
  } catch (error) {
    return true // cannot delete contact
  }
}

export async function contactRelocateAssets(
  database: Database,
  selected: AssetItem[],
  newLocation: LocationInfo,
  contactId: string,
  params: any,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address

  for (const { assetId, assetType } of selected) {
    switch (assetType) {
      case AssetType.Belonging: {
        const belongings = await database.belonging.getById(assetId)
        const oldLocation = belongings.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.belonging.update({ ...belongings, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.Art: {
        const arts = await database.art.getById(assetId)
        const oldLocation = arts.location
        if (oldLocation.locationId === newLocation.locationId || arts.closedWith) continue

        await database.art.update({ ...arts, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.OtherCollectables: {
        const otherCollectables = await database.otherCollectable.getById(assetId)
        const oldLocation = otherCollectables.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.otherCollectable.update({ ...otherCollectables, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.WineAndSpirits: {
        const wine = await database.wine.getWineById(assetId)
        const purchaseData = await database.wine.getWinePurchasesByIds(wine.purchases.map(({ id }) => id))
        for (const purchase of purchaseData) {
          const bottleIds = purchase.bottles
            .filter((bottle) => bottle.location.locationId !== newLocation.locationId)
            .map((bottle) => bottle.bottleId)
          await database.wine.relocateBottle(wine.id, purchase.id, bottleIds, newLocation)
        }

        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      default: {
        console.log(`relocateAssets: assetType ${assetType} not supported`)
        break
      }
    }
  }

  await delay()
  const refreshKeys = [
    [contactQuery.info, contactId],
    [contactQuery.list, params],
    [contactQuery.infoWithItems, contactId]
  ]
  refreshKeys.forEach((key) => mutate(key))
}

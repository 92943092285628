import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cg%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M22.429%200H1.713c-.57%200-1.07.5-1.07%201.357v37.929c0%20.214.5.714%201.071.714h28.572c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.714.071%2022.573%200%2022.429%200Z%22%20fill%3D%22%237C98FF%22%2F%3E%3Cpath%20d%3D%22M6.43%2029v-3.475h3.497V29h1.353v-7.82H9.927v3.255H6.43V21.18H5.073V29h1.358Zm11.673%200v-1.085h-3.755v-2.406h3.212v-1.074h-3.212v-2.16h3.717V21.18H12.99V29h5.113Zm2.644%200v-7.82h-1.354V29h1.354Zm4.673.107c.892%200%201.606-.233%202.144-.7.537-.468.845-1.119.923-1.953h-1.353c-.072.559-.242.958-.51%201.198-.269.24-.67.36-1.204.36-.583%200-1.026-.222-1.329-.666-.302-.444-.454-1.089-.454-1.934v-.693c.007-.834.17-1.467.486-1.898.317-.432.771-.648%201.362-.648.508%200%20.894.125%201.157.374.264.248.427.654.492%201.216h1.353c-.086-.856-.392-1.518-.918-1.987-.526-.47-1.221-.704-2.084-.704-.641%200-1.206.152-1.695.457a2.955%202.955%200%200%200-1.125%201.3c-.261.562-.392%201.212-.392%201.95v.73c.01.72.145%201.352.403%201.896.258.544.624.964%201.098%201.26.475.295%201.023.442%201.646.442Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%231F42C3%22%20d%3D%22M22.786.143V8.57h8.428z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E)
 */
export default function FileHeicIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <g fill={'none'}>
        <path
          d={
            'M22.429 0H1.713c-.57 0-1.07.5-1.07 1.357v37.929c0 .214.5.714 1.071.714h28.572c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.714.071 22.573 0 22.429 0Z'
          }
          fill={'#7C98FF'}
        />
        <path
          d={
            'M6.43 29v-3.475h3.497V29h1.353v-7.82H9.927v3.255H6.43V21.18H5.073V29h1.358Zm11.673 0v-1.085h-3.755v-2.406h3.212v-1.074h-3.212v-2.16h3.717V21.18H12.99V29h5.113Zm2.644 0v-7.82h-1.354V29h1.354Zm4.673.107c.892 0 1.606-.233 2.144-.7.537-.468.845-1.119.923-1.953h-1.353c-.072.559-.242.958-.51 1.198-.269.24-.67.36-1.204.36-.583 0-1.026-.222-1.329-.666-.302-.444-.454-1.089-.454-1.934v-.693c.007-.834.17-1.467.486-1.898.317-.432.771-.648 1.362-.648.508 0 .894.125 1.157.374.264.248.427.654.492 1.216h1.353c-.086-.856-.392-1.518-.918-1.987-.526-.47-1.221-.704-2.084-.704-.641 0-1.206.152-1.695.457a2.955 2.955 0 0 0-1.125 1.3c-.261.562-.392 1.212-.392 1.95v.73c.01.72.145 1.352.403 1.896.258.544.624.964 1.098 1.26.475.295 1.023.442 1.646.442Z'
          }
          fill={'#FFF'}
        />
        <path fill={'#1F42C3'} d={'M22.786.143V8.57h8.428z'} />
      </g>
    </svg>
  )
}

import { AuthErrorCodes } from 'firebase/auth'

export const getFirebaseAuthErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case AuthErrorCodes.USER_DELETED:
      return 'No account found with the provided details. Please check and try again.'
    case AuthErrorCodes.USER_DISABLED:
      return 'This account has been disabled. Contact support for help.'
    case AuthErrorCodes.USER_MISMATCH:
      return 'The login information does not match our records. Please check and try again.'
    case AuthErrorCodes.USER_SIGNED_OUT:
      return 'You have been signed out. Please log in again.'
    case AuthErrorCodes.INVALID_PASSWORD:
      return 'The provided password is incorrect.'
    case AuthErrorCodes.INVALID_EMAIL:
      return 'The email format is invalid. Please enter a valid email address.'
    case AuthErrorCodes.EMAIL_EXISTS:
      return 'This email is already registered. Use a different email or log in.'
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
      return 'This login information is already linked to another account.'
    case AuthErrorCodes.CREDENTIAL_MISMATCH:
      return "The credentials don't match the signed-in user. Please use the correct login information."
    case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
      return 'Incorrect login details. Please check your email and password.'
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      return 'Network error. Please check your internet connection and try again.'
    case AuthErrorCodes.TIMEOUT:
      return 'The operation took too long. Please try again.'
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      return 'Too many requests. Please wait a moment and try again.'
    case AuthErrorCodes.INVALID_API_KEY:
      return 'Invalid API key. Verify your app configuration.'
    case AuthErrorCodes.INVALID_APP_CREDENTIAL:
      return 'The credential provided does not have permission to complete this action.'
    case AuthErrorCodes.INVALID_APP_ID:
      return 'Invalid app ID. Please check your app settings.'
    case AuthErrorCodes.MFA_REQUIRED:
      return 'Extra verification is needed. Please follow the multi-factor authentication steps.'
    case AuthErrorCodes.INVALID_MFA_SESSION:
      return 'The multi-factor authentication session is invalid. Please restart the process.'
    case AuthErrorCodes.MISSING_MFA_INFO:
      return 'Missing multi-factor authentication details. Please provide them to proceed.'
    case AuthErrorCodes.INVALID_OAUTH_CLIENT_ID:
      return 'Invalid OAuth client ID. Check your setup and try again.'
    case AuthErrorCodes.INVALID_OAUTH_PROVIDER:
      return 'Invalid OAuth provider. Please verify your settings.'
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      return 'Invalid phone number format. Please enter a valid phone number.'
    case AuthErrorCodes.MISSING_PHONE_NUMBER:
      return 'Phone number is missing. Please enter a phone number.'
    case AuthErrorCodes.INVALID_CODE:
      return 'Invalid ID token. Please try logging in again.'
    case AuthErrorCodes.MISSING_CODE:
      return 'Verification code is required. Please enter it to proceed.'
    case AuthErrorCodes.INVALID_PERSISTENCE:
      return 'Invalid session persistence type. Please check your settings.'
    case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED:
      return "Your browser doesn't support web storage. Please use a different browser."
    case AuthErrorCodes.INVALID_TENANT_ID:
      return 'Invalid tenant ID. Please check your configuration.'
    case AuthErrorCodes.TENANT_ID_MISMATCH:
      return "Tenant ID does not match the user's account. Please verify and try again."
    case AuthErrorCodes.MISSING_RECAPTCHA_TOKEN:
      return 'reCAPTCHA verification is required. Please complete the reCAPTCHA.'
    case AuthErrorCodes.INVALID_RECAPTCHA_TOKEN:
      return 'Invalid reCAPTCHA token. Please try again.'
    case AuthErrorCodes.ADMIN_ONLY_OPERATION:
      return 'This action can only be performed by an administrator.'
    case AuthErrorCodes.ARGUMENT_ERROR:
      return 'One of the inputs provided is incorrect. Please check and try again.'
    case AuthErrorCodes.APP_NOT_AUTHORIZED:
      return 'The app is not authorized to use Firebase authentication. Check your setup.'
    case AuthErrorCodes.APP_NOT_INSTALLED:
      return 'The app must be installed to perform this action.'
    case AuthErrorCodes.CAPTCHA_CHECK_FAILED:
      return 'CAPTCHA verification failed. Please try again.'
    case AuthErrorCodes.CODE_EXPIRED:
      return 'The verification code has expired. Request a new one to proceed.'
    case AuthErrorCodes.CORDOVA_NOT_READY:
      return "Cordova isn't set up to handle this action."
    case AuthErrorCodes.CORS_UNSUPPORTED:
      return 'This environment does not support the required resource sharing.'
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN:
      return 'Please log in again to complete this action.'
    case AuthErrorCodes.DEPENDENT_SDK_INIT_BEFORE_AUTH:
      return 'A required SDK must be initialized after Firebase Authentication.'
    case AuthErrorCodes.DYNAMIC_LINK_NOT_ACTIVATED:
      return "Firebase Dynamic Links aren't enabled for this project."
    case AuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION:
      return 'Please verify your email address to complete this change.'
    case AuthErrorCodes.EMULATOR_CONFIG_FAILED:
      return 'Firebase Emulator setup failed. Please review the configuration.'
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return 'This action code has expired. Please request a new one.'
    case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
      return 'The popup request was canceled. Please try again.'
    case AuthErrorCodes.INTERNAL_ERROR:
      return 'An unexpected error occurred. Please try again or contact support if the issue persists.'
    case AuthErrorCodes.INVALID_AUTH_EVENT:
      return 'An invalid authentication event was detected. Please try again.'
    case AuthErrorCodes.INVALID_CERT_HASH:
      return 'The provided certificate hash is invalid. Please check the configuration.'
    case AuthErrorCodes.INVALID_CONTINUE_URI:
      return 'The continue URL is invalid. Please enter a valid URL.'
    case AuthErrorCodes.INVALID_CORDOVA_CONFIGURATION:
      return 'Cordova configuration is invalid. Please review and update the settings.'
    case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
      return 'The custom token format is invalid. Please provide a valid token.'
    case AuthErrorCodes.INVALID_DYNAMIC_LINK_DOMAIN:
      return 'The dynamic link domain is not set up for this project.'
    case AuthErrorCodes.INVALID_EMULATOR_SCHEME:
      return 'The scheme for the Firebase Emulator is invalid. Check your setup.'
    case AuthErrorCodes.INVALID_IDP_RESPONSE:
      return 'Invalid response from the identity provider. Please check your configuration.'
    case AuthErrorCodes.INVALID_MESSAGE_PAYLOAD:
      return 'The message payload is invalid. Please review the content and try again.'
    case AuthErrorCodes.MISSING_ANDROID_PACKAGE_NAME:
      return 'Please provide an Android package name to proceed with the installation.'
    case AuthErrorCodes.MISSING_AUTH_DOMAIN:
      return 'An authentication domain is needed to complete this operation.'
    case AuthErrorCodes.MISSING_CONTINUE_URI:
      return 'Please provide a valid continue URL to proceed.'
    case AuthErrorCodes.MISSING_IFRAME_START:
      return 'The iframe start URL is missing. Please check your configuration.'
    case AuthErrorCodes.MISSING_IOS_BUNDLE_ID:
      return 'The request is missing a required Bundle ID.'
    case AuthErrorCodes.MISSING_OR_INVALID_NONCE:
      return 'The nonce is either missing or invalid. Please try again.'
    case AuthErrorCodes.MODULE_DESTROYED:
      return 'The Firebase app has been deleted. Please check your project settings.'
    case AuthErrorCodes.NEED_CONFIRMATION:
      return 'An account with this email exists, but with different login credentials.'
    case AuthErrorCodes.NO_AUTH_EVENT:
      return 'No authentication event was detected. Please log in to continue.'
    case AuthErrorCodes.NO_SUCH_PROVIDER:
      return "This user doesn't have the correct provider for this action."
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      return 'The sign-in provider is disabled. Enable it in your Firebase console.'
    case AuthErrorCodes.OPERATION_NOT_SUPPORTED:
      return 'This operation is not available in this environment.'
    case AuthErrorCodes.POPUP_BLOCKED:
      return 'The browser has blocked the popup. Please allow popups and try again.'
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      return 'You closed the popup. Please try again.'
    case AuthErrorCodes.PROVIDER_ALREADY_LINKED:
      return 'This user is already linked to the provided authentication method.'
    case AuthErrorCodes.QUOTA_EXCEEDED:
      return 'The operation limit has been reached. Please try again later.'
    case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
      return 'You canceled the redirect operation. Please try again.'
    case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
      return 'A redirect operation is already in progress. Please wait or cancel it.'
    case AuthErrorCodes.REJECTED_CREDENTIAL:
      return 'The credentials were rejected. Please check and try again.'
    case AuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED:
      return 'This second authentication factor is already enabled for this account.'
    case AuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED:
      return 'You have reached the limit for adding second factors to this account.'
    case AuthErrorCodes.UNAUTHORIZED_DOMAIN:
      return 'The domain for the continue URL is not allowed. Add it to your Firebase whitelist.'
    case AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR:
      return 'The first authentication factor is not supported for this account.'
    case AuthErrorCodes.UNSUPPORTED_PERSISTENCE:
      return 'The chosen persistence type is not supported. Please use a different type.'
    case AuthErrorCodes.UNSUPPORTED_TENANT_OPERATION:
      return 'Tenant operation is not supported for this setup.'
    case AuthErrorCodes.UNVERIFIED_EMAIL:
      return 'Your email address needs to be verified to continue.'
    case AuthErrorCodes.USER_CANCELLED:
      return 'You canceled the operation. Please try again.'
    case AuthErrorCodes.ALREADY_INITIALIZED:
      return 'Firebase Authentication is already set up. No further action is needed.'
    case AuthErrorCodes.MISSING_CLIENT_TYPE:
      return 'The request is missing client type information.'
    default:
      return 'An unexpected error occurred. Please try again later or contact support.'
  }
}

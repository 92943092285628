import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23CD7BDD%22%2F%3E%3Cpath%20d%3D%22M12.024%2025.528c0%20.836-.145%201.57-.434%202.2-.289.632-.702%201.116-1.239%201.454-.537.338-1.155.507-1.854.507-.692%200-1.308-.17-1.849-.507-.541-.338-.959-.82-1.254-1.445-.295-.625-.444-1.345-.448-2.162v-.48c0-.832.147-1.567.442-2.203.295-.637.711-1.124%201.248-1.462.537-.338%201.154-.507%201.849-.507.695%200%201.311.167%201.849.501.537.334.952.814%201.245%201.441.293.627.441%201.357.445%202.189v.474Zm-1.482-.445c0-.945-.18-1.67-.537-2.174-.357-.504-.864-.756-1.52-.756-.64%200-1.142.251-1.503.753s-.546%201.212-.554%202.13v.492c0%20.938.183%201.663.548%202.174.365.512.872.768%201.52.768.657%200%201.162-.25%201.515-.75.354-.5.53-1.23.53-2.192v-.445Zm2.959%204.488v-8.53h2.52c.753%200%201.422.167%202.006.503a3.436%203.436%200%200%201%201.356%201.43c.32.617.48%201.324.48%202.12v.429c0%20.808-.16%201.52-.483%202.132a3.395%203.395%200%200%201-1.377%201.418c-.595.332-1.278.498-2.047.498h-2.455Zm1.482-7.336v6.153h.967c.777%200%201.374-.243%201.79-.73.416-.486.628-1.184.636-2.094v-.475c0-.926-.202-1.633-.604-2.121-.402-.488-.986-.733-1.752-.733h-1.037Zm7.84%204.167v3.17H21.34V21.04h3.264c.953%200%201.71.248%202.27.744.561.496.841%201.153.841%201.97%200%20.835-.274%201.485-.823%201.95-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527%200%20.93-.124%201.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.425h-1.822v2.977Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23A555B5%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileOdpIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#CD7BDD'}
      />
      <path
        d={
          'M12.024 25.528c0 .836-.145 1.57-.434 2.2-.289.632-.702 1.116-1.239 1.454-.537.338-1.155.507-1.854.507-.692 0-1.308-.17-1.849-.507-.541-.338-.959-.82-1.254-1.445-.295-.625-.444-1.345-.448-2.162v-.48c0-.832.147-1.567.442-2.203.295-.637.711-1.124 1.248-1.462.537-.338 1.154-.507 1.849-.507.695 0 1.311.167 1.849.501.537.334.952.814 1.245 1.441.293.627.441 1.357.445 2.189v.474Zm-1.482-.445c0-.945-.18-1.67-.537-2.174-.357-.504-.864-.756-1.52-.756-.64 0-1.142.251-1.503.753s-.546 1.212-.554 2.13v.492c0 .938.183 1.663.548 2.174.365.512.872.768 1.52.768.657 0 1.162-.25 1.515-.75.354-.5.53-1.23.53-2.192v-.445Zm2.959 4.488v-8.53h2.52c.753 0 1.422.167 2.006.503a3.436 3.436 0 0 1 1.356 1.43c.32.617.48 1.324.48 2.12v.429c0 .808-.16 1.52-.483 2.132a3.395 3.395 0 0 1-1.377 1.418c-.595.332-1.278.498-2.047.498h-2.455Zm1.482-7.336v6.153h.967c.777 0 1.374-.243 1.79-.73.416-.486.628-1.184.636-2.094v-.475c0-.926-.202-1.633-.604-2.121-.402-.488-.986-.733-1.752-.733h-1.037Zm7.84 4.167v3.17H21.34V21.04h3.264c.953 0 1.71.248 2.27.744.561.496.841 1.153.841 1.97 0 .835-.274 1.485-.823 1.95-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527 0 .93-.124 1.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.425h-1.822v2.977Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#A555B5'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%234A90E2%22%2F%3E%3Cpath%20d%3D%22M14.403%2028.464c-.309.402-.736.707-1.283.914-.547.207-1.168.31-1.864.31-.714%200-1.347-.161-1.898-.486a3.24%203.24%200%200%201-1.274-1.391c-.3-.604-.455-1.308-.466-2.113v-.668c0-1.289.308-2.295.926-3.02.617-.725%201.478-1.087%202.584-1.087.949%200%201.703.234%202.261.703.559.469.895%201.145%201.008%202.027h-1.453c-.164-1.027-.76-1.54-1.787-1.54-.664%200-1.17.239-1.515.717-.346.479-.524%201.18-.536%202.107v.656c0%20.922.194%201.638.583%202.147.389.51.929.765%201.62.765.758%200%201.297-.172%201.617-.516v-1.67h-1.757v-1.125h3.234v3.27Zm3.193%201.107H16.12v-8.53h1.476v8.53Zm6.739-3.615h-3.434v3.615h-1.482v-8.53h5.42v1.194h-3.938v2.538h3.434v1.183Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%231136BC%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileGifIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#4A90E2'}
      />
      <path
        d={
          'M14.403 28.464c-.309.402-.736.707-1.283.914-.547.207-1.168.31-1.864.31-.714 0-1.347-.161-1.898-.486a3.24 3.24 0 0 1-1.274-1.391c-.3-.604-.455-1.308-.466-2.113v-.668c0-1.289.308-2.295.926-3.02.617-.725 1.478-1.087 2.584-1.087.949 0 1.703.234 2.261.703.559.469.895 1.145 1.008 2.027h-1.453c-.164-1.027-.76-1.54-1.787-1.54-.664 0-1.17.239-1.515.717-.346.479-.524 1.18-.536 2.107v.656c0 .922.194 1.638.583 2.147.389.51.929.765 1.62.765.758 0 1.297-.172 1.617-.516v-1.67h-1.757v-1.125h3.234v3.27Zm3.193 1.107H16.12v-8.53h1.476v8.53Zm6.739-3.615h-3.434v3.615h-1.482v-8.53h5.42v1.194h-3.938v2.538h3.434v1.183Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#1136BC'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

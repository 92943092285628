import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M20%2028.333q.708%200%201.188-.48t.479-1.186a1.62%201.62%200%200%200-.48-1.187A1.6%201.6%200%200%200%2020%2025q-.708%200-1.187.48a1.62%201.62%200%200%200-.48%201.187q0%20.706.48%201.188.482.481%201.187.478m-1.667-6.666h3.334v-10h-3.334zm1.667%2015q-3.459%200-6.5-1.314-3.042-1.312-5.292-3.561-2.25-2.25-3.561-5.292-1.312-3.044-1.314-6.5%200-3.457%201.314-6.5t3.561-5.292Q10.455%205.96%2013.5%204.647q3.045-1.314%206.5-1.314t6.5%201.314%205.292%203.561q2.246%202.25%203.563%205.292%201.317%203.043%201.312%206.5t-1.314%206.5q-1.309%203.044-3.561%205.292-2.254%202.248-5.292%203.563T20%2036.667m0-3.334q5.583%200%209.458-3.875T33.333%2020t-3.875-9.458T20%206.667t-9.458%203.875T6.667%2020t3.875%209.458T20%2033.333%22%2F%3E%3C%2Fsvg%3E)
 */
export default function AlertCircleIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 40 40'}
      fill={'currentColor'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'M20 28.333q.708 0 1.188-.48t.479-1.186a1.62 1.62 0 0 0-.48-1.187A1.6 1.6 0 0 0 20 25q-.708 0-1.187.48a1.62 1.62 0 0 0-.48 1.187q0 .706.48 1.188.482.481 1.187.478m-1.667-6.666h3.334v-10h-3.334zm1.667 15q-3.459 0-6.5-1.314-3.042-1.312-5.292-3.561-2.25-2.25-3.561-5.292-1.312-3.044-1.314-6.5 0-3.457 1.314-6.5t3.561-5.292Q10.455 5.96 13.5 4.647q3.045-1.314 6.5-1.314t6.5 1.314 5.292 3.561q2.246 2.25 3.563 5.292 1.317 3.043 1.312 6.5t-1.314 6.5q-1.309 3.044-3.561 5.292-2.254 2.248-5.292 3.563T20 36.667m0-3.334q5.583 0 9.458-3.875T33.333 20t-3.875-9.458T20 6.667t-9.458 3.875T6.667 20t3.875 9.458T20 33.333'
        }
      />
    </svg>
  )
}

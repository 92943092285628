import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%237F6363%22%2F%3E%3Cpath%20d%3D%22m7.211%2025.05%201.494-2.568h1.425l-2.148%203.525%202.197%203.584h-1.44l-1.528-2.607-1.535%202.606H4.242l2.202-3.584-2.153-3.525h1.425l1.495%202.568Zm5.117%203.555h3.227v.986h-4.463v-7.11h1.236v6.124Zm7.89-.845c0-.313-.11-.553-.33-.723-.219-.169-.615-.34-1.188-.512-.573-.173-1.029-.365-1.367-.577-.648-.407-.972-.937-.972-1.591%200-.573.233-1.045.7-1.416.468-.372%201.074-.557%201.82-.557.494%200%20.935.091%201.323.273.387.183.691.442.913.78.221.336.332.71.332%201.12h-1.23c0-.371-.117-.662-.35-.872-.233-.21-.565-.315-.998-.315-.404%200-.717.087-.94.259-.223.173-.335.413-.335.723%200%20.26.12.477.362.652.24.174.638.343%201.191.507.553.165.998.352%201.333.562.335.21.581.45.737.723.157.271.235.59.235.954%200%20.593-.227%201.064-.681%201.414-.455.35-1.07.525-1.849.525-.514%200-.987-.096-1.418-.286-.431-.19-.767-.453-1.006-.789a1.965%201.965%200%200%201-.359-1.171h1.236c0%20.403.133.716.4.937.267.221.65.332%201.147.332.43%200%20.753-.087.97-.261a.843.843%200%200%200%20.324-.691Zm4.766-2.71%201.494-2.568h1.426l-2.149%203.525%202.198%203.584h-1.44l-1.529-2.607-1.533%202.607h-1.436l2.202-3.584-2.153-3.525h1.426l1.494%202.568Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23624242%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileXlsxIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#7F6363'}
      />
      <path
        d={
          'm7.211 25.05 1.494-2.568h1.425l-2.148 3.525 2.197 3.584h-1.44l-1.528-2.607-1.535 2.606H4.242l2.202-3.584-2.153-3.525h1.425l1.495 2.568Zm5.117 3.555h3.227v.986h-4.463v-7.11h1.236v6.124Zm7.89-.845c0-.313-.11-.553-.33-.723-.219-.169-.615-.34-1.188-.512-.573-.173-1.029-.365-1.367-.577-.648-.407-.972-.937-.972-1.591 0-.573.233-1.045.7-1.416.468-.372 1.074-.557 1.82-.557.494 0 .935.091 1.323.273.387.183.691.442.913.78.221.336.332.71.332 1.12h-1.23c0-.371-.117-.662-.35-.872-.233-.21-.565-.315-.998-.315-.404 0-.717.087-.94.259-.223.173-.335.413-.335.723 0 .26.12.477.362.652.24.174.638.343 1.191.507.553.165.998.352 1.333.562.335.21.581.45.737.723.157.271.235.59.235.954 0 .593-.227 1.064-.681 1.414-.455.35-1.07.525-1.849.525-.514 0-.987-.096-1.418-.286-.431-.19-.767-.453-1.006-.789a1.965 1.965 0 0 1-.359-1.171h1.236c0 .403.133.716.4.937.267.221.65.332 1.147.332.43 0 .753-.087.97-.261a.843.843 0 0 0 .324-.691Zm4.766-2.71 1.494-2.568h1.426l-2.149 3.525 2.198 3.584h-1.44l-1.529-2.607-1.533 2.607h-1.436l2.202-3.584-2.153-3.525h1.426l1.494 2.568Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#624242'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

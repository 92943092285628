import { BelongingType } from "./type";
import { CoreFirestore } from "../../../coreFirebase";
import { Encrypted, Encryption } from "../../database/encryption";
import {
  OmitKeys,
  RequireKeys,
  UpdateObject,
  buildUpdate,
  optionalDateEqual,
} from "../../utils";
import { AggregateRoot, RepoAndAggregates } from "../aggregate";
import {
  Amount,
  AssetType,
  AssetV2WithCollectableAttributes,
  Attachment,
  LocationType,
  Owner,
  Ownership,
  compareGroupUpdate,
} from "../common";
import { Asset } from "../common/asset";
import { ErrorDataOutDated } from "../error";
import { LocationInfo } from "../relations/locationInfo";
import {
  BelongingTypeVersion,
  VersionedType,
  validateTypeUpToDate,
} from "../typeVersion";
import {
  Belonging,
  BelongingDraft,
  BelongingDraftWithState,
} from "./belonging";
import { BelongingAggregate } from "./belongingsAggregate";
import {
  EncryptionFieldDefaultValue,
  EncryptionFieldKey,
  IVSaltFieldDefaultValue,
  IVSaltFieldKey,
} from "../../encryption/utils";
import { Valuation } from "../actions/valuation";
import { getFieldsByDecorator, Validations } from "../../decorators";
import { CollectableAcquisition } from "../common/acquisition";

//#NOTE: Move encrypt, decrypt, and some validations to here
//#NOTE: We can compare this to PropertyUtils to see if these functions or types can be generic or shared?
export namespace BelongingsUtils {
  export function assureVersion(
    input: Encrypted<Belonging>,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input as Belonging,
      BelongingTypeVersion,
      errorOnCoreOutDated
    );
  }

  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Belonging);
  }

  export const datePaths = getFieldsByDecorator(Validations.isDate, Belonging);
  export const amountPaths = getFieldsByDecorator(
    Validations.isAmount,
    Belonging
  );

  // for activity log (maybe can use decorator in the future)
  export const primaryDetailKeys: readonly (keyof Belonging)[] = [
    "name",
    "subtype",
    "brand",
    // locationType, locationId
    "personalRefNo",
    "purchaseDate",
    "number",
    "price",
    "purchasePrice",
    "value",
    "groupIds",
    "notes",
  ] as const;
  export const attributeKeys: readonly (keyof Belonging)[] = [
    ...AssetV2WithCollectableAttributes.baseAttributeKeys,
    "medium",
  ];

  export async function decryptAndConvertDate(
    input: Encrypted<Belonging>,
    encryption: Encryption
  ): Promise<Belonging> {
    const decrypted = await decrypt(input, encryption);
    CoreFirestore.convertDateFieldsFromFirestoreNotStrict(decrypted, datePaths);
    return decrypted;
  }

  //#TODO: Maybe we can use deorators to retrieve these fields
  export type CreateFields = OmitKeys<
    Belonging,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt" | "valueSourceId"
  >;

  export type DraftDisplay = RequireKeys<
    Encrypted<Belonging>,
    "name" | "assetType" | "subtype" | "value" | "updateAt"
  >;

  export function fromCreate(from: CreateFields, ownerId: string): Belonging {
    const belonging: Belonging = {
      ...from,
      ownerId,
      version: 0,
      createAt: new Date(),
      updateAt: new Date(),
      "@type": BelongingTypeVersion,
    };

    return belonging;
  }

  export function intoUpdate(
    current: Belonging,
    update: Belonging
  ): {
    updates: UpdateObject<Belonging>;
    metadata: {
      addedToGroup: Asset["groupIds"];
      removedFromGroup: Asset["groupIds"];
      newImages?: string[];
      newMainImage?: string;
      removedImages?: string[];
      locationPrimaryDetailsUpdated?: boolean;
    };
  } {
    const metadata: any = {};
    const baseUpdateFields = buildUpdate(current, update, Belonging);

    if (!LocationInfo.equal(current.location, update.location)) {
      if (!LocationInfo.primaryDetailEqual(current.location, update.location))
        metadata.locationPrimaryDetailsUpdated = true;
      baseUpdateFields.location = update.location;
    }
    if (!Ownership.optionalEqual(current.ownership, update.ownership))
      if (update.ownership) baseUpdateFields.ownership = update.ownership;
      else baseUpdateFields.ownership = null;
    if (!Owner.optionalEqual(current.beneficiary, update.beneficiary))
      if (update.beneficiary) baseUpdateFields.beneficiary = update.beneficiary;
      else baseUpdateFields.beneficiary = null;

    const { fieldUpdate: groupIdUpdate, groupChanges } = compareGroupUpdate(
      current.groupIds,
      update.groupIds
    );
    if (groupIdUpdate !== undefined) {
      baseUpdateFields.groupIds = groupIdUpdate;
    }
    if (groupChanges.addedToGroup)
      metadata.addedToGroup = groupChanges.addedToGroup;
    if (groupChanges.removedFromGroup)
      metadata.removedFromGroup = groupChanges.removedFromGroup;

    if (!Amount.equal(current.value, update.value)) {
      baseUpdateFields.value = update.value;
    }
    if (!Amount.equal(current.price, update.price)) {
      baseUpdateFields.price = update.price;
    }
    if (!Amount.equal(current.purchasePrice, update.purchasePrice)) {
      baseUpdateFields.purchasePrice = update.purchasePrice;
    }
    if (!Amount.equal(current.totalCost, update.totalCost)) {
      baseUpdateFields.totalCost = update.totalCost;
    }
    if (current.purchaseDate.getTime() !== update.purchaseDate.getTime()) {
      baseUpdateFields.purchaseDate = update.purchaseDate;
    }
    if (!optionalDateEqual(current.dateExecuted, update.dateExecuted)) {
      if (update.dateExecuted)
        baseUpdateFields.dateExecuted = update.dateExecuted;
      else baseUpdateFields.dateExecuted = null;
    }
    if (
      !optionalDateEqual(
        current.creationCompletionYear,
        update.creationCompletionYear
      )
    ) {
      if (update.creationCompletionYear) {
        baseUpdateFields.creationCompletionYear = update.creationCompletionYear;
      } else {
        baseUpdateFields.creationCompletionYear = null;
      }
    }
    if (
      !CollectableAcquisition.optionalEqual(
        current.acquisition,
        update.acquisition
      )
    ) {
      if (update.acquisition) baseUpdateFields.acquisition = update.acquisition;
      else baseUpdateFields.acquisition = null;
    }

    const { attachments, newImages, removedImages } = Attachment.compareUpdate(
      current,
      update
    );
    if (newImages.length > 0) metadata.newImages = newImages;
    if (removedImages.length > 0) metadata.removedImages = removedImages;
    if (attachments !== undefined) baseUpdateFields.attachments = attachments;
    if (baseUpdateFields.mainImage !== undefined)
      metadata.newMainImage = baseUpdateFields.mainImage;

    return { updates: baseUpdateFields, metadata };
  }

  export async function encrypt(
    input: Belonging,
    encryption: Encryption
  ): Promise<Encrypted<Belonging>> {
    return await encryption.encryptObject(input, Belonging);
  }

  export async function decrypt(
    data: Encrypted<Belonging>,
    encryption: Encryption
  ): Promise<Belonging> {
    return await encryption.decryptObject(data, Belonging);
  }

  export async function encryptDraft(
    input: BelongingDraft,
    encryption: Encryption
  ): Promise<Encrypted<BelongingDraft>> {
    return await encryption.encryptObject(input, BelongingDraft);
  }

  export async function decryptDraftWithStateAndConvertDate(
    data: Encrypted<BelongingDraftWithState>,
    encryption: Encryption,
    decryptState: boolean = false
  ) {
    const result = decryptState
      ? await encryption
          .decryptObject(data, BelongingDraftWithState)
          .then((data) => {
            CoreFirestore.convertDateFieldsFromFirestoreNotStrict(
              data.state,
              datePaths
            );
            return data;
          })
      : {
          ...(await encryption.decryptObject(
            {
              [EncryptionFieldKey]: data[EncryptionFieldKey],
              ownerId: data.ownerId,
            },
            BelongingDraft
          )),
          state: data.state!,
        };
    // NOTE: date after encryption will be string, convert back to Date
    for (const key of datePaths) {
      if (key in result.req) {
        (result.req[key as keyof BelongingDraft["req"]] as Date) = new Date(
          result.req[key as keyof BelongingDraft["req"]] as string
        );
      }
    }
    return result;
  }

  export function newAggregateRoot(
    belonging: Encrypted<Belonging>,
    relatedReads?: RelatedReads
  ) {
    return new AggregateRoot(new BelongingAggregate(belonging, relatedReads));
  }

  export function defaultStateValue(): Encrypted<Belonging> {
    return {
      "@type": BelongingTypeVersion,
      assetType: AssetType.Belonging,
      subtype: BelongingType.Other,
      id: "",
      name: "",
      ownerId: "",
      version: 0,
      createAt: new Date(0),
      updateAt: new Date(0),
      purchaseDate: new Date(0),
      number: 1,
      price: Amount.defaultValue(),
      purchasePrice: Amount.defaultValue(),
      value: Amount.defaultValue(),
      totalCost: Amount.defaultValue(),
      brand: "",
      location: {
        locationId: "",
        locationType: LocationType.MyProperty,
        [EncryptionFieldKey]: {
          data: EncryptionFieldDefaultValue,
          [IVSaltFieldKey]: IVSaltFieldDefaultValue,
        },
      },
      [EncryptionFieldKey]: {
        data: EncryptionFieldDefaultValue, //`{name:""}`
        [IVSaltFieldKey]: IVSaltFieldDefaultValue,
      },
    };
  }

  export type SupportActions = Valuation;
  export type SupportActionsEncrypted = Valuation.Encrypted;
  export interface RelatedReads {
    actions: { [id: string]: SupportActionsEncrypted };
  }
  export interface RelatedUpdates {
    addedGroupIds?: string[];
    removedGroupIds?: string[];
    addedInsuranceIds?: string[];
    removedInsuranceIds?: string[];
    setActions?: SupportActionsEncrypted[];
    removedActionIds?: string[];
  }

  export interface RelatedAggregates {
    group?: RepoAndAggregates<any, any, any>;
    insurance?: RepoAndAggregates<any, any, any>;
    cashAndBanking?: RepoAndAggregates<any, any, any>;
  }
}

import { useCallback, useEffect, useId, useMemo, useState } from 'react'
import { format } from 'date-fns'
import { Loader2Icon } from 'lucide-react'
import {
  useController,
  useFieldArray,
  useForm,
  useWatch,
  type Control,
  type FieldPath,
  type FieldValues,
  type RegisterOptions,
  type SubmitHandler
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { components, type IndicatorsContainerProps, type InputActionMeta, type InputProps } from 'react-select'
import useSWR from 'swr'

import type { Database } from 'core/remodel/database'
import { AssetType, AttachmentKind, Currency, TimeZone, type Amount } from 'core/remodel/types/common'
import { currencyOptions } from 'core/remodel/types/options'
import { HoldingType, type HoldingItem, type Portfolio } from 'core/remodel/types/traditionalInvestments'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { addContact, contactQuery, fetchContactOptions } from '@/api/ContactService'
import { fetchGroupOptions, groupQuery } from '@/api/GroupService'
import { fallbackCurrency } from '@/constants/preference'
import { cn } from '@/utils/classnames'
import { startOfDayInTz } from '@/utils/formatter'
import { useDebounce } from '@/hooks/useDebounce'
import { useAuthStore } from '@/store/authStore'
import {
  Autocomplete,
  Button,
  FormAutocomplete,
  FormCreatableAutocomplete,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormPercentInput,
  FormPriceInput,
  Input
} from '@/components/base'
import AttachmentPanel from '@/components/AttachmentPanel'
import { AlertCircleIcon, AttachmentIcon, ChevronDownIcon, PlusIcon, StarIcon, XIcon } from '@/components/icon'
import { TabPanel, type Tab } from '@/components/TabPanel'

export type PortfolioValues = Omit<Portfolio, 'holdings'> & {
  holdings: HoldingItem.Create[]
}

const getDefaultValues = (currency: Currency = fallbackCurrency): Partial<PortfolioValues> => ({
  subtype: '-',
  assetType: AssetType.TraditionalInvestments,
  // primary details
  name: '',
  portfolioCurrency: currency,
  institution: '',
  holdings: [],
  // product details
  accountNumber: '',
  productName: '',
  groupIds: [],
  // ownership
  ownership: { myOwnership: 100, shareholder: [] },
  beneficiary: [],
  // attachments
  attachments: [],
  mainImage: undefined
})

const getDefaultHoldingValues = (currency: Currency = fallbackCurrency, timeZone: TimeZone): HoldingItem.Create => ({
  name: '',
  holdingType: HoldingType.Holding,
  isin: '',
  symbol: '',
  price: { currency, value: 0 },
  unit: 1,
  date: startOfDayInTz(new Date(), timeZone)
})

interface PortfolioFormProps {
  assetId: string | null
  values?: PortfolioValues
  mode?: 'Create' | 'Edit'
  onSubmit: SubmitHandler<PortfolioValues>
  onCancel: () => void
}

export function PortfolioForm({ assetId, values, mode = 'Create', onSubmit, onCancel }: PortfolioFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(() => getDefaultValues(preferences.baseCurrency), [preferences.baseCurrency])
  const defaultHoldingValue = useMemo(
    () => getDefaultHoldingValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  // form
  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    watch
  } = useForm<PortfolioValues>({ defaultValues })
  const myOwnership = watch('ownership.myOwnership')
  const shareholder = watch('ownership.shareholder')
  const beneficiary = watch('beneficiary')
  const holdings = watch('holdings')
  const shareholderArray = useFieldArray({
    control,
    name: 'ownership.shareholder',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, myOwnership) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const beneficiaryArray = useFieldArray({
    control,
    name: 'beneficiary',
    rules: {
      validate: {
        percent: (owners) => owners.reduce((prev, { percent }) => prev + percent, 0) <= 100,
        notEmpty: (owners) => owners.every((owner) => owner.contactId !== '')
      }
    }
  })
  const holdingArray = useFieldArray({ control, name: 'holdings' })
  const controlledShareholder = shareholderArray.fields.map((f, i) => ({ ...f, ...shareholder[i] }))
  const controlledBeneficiary = beneficiaryArray.fields.map((f, i) => ({ ...f, ...beneficiary?.[i] }))
  const controlledHoldings = holdingArray.fields.map((f, i) => ({ ...f, ...holdings[i] }))
  const remainingOwnership = useMemo(() => {
    const shared = controlledShareholder.reduce((acc, curr) => acc + curr.percent, 0)
    const total = shared + myOwnership
    return 100 - total
  }, [myOwnership, controlledShareholder])
  const remainingBeneficiary = useMemo(() => {
    const total = controlledBeneficiary.reduce((acc, curr) => acc + curr.percent, 0)
    return 100 - total
  }, [controlledBeneficiary])
  // preferences and options
  const contactOptionsSWR = useSWR([contactQuery.options], fetchContactOptions(database!))
  const { data: contactOptions, isValidating: loadingContacts } = contactOptionsSWR
  const groupOptionsSWR = useSWR([groupQuery.options], fetchGroupOptions(database!))
  const { data: groupOptions, isValidating: loadingGroups } = groupOptionsSWR
  // tabs
  const tabs: Tab[] = [
    {
      key: 'primary',
      label: t('PrimaryDetails'),
      desc: t('Required'),
      icon: <StarIcon />
    },
    {
      key: 'additional',
      label: t('AdditionalDetails'),
      desc: t('Optional'),
      icon: <PlusIcon />,
      subTabs: [
        { key: 'productDetails', label: t('finances:ProductDetails') },
        { key: 'ownership', label: t('Ownership') }
      ]
    },
    {
      key: 'attachments',
      label: t('Attachments'),
      icon: <AttachmentIcon />
    }
  ]

  const ShareholderUnselectContact = useCallback(
    (index: number) => {
      const otherShareHolders = [...controlledShareholder.slice(0, index), ...controlledShareholder.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherShareHolders.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledShareholder]
  )

  const beneficiaryUnselectContact = useCallback(
    (index: number) => {
      const otherBeneficiaries = [...controlledBeneficiary.slice(0, index), ...controlledBeneficiary.slice(index + 1)]
      const result = contactOptions?.filter(
        (option) => !otherBeneficiaries?.some((contact) => option.value === contact.contactId)
      )
      return result
    },
    [contactOptions, controlledBeneficiary]
  )

  useEffect(() => {
    if (mode === 'Edit' && values !== undefined) {
      reset(values, { keepDirtyValues: true })
    }
  }, [values, mode, reset])

  const addNewContact = async (firstName: string) => await addContact(database!, { firstName })

  return (
    <TabPanel defaultValue={tabs[0].key}>
      {/* left */}
      <TabPanel.SideNav tabs={tabs} />

      {/* right */}
      <form className={'flex h-[95vh] grow flex-col gap-4 md:h-[700px]'} onSubmit={handleSubmit(onSubmit)}>
        <div className={'grow overflow-auto px-4 pt-4'}>
          <TabPanel.Section value={'primary'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'flex flex-col gap-4'}>
                <div className={'grid gap-y-1'}>
                  <label className={'text-xs text-[#414554]'} htmlFor={'portfolioName'}>
                    {t('finances:Field.PortfolioName')}
                    <span className={'ml-1 text-red-500'}>{'*'}</span>
                  </label>
                  <div className={'flex items-center gap-x-1'}>
                    <FormInput control={control} name={'name'} rules={{ required: true }} />
                    <FormAutocomplete
                      className={'shrink-0'}
                      control={control}
                      name={'portfolioCurrency'}
                      options={currencyOptions}
                      components={{ IndicatorsContainer }}
                    />
                  </div>
                </div>
              </div>
              <div className={'flex flex-col gap-4'}>
                <FormInput control={control} name={'institution'} label={t('finances:Field.InstitutionCompany')} />
              </div>
            </div>

            <div className={'col-span-2 min-h-0 space-y-2'}>
              <Button
                className={'group w-max gap-x-2'}
                onClick={() => holdingArray.append(defaultHoldingValue)}
                disabled={mode === 'Edit'}
              >
                <div className={'rounded border border-primary p-1 group-hover:bg-primary'}>
                  <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                </div>
                <p className={'text-xs text-text group-hover:text-primary'}>{t('finances:HoldingCash')}</p>
              </Button>
              <div className={'space-y-2'}>
                <div
                  className={cn('grid grid-cols-[1fr_60px_150px_120px_40px] gap-x-2 text-xs', {
                    'grid-cols-[1fr_60px_1fr_40px]': mode === 'Edit'
                  })}
                >
                  <p>
                    <span>{t('finances:Field.HoldingNameCode')}</span>
                    <span className={'ml-1 text-red-500'}>{'*'}</span>
                  </p>
                  <p>{t('finances:Field.Units')}</p>
                  {mode === 'Create' ? <p>{t('Field.CurrentPrice')}</p> : <p>{t('Field.CurrentAveragePrice')}</p>}
                  {mode === 'Create' && <p>{t('Field.PurchaseDate')}</p>}
                </div>
                <div className={'h-px w-full bg-grey/50'} />
                <ul className={'max-h-[300px] space-y-2 overflow-y-auto px-0.5 py-1'}>
                  {controlledHoldings.map((holding, index) => (
                    <HoldingField
                      key={holding.id}
                      mode={mode}
                      index={index}
                      holding={holding}
                      control={control}
                      onUpdate={holdingArray.update}
                      onRemove={holdingArray.remove}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.productDetails'}>
            <div className={'flex flex-col gap-4'}>
              <FormInput control={control} name={'accountNumber'} label={t('finances:Field.AccountNumber')} />
              <FormInput control={control} name={'productName'} label={t('finances:Field.ProductName')} />
              <FormAutocomplete
                control={control}
                name={'groupIds'}
                label={t('Groups')}
                options={groupOptions}
                isLoading={loadingGroups}
                isMulti={true}
                placeholder={t('TypeToSearch')}
              />
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'additional.ownership'}>
            <div className={'grid grid-cols-1 gap-4 md:grid-cols-2'}>
              <div className={'space-y-4'}>
                <div className={'grid gap-1'}>
                  <div className={'flex items-center justify-between pr-10'}>
                    <span className={'text-sm'}>{t('Field.Ownership')}</span>
                    {remainingOwnership === 0 && (
                      <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                    )}
                    {remainingOwnership > 0 && (
                      <span className={'text-xs text-[#d89423]'}>
                        {t('RemainingPercent', { percent: remainingOwnership })}
                      </span>
                    )}
                    {remainingOwnership < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                  </div>
                  <div className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                    <Input className={'h-[38px] border'} defaultValue={t('Field.MyOwnership')} disabled={true} />
                    <FormPercentInput control={control} name={`ownership.myOwnership`} />
                  </div>
                </div>

                <div className={'grid gap-1'}>
                  <span className={'text-sm'}>{t('Field.Shareholder')}</span>
                  <ul className={'space-y-2 empty:hidden'}>
                    {shareholderArray.fields.map((shareholder, index) => (
                      <li key={shareholder.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                        <FormCreatableAutocomplete
                          control={control}
                          name={`ownership.shareholder.${index}.contactId`}
                          options={ShareholderUnselectContact(index)}
                          onCreate={addNewContact}
                          isLoading={loadingContacts}
                          placeholder={t('SearchOrCreate')}
                        />
                        <FormPercentInput control={control} name={`ownership.shareholder.${index}.percent`} />
                        <Button
                          className={'rounded-full transition-colors hover:bg-grey/20'}
                          onClick={() => shareholderArray.remove(index)}
                        >
                          <XIcon className={'text-primary'} />
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <Button
                    className={'group justify-start gap-x-3 justify-self-start py-2'}
                    onClick={() => shareholderArray.append({ contactId: '', percent: 0 })}
                  >
                    <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                      <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                    </div>
                    <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                      {t('AddShareholders')}
                    </span>
                  </Button>
                </div>
              </div>
              <div className={'space-y-1'}>
                <div className={'flex items-center justify-between pr-10'}>
                  <span className={'text-sm'}>{t('Field.Beneficiary')}</span>
                  {remainingBeneficiary === 0 && (
                    <span className={'text-xs text-[#6cb21f]'}>{t('SharedOutPercent')}</span>
                  )}
                  {remainingBeneficiary > 0 && (
                    <span className={'text-xs text-[#d89423]'}>
                      {t('RemainingPercent', { percent: remainingBeneficiary })}
                    </span>
                  )}
                  {remainingBeneficiary < 0 && <span className={'text-xs text-[#d89423]'}>{t('LimitedPercent')}</span>}
                </div>
                <ul className={'space-y-2 empty:hidden'}>
                  {beneficiaryArray.fields.map((beneficiary, index) => (
                    <li key={beneficiary.id} className={'grid grid-cols-[1fr_60px_38px] gap-x-0.5'}>
                      <FormCreatableAutocomplete
                        control={control}
                        name={`beneficiary.${index}.contactId`}
                        options={beneficiaryUnselectContact(index)}
                        onCreate={addNewContact}
                        isLoading={loadingContacts}
                        placeholder={t('SearchOrCreate')}
                      />
                      <FormPercentInput control={control} name={`beneficiary.${index}.percent`} />
                      <Button
                        className={'rounded-full transition-colors hover:bg-grey/20'}
                        onClick={() => beneficiaryArray.remove(index)}
                      >
                        <XIcon className={'text-primary'} />
                      </Button>
                    </li>
                  ))}
                </ul>
                <Button
                  className={'group justify-start gap-x-3 justify-self-start py-2'}
                  onClick={() => beneficiaryArray.append({ contactId: '', percent: 0 })}
                >
                  <div className={'rounded border border-primary p-1 transition group-hover:bg-primary'}>
                    <PlusIcon className={'text-primary group-hover:text-white'} size={20} />
                  </div>
                  <span className={'text-sm text-text transition-colors ease-out group-hover:text-primary'}>
                    {t('AddBeneficiaries')}
                  </span>
                </Button>
              </div>
            </div>
          </TabPanel.Section>
          <TabPanel.Section value={'attachments'} className={'h-full md:grid-cols-1'}>
            {assetId && (
              <AttachmentPanel
                assetId={assetId}
                control={control}
                name={{ mainImage: 'mainImage', attachments: 'attachments' }}
                widgetOptions={[AttachmentKind.PrimaryDetails]}
              />
            )}
          </TabPanel.Section>
        </div>

        <div className={'flex flex-col gap-y-4 border-t p-4'}>
          <TabPanel.Section value={'primary'}>
            <p className={'text-sm text-[#D48F1B]'}>{t('finances:PortfolioTip')}</p>
          </TabPanel.Section>
          <fieldset className={'flex justify-end gap-2'} disabled={isSubmitting}>
            <Button
              id={'finances_add_traditional_investment_cancel'}
              className={'min-w-[130px]'}
              variant={'outline'}
              size={'md'}
              onClick={onCancel}
            >
              {t('Cancel')}
            </Button>
            <Button
              id={'finances_add_traditional_investment_create'}
              className={'group relative min-w-[130px]'}
              variant={'solid'}
              size={'md'}
              type={'submit'}
              disabled={!isValid}
            >
              {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
              <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
            </Button>
          </fieldset>
        </div>
      </form>
    </TabPanel>
  )
}

export function PortfolioFormSkeleton() {
  return (
    <div className={'flex h-[600px] gap-x-2 p-2'}>
      <div className={'basis-52 animate-pulse rounded bg-grey/20'} />
      <div className={'grow animate-pulse rounded bg-grey/20'} />
    </div>
  )
}

function IndicatorsContainer({ selectProps }: IndicatorsContainerProps) {
  return (
    <ChevronDownIcon
      className={cn('-ml-2 mr-2 cursor-pointer rounded-full text-primary hover:bg-gray-100', {
        'rotate-180': selectProps.menuIsOpen
      })}
      size={20}
    />
  )
}

type StockOption = {
  label: string
  value: Record<'symbol' | 'exchange', string>
}

interface StocksAutocompleteProps<TValues extends FieldValues, TPath extends FieldPath<TValues>> {
  // react-hook-form
  control: Control<TValues>
  name: TPath
  rules?: RegisterOptions<TValues, TPath>
  label?: string
  // custom
  disabled?: boolean
  onSelected: (value: StockOption) => void
  onLoading: (value: boolean) => void
}

function StocksAutocomplete<TValues extends FieldValues, TPath extends FieldPath<TValues>>({
  label,
  control,
  name,
  rules: rulesProp,
  disabled,
  onSelected,
  onLoading
}: StocksAutocompleteProps<TValues, TPath>) {
  const { t } = useTranslation()
  const id = useId()
  const database = useAuthStore((state) => state.database)

  // react-hook-form
  const rules: RegisterOptions = {
    ...rulesProp,
    validate: { ...rulesProp?.validate, noHTMLTags: (v: string) => !/<[^>]*>/.test(v) }
  }
  const { field, fieldState } = useController({ control, name, rules })
  const controlledValue = useWatch({ control, name })
  const { ref, value, onChange, ...fieldProps } = field
  const isRequired = rules?.required !== undefined

  const [options, setOptions] = useState<StockOption[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const debouncedInputValue = useDebounce(inputValue, 500)
  const option: StockOption = {
    label: controlledValue.symbol,
    value: { symbol: controlledValue.symbol, exchange: controlledValue.exchange }
  }

  const handleChange = async (newValue: StockOption | null) => {
    if (newValue) {
      onSelected(newValue)
      setInputValue('')
    }
  }

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') setInputValue(newValue)
  }

  // load options
  useEffect(() => {
    if (!database) return
    if (debouncedInputValue.length === 0) return

    const loadOptions = async (keyword: string) => {
      try {
        onLoading(true)
        const results = await database.getStocksByKeyword({ keyword })
        const newOptions = results.map((value) => ({ label: value.symbol, value }))
        setOptions(newOptions)
      } finally {
        onLoading(false)
      }
    }
    loadOptions(debouncedInputValue)
  }, [database, debouncedInputValue, onLoading])

  return (
    <div className={'grid grid-cols-1 gap-y-1'}>
      {label && (
        <label
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        <Autocomplete
          id={id}
          innerRef={field.ref}
          value={option}
          onChange={handleChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={options}
          components={{
            Input: AutocompleteInput,
            IndicatorsContainer: AutocompleteIndicator
          }}
          isMulti={false}
          isDisabled={disabled}
          placeholder={t('TypeToSearch')}
          noOptionsMessage={() => t('NoResultsFound')}
          {...fieldProps}
        />
        {fieldState.error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
      </div>
      {fieldState.error?.message && <p className={'text-xs text-red-500'}>{fieldState.error.message}</p>}
    </div>
  )
}

function AutocompleteInput(props: InputProps<any>) {
  return <components.Input {...props} isHidden={false} />
}

function AutocompleteIndicator() {
  return null
}

async function getStockInfo(database: Database, params: Record<'exchange' | 'code' | 'specificDate', string>) {
  const result = await database.getStockInfosByDate(params)

  if (result.length > 0) {
    const { nameWithCode, priceWithCurrency } = result[0]
    const [currency, value] = priceWithCurrency.split(' ')

    return {
      name: nameWithCode,
      symbol: params.code,
      exchange: params.exchange,
      price: { currency, value: parseFloat(value) } as Amount
    }
  }

  return undefined
}

interface HoldingFieldProps {
  mode: 'Create' | 'Edit'
  index: number
  holding: HoldingItem.Create
  control: Control<PortfolioValues, any>
  onUpdate: (index: number, value: HoldingItem.Create) => void
  onRemove: (index: number) => void
}

function HoldingField({ mode, index, holding, control, onUpdate, onRemove }: HoldingFieldProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const database = useAuthStore((state) => state.database)

  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultHoldingValue = useMemo(
    () => getDefaultHoldingValues(preferences.baseCurrency, preferences.timeZone),
    [preferences.baseCurrency, preferences.timeZone]
  )
  return (
    <li
      className={cn('grid grid-cols-[1fr_60px_150px_120px_40px] gap-x-2', {
        'grid-cols-[1fr_60px_1fr_40px]': mode === 'Edit'
      })}
    >
      <StocksAutocomplete
        control={control}
        name={`holdings.${index}`}
        disabled={mode === 'Edit'}
        rules={{ required: true }}
        onLoading={setIsLoading}
        onSelected={async (option) => {
          if (!database) return

          const { symbol, exchange } = option.value
          const stockInfo = await getStockInfo(database, {
            code: symbol,
            exchange,
            specificDate: format(holding.date, 'yyyy-MM-dd')
          })

          if (stockInfo) {
            onUpdate(index, {
              ...holding,
              symbol,
              exchange: stockInfo.exchange,
              name: stockInfo.name,
              price: stockInfo.price
            })
          } else {
            onUpdate(index, {
              ...holding,
              symbol,
              exchange,
              name: defaultHoldingValue.name,
              price: defaultHoldingValue.price
            })
          }
        }}
      />
      <FormNumberInput
        control={control}
        name={`holdings.${index}.unit`}
        disabled={mode === 'Edit'}
        format={preferences.numberFormat}
        digits={2}
      />
      <FormPriceInput
        control={control}
        name={{ currency: `holdings.${index}.price.currency`, value: `holdings.${index}.price.value` }}
        disabled={{ currency: mode === 'Edit', value: mode === 'Edit' }}
        format={preferences.numberFormat}
        digits={8}
        loading={isLoading}
      />
      {mode === 'Create' && (
        <>
          <FormDatePicker
            control={control}
            name={`holdings.${index}.date`}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
            onSelected={async (date) => {
              if (!database) return
              if (!holding.exchange || !holding.symbol) return

              const stockInfo = await getStockInfo(database, {
                code: holding.symbol,
                exchange: holding.exchange,
                specificDate: format(date, 'yyyy-MM-dd')
              })

              if (stockInfo) {
                onUpdate(index, {
                  ...holding,
                  name: stockInfo.name,
                  exchange: stockInfo.exchange,
                  price: stockInfo.price
                })
              } else {
                onUpdate(index, {
                  ...holding,
                  price: defaultHoldingValue.price
                })
              }
            }}
          />
          <Button
            className={'h-8 w-8 self-center justify-self-end rounded-full hover:bg-grey/20'}
            onClick={() => onRemove(index)}
          >
            <XIcon className={'text-primary'} size={20} />
          </Button>
        </>
      )}
    </li>
  )
}

import { CoreFirestore } from "../../coreFirebase";
import { Encrypted } from "../database/encryption";
import { PermissionCategory, getPermissionCategory } from "../refPaths";
import { Art } from "./arts";
import { Belonging } from "./belongings";
import { Allocation } from "./cashAndBanking/loan";
import { SupportLiabilityType } from "./cashAndBankingSummary";
import { Cryptocurrency } from "./cryptocurrencies";
import { AssetType } from "./enums";
import { Insurance } from "./insurance";
import { OtherInvestment } from "./otherInvestments";
import { OwnerDetail, PropertyUtils, RentalDetail } from "./properties";
import { Portfolio } from "./traditionalInvestments";
import { Wine, WinePurchase, WineStatus } from "./wineAndSprits";
import { Amount, Beneficiary, LocationType, Owner, Ownership } from "./common";
import { LocationInfo } from "./relations/locationInfo";

//#NOTE this is Item in property, it doesn't care about the position, bin, shelf
export interface LocationItem {
  assetId: string;
  assetType: LocationItem.SupportedAssetType;
  subtype: string;
  value: Amount;
  roomId?: string;
  bottles?: PurchaseAndBottleId[];
  //#NOTE may need
  updateAt?: Date;
  //#NOTE only for art
  sold?: boolean;
}
export namespace LocationItem {
  export type SupportedAssetType = Extract<
    AssetType,
    "Art" | "OtherCollectables" | "Belonging" | "WineAndSpirits"
  >;

  export function convertDate(input: LocationItem) {
    CoreFirestore.convertDateFieldsFromFirestore(input, ["updateAt"]);
  }
}
export interface PurchaseAndBottleId {
  purchaseId: string;
  bottleId: string;
}

export enum RoleToAsset {
  //any asset that has auction
  Seller = "Seller",
  //otherInvestment
  Contact = "Contact",
  //insurance
  Specialist = "Specialist",
  Broker = "Broker",
  Insured = "Insured",
  //property
  Landlord = "Landlord",
  //any asset that has ShareHolder
  Shareholder = "Shareholder",
  Beneficiary = "Beneficiary",
  AssetLocation = "AssetLocation",
  //cashAndBanking loan/mortgage
  AssociatedAsset = "AssociatedAsset",
}

export function isRole(relationToAsset: RelationToAsset, role: RoleToAsset) {
  return (
    relationToAsset !== null && relationToAsset.relations[role] !== undefined
  );
}

export interface IdAndRole {
  contactId: string;
  role: RoleToAsset;
}

export type WineIdAndRoles = {
  wineId: string;
  purchaseId: string;
  roles: RoleToAsset[];
};

export type AssetIdAndRoles = { assetId: string; roles: RoleToAsset[] };
export type AllContactRoles = {
  art: AssetIdAndRoles[];
  belonging: AssetIdAndRoles[];
  cashAndBanking: AssetIdAndRoles[];
  cryptocurrency: AssetIdAndRoles[];
  insurance: AssetIdAndRoles[];
  otherCollectable: AssetIdAndRoles[];
  otherInvestment: AssetIdAndRoles[];
  property: AssetIdAndRoles[];
  traditionalInvestment: AssetIdAndRoles[];
  wine: WineIdAndRoles[];
};

type RelationData = {
  [RoleToAsset.Seller]?: null;
  [RoleToAsset.Contact]?: null;
  [RoleToAsset.Specialist]?: null;
  [RoleToAsset.Broker]?: null;
  [RoleToAsset.Insured]?: null;
  [RoleToAsset.Landlord]?: null;
  [RoleToAsset.Shareholder]?: number; // percentage
  [RoleToAsset.Beneficiary]?: number; // percentage
  [RoleToAsset.AssetLocation]?: {
    // assets except wine should use room / position in `location`
    location: LocationInfo.Encrypted;
    // purchase bottle can be in different room / position, so should use the ones stored in `bottles`
    bottles?: { bottleId: string; roomId?: string; position?: string }[];
  };
  [RoleToAsset.AssociatedAsset]?: number; // percentage
};

export enum RelationTargetType {
  Contact = "Contact",
  Property = "Property",
  Asset = "Asset",
}

export type RelationToAsset = {
  targetId: string;
  targetType: RelationTargetType;
  // used if targetType is Asset
  assetType?: SupportLiabilityType;
  relations: RelationData;
};

type RelationsOfAssetData = {
  id: string;
  secondaryId?: string;
  assetType: AssetType;
  permissionCategory: PermissionCategory;
  // used to query loan/mortgage or assets in property
  keyword?: RelationSearchKeyword;
};
type RelationsOfAssetMap = {
  // targetId: RelationToAsset
  [Key in string]: RelationToAsset;
};

export enum RelationSearchKeyword {
  // can be searched by `keyword` field
  LiabilityAllocated = "LiabilityAllocated",
  PropertyAsset = "PropertyAsset",
  // #NOTE below can only be searched by id combined query
  WineBottleLocation = "WineBottleLocation",
}

function addSimpleRelation(
  map: RelationsOfAssetMap,
  targetId: string,
  targetType: RelationTargetType,
  relation: Exclude<
    RoleToAsset,
    | RoleToAsset.Shareholder
    | RoleToAsset.Beneficiary
    | RoleToAsset.AssetLocation
    | RoleToAsset.AssociatedAsset
  >
) {
  if (map[targetId]) map[targetId].relations[relation] = null;
  else {
    const newRelationData: RelationToAsset = {
      targetId,
      targetType,
      relations: { [relation]: null },
    };
    map[targetId] = newRelationData;
  }
  return map;
}
function addOwnerArrayRelations(
  map: RelationsOfAssetMap,
  relation: RoleToAsset.Shareholder | RoleToAsset.Beneficiary,
  targets: Owner[]
) {
  targets.forEach(({ contactId, percent }) => {
    if (map[contactId]) map[contactId].relations[relation] = percent;
    else {
      const newRelationData: RelationToAsset = {
        targetId: contactId,
        targetType: RelationTargetType.Contact,
        relations: { [relation]: percent },
      };
      map[contactId] = newRelationData;
    }
  });
  return map;
}
function addLocationRelation(
  map: RelationsOfAssetMap,
  location: LocationInfo.Encrypted,
  id?: string
) {
  if (map[location.locationId]) {
    if (id) {
      const matchedLocation =
        map[location.locationId].relations[RoleToAsset.AssetLocation];
      const bottle = {
        bottleId: id,
        roomId: location.roomId,
        position: location.position,
      };
      if (matchedLocation) {
        if (matchedLocation.bottles) matchedLocation.bottles.push(bottle);
        else matchedLocation.bottles = [bottle];
      } else {
        // wine do not store room / position in `location`, store in `bottles`
        const { roomId, position, ...rest } = location;
        map[location.locationId].relations[RoleToAsset.AssetLocation] = {
          location: { ...rest },
          bottles: [bottle],
        };
      }
    } else {
      map[location.locationId].relations[RoleToAsset.AssetLocation] = {
        location,
      };
    }
  } else {
    const newRelationData: RelationToAsset = {
      targetId: location.locationId,
      targetType:
        location.locationType === LocationType.MyProperty
          ? RelationTargetType.Property
          : RelationTargetType.Contact,
      relations: { [RoleToAsset.AssetLocation]: { location: { ...location } } },
    };
    if (id) {
      const locationRelation =
        newRelationData.relations[RoleToAsset.AssetLocation]!;
      locationRelation.bottles = [
        {
          bottleId: id,
          roomId: location.roomId,
          position: location.position,
        },
      ];
      // wine do not store room / position in `location`, store in `bottles`
      delete locationRelation.location.roomId;
      delete locationRelation.location.position;
    }
    map[location.locationId] = newRelationData;
  }
  return map;
}

//#NOTE asset that needs relation should have this and function build this
export type RelationsOfAsset = RelationsOfAssetData & RelationsOfAssetMap;

export function assetRelationEqual(
  a: RelationsOfAsset,
  b: RelationsOfAsset
): boolean {
  if (a.assetType !== b.assetType) return false;
  const { id, assetType, ...aRelations } = a;
  const { id: _, assetType: __, ...bRelations } = b;
  if (Object.keys(aRelations).length !== Object.keys(bRelations).length)
    return false;

  for (const [key, value] of Object.entries(aRelations)) {
    if (bRelations[key] === undefined) return false;
    if (JSON.stringify(value) !== JSON.stringify(bRelations[key])) return false;
  }
  return true;
}

//#NOTE each RelationSearchKeyword require different side's id
// - LiabilityAllocated => target asset id
// - PropertyAsset => property id
// - WineBottleLocation => location id (property or contact)
export function toKeywordWithId(keyword: RelationSearchKeyword, id: string) {
  return `${keyword}_${id}`;
}
const KeywordWithIdStringData: any = null;
function appendKeywordWithId(
  relation: RelationsOfAsset,
  ...keywords: string[]
): RelationsOfAsset {
  keywords.forEach((keyword) => {
    relation[keyword] = KeywordWithIdStringData;
  });
  return relation;
}

//Art
export function buildArtRelation({
  id,
  assetType,
  acquisition,
  ownership,
  beneficiary,
  location,
}: Pick<
  Art.Encrypted,
  "id" | "assetType" | "acquisition" | "ownership" | "beneficiary" | "location"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const sellerId = acquisition?.sellerId;
  if (sellerId) {
    addSimpleRelation(
      result,
      sellerId,
      RelationTargetType.Contact,
      RoleToAsset.Seller
    );
  }
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  if (location) {
    addLocationRelation(result, location);
  }
  const keyword =
    location.locationType === LocationType.MyProperty
      ? RelationSearchKeyword.PropertyAsset
      : undefined;
  const relation = {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
    keyword,
  } as RelationsOfAsset;
  const keywordWithId = keyword
    ? toKeywordWithId(keyword, location.locationId)
    : undefined;
  if (keywordWithId) appendKeywordWithId(relation, keywordWithId);
  return relation;
}

//Belonging
export function buildBelongingRelation({
  id,
  assetType,
  acquisition,
  ownership,
  beneficiary,
  location,
}: Pick<
  Encrypted<Belonging>,
  "id" | "assetType" | "acquisition" | "ownership" | "beneficiary" | "location"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const sellerId = acquisition?.sellerId;
  if (sellerId) {
    addSimpleRelation(
      result,
      sellerId,
      RelationTargetType.Contact,
      RoleToAsset.Seller
    );
  }
  const shareholder = (ownership as Ownership | undefined)?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary as Beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  if (location) {
    addLocationRelation(result, location as LocationInfo.Encrypted);
  }
  const keyword =
    location!.locationType === LocationType.MyProperty
      ? RelationSearchKeyword.PropertyAsset
      : undefined;
  const relation = {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType!),
    keyword,
  } as RelationsOfAsset;
  const keywordWithId = keyword
    ? toKeywordWithId(keyword, location!.locationId!)
    : undefined;
  if (keywordWithId) appendKeywordWithId(relation, keywordWithId);
  return relation;
}

//CashAndBanking
export function buildCashAndBankingRelation({
  id,
  ownerId,
  assetType,
  ownership,
  beneficiary,
  allocations,
  linkToPropertyId,
}: {
  id: string;
  ownerId: string;
  assetType: AssetType.CashAndBanking;
  beneficiary?: Beneficiary;
  ownership?: Ownership;
  allocations?: Allocation[];
  linkToPropertyId?: string;
}): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  let keyword;
  const keywordWithIdList: string[] = [];
  if (allocations) {
    allocations.forEach(({ assetId, percent, assetType }) => {
      if (assetType === AssetType.WineAndSpirits) {
        assetId = Wine.checkAndBuildWineId(ownerId, assetId);
      }
      if (result[assetId])
        result[assetId].relations[RoleToAsset.AssociatedAsset] = percent;
      else {
        const newRelationData: RelationToAsset = {
          targetId: assetId,
          targetType: RelationTargetType.Asset,
          assetType,
          relations: { [RoleToAsset.AssociatedAsset]: percent },
        };
        result[assetId] = newRelationData;
      }
      keywordWithIdList.push(
        toKeywordWithId(RelationSearchKeyword.LiabilityAllocated, assetId)
      );
    });
    if (allocations.length > 0)
      keyword = RelationSearchKeyword.LiabilityAllocated;
  }
  if (linkToPropertyId) {
    if (result[linkToPropertyId])
      result[linkToPropertyId].relations[RoleToAsset.AssociatedAsset] = 100;
    else {
      const newRelationData: RelationToAsset = {
        targetId: linkToPropertyId,
        targetType: RelationTargetType.Asset,
        assetType: AssetType.Property,
        relations: { [RoleToAsset.AssociatedAsset]: 100 },
      };
      result[linkToPropertyId] = newRelationData;
    }
    keyword = RelationSearchKeyword.LiabilityAllocated;
    keywordWithIdList.push(toKeywordWithId(keyword, linkToPropertyId));
  }
  const relation = {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
    keyword,
  } as RelationsOfAsset;
  if (keywordWithIdList.length > 0) {
    appendKeywordWithId(relation, ...keywordWithIdList);
  }
  return relation;
}

//Crypto
export function buildCryptocurrencyRelation({
  id,
  assetType,
  ownership,
  beneficiary,
}: Pick<
  Cryptocurrency.Encrypted,
  "id" | "assetType" | "ownership" | "beneficiary"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  return {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
  } as RelationsOfAsset;
}

//insurance
export function buildInsuranceRelation({
  id,
  assetType,
  specialistId,
  brokerId,
  beneficiary,
  insured,
}: Pick<
  Insurance.Encrypted,
  "id" | "assetType" | "specialistId" | "brokerId" | "beneficiary" | "insured"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  if (specialistId) {
    addSimpleRelation(
      result,
      specialistId,
      RelationTargetType.Contact,
      RoleToAsset.Specialist
    );
  }
  if (brokerId) {
    addSimpleRelation(
      result,
      brokerId,
      RelationTargetType.Contact,
      RoleToAsset.Broker
    );
  }
  if (insured) {
    for (const { targetId, targetType } of insured) {
      if (targetType === "Person") {
        addSimpleRelation(
          result,
          targetId,
          RelationTargetType.Contact,
          RoleToAsset.Insured
        );
      }
    }
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  return {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
  } as RelationsOfAsset;
}

//otherInvestment
export function buildOtherInvestmentRelation({
  id,
  assetType,
  contactIds,
  ownership,
  beneficiary,
}: Pick<
  OtherInvestment.Encrypted,
  "id" | "assetType" | "contactIds" | "ownership" | "beneficiary"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  contactIds.forEach((contactId) => {
    addSimpleRelation(
      result,
      contactId,
      RelationTargetType.Contact,
      RoleToAsset.Contact
    );
  });
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  return {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
  } as RelationsOfAsset;
}

//property
export function buildPropertyRelation({
  id,
  assetType,
  detail,
}: Pick<PropertyUtils.State, "id" | "assetType" | "detail">): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};

  let { acquisition, ownership, beneficiary } = detail as OwnerDetail;
  let { landlordId } = detail as RentalDetail;

  const sellerId = acquisition?.sellerId;
  if (sellerId) {
    addSimpleRelation(
      result,
      sellerId,
      RelationTargetType.Contact,
      RoleToAsset.Seller
    );
  }
  if (landlordId) {
    addSimpleRelation(
      result,
      landlordId,
      RelationTargetType.Contact,
      RoleToAsset.Landlord
    );
  }
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }

  return {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType!),
  } as RelationsOfAsset;
}

//traditionalInvestment
export function buildPortfolioRelation({
  id,
  assetType,
  ownership,
  beneficiary,
}: Pick<
  Portfolio.Encrypted,
  "id" | "assetType" | "ownership" | "beneficiary"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  return {
    ...result,
    id,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
  } as RelationsOfAsset;
}

//wineAndSpirits
//#NOTE one purchase has one relation Doc attached with wineId, query `secondaryId == wineId` to find full relation of a wine
export function buildWineRelation({
  id,
  ownerId,
  wineId,
  ownership,
  beneficiary,
  bottles,
  acquisition,
}: Pick<
  WinePurchase.Encrypted,
  | "id"
  | "ownerId"
  | "wineId"
  | "ownership"
  | "beneficiary"
  | "bottles"
  | "acquisition"
>): RelationsOfAsset {
  let result: RelationsOfAssetMap = {};
  const shareholder = ownership?.shareholder;
  if (shareholder) {
    addOwnerArrayRelations(result, RoleToAsset.Shareholder, shareholder);
  }
  const beneficiaries = beneficiary;
  if (beneficiaries) {
    addOwnerArrayRelations(result, RoleToAsset.Beneficiary, beneficiaries);
  }
  let keyword;
  const keywordWithIdList: string[] = [];
  bottles.forEach(({ bottleId, status, location }) => {
    if (status === WineStatus.Consumed) return;
    addLocationRelation(result, location, bottleId);
    if (location.locationType === LocationType.MyProperty) {
      keyword = RelationSearchKeyword.PropertyAsset;
      keywordWithIdList.push(toKeywordWithId(keyword, location.locationId));
    }
    keywordWithIdList.push(
      toKeywordWithId(
        RelationSearchKeyword.WineBottleLocation,
        location.locationId
      )
    );
  });
  const sellerId = acquisition?.sellerId;
  if (sellerId) {
    addSimpleRelation(
      result,
      sellerId,
      RelationTargetType.Contact,
      RoleToAsset.Seller
    );
  }

  const assetType = AssetType.WineAndSpirits;
  const relation = {
    ...result,
    assetType,
    permissionCategory: getPermissionCategory(assetType),
    id,
    secondaryId: Wine.checkAndBuildWineId(ownerId, wineId),
    keyword,
  } as RelationsOfAsset;
  if (keywordWithIdList.length > 0) {
    appendKeywordWithId(relation, ...keywordWithIdList);
  }
  return relation;
}

export function fromRelationsOfAsset(relation: RelationsOfAsset) {
  const {
    id,
    secondaryId,
    assetType,
    permissionCategory,
    keyword,
    ...relatedTargets
  } = relation;
  return {
    id,
    secondaryId,
    assetType,
    permissionCategory,
    keyword,
    relatedTargets,
  };
}

import {
  Amount,
  AssetType,
  MultiCurrencyAmount,
  Optional,
  PathsOfDateField,
} from "./common";
import {
  addDecimal,
  OmitKeys,
  subDecimal,
  validateStringNotEmpty,
} from "../utils";
import { IAggregateData, RepoAndAggregates } from "./aggregate";
import { ErrorDataOutDated, InvalidInput } from "./error";
import {} from "./relations";
import {
  CollectionReference,
  CoreFirestore,
  DocumentData,
  DocumentSnapshot,
  QueryConstraint,
  QueryDocumentSnapshot,
  WithFieldValue,
} from "../../coreFirebase";
import {
  GroupInfoTypeVersion,
  VersionedType,
  VersionedTypeString,
  validateTypeUpToDate,
} from "./typeVersion";

export type Groupable = Exclude<
  AssetType,
  | AssetType.WineAndSpirits
  | AssetType.WinePurchases
  | AssetType.BankOrInstitution
  | AssetType.Cryptocurrency
>;

//#NOTE ask the global summary thing to sync and get valuation/units and updateAt
// - names require decryption, so they must be fetch from firestore
export interface GroupInfo extends IAggregateData {
  "@type": VersionedTypeString<VersionedType.GroupInfo, 2>;
  ownerId: string;
  name: string;

  isDraft?: boolean;
  draftInfo?: DraftMetadata;

  createAt: Date;
  updateAt: Date;
}

//#NOTE just a helper type to return the items with the group
export type GroupWithItems = {
  info: GroupInfo;
  items: GroupItem[];
};

export interface GroupItem {
  assetId: string;
  assetType: Groupable;
  subtype: string;
  draftData?: DraftItemData;
}
export namespace GroupItem {
  export function equal(a: GroupItem, b: GroupItem): boolean {
    return (
      a.assetId === b.assetId &&
      a.assetType === b.assetType &&
      a.subtype === b.subtype
    );
  }
}

export type DraftItemData = {
  assets?: Amount;
  liabilities?: Amount;
};
export type DraftMetadata = {
  assets?: MultiCurrencyAmount;
  liabilities?: MultiCurrencyAmount;
  assetNumber: number;
};
export type DraftGroupInfo = GroupInfo & {
  isDraft: true;
  draftInfo: DraftMetadata;
};

export function isDraftGroupInfo(group: GroupInfo): group is DraftGroupInfo {
  return group.isDraft === true;
}

export namespace DraftMetadata {
  export function addItem(
    draftInfo: DraftMetadata,
    itemData: DraftItemData
  ): DraftMetadata {
    if (itemData.assets) {
      if (draftInfo.assets === undefined) {
        draftInfo.assets = {};
      }
      const currency = itemData.assets.currency;
      if (draftInfo.assets[currency] === undefined) {
        draftInfo.assets[currency] = 0;
      }
      draftInfo.assets[currency] = addDecimal(
        draftInfo.assets[currency]!,
        itemData.assets.value
      );
    }
    if (itemData.liabilities) {
      if (draftInfo.liabilities === undefined) {
        draftInfo.liabilities = {};
      }
      const currency = itemData.liabilities.currency;
      if (draftInfo.liabilities[currency] === undefined) {
        draftInfo.liabilities[currency] = 0;
      }
      draftInfo.liabilities[currency] = addDecimal(
        draftInfo.liabilities[currency]!,
        itemData.liabilities.value
      );
    }
    draftInfo.assetNumber++;
    return draftInfo;
  }

  export function removeItem(
    draftInfo: DraftMetadata,
    itemData: DraftItemData
  ): DraftMetadata {
    if (itemData.assets) {
      if (draftInfo.assets === undefined) {
        draftInfo.assets = {};
      }
      const currency = itemData.assets.currency;
      if (draftInfo.assets[currency] === undefined) {
        draftInfo.assets[currency] = 0;
      }
      draftInfo.assets[currency] = subDecimal(
        draftInfo.assets[currency]!,
        itemData.assets.value
      );
    }
    if (itemData.liabilities) {
      if (draftInfo.liabilities === undefined) {
        draftInfo.liabilities = {};
      }
      const currency = itemData.liabilities.currency;
      if (draftInfo.liabilities[currency] === undefined) {
        draftInfo.liabilities[currency] = 0;
      }
      draftInfo.liabilities[currency] = subDecimal(
        draftInfo.liabilities[currency]!,
        itemData.liabilities.value
      );
    }
    draftInfo.assetNumber--;
    return draftInfo;
  }
}
export namespace GroupInfo {
  export function assureVersion(
    input: GroupInfo,
    errorOnCoreOutDated: boolean = true
  ) {
    return validateTypeUpToDate(
      input,
      GroupInfoTypeVersion,
      errorOnCoreOutDated
    );
  }
  export function handleOutDated() {
    ErrorDataOutDated(VersionedType.Group);
  }

  export const datePaths: readonly PathsOfDateField<GroupInfo>[] = [
    "createAt",
    "updateAt",
  ] as const;
  export function convertDate(input: GroupInfo): GroupInfo {
    CoreFirestore.convertDateFieldsFromFirestore(input, datePaths);
    return input;
  }

  export type CreateFields = OmitKeys<
    GroupInfo,
    "@type" | "ownerId" | "version" | "createAt" | "updateAt"
  >;

  export function fromCreate(from: CreateFields, ownerId: string): GroupInfo {
    const group: WithFieldValue<GroupInfo> = {
      ...from,
      ownerId,
      version: 0,
      createAt: CoreFirestore.serverTimestamp(),
      updateAt: CoreFirestore.serverTimestamp(),
      "@type": GroupInfoTypeVersion,
    };
    return group as GroupInfo;
  }

  export function defaultStateValue(): GroupInfo {
    const group: GroupInfo = {
      id: "",
      ownerId: "",
      name: "",
      createAt: new Date(),
      updateAt: new Date(),
      "@type": GroupInfoTypeVersion,
      version: 0,
    };
    return group;
  }

  //#TODO need checks
  export async function validate(
    data: CreateFields,
    isCreate: boolean = false
  ) {
    if (
      (isCreate || data.name !== undefined) &&
      !validateStringNotEmpty(data.name)
    ) {
      throw new InvalidInput("Name is required");
    }
  }

  export type RelatedUpdates = {
    [key in Groupable]: { id: string; action: "add" | "remove" }[];
  };
  export type RelatedAggregates = {
    [key in Groupable]?: RepoAndAggregates<any, any, any>;
  };
}

interface QuerierCore<T> {
  readonly collectionRef: CollectionReference<T>;
  readonly constraints: QueryConstraint[];
  readonly batchConstraint: QueryConstraint;
  readonly batchSize: number;

  getSnapshot(): Promise<Optional<DocumentSnapshot<T>>>;
  getAllConstraint(): QueryConstraint[];

  setCursor(snapshot: QueryDocumentSnapshot<T>): void;
}

class BasicQuerierCore<T> implements QuerierCore<T> {
  readonly collectionRef: CollectionReference<T>;
  readonly constraints: QueryConstraint[];
  readonly batchConstraint: QueryConstraint;
  readonly batchSize: number;
  cursor?: string;

  constructor(
    collectionRef: CollectionReference<T>,
    constraints: QueryConstraint[],
    batchSize: number = 500
  ) {
    this.collectionRef = collectionRef;
    this.batchSize = batchSize;
    this.constraints = constraints;
    this.batchConstraint = CoreFirestore.limit(batchSize);
  }

  async getSnapshot(): Promise<Optional<DocumentSnapshot<T>>> {
    return this.cursor
      ? await CoreFirestore.getDoc(
          CoreFirestore.docFromCollection(this.collectionRef, this.cursor)
        )
      : undefined;
  }

  getAllConstraint(): QueryConstraint[] {
    return [...this.constraints, this.batchConstraint];
  }

  setCursor(snapshot: Optional<QueryDocumentSnapshot<T, DocumentData>>): void {
    this.cursor = snapshot ? snapshot.id : undefined;
  }
}

/*
let querier = new BatchQuerierStartAt(new BasicQuerierCore( ... ))

let result = await querier.getNext();
while (result.length > 0) {
  ...process result...
  querier.setCursor();
  result = await querier.getNext();
}
  
*/
export class BatchQuerierStartAt<T, Q extends QuerierCore<T>> {
  private readonly core: Q;
  private tempCursor?: QueryDocumentSnapshot<T>;
  private batchCount: number = 0;

  constructor(core: Q) {
    this.core = core;
  }

  async getTotalCount(): Promise<number> {
    return await CoreFirestore.getCountFromServer(
      this.core.collectionRef,
      ...this.core.constraints
    ).then((v) => v.data().count);
  }

  async getNext(): Promise<{ data: T; id: string }[]> {
    const cursorSnapshot = await this.core.getSnapshot();
    let snapshots;
    if (cursorSnapshot === undefined) {
      if (this.batchCount !== 0) {
        return [];
      } else {
        snapshots = await CoreFirestore.getDocsFromCollection(
          this.core.collectionRef,
          ...this.core.getAllConstraint()
        );
      }
    } else {
      snapshots = await CoreFirestore.getDocsFromCollection(
        this.core.collectionRef,
        ...this.core.getAllConstraint(),
        CoreFirestore.startAt(cursorSnapshot)
      );
    }
    if (snapshots.docs.length == this.core.batchSize - 1) {
      this.tempCursor = snapshots.docs[snapshots.docs.length - 1];
    } else {
      this.tempCursor = undefined;
    }
    return snapshots.docs.slice(0, snapshots.docs.length - 1).map((v) => ({
      data: v.data(),
      id: v.id,
    }));
  }

  async getAll(): Promise<T[]> {
    let next = await this.getNext();
    let result: T[] = [];
    while (next.length > 0) {
      await CoreFirestore.runTransaction(async (transaction) => {
        next.forEach((item) => {
          transaction.delete(
            CoreFirestore.docFromCollection(this.core.collectionRef, item.id)
          );
        });
      });

      this.setCursor();
      result = result.concat(next.map((v) => v.data));
      next = await this.getNext();
    }

    return result;
  }

  async setCursor() {
    if (this.tempCursor) {
      this.core.setCursor(this.tempCursor);
    }
  }
}

export function buildGroupItemQuerier(
  groupRelationsCollectionRef: CollectionReference<GroupItem>
): BatchQuerierStartAt<GroupItem, BasicQuerierCore<GroupItem>> {
  return new BatchQuerierStartAt(
    new BasicQuerierCore<GroupItem>(groupRelationsCollectionRef, [], 501)
  );
}

import { AssetType } from 'core/remodel/types/enums'

export const placeholderImageMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/images/finances/finance.png',
  [AssetType.TraditionalInvestments]: '/images/finances/finance.png',
  [AssetType.OtherInvestment]: '/images/finances/finance.png',
  [AssetType.Cryptocurrency]: '/images/finances/coin.svg',
  [AssetType.Insurance]: '/images/finances/finance.png',
  /* Property */
  [AssetType.Property]: '/images/properties/property.png',
  /* Collectable */
  [AssetType.Art]: '/images/collectables/art.png',
  [AssetType.WineAndSpirits]: '/images/collectables/wine.png',
  [AssetType.OtherCollectables]: '/images/collectables/other.png',
  [AssetType.Belonging]: '/images/collectables/belonging.png',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/images/finances/finance.png',
  [AssetType.WinePurchases]: '/images/collectables/wine.png'
}

export const infoPathnameMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/finances/accounts/info/',
  [AssetType.TraditionalInvestments]: '/finances/tradit-invest/info/',
  [AssetType.OtherInvestment]: '/finances/other-invest/info/',
  [AssetType.Cryptocurrency]: '/finances/crypto/',
  [AssetType.Insurance]: '/finances/insurance/info/',
  /* Property */
  [AssetType.Property]: '/properties/summary/info/',
  /* Collectable */
  [AssetType.Art]: '/collectables/art/info/',
  [AssetType.WineAndSpirits]: '/collectables/wine/info/',
  [AssetType.OtherCollectables]: '/collectables/other/info/',
  [AssetType.Belonging]: '/belongings/summary/info/',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/finances/summary/',
  [AssetType.WinePurchases]: '/collectables/wine/'
}

export const listPathnameMap = {
  /* Finance */
  [AssetType.CashAndBanking]: '/finances/accounts/',
  [AssetType.TraditionalInvestments]: '/finances/tradit-invest/',
  [AssetType.OtherInvestment]: '/finances/other-invest/',
  [AssetType.Cryptocurrency]: '/finances/crypto/',
  [AssetType.Insurance]: '/finances/insurance/',
  /* Property */
  [AssetType.Property]: '/properties/summary/list/',
  /* Collectable */
  [AssetType.Art]: '/collectables/art/list/',
  [AssetType.WineAndSpirits]: '/collectables/wine/list/',
  [AssetType.OtherCollectables]: '/collectables/other/list/',
  [AssetType.Belonging]: '/belongings/summary/list/',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '/finances/summary/',
  [AssetType.WinePurchases]: '/collectables/wine/'
}

export const subtypeOptionMap = {
  /* Finance */
  [AssetType.CashAndBanking]: 'finances:AccountType.',
  [AssetType.TraditionalInvestments]: '',
  [AssetType.OtherInvestment]: 'finances:InvestmentTypeOptions.',
  [AssetType.Cryptocurrency]: '',
  [AssetType.Insurance]: 'finances:InsuranceSubType.',
  /* Property */
  [AssetType.Property]: 'properties:PropertyTypeOptions.',
  /* Collectable */
  [AssetType.Art]: 'collectables:ArtTypeOptions.',
  [AssetType.WineAndSpirits]: 'collectables:WineTypeOptions.',
  [AssetType.OtherCollectables]: 'collectables:OtherCollectableTypeOptions.',
  [AssetType.Belonging]: 'collectables:BelongingTypeOptions.',
  /* Will not be used */
  [AssetType.BankOrInstitution]: '',
  [AssetType.WinePurchases]: ''
}

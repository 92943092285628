import { useEffect } from 'react'
import * as React from 'react'
import { AlertCircleIcon } from 'lucide-react'
import {
  useController,
  useWatch,
  type Control,
  type FieldPath,
  type FieldValues,
  type RegisterOptions
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { components, type InputActionMeta, type InputProps } from 'react-select'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'

import type { ContactLocation } from 'core/remodel/types/properties'
import type { Option } from '@/types/common'
import { cn } from '@/utils/classnames'
import { useMap } from '@/store/map'
import { Autocomplete } from '@/components/base'
import { XIcon } from '@/components/icon'

export type Place = Required<Omit<ContactLocation, '@type'>>

interface PlacesAutocompleteProps<Values extends FieldValues, Path extends FieldPath<Values>> {
  onSelected: (place: Place) => void
  // control props
  control: Control<Values>
  name: Path
  rules?: RegisterOptions<Values, Path>
  label?: string
  onCancel?: () => void
  onBlur?: () => void
}

export function PlacesAutocomplete<Values extends FieldValues, Path extends FieldPath<Values>>({
  control,
  name,
  rules: rulesFromProps,
  label,
  onCancel,
  onSelected,
  onBlur
}: PlacesAutocompleteProps<Values, Path>) {
  const { isLoaded } = useMap()
  const { t } = useTranslation()
  const id = React.useId()
  const {
    ready,
    value,
    suggestions: { loading, data },
    setValue,
    init
  } = usePlacesAutocomplete({ initOnMount: false, debounce: 500 })
  const rules: RegisterOptions<Values, Path> = {
    ...rulesFromProps,
    validate: {
      ...rulesFromProps?.validate,
      noHTMLTags: (v) => !/<[^>]*>/.test(v)
    }
  }
  const {
    field,
    fieldState: { error }
  } = useController({ control, name, rules })
  const { value: _, onChange, ref, onBlur: _onBlur, ...fieldProps } = field
  const selectedValue = useWatch({ control, name })
  const option: Option = { label: selectedValue, value: selectedValue }
  const options = data.map((suggestion) => ({ label: suggestion.description, value: suggestion.description }))
  const isRequired = rules?.required !== undefined

  const handleChange = async (newValue: Option | null) => {
    if (newValue) {
      setValue(newValue.label)
      onChange(newValue.value)
      onSelected(await getPlace(newValue.value))
    }
  }

  const handleInputChange = (newValue: string, meta: InputActionMeta) => {
    if (meta.action === 'input-change') {
      setValue(newValue)
      onChange(newValue)
    }
  }

  useEffect(() => {
    if (isLoaded) init()
  }, [isLoaded, init])

  useEffect(() => {
    setValue(selectedValue, false)
  }, [selectedValue, setValue])

  return (
    <div className={'grid grid-cols-1 gap-y-1'}>
      {label && (
        <label
          className={cn('text-xs text-[#414554]', { 'after:ml-0.5 after:text-error after:content-["*"]': isRequired })}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className={'relative'}>
        <Autocomplete
          id={id}
          innerRef={ref}
          value={option}
          onChange={handleChange}
          inputValue={value}
          onInputChange={handleInputChange}
          options={options}
          controlShouldRenderValue={false}
          components={{ Input, IndicatorsContainer: () => null }}
          isMulti={false}
          isLoading={loading}
          isDisabled={!ready}
          placeholder={t('TypeToSearch')}
          noOptionsMessage={() => t('NoResultsFound')}
          onBlur={onBlur}
          {...fieldProps}
        />
        {error && <AlertCircleIcon className={'absolute right-0 top-0.5 mx-2 my-1.5 text-red-500'} />}
        {value && onCancel && (
          <XIcon
            className={cn('absolute right-0 top-0.5 mx-2 my-1.5 cursor-pointer text-primary', error && 'right-6')}
            onClick={onCancel}
          />
        )}
      </div>
      {error?.message && <p className={'text-xs text-red-500'}>{error.message}</p>}
    </div>
  )
}

function Input(props: InputProps<Option>) {
  return <components.Input {...props} isHidden={false} />
}

// https://developers.google.com/maps/documentation/javascript/geocoding?hl=zh-tw#GeocodingAddressTypes
// https://github.com/MyAssets-HK/core/blob/develop/domain/types/location.ts
enum AddressType {
  ROUTE = 'route',
  POLITICAL = 'political',
  COUNTRY = 'country',
  AREA_LEVEL1 = 'administrative_area_level_1',
  AREA_LEVEL2 = 'administrative_area_level_2',
  LOCALITY = 'locality',
  SUBLOCALITY = 'sublocality',
  POSTAL_CODE = 'postal_code',
  POSTAL_TOWN = 'postal_town',
  STREET_NUMBER = 'street_number'
}

// https://github.com/MyAssets-HK/core/blob/develop/prelude/data/helpers/location.ts
export async function getPlace(selectedAddress: string) {
  const results = await getGeocode({ address: selectedAddress })
  const latLng = getLatLng(results[0])
  const addresses = results[0].address_components

  const getName = (type: AddressType) => {
    const address = addresses.find((address) => address.types.includes(type))
    return address ? address.long_name : null
  }

  const route = getName(AddressType.ROUTE)
  const political = getName(AddressType.POLITICAL)
  const country = getName(AddressType.COUNTRY)
  const areaLevel1 = getName(AddressType.AREA_LEVEL1)
  const areaLevel2 = getName(AddressType.AREA_LEVEL2)
  const locality = getName(AddressType.LOCALITY)
  const sublocality = getName(AddressType.SUBLOCALITY)
  const postalCode = getName(AddressType.POSTAL_CODE)
  const postalTown = getName(AddressType.POSTAL_TOWN)
  const streetNumber = getName(AddressType.STREET_NUMBER)

  // address line 1 = street_number + route + sublocality
  const part = [streetNumber, route].filter(Boolean).join(' ')
  const addressLine1 = sublocality ? `${part}, ${sublocality}` : part

  return {
    address: selectedAddress,
    center: latLng,
    addressLine1,
    addressLine2: '',
    town: postalTown ?? locality ?? areaLevel1 ?? '',
    state: areaLevel1 ?? areaLevel2 ?? political ?? '',
    zipCode: postalCode ?? '',
    country: country ?? ''
  } satisfies Place
}

import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

import { Language } from 'core/remodel/types/common'

export const supportedLngs = Object.values(Language)
export const defaultLng = Language.EN

i18n
  .use(resourcesToBackend((lng: string, ns: string) => import(`../../public/locales/${lng}/${ns}.json`)))
  .use(initReactI18next)
  .init({
    ns: [
      'common',
      'nav',
      'validation',
      'auth',
      'setup',
      'finances',
      'properties',
      'collectables',
      'belongings',
      'groups',
      'documents',
      'wishlist',
      'account',
      'dashboard',
      'delegates',
      'liveChat'
    ],
    defaultNS: ['common'],
    supportedLngs,
    lng: defaultLng,
    fallbackLng: defaultLng,
    load: 'currentOnly',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false
    }
  })

export default i18n

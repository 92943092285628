export enum AttachmentKind {
  PrimaryDetails = "Primary Details",
  Acquisition = "Acquisition",
  AssetImage = "AssetImage",
  Location = "Location",
  Attributes = "Attributes",
  Ownership = "Ownership",
  Beneficiaries = "Beneficiaries",
  Uncategorized = "Uncategorized"
}

export enum AttachmentCategory {
  Uncategorized = "Uncategorized",
  // ... add more if needed
}

export const AttachmentKindOptions = Object.values(AttachmentKind).map(
  (value) => ({ label: value, value })
);

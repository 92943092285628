import { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23577BFF%22%2F%3E%3Cpath%20d%3D%22M6.136%2026.402v3.17H4.652V21.04h3.264c.953%200%201.71.248%202.27.744s.84%201.153.84%201.97c0%20.835-.274%201.485-.822%201.95-.55.465-1.318.698-2.306.698H6.135Zm0-1.19h1.78c.528%200%20.93-.124%201.208-.372.277-.248.416-.606.416-1.075%200-.461-.141-.83-.422-1.105s-.668-.417-1.16-.425H6.135v2.977Zm12.954%204.36h-1.482l-3.803-6.053v6.052h-1.482v-8.53h1.482l3.815%206.075V21.04h1.47v8.531Zm8.291-1.108c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715%200-1.348-.161-1.898-.486a3.24%203.24%200%200%201-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725%201.479-1.087%202.584-1.087.95%200%201.703.234%202.262.703.558.469.894%201.145%201.008%202.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664%200-1.169.239-1.514.717-.346.479-.525%201.18-.536%202.107v.656c0%20.922.194%201.638.583%202.147.388.51.928.765%201.62.765.757%200%201.297-.172%201.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%232B4FD2%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FilePngIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#577BFF'}
      />
      <path
        d={
          'M6.136 26.402v3.17H4.652V21.04h3.264c.953 0 1.71.248 2.27.744s.84 1.153.84 1.97c0 .835-.274 1.485-.822 1.95-.55.465-1.318.698-2.306.698H6.135Zm0-1.19h1.78c.528 0 .93-.124 1.208-.372.277-.248.416-.606.416-1.075 0-.461-.141-.83-.422-1.105s-.668-.417-1.16-.425H6.135v2.977Zm12.954 4.36h-1.482l-3.803-6.053v6.052h-1.482v-8.53h1.482l3.815 6.075V21.04h1.47v8.531Zm8.291-1.108c-.308.402-.736.707-1.283.914-.547.207-1.168.31-1.863.31-.715 0-1.348-.161-1.898-.486a3.24 3.24 0 0 1-1.275-1.391c-.299-.604-.454-1.308-.466-2.113v-.668c0-1.289.309-2.295.926-3.02.617-.725 1.479-1.087 2.584-1.087.95 0 1.703.234 2.262.703.558.469.894 1.145 1.008 2.027h-1.454c-.164-1.027-.76-1.54-1.787-1.54-.664 0-1.169.239-1.514.717-.346.479-.525 1.18-.536 2.107v.656c0 .922.194 1.638.583 2.147.388.51.928.765 1.62.765.757 0 1.297-.172 1.617-.516v-1.67h-1.759v-1.125h3.234v3.27Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#2B4FD2'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%234A4A4A%22%2F%3E%3Cpath%20d%3D%22M7.87%2026.519v3.17H6.386v-8.532H9.65c.953%200%201.71.248%202.27.745.561.496.841%201.152.841%201.968%200%20.836-.274%201.487-.823%201.951-.549.465-1.317.698-2.306.698H7.87Zm0-1.19h1.781c.527%200%20.93-.124%201.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H7.87v2.976Zm7.67%201.19v3.17h-1.484v-8.532h3.264c.953%200%201.71.248%202.27.745.56.496.841%201.152.841%201.968%200%20.836-.274%201.487-.823%201.951-.549.465-1.317.698-2.306.698h-1.763Zm0-1.19h1.78c.528%200%20.93-.124%201.208-.372.277-.248.416-.606.416-1.075%200-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H15.54v2.976Zm12.351-2.976h-2.66v7.336h-1.47v-7.336h-2.637v-1.196h6.767v1.196Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23372F2F%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FilePptIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#4A4A4A'}
      />
      <path
        d={
          'M7.87 26.519v3.17H6.386v-8.532H9.65c.953 0 1.71.248 2.27.745.561.496.841 1.152.841 1.968 0 .836-.274 1.487-.823 1.951-.549.465-1.317.698-2.306.698H7.87Zm0-1.19h1.781c.527 0 .93-.124 1.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H7.87v2.976Zm7.67 1.19v3.17h-1.484v-8.532h3.264c.953 0 1.71.248 2.27.745.56.496.841 1.152.841 1.968 0 .836-.274 1.487-.823 1.951-.549.465-1.317.698-2.306.698h-1.763Zm0-1.19h1.78c.528 0 .93-.124 1.208-.372.277-.248.416-.606.416-1.075 0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424H15.54v2.976Zm12.351-2.976h-2.66v7.336h-1.47v-7.336h-2.637v-1.196h6.767v1.196Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#372F2F'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%23BD10E0%22%2F%3E%3Cpath%20d%3D%22M11.411%2025.528c0%20.836-.145%201.57-.434%202.2-.289.632-.702%201.116-1.24%201.454-.536.338-1.154.507-1.854.507-.691%200-1.307-.17-1.848-.507-.541-.338-.96-.82-1.254-1.445-.295-.625-.444-1.345-.448-2.162v-.48c0-.832.147-1.567.442-2.203.295-.637.71-1.124%201.248-1.462.537-.338%201.153-.507%201.849-.507.695%200%201.311.167%201.848.501.537.334.952.814%201.245%201.441.293.627.442%201.357.446%202.189v.474Zm-1.483-.445c0-.945-.178-1.67-.536-2.174-.357-.504-.864-.756-1.52-.756-.641%200-1.142.251-1.503.753-.362.502-.546%201.212-.554%202.13v.492c0%20.938.183%201.663.548%202.174.365.512.872.768%201.52.768.657%200%201.162-.25%201.515-.75.354-.5.53-1.23.53-2.192v-.445Zm2.96%204.488v-8.53h2.519c.754%200%201.423.167%202.007.503a3.436%203.436%200%200%201%201.356%201.43c.32.617.48%201.324.48%202.12v.429c0%20.808-.16%201.52-.483%202.132a3.395%203.395%200%200%201-1.377%201.418c-.595.332-1.278.498-2.048.498h-2.455Zm1.482-7.336v6.153h.967c.777%200%201.374-.243%201.79-.73.416-.486.627-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.121-.403-.488-.987-.733-1.752-.733H14.37Zm12.521%200h-2.66v7.336h-1.47v-7.336h-2.637V21.04h6.767v1.195Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23750F89%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileOdtIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#BD10E0'}
      />
      <path
        d={
          'M11.411 25.528c0 .836-.145 1.57-.434 2.2-.289.632-.702 1.116-1.24 1.454-.536.338-1.154.507-1.854.507-.691 0-1.307-.17-1.848-.507-.541-.338-.96-.82-1.254-1.445-.295-.625-.444-1.345-.448-2.162v-.48c0-.832.147-1.567.442-2.203.295-.637.71-1.124 1.248-1.462.537-.338 1.153-.507 1.849-.507.695 0 1.311.167 1.848.501.537.334.952.814 1.245 1.441.293.627.442 1.357.446 2.189v.474Zm-1.483-.445c0-.945-.178-1.67-.536-2.174-.357-.504-.864-.756-1.52-.756-.641 0-1.142.251-1.503.753-.362.502-.546 1.212-.554 2.13v.492c0 .938.183 1.663.548 2.174.365.512.872.768 1.52.768.657 0 1.162-.25 1.515-.75.354-.5.53-1.23.53-2.192v-.445Zm2.96 4.488v-8.53h2.519c.754 0 1.423.167 2.007.503a3.436 3.436 0 0 1 1.356 1.43c.32.617.48 1.324.48 2.12v.429c0 .808-.16 1.52-.483 2.132a3.395 3.395 0 0 1-1.377 1.418c-.595.332-1.278.498-2.048.498h-2.455Zm1.482-7.336v6.153h.967c.777 0 1.374-.243 1.79-.73.416-.486.627-1.184.635-2.094v-.475c0-.926-.2-1.633-.603-2.121-.403-.488-.987-.733-1.752-.733H14.37Zm12.521 0h-2.66v7.336h-1.47v-7.336h-2.637V21.04h6.767v1.195Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#750F89'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

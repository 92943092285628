import { AssetType } from 'core/remodel/types/enums'

export const templateKeyMap = {
  [AssetType.Art]: 'Art_Import_v2',
  [AssetType.OtherCollectables]: 'Other_Collectables_Import_v2',
  [AssetType.Belonging]: 'MyBelongings_Import_v2',
  [AssetType.WineAndSpirits]: 'AET_Wine_v1.1'
} as const
export type Template = keyof typeof templateKeyMap

/* Arts */
export type ArtRecord = {
  art_title_: string
  artist_name_: string
  art_type_: string
  art_style?: string
  location_type: 'My Property' | 'Address Book'
  location_of_asset: string
  personal_reference_number?: string
  purchase_date: string
  currency_: string
  item_price: string
  number_of_items: string
  purchase_price_: string
  valuation_currency: string
  current_valuation_: string
  primary_notes?: string
  acquisition_type: 'Direct' | 'Auction'
  seller?: string
  auction_namenumber?: string
  lot_number?: string
  paddle_number?: string
  invoice_number?: string
  discountprice_adjustment?: string
  hammer_price?: string
  tax_payable?: string
  buyer_premium?: string
  other_costs?: string
  total_cost?: string
  initial_valuation_at_purchase?: string
  use_total_cost_as_asset_value: 'YES' | 'NO'
  exclude_items_from_net_worth_calculation: 'YES' | 'NO'
  acquisition_notes?: string
  room?: string
  position?: string
  location_notes?: string
  measurement: 'Metric' | 'Imperial'
  height?: string
  width?: string
  depth?: string
  weight?: string
  dimensions_summary?: string
  edition_details?: string
  creationcompletion_year_date_executed_previosly?: string
  geography?: string
  medium?: string
  provenance?: string
  inscription?: string
  catalogue_text?: string
  attribute_notes?: string
  my_ownership_: string
  attachments?: string
}

/* Other Collectables */
export type OtherCollectableRecord = {
  collectable_type: string
  brand_manufacturer_: string
  collectable_name: string
  location_type: 'My Property' | 'Address Book'
  location_of_asset: string
  personal_ref_number?: string
  purchase_date: string
  purchase_price_: string
  currency_: string
  item_price_: string
  number_of_items_: string
  valuation_currency: string
  current_valuation_: string
  primary_notes?: string
  acquisition_type: 'Direct' | 'Auction'
  seller?: string
  auction_namenumber?: string
  lot_number?: string
  paddle_number?: string
  invoice_number?: string
  discountprice_adjustment?: string
  hammer_price?: string
  tax_payable?: string
  buyer_premium?: string
  other_costs?: string
  total_cost?: string
  initial_valuation_at_purchase?: string
  use_total_cost_as_asset_value: 'YES' | 'NO'
  exclude_items_from_net_worth_calculation: 'YES' | 'NO'
  acquisition_notes?: string
  room?: string
  position?: string
  location_notes?: string
  measurement_units: 'Metric' | 'Imperial'
  height?: string
  width?: string
  depth?: string
  weight?: string
  dimensions_summary?: string
  edition_details?: string
  creationcompletion_year_date_executed_previosly?: string
  geography?: string
  medium?: string
  provenance?: string
  inscription?: string
  catalogue_text?: string
  attribute_notes?: string
  my_ownership_: string
  attachments?: string
}

/* My Belongings */
export type BelongingRecord = {
  belonging_name_: string
  belonging_type_: string
  brand_manufacturer_: string
  location_type: 'My Property' | 'Address Book'
  location_of_asset: string
  personal_ref_number?: string
  purchase_date: string
  purchase_price_: string
  currency_: string
  item_price_: string
  number_of_items_: string
  valuation_currency: string
  current_valuation_: string
  primary_notes?: string
  acquisition_type: 'Direct' | 'Auction'
  seller?: string
  auction_namenumber?: string
  lot_number?: string
  paddle_number?: string
  invoice_number?: string
  discountprice_adjustment?: string
  hammer_price?: string
  tax_payable?: string
  buyer_premium?: string
  other_costs?: string
  total_cost?: string
  initial_valuation_at_purchase?: string
  use_total_cost_as_asset_value: 'YES' | 'NO'
  exclude_items_from_net_worth_calculation: 'YES' | 'NO'
  acquisition_notes?: string
  room?: string
  position?: string
  location_notes?: string
  measurement_units: 'Metric' | 'Imperial'
  height?: string
  width?: string
  depth?: string
  weight?: string
  dimensions_summary?: string
  edition_details?: string
  creationcompletion_year_date_executed_previosly?: string
  geography?: string
  medium?: string
  provenance?: string
  inscription?: string
  catalogue_text?: string
  attribute_notes?: string
  my_ownership_: string
  attachments?: string
}

/* Wine And Spirits */
export type WineRecord = {
  wineId: string
  status: 'Delivered' | 'Pending'
  bottle_size: string
  location_type: 'My Property' | 'Address Book'
  location_of_asset: string
  delivery_date: string
  purchase_date: string
  pricing_method: 'Bottle Price' | 'Lot/Bulk Price'
  number_of_bottles: string
  currency: string
  bottle_price: string
  valuation_per_bottle?: string
  total_cost?: string
  country?: string
  region?: string
  subregion?: string
  master_varietal?: string
  acquisition_type: 'Direct' | 'Auction'
  sellerretailer?: string
  lot_number?: string
  paddle_number?: string
  invoice_number?: string
  hammer_price?: string
  tax_payable?: string
  buyer_premium?: string
  other_costs?: string
  initial_valuation_at_purchase?: string
  use_total_cost_as_asset_value?: 'Yes' | 'No'
  bin?: string
  shelf?: string
  location_notes?: string
  attributes_notes?: string
  my_ownership_?: string
  attachments?: string
  appellation?: string
}

export type TemplateRecord = ArtRecord | BelongingRecord | OtherCollectableRecord | WineRecord

type ErrorRecord = {
  data: TemplateRecord
  errors: Record<keyof TemplateRecord, number[]>
}

type Column = {
  sheet_column_name: string
  template_column_name: string
  template_column_key: string
}

type SheetMetadata = {
  original_file_name: string
  original_sheet_name: string
}

export type Result = {
  embed_id: number
  template_key: Template
  workspace_id: number
  workspace_metadata: unknown
  sheet_id: number
  sheet_metadata: SheetMetadata
  columns: Column[]
  count: number
  records: TemplateRecord[]
  error_records: ErrorRecord[]
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzNfOTI3KSI+CjxwYXRoIGQ9Ik01IDIwSDE5VjE4SDVWMjBaTTE5IDlIMTVWM0g5VjlINUwxMiAxNkwxOSA5WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8zXzkyNyI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4=)
 */
export default function DownloadIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg width={size} height={size} viewBox={'0 0 24 24'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'} {...props}>
      <g clipPath={'url(#clip0_3_927)'}>
        <path d={'M5 20H19V18H5V20ZM19 9H15V3H9V9H5L12 16L19 9Z'} fill={'white'} />
      </g>
      <defs>
        <clipPath id={'clip0_3_927'}>
          <rect width={'24'} height={'24'} fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  )
}

import { FirebaseError } from 'firebase/app'

import { getFirebaseAuthErrorMessage } from '@/constants/errorMessage'

function isFirebaseError(error: unknown): error is FirebaseError {
  return (error as FirebaseError).code !== undefined
}
const devMode = process.env.NODE_ENV !== 'production'

export const parseErrorMessage = (error: unknown, defaultMessage?: string) => {
  // handle firebase error
  if (isFirebaseError(error)) {
    switch (true) {
      // auth error
      case error.code.startsWith('auth/'):
        return getFirebaseAuthErrorMessage(error.code)
      // other error
      default:
        return error.message || defaultMessage
    }
  }

  // handle custom error
  if (error instanceof Error) {
    if (devMode) console.error(error.message)
    return defaultMessage
  }

  return defaultMessage
}

import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%239B9B9B%22%2F%3E%3Cpath%20d%3D%22m8.618%2024.122%201.793-3.082h1.71l-2.577%204.23%202.636%204.301h-1.728l-1.834-3.128-1.84%203.128H5.055l2.643-4.3-2.584-4.23h1.71l1.794%203.081Zm6.14%204.266h3.873v1.183h-5.355v-8.53h1.482v7.347Zm9.47-1.014c0-.375-.133-.664-.396-.867-.264-.203-.74-.408-1.427-.615-.688-.207-1.235-.438-1.64-.692-.778-.488-1.167-1.125-1.167-1.91%200-.687.28-1.254.841-1.699.56-.445%201.288-.668%202.183-.668.593%200%201.123.11%201.588.328.464.219.83.53%201.095.935.266.404.399.852.399%201.344h-1.478c0-.445-.14-.794-.419-1.045-.28-.252-.679-.378-1.198-.378-.484%200-.86.103-1.128.31-.268.207-.401.496-.401.867%200%20.313.144.574.433.783.29.209.766.412%201.43.609.664.197%201.197.422%201.6.674.402.252.697.54.884.867.188.326.282.708.282%201.145%200%20.711-.273%201.277-.818%201.697-.545.42-1.284.63-2.218.63a4.168%204.168%200%200%201-1.702-.343c-.517-.229-.92-.544-1.207-.946a2.358%202.358%200%200%201-.43-1.407h1.482c0%20.485.16.86.48%201.125.32.266.78.399%201.377.399.516%200%20.904-.105%201.163-.314.26-.209.39-.485.39-.829Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%237E7676%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileXlsIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#9B9B9B'}
      />
      <path
        d={
          'm8.618 24.122 1.793-3.082h1.71l-2.577 4.23 2.636 4.301h-1.728l-1.834-3.128-1.84 3.128H5.055l2.643-4.3-2.584-4.23h1.71l1.794 3.081Zm6.14 4.266h3.873v1.183h-5.355v-8.53h1.482v7.347Zm9.47-1.014c0-.375-.133-.664-.396-.867-.264-.203-.74-.408-1.427-.615-.688-.207-1.235-.438-1.64-.692-.778-.488-1.167-1.125-1.167-1.91 0-.687.28-1.254.841-1.699.56-.445 1.288-.668 2.183-.668.593 0 1.123.11 1.588.328.464.219.83.53 1.095.935.266.404.399.852.399 1.344h-1.478c0-.445-.14-.794-.419-1.045-.28-.252-.679-.378-1.198-.378-.484 0-.86.103-1.128.31-.268.207-.401.496-.401.867 0 .313.144.574.433.783.29.209.766.412 1.43.609.664.197 1.197.422 1.6.674.402.252.697.54.884.867.188.326.282.708.282 1.145 0 .711-.273 1.277-.818 1.697-.545.42-1.284.63-2.218.63a4.168 4.168 0 0 1-1.702-.343c-.517-.229-.92-.544-1.207-.946a2.358 2.358 0 0 1-.43-1.407h1.482c0 .485.16.86.48 1.125.32.266.78.399 1.377.399.516 0 .904-.105 1.163-.314.26-.209.39-.485.39-.829Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#7E7676'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

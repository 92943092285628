import Image from 'next/image'
import Link from 'next/link'
import { Trans, useTranslation } from 'react-i18next'

import { Modal } from '@/components/base'

export function MaintenancePage() {
  const { t } = useTranslation()

  return (
    <Modal className={'min-h-screen max-w-full rounded-none bg-background'}>
      <Modal.Content className={'h-screen w-screen p-5'}>
        <div className={'flex h-8 justify-start'}>
          <Image src={'/images/myassets-logo.svg'} width={200} height={33} alt={'myasset-logo'} quality={100} />
        </div>
        <div className={'grid grid-cols-1 pb-20'}>
          <div className={'mb-10 flex items-center justify-center'}>
            <Image src={'/images/underMaintenance.png'} width={443} height={428} alt={'maintenance'} />
          </div>
          <div className={'gap-4 text-white lg:flex lg:flex-col lg:items-center lg:justify-center lg:px-10'}>
            <h1 className={'mb-5 text-4xl font-bold'}>{t('MaintenanceTitle')}</h1>
            <p className={'text-center'}>
              <Trans t={t} i18nKey={'MaintenanceContent'} />
            </p>
            <div className={'flex items-center justify-center gap-4'}>
              <Link href={'https://www.facebook.com/UseMyAssets'}>
                <Image src={'/images/facebook.svg'} width={40} height={41} alt={'facebook'} />
              </Link>
              <Link href={'https://www.instagram.com/myassets_/'}>
                <Image src={'/images/instagram.svg'} width={40} height={41} alt={'instagram'} />
              </Link>
              <Link href={'https://twitter.com/MyAssets_'}>
                <Image src={'/images/tweeter.svg'} width={40} height={41} alt={'tweeter'} />
              </Link>
            </div>
            <p className={'mt-5 text-center'}>
              <Trans
                t={t}
                i18nKey={'MaintenanceInquiry'}
                components={{
                  Link: <Link href={'https://myassets.com/contact-us/'} className={'text-primary underline'} />
                }}
              />
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

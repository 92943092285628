import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2032%2040%22%3E%3Cpath%20d%3D%22M22.429%200H1.715C1.143%200%20.643.5.643%201.357v37.929c0%20.214.5.714%201.072.714h28.571c.571%200%201.071-.5%201.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071%2022.572%200%2022.429%200Z%22%20fill%3D%22%2350E3C2%22%2F%3E%3Cpath%20d%3D%22M4.264%2029.689v-8.532h2.924c.965%200%201.7.194%202.203.58.504.387.756.963.756%201.729%200%20.39-.105.742-.316%201.055-.211.312-.52.554-.926.726.46.125.817.36%201.07.706.251.346.377.761.377%201.245%200%20.801-.257%201.416-.77%201.846-.514.43-1.25.645-2.206.645H4.264Zm1.483-3.85v2.666h1.646c.465%200%20.828-.115%201.09-.346.262-.23.393-.55.393-.96%200-.887-.454-1.34-1.36-1.36h-1.77Zm0-1.09H7.2c.46%200%20.821-.104%201.08-.313.26-.21.39-.505.39-.888%200-.422-.12-.727-.36-.914-.24-.188-.614-.281-1.122-.281H5.747v2.396Zm8.01-3.592%202.46%206.54%202.455-6.54h1.916v8.532h-1.476v-2.813l.146-3.762-2.52%206.575h-1.06l-2.512-6.569.146%203.756v2.813h-1.476v-8.532h1.921Zm10.066%205.362v3.17H22.34v-8.532h3.264c.953%200%201.71.248%202.27.745.561.496.841%201.152.841%201.968%200%20.836-.274%201.487-.823%201.951-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527%200%20.93-.124%201.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424h-1.822v2.976Z%22%20fill%3D%22%23FFF%22%2F%3E%3Cpath%20fill%3D%22%23139679%22%20d%3D%22M22.786.143V8.57h8.429z%22%2F%3E%3C%2Fsvg%3E)
 */
export default function FileBmpIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 32 40'} width={size} height={size} {...props}>
      <path
        d={
          'M22.429 0H1.715C1.143 0 .643.5.643 1.357v37.929c0 .214.5.714 1.072.714h28.571c.571 0 1.071-.5 1.071-.714v-30c0-.5-.071-.643-.214-.786L22.857.214C22.716.071 22.572 0 22.429 0Z'
        }
        fill={'#50E3C2'}
      />
      <path
        d={
          'M4.264 29.689v-8.532h2.924c.965 0 1.7.194 2.203.58.504.387.756.963.756 1.729 0 .39-.105.742-.316 1.055-.211.312-.52.554-.926.726.46.125.817.36 1.07.706.251.346.377.761.377 1.245 0 .801-.257 1.416-.77 1.846-.514.43-1.25.645-2.206.645H4.264Zm1.483-3.85v2.666h1.646c.465 0 .828-.115 1.09-.346.262-.23.393-.55.393-.96 0-.887-.454-1.34-1.36-1.36h-1.77Zm0-1.09H7.2c.46 0 .821-.104 1.08-.313.26-.21.39-.505.39-.888 0-.422-.12-.727-.36-.914-.24-.188-.614-.281-1.122-.281H5.747v2.396Zm8.01-3.592 2.46 6.54 2.455-6.54h1.916v8.532h-1.476v-2.813l.146-3.762-2.52 6.575h-1.06l-2.512-6.569.146 3.756v2.813h-1.476v-8.532h1.921Zm10.066 5.362v3.17H22.34v-8.532h3.264c.953 0 1.71.248 2.27.745.561.496.841 1.152.841 1.968 0 .836-.274 1.487-.823 1.951-.549.465-1.317.698-2.305.698h-1.764Zm0-1.19h1.781c.527 0 .93-.124 1.207-.372s.416-.606.416-1.075c0-.461-.14-.83-.422-1.105-.281-.275-.668-.417-1.16-.424h-1.822v2.976Z'
        }
        fill={'#FFF'}
      />
      <path fill={'#139679'} d={'M22.786.143V8.57h8.429z'} />
    </svg>
  )
}

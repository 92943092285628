import { useMemo } from 'react'
import { Loader2Icon } from 'lucide-react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { Literature } from 'core/remodel/types/actions/literature'
import { TimeZone } from 'core/remodel/types/common'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { cn } from '@/utils/classnames'
import { startOfDayInTz } from '@/utils/formatter'
import { useAuthStore } from '@/store/authStore'
import { Button, FormDatePicker, FormInput, FormTextarea } from '@/components/base'
import UploadFiles from '@/components/UploadFiles'

export type LiteratureValues = Literature.CreateFields

const getDefaultValues = (timeZone: TimeZone): Partial<LiteratureValues> => ({
  title: '',
  date: startOfDayInTz(new Date(), timeZone),
  website: '',
  notes: '',
  file: []
})

interface LiteratureFormProps {
  assetId: string | null
  mode?: 'Create' | 'Edit'
  values?: LiteratureValues
  onSubmit: SubmitHandler<LiteratureValues>
  onCancel: () => void
}

export function LiteratureForm({ assetId, mode = 'Create', values, onCancel, onSubmit }: LiteratureFormProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const defaultValues = useMemo(() => getDefaultValues(preferences.timeZone), [preferences.timeZone])
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm<LiteratureValues>({ values, defaultValues })

  return (
    <form className={'flex h-[95vh] flex-col gap-y-4 bg-white md:min-h-0'} onSubmit={handleSubmit(onSubmit)}>
      <div className={'flex flex-col gap-x-4 overflow-y-auto px-4 pt-4 md:flex-row'}>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormInput control={control} name={'title'} label={t('Field.LiteratureTitle')} rules={{ required: true }} />
          <FormDatePicker
            control={control}
            name={'date'}
            label={t('Field.Date')}
            format={preferences.dateFormat}
            timeZone={preferences.timeZone}
            rules={{ required: true }}
          />
        </div>
        <div className={'flex flex-1 flex-col gap-y-4'}>
          <FormInput control={control} name={'website'} label={t('Field.LiteratureWebsite')} />
          <FormTextarea control={control} name={'notes'} label={t('Field.Notes')} />
          <UploadFiles assetId={assetId} control={control} name={'file'} />
        </div>
      </div>
      <fieldset className={'flex justify-end gap-2 p-4'} disabled={isSubmitting}>
        <Button className={'min-w-[130px]'} variant={'outline'} size={'md'} onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          className={'group relative min-w-[130px]'}
          variant={'solid'}
          size={'md'}
          type={'submit'}
          disabled={!isValid}
        >
          {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
          <span className={cn({ 'opacity-0': isSubmitting })}>{mode === 'Create' ? t('Create') : t('Update')}</span>
        </Button>
      </fieldset>
    </form>
  )
}

import { Loader2Icon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { validation } from '@/constants/validation'
import { cn } from '@/utils/classnames'
import { Button, FormInput, FormSelect, FormTextarea, SelectItem } from '@/components/base'

export enum LiveChatEnquiryType {
  TechnicalSupport = 'technicalSupport',
  BillingEnquiry = 'billingEnquiry',
  SecurityMyAccount = 'securityMyAccount',
  SubscriptionPlanChangeRenewal = 'subscriptionPlanChangeRenewal',
  DataPrivacy = 'dataPrivacy',
  NewFeatureRequest = 'newFeatureRequest',
  MarketingPartnershipEnquiry = 'marketingPartnershipEnquiry'
}

export type LiveChatValues = {
  enquiryType: LiveChatEnquiryType | string | null
  subject: string
  description: string
}

const defaultValues: LiveChatValues = {
  enquiryType: null,
  subject: '',
  description: ''
}

interface LiveChatFormProps {
  values?: LiveChatValues
  onSubmit: SubmitHandler<LiveChatValues>
}

export function LiveChatForm({ values, onSubmit }: LiveChatFormProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<LiveChatValues>({ defaultValues, values })
  const { t } = useTranslation()
  const enquiryTypeOptions = Object.values(LiveChatEnquiryType).map((value) => ({
    value,
    label: t(`liveChat:enquiryTypes.${value}`)
  }))

  const onSubmitHandler: SubmitHandler<LiveChatValues> = (data) => {
    const enquiryTypeLabels: Record<LiveChatEnquiryType, string> = {
      [LiveChatEnquiryType.TechnicalSupport]: 'Technical enquiry',
      [LiveChatEnquiryType.BillingEnquiry]: 'Pricing enquiry',
      [LiveChatEnquiryType.SecurityMyAccount]: 'Security / MyAccount enquiry',
      [LiveChatEnquiryType.SubscriptionPlanChangeRenewal]: 'Subscription enquiry',
      [LiveChatEnquiryType.DataPrivacy]: 'Data & Privacy',
      [LiveChatEnquiryType.NewFeatureRequest]: 'Feature request/information',
      [LiveChatEnquiryType.MarketingPartnershipEnquiry]: 'Marketing / Partnership enquiry'
    }
    onSubmit({
      ...data,
      enquiryType: enquiryTypeLabels[data.enquiryType as LiveChatEnquiryType]
    })
  }

  return (
    <form className={'live-chat-form relative flex flex-col gap-2'} onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <p className={'mb-1 text-xs/5 font-bold'}>{t('liveChat:EnquiryType') + ':'}</p>
        <FormSelect
          control={control}
          name={'enquiryType'}
          contentClassName={'z-[9999]'}
          placeholder={<span className={'font-light text-text-placeholder'}>{t('liveChat:EnquiryType')}</span>}
          rules={{ required: validation.required.message() }}
        >
          {enquiryTypeOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </FormSelect>
      </div>

      <div>
        <p className={'mb-1 text-xs/5 font-bold'}>{t('liveChat:Subject') + ':'}</p>
        <FormInput
          control={control}
          name={'subject'}
          type={'text'}
          placeholder={t('liveChat:Subject')}
          rules={{
            required: validation.required.message(),
            validate: {
              checkMinLength: (value) => value.trim().length >= 5 || validation.minLength.message(5),
              checkMaxLength: (value) => value.trim().length <= 100 || validation.maxLength.message(100)
            }
          }}
        />
      </div>

      <div>
        <p className={'mb-1 text-xs/5 font-bold'}>{t('liveChat:Description') + ':'}</p>
        <FormTextarea
          control={control}
          name={'description'}
          className={'text-black'}
          placeholder={t('liveChat:Description')}
          rules={{
            required: validation.required.message(),
            validate: {
              checkMinLength: (value) => (value && value.trim().length >= 10) || validation.minLength.message(10),
              checkMaxLength: (value) => (value && value.trim().length <= 1000) || validation.maxLength.message(1000)
            }
          }}
        />
      </div>

      <Button
        className={'group mt-6 w-20 self-end'}
        variant={'solid'}
        size={'md'}
        type={'submit'}
        disabled={isSubmitting}
      >
        {isSubmitting && <Loader2Icon className={'absolute animate-spin'} />}
        <span className={cn({ 'opacity-0': isSubmitting })}>{t('Submit')}</span>
      </Button>
    </form>
  )
}

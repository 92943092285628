import type { SVGBaseProps } from '@/types/common'

/**
 * @preview ![img](data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2040%2040%22%20fill%3D%22%23fff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m17.667%2027.667%2011.75-11.75-2.334-2.334L17.668%2023l-4.75-4.75-2.333%202.333zm2.333%209q-3.458%200-6.5-1.314-3.042-1.312-5.291-3.561-2.25-2.25-3.562-5.292-1.312-3.044-1.314-6.5%200-3.457%201.314-6.5t3.562-5.292Q10.455%205.96%2013.5%204.647q3.045-1.314%206.5-1.314t6.5%201.314%205.292%203.561q2.247%202.25%203.563%205.292%201.317%203.043%201.312%206.5t-1.314%206.5q-1.308%203.044-3.561%205.292T26.5%2035.355%2020%2036.667m0-3.334q5.583%200%209.459-3.875T33.334%2020t-3.876-9.458T20%206.667t-9.458%203.875T6.667%2020t3.875%209.458T20%2033.333%22%2F%3E%3C%2Fsvg%3E)
 */
export default function CheckCircleIcon({ size = 24, ...props }: SVGBaseProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 40 40'}
      fill={'currentColor'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'm17.667 27.667 11.75-11.75-2.334-2.334L17.668 23l-4.75-4.75-2.333 2.333zm2.333 9q-3.458 0-6.5-1.314-3.042-1.312-5.291-3.561-2.25-2.25-3.562-5.292-1.312-3.044-1.314-6.5 0-3.457 1.314-6.5t3.562-5.292Q10.455 5.96 13.5 4.647q3.045-1.314 6.5-1.314t6.5 1.314 5.292 3.561q2.247 2.25 3.563 5.292 1.317 3.043 1.312 6.5t-1.314 6.5q-1.308 3.044-3.561 5.292T26.5 35.355 20 36.667m0-3.334q5.583 0 9.459-3.875T33.334 20t-3.876-9.458T20 6.667t-9.458 3.875T6.667 20t3.875 9.458T20 33.333'
        }
      />
    </svg>
  )
}
